// Packages or third-party libraries
import React, { FC, useRef } from "react";
import { InputError, Text } from "@epignosis_llc/gnosis";
import { Controller, UseFormReturn } from "react-hook-form";
import { SerializedStyles } from "@emotion/react";

// Styles
import { courseCodeStyles, titleContainerStyles } from "./styles";

// Components
import { EditableText } from "@components/ReusableComponents";
import CategoryText from "./CategoryText";

// Utils, hooks
import { useApplyTranslations } from "@hooks";
import { useUIStore, useUnitStore } from "@stores";

// Other imports
import { Course } from "types/entities";
import { EditCourseData } from "@views/CourseEdit/api";
import AICourseTitleButton from "@components/CourseOverview/components/CourseHeader/AICourseTitleButton";
import { courseOptionsIds, TabKey } from "@views/CourseEdit/components/CourseOptions";

type TitleContainerProps = {
  category: Course["category"];
  categoryText: string;
  allCategoryParent: string[];
  name: Course["name"];
  code: Course["code"];
  form?: UseFormReturn<EditCourseData>;
  canEditOptions?: boolean;
  canEdit?: boolean;
  onSave?: () => void;
};

const TitleContainer: FC<TitleContainerProps> = ({
  category,
  categoryText,
  allCategoryParent,
  name,
  code,
  form,
  canEditOptions = false,
  canEdit = false,
  onSave,
}) => {
  const { isCourseHeaderSticky } = useUnitStore();
  const { setCourseOptionsState } = useUIStore();
  const { t } = useApplyTranslations();

  const titleRef = useRef<HTMLDivElement>(null);
  const showCategory = Boolean(category && !isCourseHeaderSticky);
  const nameFontSize = isCourseHeaderSticky ? "lg" : "4xl";
  const showCode = Boolean(code && !isCourseHeaderSticky);

  const handleCategoryClicked = (): void => handleInfoClicked(courseOptionsIds.categoryOptions);
  const handleCodeClicked = (): void => handleInfoClicked(courseOptionsIds.codeOptions);

  const handleInfoClicked = (scrollId: string): void => {
    if (!canEditOptions) return;

    setCourseOptionsState({ isOpen: true, activeTab: TabKey.Info, scrollId });
  };

  return (
    <div css={titleContainerStyles}>
      {showCategory && (
        <CategoryText
          onClick={handleCategoryClicked}
          categoryText={categoryText}
          allCategoryParent={allCategoryParent}
          canEditOptions={canEditOptions}
        />
      )}
      {form ? (
        <>
          <Controller
            name="name"
            control={form.control}
            render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => (
              <div className="editable-name-container">
                <EditableText
                  text={value ?? ""}
                  placeholder={t("general.title")}
                  canEdit={canEdit}
                  autoFocus={true}
                  fontSize={nameFontSize}
                  ref={titleRef}
                  onInput={(newContent): void => onChange(newContent ?? "")}
                  onBlur={(newContent): void => {
                    onChange(newContent ? newContent : name);
                    onSave?.();
                  }}
                />
                {canEdit && <AICourseTitleButton prompt={value ?? ""} contentRef={titleRef} />}
                {error && <InputError className="name-error">{error.message}</InputError>}
              </div>
            )}
          />
        </>
      ) : (
        <Text fontSize={nameFontSize} weight="700" className="name">
          {name}
        </Text>
      )}
      {showCode && (
        <Text
          fontSize="sm"
          data-testid="course-edit-code"
          css={(theme): SerializedStyles => courseCodeStyles(theme, { canEditOptions })}
          onClick={handleCodeClicked}
        >
          {code}
        </Text>
      )}
    </div>
  );
};

export default TitleContainer;
