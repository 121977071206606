import { MyUnit } from "./Unit";
import { Price, Image, RichText, CustomFieldWithValue } from "./Common";
import { Bundle } from "./Catalog";
import { CertificateDuration, CertificateReassignWhen } from "./Certificates";

export type Course = {
  id: number;
  name: string;
  category: CourseCategory | null;
  description: RichText | null;
  code: string | null;
  status: CourseStatus;
  creation_date: string;
  last_updated_on: string | null;
  price: Price | null;
  policies?: CoursePolicies;
  discounted_price: Price | null;
  intro_video: {
    url: string;
    type: "file" | "youtube";
    status: CourseIntroVideoStatus;
  } | null;
  cover_image: Image | null;
  capacity?: number | null;
  level: number | null;
  is_hidden_from_catalog?: boolean;
  is_locked?: boolean;
  custom_fields: CustomFieldWithValue[] | null;
  rating: CourseRating | null;
  rules: CourseRules;
  progress: CourseProgress | null;
  certificate?: Certificate | null;
  starts_at: string | null;
  start_datetime?: string;
  expires_at: string | null;
  time_limit: number | null;
  user_access_expires_at: string | null;
  availability: { is_available: boolean; reason: AvailabilityReason | null } | null;
  enrolled: boolean | null;
  role_in_course?: "instructor" | "learner";
  retain_access_after_completion: boolean;
  public_key: string | null; // This is for public courses
  bundles: Bundle[] | null;
  is_prerequisite: boolean;
  remaining_licenses: number | null;
  has_open_sesame_changes?: boolean;
  mapped_course_id?: number | null;
  ilt_session_start_datetime?: string | null;
  has_ilt_unit?: boolean;
};

export type CourseLight = {
  id: Course["id"];
  name: Course["name"];
};

type Certificate = {
  id: number;
  duration_type: CertificateDuration;
  duration: number | null;
  expiration_month: number | null;
  expiration_day: number | null;
  reassign_when: CertificateReassignWhen | null;
  name?: string;
};

export type AvailabilityReason =
  | "pending-prerequisites"
  | "not-started"
  | "expired"
  | "unlocks-on-higher-level"
  | "cancelled";

export type CourseProgress = {
  certificate: CourseCertificate | null;
  completion_date: string | null;
  completion_percentage: number;
  completion_status: "not_attempted" | "incomplete" | "completed" | "failed";
  last_viewed_unit_id: number | null;
  score: number | null;
  total_time: number | null;
  next_course_id?: number;
};

export type CourseCertificate = {
  archived: boolean;
  course_code: string;
  course_id: number;
  course_name: string;
  expires_at: string | null;
  issued_at: string | null;
  url: string;
};

type CourseStatus = "active" | "inactive" | "archived";

export type CoursePolicies = {
  can_delete: boolean;
  can_clone: boolean;
  can_clone_policy: boolean;
  can_clone_plan: boolean;
  can_edit: boolean;
  can_view_reports: boolean;
  can_view: boolean;
  can_update_content: boolean;
  can_update_rules: boolean;
  can_add_unit: boolean;
  can_view_units: boolean;
  can_view_users: boolean;
  can_publish: boolean;
  can_upload_files: boolean;
  can_preview_content: boolean;
  can_view_course_files: boolean;
  can_view_report_units: boolean; // This is for the tab units on course page
  can_update_price: boolean;
  can_update_level: boolean;
  can_update_locked: boolean;
  can_invite_public_course: boolean;
  can_view_rules: boolean;
  can_enroll_users: boolean;
  can_share: boolean;
  can_create_category: boolean;
  can_mass_unenroll_users: boolean;
  can_mass_reset_progress: boolean;
  can_mass_sync_progress: boolean;
  can_mass_enroll_users: boolean;
  can_generate_image_with_ai: boolean;
};

export type CourseCategory = {
  id: number;
  parent_id: number | null;
  name: string;
  price: Price | null;
  courses_count: number | null;
  children: [];
  discounted_price?: Price | null;
  parent_name: string | null;
};

export type CourseRating = {
  average: number;
  user: number;
};

type CourseRules = {
  traversal: "any" | "sequential";
  prerequisites: CoursePrerequisite[];
  completion: CourseCompletionRule[];
  score_calculation: ScoreCalculationRule[];
};

export type CoursePrerequisite = {
  id: number;
  name: string;
  rule_set: number;
  available_in_catalog: boolean;
  category: CourseCategory | null;
  // Completion status is missing when the user is not enrolled to the course
  completion_status?: CourseProgress["completion_status"] | null;
};

export type CourseCompletionRule = {
  rule_type: "all_units" | "certain_units" | "unit_percentage" | "passed_test";
  unit_percentage: number | null;
  complete_units: UnitPrerequisite[] | null;
};

export enum ScoreRule {
  AllTestsAndAssignments = "all_tests_and_assignments",
  TestsOnly = "tests_only",
  SpecificUnits = "specific_units",
}

type ScoreCalculationRule = {
  rule_type: ScoreRule;
  score_units: ScoreUnit[] | null;
};

type UnitPrerequisite = Pick<MyUnit, "id" | "name">;
type ScoreUnit = { unit: UnitPrerequisite; weight: number };

export type ValidCourseSortingField =
  | "name"
  | "-name"
  | "code"
  | "-code"
  | "status"
  | "-status"
  | "creation_date"
  | "-creation_date"
  | "last_updated_on"
  | "-last_updated_on"
  | "rating"
  | "-rating"
  | "enroll_date"
  | "-enroll_date";

export type CatalogCoursesFilters = {
  category: string[];
  bundle: {
    id: number;
    type: "category" | "group";
  } | null;
};

export type EnrollmentPostData = {
  id: number;
  type: "course" | "category" | "group";
};

export type CoursesFilters = {
  branch_id: string | null;
  group_id: string | null;
  status: "active" | "inactive" | null;
};

export type CourseMicrostats = {
  completion_rate: number;
  completed_courses: number;
  courses_in_progress: number;
  courses_not_passed: number;
  courses_not_started: number;
  completed_learners: number;
  learners_in_progress: number;
  learners_not_passed: number;
  learners_not_started: number;
  training_time: number;
};

export enum CourseIntroVideoStatus {
  Ready = "ready",
  Processing = "processing",
  Failed = "failed",
}

export type OtherProvidersCourses = Course & {
  has_course: boolean | null;
};

export type CourseProviders = {
  id: number;
  name: string;
  number_of_courses: number;
};

export type OtherProvidersCourse = OtherProvidersCourses & {
  provider: CourseProviders;
};

export type OtherProviderCoursePriceEstimation = {
  discount: Price;
  discounted_price: Price;
  price: Price;
};

export type OtherProviderCourseSubscription = {
  price: number;
  return_path: string;
  cancel_path: string;
  licenses: number;
};
