import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import {
  type AiJobsStatusRes,
  type AssignSkillsToUsersPayload,
  ExtResource,
  JobRoleCandidatesRes,
  JobRoleRes,
  JobRolesSearchRes,
  LinkCoursesToSkillsPayload,
  type NewSkill,
  RecommendSkillsToUsersPayload,
  Skill,
  SkillAssessmentQuestionData,
  SkillAssessmentQuestionSubmissionPayload,
  SkillAssessmentQuestionSubmitRes,
  SkillAssessmentRes,
  SkillAssessmentResultsRes,
  SkillAssessmentStartRes,
  SkillCourseRes,
  SkillExtResourcesRes,
  SkillQuestionRes,
  SkillQuestionsRes,
  SkillRes,
  SkillsRecommendationRes,
  SkillsRes,
  SkillsSettings,
  SkillsSettingsRes,
  SkillsUpgradesRes,
  UsersForSkillsRecommendationRes,
  UserWithProgressRes,
  UsersWithSkillStatsRes,
  SkillNameSuggestionsRes,
  SingleUserWithSkillStatsRes,
  type JobRoleSkillsPayload,
  AIJobsRes,
} from "./types";
import { CountMassActionResponse, FilesRes, MassActionResultResponse } from "types/responses";
import { convertNumberToPercentage } from "./utils";

export const createSkill = async (force: boolean, skillData: NewSkill): Promise<SkillRes> => {
  const res = await HttpClient.post(ENDPOINTS.skills.newSkill.create(force), skillData);
  return res.data;
};

export const createSkillResource = async (
  skillId: number,
  data: Pick<ExtResource, "name" | "url">,
): Promise<void> => {
  const res = await HttpClient.post(ENDPOINTS.skills.skillResources(skillId.toString()), data);
  return res.data;
};

export const deleteSkillResource = async (skillId: number, resourceId: number): Promise<void> => {
  const res = await HttpClient.delete(
    ENDPOINTS.skills.skillResource(skillId.toString(), resourceId.toString()),
  );
  return res.data;
};

export const updateSkillResource = async (
  skillId: number,
  resource: ExtResource,
): Promise<void> => {
  const res = await HttpClient.patch(
    ENDPOINTS.skills.skillResource(skillId.toString(), resource.id.toString()),
    resource,
  );
  return res.data;
};

export const suggestSkillResources = async (
  skillId: number,
  signal: AbortSignal,
): Promise<SkillExtResourcesRes> => {
  const res = await HttpClient.get(ENDPOINTS.skills.suggestSkillResources(skillId.toString()), {
    signal,
  });
  return res.data;
};

export const getSkillResources = async (skillId: number): Promise<SkillExtResourcesRes> => {
  const res = await HttpClient.get(ENDPOINTS.skills.skillResources(skillId.toString()));
  return res.data;
};

export const generateSkill = async (force: boolean, skillData: NewSkill): Promise<SkillRes> => {
  const res = await HttpClient.post(ENDPOINTS.skills.newSkill.generate(force), skillData);
  return res.data;
};

export const getSkillNameSuggestions = async (query: string): Promise<SkillNameSuggestionsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.newSkill.suggestNames}${query}`);
  return res.data;
};

export const getSkill = async (skillId: string): Promise<SkillRes> => {
  const res = await HttpClient.get(ENDPOINTS.skills.skill(skillId));
  return res.data;
};

export const getSkillAiJobStatus = async (
  skillId: string,
  signal: AbortSignal,
): Promise<AIJobsRes> => {
  const res = await HttpClient.get(ENDPOINTS.skills.skillAiJobsStatus(skillId), { signal });
  return res.data;
};

export const updateSkill = async (skill: Skill): Promise<SkillRes> => {
  const res = await HttpClient.patch(ENDPOINTS.skills.skill(skill.id.toString()), skill);
  return res.data;
};

export const deleteSkill = async (skillId: number): Promise<void> => {
  const res = await HttpClient.delete(ENDPOINTS.skills.skill(skillId.toString()));
  return res.data;
};

export const postSkillImage = async (file: File, skillId: string): Promise<FilesRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("skill_image", file);

  const res = await HttpClient.post(ENDPOINTS.skills.image(skillId), bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const deleteSkillImage = async (skillId: string): Promise<FilesRes> => {
  const res = await HttpClient.delete(ENDPOINTS.skills.image(skillId));

  return res.data;
};

export const getSkills = async (queryStr = ""): Promise<SkillsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.skills}${queryStr}`);
  return res.data;
};

export const getUsersWithSkillStats = async (queryStr = ""): Promise<UsersWithSkillStatsRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.skills.usersWithTotalProgressAndRecommendations}${queryStr}`,
  );
  return res.data;
};

export const getSingleUserWithStats = async (
  userId: string,
): Promise<SingleUserWithSkillStatsRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.skills.singleUserWithTotalProgressAndRecommendations(userId)}`,
  );
  return res.data;
};

export const getUsersSkillProgression = async (
  skillId: number,
  queryStr = "",
): Promise<UserWithProgressRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.usersWithSkill(skillId)}${queryStr}`);
  return res.data;
};

export const getUserSkills = async (userId: string, queryStr = ""): Promise<SkillsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.users.skills(userId)}${queryStr}`);
  return res.data;
};

export const getUserSkill = async (userId: string, skillId: number): Promise<SkillRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.users.skill(userId, skillId)}`);
  return res.data;
};

export const getMySkills = async (queryStr = ""): Promise<SkillsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.user.mySkills}${queryStr}`);
  return res.data;
};

export const getMySkill = async (skillId: string): Promise<SkillRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.user.skill(skillId)}`);
  return res.data;
};

export const massAssignSkills = async (
  data: AssignSkillsToUsersPayload,
): Promise<MassActionResultResponse> => {
  const res = await HttpClient.put(`${ENDPOINTS.skills.assign}`, data);
  return res.data;
};

export const massAssignSkillsCount = async (
  data: AssignSkillsToUsersPayload,
): Promise<CountMassActionResponse> => {
  const res = await HttpClient.post(`${ENDPOINTS.skills.assignCount}`, data);
  return res.data;
};

export const massUnassignSkills = async (
  data: AssignSkillsToUsersPayload,
): Promise<MassActionResultResponse> => {
  const res = await HttpClient.put(`${ENDPOINTS.skills.unassign}`, data);
  return res.data;
};

export const massUnassignSkillsCount = async (
  data: AssignSkillsToUsersPayload,
): Promise<CountMassActionResponse> => {
  const res = await HttpClient.post(`${ENDPOINTS.skills.unassignCount}`, data);
  return res.data;
};

export const assignSkillLevelToUser = async (
  userId: number,
  skillId: number,
  levelId: number,
): Promise<void> => {
  const res = await HttpClient.put(
    `${ENDPOINTS.skills.users.changeLevel(userId, skillId, levelId)}`,
  );
  return res.data;
};

export const setUserSkillLevel = async (
  userId: string,
  skillId: number,
  levelId: string,
): Promise<void> => {
  const data = {
    level_id: levelId,
  };

  const res = await HttpClient.post(`${ENDPOINTS.skills.users.skill(userId, skillId)}`, data);
  return res.data;
};

export const assignSkillLevel = async (
  userId: string,
  skillId: number,
  levelId: string,
): Promise<void> => {
  const data = {
    level_id: levelId,
  };

  const res = await HttpClient.post(`${ENDPOINTS.skills.users.skill(userId, skillId)}`, data);
  return res.data;
};

export const searchUsersForSkillsRecommendation = async (
  queryStr = "",
): Promise<UsersForSkillsRecommendationRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.recommendations.users}${queryStr}`);
  return res.data;
};

export const requestSkillUpgrade = async (skillId: string): Promise<MassActionResultResponse> => {
  const res = await HttpClient.post(`${ENDPOINTS.skills.upgrades.request(skillId)}`);
  return res.data;
};

export const massRecommendSkills = async (
  payload: RecommendSkillsToUsersPayload,
): Promise<MassActionResultResponse> => {
  const res = await HttpClient.post(`${ENDPOINTS.skills.recommendations.recommend}`, payload);
  return res.data;
};

export const getSkillRecommendationsForUser = async (
  userId: string,
  queryStr = "",
): Promise<SkillsRecommendationRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.skills.users.skillRecommendations(userId)}${queryStr}`,
  );
  return res.data;
};

export const getSkillUpgradesForUser = async (
  userId: string,
  queryStr = "",
): Promise<SkillsUpgradesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.users.skillUpgrades(userId)}${queryStr}`);
  return res.data;
};

export const rejectRecommendedSkills = async (
  payload: RecommendSkillsToUsersPayload,
): Promise<void> => {
  const res = await HttpClient.put(`${ENDPOINTS.skills.recommendations.reject}`, payload);
  return res.data;
};

export const approveRecommendedSkills = async (
  payload: RecommendSkillsToUsersPayload,
): Promise<void> => {
  const res = await HttpClient.put(`${ENDPOINTS.skills.recommendations.approve}`, payload);
  return res.data;
};

export const rejectSkillUpgrade = async (id: string): Promise<void> => {
  const res = await HttpClient.put(`${ENDPOINTS.skills.upgrades.reject(id)}`);
  return res.data;
};

export const approveSkillUpgrade = async (id: string): Promise<void> => {
  const res = await HttpClient.put(`${ENDPOINTS.skills.upgrades.approve(id)}`);
  return res.data;
};

export const getSkillCourses = async (skillId: string, queryStr = ""): Promise<SkillCourseRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.skillCourses(skillId)}${queryStr}`);
  return res.data;
};

export const getAvailableSkillCourses = async (
  skillId: string,
  queryStr = "",
): Promise<SkillCourseRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.availableSkillCourses(skillId)}${queryStr}`);
  return res.data;
};

export const assignSkillCoursesByAI = async (skillId: string): Promise<SkillCourseRes> => {
  const res = await HttpClient.put(ENDPOINTS.skills.assignSkillCoursesByAI(skillId));
  return res.data;
};

export const massLinkCoursesToSkill = async (
  payload: LinkCoursesToSkillsPayload,
): Promise<MassActionResultResponse> => {
  const res = await HttpClient.put(`${ENDPOINTS.skills.skillCoursesLink}`, payload);
  return res.data;
};

export const massUnlinkCoursesFromSkill = async (
  payload: LinkCoursesToSkillsPayload,
): Promise<MassActionResultResponse> => {
  const res = await HttpClient.put(`${ENDPOINTS.skills.skillCoursesUnlink}`, payload);
  return res.data;
};

export const getJobRoleSearch = async (queryStr = ""): Promise<JobRolesSearchRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.jobRoleSearch}${queryStr}`);
  return res.data;
};

export const getJobRoleDetails = async (
  jobRoleString: string,
  isState = true,
  signal: AbortSignal | null = null,
): Promise<JobRoleRes> => {
  const config = signal ? { signal } : undefined;
  const res = await HttpClient.get(ENDPOINTS.skills.jobRoleDetails(jobRoleString, isState), config);
  return res.data;
};

export const getJobCandidates = async (
  jobRoleString: string,
  searchQuery?: string,
  isState = true,
  payload: JobRoleSkillsPayload | null = null, // TODO
  signal: AbortSignal | null = null,
): Promise<JobRoleCandidatesRes> => {
  const config = signal ? { signal } : undefined;
  const res = await HttpClient.post(
    ENDPOINTS.skills.jobCandidates(jobRoleString, isState, searchQuery),
    payload,
    config,
  );
  return res.data;
};

export const getJobRoleAiJobStatus = async (
  jobRoleString: string,
  signal: AbortSignal,
): Promise<AiJobsStatusRes> => {
  const res = await HttpClient.get(ENDPOINTS.skills.jobRoleAiJobsStatus(jobRoleString), { signal });
  return res.data;
};

export const getSkillsSettings = async (): Promise<SkillsSettingsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.skillsSettings}`);
  return res.data;
};

export const saveSkillsSettings = async (
  skillSettingsUpdate: Partial<SkillsSettings>,
): Promise<SkillsSettingsRes> => {
  // Covert passmark to a number in the range [0, 1]
  let passMark = skillSettingsUpdate.assessments?.pass_mark ?? 0;
  if (Number.isInteger(passMark) && passMark >= 0 && passMark <= 100) {
    passMark = convertNumberToPercentage(passMark);
  } else {
    passMark = 0;
  }

  const res = await HttpClient.patch(ENDPOINTS.skills.skillsSettings, {
    ...skillSettingsUpdate,
    assessments: {
      ...skillSettingsUpdate.assessments,
      pass_mark: passMark,
    },
  });
  return res.data;
};

export const getAssessment = async (skillId: string): Promise<SkillAssessmentRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.assessments.assessment(skillId)}`);
  return res.data;
};

export const startAssessment = async (skillId: string): Promise<SkillAssessmentStartRes> => {
  const res = await HttpClient.post(`${ENDPOINTS.skills.assessments.start(skillId)}`);
  return res.data;
};

export const resetAssessment = async (skillId: number): Promise<void> => {
  await HttpClient.post(`${ENDPOINTS.skills.assessments.reset(skillId)}`);
};

export const getAssessmentQuestions = async (
  skillId: string,
  levelId: string,
  queryStr = "",
): Promise<SkillQuestionsRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.skills.assessments.questions(skillId, levelId)}${queryStr}`,
  );
  return res.data;
};

export const getAssessmentQuestion = async (
  skillId: string,
  questionNumber: string,
): Promise<SkillQuestionRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.skills.assessments.getQuestion(skillId, questionNumber)}`,
  );
  return res.data;
};

export const submitAssessmentQuestion = async (
  skillId: string,
  questionIndex: number,
  payload: SkillAssessmentQuestionSubmissionPayload,
): Promise<SkillAssessmentQuestionSubmitRes> => {
  const res = await HttpClient.post(
    `${ENDPOINTS.skills.assessments.submitQuestion(skillId, questionIndex)}`,
    payload,
  );
  return res.data;
};

export const completeAssessment = async (skillId: string): Promise<void> => {
  const res = await HttpClient.post(`${ENDPOINTS.skills.assessments.complete(skillId)}`);
  return res.data;
};

export const getAssessmentResults = async (skillId: string): Promise<SkillAssessmentResultsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.skills.assessments.results(skillId)}`);
  return res.data;
};

export const createAssessmentQuestion = async (
  skillId: string,
  levelId: string,
  payload: SkillAssessmentQuestionData,
): Promise<SkillQuestionRes> => {
  const res = await HttpClient.post(
    `${ENDPOINTS.skills.assessments.newQuestion.create(skillId, levelId)}`,
    payload,
  );
  return res.data;
};

export const updateAssessmentQuestion = async (
  skillId: string,
  questionId: string,
  data: SkillAssessmentQuestionData,
): Promise<void> => {
  const res = await HttpClient.patch(
    `${ENDPOINTS.skills.assessments.updateQuestion(skillId, questionId)}`,
    data,
  );
  return res.data;
};

export const reorderAssessmentQuestions = async (
  skillId: string,
  question_ids: string[],
): Promise<void> => {
  await HttpClient.put(ENDPOINTS.skills.assessments.order(skillId), {
    question_ids,
  });
};

export const deleteAssessmentQuestion = async (questionId: string): Promise<void> => {
  const res = await HttpClient.delete(`${ENDPOINTS.skills.questions(questionId)}`);
  return res.data;
};

export const generateAssessmentQuestion = async (
  skillId: string,
  levelId: string,
  questionsCount: number,
): Promise<SkillQuestionRes> => {
  const res = await HttpClient.post(
    `${ENDPOINTS.skills.assessments.newQuestion.generate(skillId, levelId)}`,
    { count: questionsCount },
  );
  return res.data;
};

export const generateSkillImage = async (skillId: string, aiPrompt: string): Promise<void> => {
  const res = await HttpClient.post(`${ENDPOINTS.skills.generateImage(skillId)}`, {
    prompt: aiPrompt,
  });
  return res.data;
};

export const getCourseAIJobs = async (
  courseId: string,
  signal: AbortSignal,
): Promise<AIJobsRes> => {
  const res = await HttpClient.get(ENDPOINTS.ai.jobsForCourse(courseId), { signal });
  return res.data;
};

export const getUnitAIJobs = async (unitId: string, signal: AbortSignal): Promise<AIJobsRes> => {
  const res = await HttpClient.get(ENDPOINTS.ai.jobsForUnit(unitId), { signal });
  return res.data;
};

export const dismissAdminOnboarding = async (): Promise<void> => {
  const res = await HttpClient.put(ENDPOINTS.skills.adminOnboarding.dismiss);
  return res.data;
};
