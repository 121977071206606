import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const dialogBoxStyles = ({ colors, dialogBox }) => css`
  // Mobile first Version
  position: fixed;
  max-width: 100%;
  width: 100%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  padding: 1rem;
  background: ${colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 0.375rem 0 ${dialogBox.boxShadow};
  z-index: 1000;

  /* Hide in TEST RESULTS PRINT */
  @media print {
    display: none;
  }

  // Title SVG icon should be hidden in mobile version.
  .dialog-box-title svg {
    display: none;
  }

  // Desktop version
  ${mq["md"]} {
    max-width: 24.0625rem;
    inset-inline-start: auto;
    inset-inline-end: 1.875rem;
    bottom: 1.875rem;
    background: ${dialogBox.background};

    // Title SVG icon should be visible in desktop version.
    .dialog-box-title svg {
      display: inline-block;
    }
  }

  // Mobile first version.
  .dialog-box-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    // Desktop version
    ${mq["md"]} {
      justify-content: space-between;
    }

    .dialog-box-title {
      display: flex;
      align-items: center;

      .prefix-icon {
        flex-shrink: 0;
      }
    }

    // Mobile first version.
    .close-btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.125rem;
      height: 3.125rem;
      top: -3.75rem;
      inset-inline-start: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      background: ${colors.black};
      cursor: pointer;

      svg {
        path {
          fill: ${colors.white};
        }
      }

      // Desktop version
      ${mq["md"]} {
        display: inline-block;
        position: relative;
        min-width: inherit;
        width: auto;
        height: auto;
        top: auto;
        inset-inline-start: auto;
        transform: translateX(0);
        padding: 0;
        border-radius: 0;
        background: none;

        svg {
          path {
            fill: ${colors.black};
          }
        }
      }
    }
  }

  .action-buttons {
    display: flex;
    margin-top: 1rem;
  }
`;

export const overlayStyles = ({ dialogBox }) => css`
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background-color: ${dialogBox.overlayBackground};
  z-index: 999;

  ${mq["md"]} {
    display: none;
  }

  /* Hide in TEST RESULTS PRINT */
  @media print {
    display: none;
  }
`;
