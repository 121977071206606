import axios from "axios";
import { convertBase64ToBlob, convertBytesToSizeUnit } from "@utils/helpers";
import { t } from "@utils/i18n";
import permissions from "@utils/permissions";
import authService from "@utils/services/AuthService";
import { useConfigurationStore } from "@stores";
import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";
import {
  DomainSettings,
  UploadValidation,
  FileValidationsObj,
  GroupedMimeTypes,
} from "types/entities";

type DownloadFileArgs = {
  fileUrl: string;
  fileName: string;
  isDirectDownload?: boolean; // This is used to download the file directly from the URL instead of making a fetch request.
  fileId?: number;
};

const handleDirectDownload = ({
  fileUrl,
  fileName,
  openInNewPage,
}: {
  fileUrl: string;
  fileName: string;
  openInNewPage?: boolean;
}): void => {
  const link = createLinkElement();
  if (openInNewPage) {
    link.target = "_blank";
  }

  link.href = fileUrl;
  link.download = fileName;
  link.click();
};

export const downloadFile = async ({
  fileUrl,
  fileName,
  isDirectDownload = false,
  fileId,
}: DownloadFileArgs): Promise<void> => {
  const { canAccessProfile } = permissions.profilePermissions;
  const canViewProfile = canAccessProfile();
  const defaultRole = authService.getDefaultRole();
  const isLearner = defaultRole === "learner";
  const fileOrigin = new URL(fileUrl).origin;
  const shouldDownloadWithCors = fileOrigin !== location.origin;

  // Even if the actual action could not be performed, we still want to register the download when the user tries to do so
  const shouldRegisterDownloadToTimeline = isLearner && canViewProfile && fileId;
  if (shouldRegisterDownloadToTimeline) {
    await registerFileDownload(fileId);
  }

  if (isDirectDownload || !shouldDownloadWithCors) {
    handleDirectDownload({ fileUrl, fileName });
    return;
  }

  const corsEnabled = await isCorsEnabled(fileUrl);

  if (corsEnabled) {
    await getFile(fileUrl);
    return;
  }

  // Force the file to open in a new tab when encountering CORS issues
  handleDirectDownload({ fileUrl, fileName, openInNewPage: true });
};

const createLinkElement = (): HTMLAnchorElement => {
  const link = document.createElement("a");
  link.rel = "noopener";

  return link;
};

const isCorsEnabled = async (url: string): Promise<boolean> => {
  try {
    const response = await axios({
      url: url,
      method: "HEAD",
    });

    return response?.status >= 200 && response?.status <= 299;
  } catch {
    return false;
  }
};

const registerFileDownload = async (fileId: number): Promise<void> => {
  try {
    await HttpClient.post(ENDPOINTS.files.register(fileId));
  } catch (err) {
    return;
  }
};

const getFile = async (fileUrl: string): Promise<void> => {
  const filenameFromUrl = getFilenameFromUrl(fileUrl);

  const response = await axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Disposition": "attachment",
      filename: encodeURIComponent(filenameFromUrl),
    },
  });

  if (response.status === 200) {
    const link = createLinkElement();
    link.href = window.URL.createObjectURL(new Blob([response.data]));
    link.setAttribute("download", filenameFromUrl);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const getFileFromBlobUrl = async (
  blobUrl: string,
  fileName: string,
  fileOptions?: FilePropertyBag,
): Promise<File> => {
  return await axios.get(blobUrl, { responseType: "blob" }).then((response) => {
    return new File([response.data], fileName, fileOptions);
  });
};

export const getFileValidations = (
  paths: string | string[],
): UploadValidation | UploadValidation[] | null => {
  const { getState } = useConfigurationStore;
  const domainSettings = getState().domainSettings;
  let validations: UploadValidation | UploadValidation[] | null = null;

  if (Array.isArray(paths)) {
    validations = paths.reduce((array, path) => {
      array.push((domainSettings as DomainSettings).upload_limits[path]);
      return array;
    }, [] as UploadValidation[]);
  }

  if (typeof paths === "string") {
    validations = (domainSettings as DomainSettings).upload_limits[paths];
  }

  return validations;
};

export const buildFileValidations = (
  validations: UploadValidation[] | UploadValidation | null,
): FileValidationsObj => {
  let result: FileValidationsObj | null = null;

  if (Array.isArray(validations)) {
    // Is array of objects
    const obj = validations
      .flatMap(({ mime_types, size_limit }) =>
        mime_types.map((mimeType) => ({ [mimeType]: size_limit })),
      )
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as FileValidationsObj;

    result = obj;
  } else {
    // Is object
    const { mime_types: mimeTypes, size_limit: sizeLimit } = validations as UploadValidation;
    const obj = mimeTypes
      .map((mimeType) => ({ [mimeType]: sizeLimit }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as FileValidationsObj;

    result = obj;
  }

  return result;
};

// Get filename from given url and decode any special characters that might has
export const getFilenameFromUrl = (url: string): string => {
  return decodeURI(new URL(url).pathname.split("/").pop() || "");
};

export const validateSelectedFiles = ({
  files,
  acceptedFileExtensions = [],
  mimeTypeAndFilesizeValidations,
  selectedFiles,
  maxFiles,
  onFileError,
}: {
  files: FileList;
  acceptedFileExtensions?: string[];
  onFileError: (error: string) => void;
  mimeTypeAndFilesizeValidations: FileValidationsObj;
  selectedFiles: File[];
  maxFiles: number;
}): File[] => {
  // Save current files number
  let currentFilesNumber = selectedFiles.length;
  let filesToAdd: File[] = [];
  const acceptedFileTypes = Object.keys(mimeTypeAndFilesizeValidations);

  Array.from(files).forEach((file): boolean => {
    // Exceeds files limit
    if (currentFilesNumber >= maxFiles) {
      onFileError(t("validationFiles.filesAllowedToUpload", { count: maxFiles }));

      return true;
    }

    // Validate with file mime type
    // Check if file has an accepted mime type
    if (!acceptedFileExtensions.length && !acceptedFileTypes.includes(file.type)) {
      onFileError(`${t("notifications.fileTypeNotSupported")}`);

      return true;
    }

    // Validate with file extension
    // Check if file has an accepted extension or an accepted mime type
    if (acceptedFileExtensions.length) {
      const filename: string = file.name;
      const fileExtension = filename.split(".").pop()?.toLowerCase() as string;
      const hasAcceptedExtension = acceptedFileExtensions.includes(fileExtension);
      const hasAcceptedMimeType = acceptedFileTypes.includes(file.type);

      if (!hasAcceptedExtension && !hasAcceptedMimeType) {
        onFileError(`${t("notifications.fileTypeNotSupported")}`);

        return true;
      }
    }

    // File exceeds file size limit
    if (file.size > mimeTypeAndFilesizeValidations[file.type]) {
      onFileError(
        t("validationFiles.fileSizeExceedLimit", {
          number: convertBytesToSizeUnit(mimeTypeAndFilesizeValidations[file.type], "MB"),
          type: "MB",
        }),
      );

      return true;
    }

    currentFilesNumber++;
    filesToAdd = [...filesToAdd, file] as File[];

    return true;
  });

  return filesToAdd;
};

export const arrayToFileList = (files: File[]): FileList => {
  const fileList = {};

  files.forEach((file, index) => {
    fileList[index] = file;
  });

  return fileList as FileList;
};

export function buildImageFile(
  croppedCanvas: HTMLCanvasElement,
  imageFile: File | null,
): File | null {
  if (!imageFile) return null;

  const name = imageFile.name.slice(0, imageFile.name.lastIndexOf("."));
  const extension = imageFile.name.split(".").pop();
  const fileType = imageFile.type ? imageFile.type : "image/png";

  const newFileData = {
    name,
    extension: fileType === "image/gif" ? "png" : extension,
    type: fileType === "image/gif" ? "image/png" : fileType,
  };

  const dataUrl = croppedCanvas.toDataURL(newFileData.type);
  const blob = convertBase64ToBlob(dataUrl);

  const imgFile = new File([blob], `${newFileData.name}.${newFileData.extension}`, {
    type: newFileData.type,
  });

  return imgFile;
}

/**
 * Group mime types based on size limit
 * @returns example: {10485760: [mimeTypes], 104857600: [mimeTypes], 314572800: [mimeTypes])}
 * */
//
export const getGroupedMimeTypes = (
  mimeTypeAndFilesizeValidations: FileValidationsObj,
): GroupedMimeTypes => {
  return Object.entries(mimeTypeAndFilesizeValidations).reduce((mimeTypeGroups, [key, value]) => {
    // Initialize the group if it doesn't exist
    if (!mimeTypeGroups[value]) {
      mimeTypeGroups[value] = [];
    }
    // Add the key to the appropriate group
    mimeTypeGroups[value].push(key);

    return mimeTypeGroups;
  }, {});
};

/**
 * Adds a dot prefix to each string in the array.
 * @param arr - Array of strings to prefix with a dot.
 * @returns A new array with each string prefixed by a dot.
 */

export const addDotPrefix = (arr: string[]): string[] => {
  return arr.map((item) => `.${item}`);
};
