import { Timeout } from "ahooks/lib/useRequest/src/types";
import { useEffect, useState } from "react";
import { CountdownTimerResults, ICountdownTimerParams } from "./types";

/**
 * Create a configurable countdown timer.
 * https://medium.com/@bsalwiczek/building-timer-in-react-its-not-as-simple-as-you-may-think-80e5f2648f9b
 */

const useCountdownTimer = ({
  timer,
  canStart = false,
  onExpire,
}: ICountdownTimerParams): CountdownTimerResults => {
  const [remainingTime, setRemainingTime] = useState<number>(timer);
  const [referenceTime, setReferenceTime] = useState<number>(Date.now());

  // Reset remainingTime on timer change
  useEffect(() => {
    if (remainingTime !== timer) setRemainingTime(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  useEffect(() => {
    let timeOutId: Timeout | null = null;

    if (remainingTime && canStart) {
      const countDownUntilZero = (): void => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 0) return 0;

          // Calculate the actual elapsed time (interval) since the last update (referenceTime)
          const now = Date.now();
          const interval = now - referenceTime;
          setReferenceTime(now);

          // Calculate the new remaining time by subtracting the interval (converted to seconds) from the previous remaining time
          // This approach adjusts the remaining time based on the actual elapsed time instead of assuming the callback function runs exactly every second
          const newRemainingTime = prevTime - interval;
          return newRemainingTime;
        });
      };
      timeOutId = setTimeout(countDownUntilZero, 1000);

      // When duration is over submit the question
      if (remainingTime <= 0) {
        clearTimeout(timeOutId);
        setRemainingTime(0);
        onExpire?.();
      }
    }

    return (): void => {
      if (timeOutId) clearTimeout(timeOutId);
    };
  }, [canStart, onExpire, referenceTime, remainingTime]);

  return {
    countdown: remainingTime,
  };
};

export default useCountdownTimer;
