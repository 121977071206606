// Packages or third-party libraries
import React from "react";
import { QuoteLeftSVG, CopySolidSVG, LinkedUnitSVG } from "@epignosis_llc/gnosis/icons";

// Utils
import { getUnitIconByType } from "@utils/helpers";
import { t } from "@utils/i18n";

// Other imports
import { UnitMenu } from "./types";
import { CreateUnitActions, CreateUnitMenu } from "@views/CourseEdit/constants";

const {
  CREATE_SECTION,
  CREATE_CONTENT_UNIT,
  CREATE_WEB_CONTENT_UNIT,
  CREATE_VIDEO_UNIT,
  CREATE_AUDIO_UNIT,
  CREATE_DOCUMENT_UNIT,
  CREATE_SCORM_UNIT,
  CREATE_IFRAME_UNIT,
  CREATE_TALENT_CRAFT_UNIT,
  CREATE_TEST_UNIT,
  CREATE_ASSIGNMENT_UNIT,
  CREATE_SURVEY_UNIT,
  CLONE_FROM_COURSE,
  LINK_FROM_COURSE,
  CREATE_ILT_UNIT,
} = CreateUnitActions;

const { CREATE_CONTENT_MENU, CREATE_LEARNING_MENU, CREATE_MORE_MENU } = CreateUnitMenu;

export const getUnitsMenu = (): UnitMenu[] => {
  const options: UnitMenu[] = [
    // Talent craft Unit
    {
      label: t("general.talentCraft"),
      value: CREATE_TALENT_CRAFT_UNIT,
      description: t("units.descriptions.talentCraft"),
      icon: getUnitIconByType(CREATE_TALENT_CRAFT_UNIT),
      isFeatured: true,
    },
    // Standard content units
    {
      label: t("general.standardContent"),
      description: t("units.descriptions.standardContent"),
      icon: getUnitIconByType(CREATE_CONTENT_MENU),
      subMenu: [
        {
          label: t("myCourses.content"),
          value: CREATE_CONTENT_UNIT,
          icon: getUnitIconByType(CREATE_CONTENT_UNIT),
        },
        {
          label: t("general.webContent"),
          value: CREATE_WEB_CONTENT_UNIT,
          icon: getUnitIconByType(CREATE_WEB_CONTENT_UNIT),
        },
        {
          label: t("general.video"),
          value: CREATE_VIDEO_UNIT,
          icon: getUnitIconByType(CREATE_VIDEO_UNIT),
        },
        {
          label: t("general.audio"),
          value: CREATE_AUDIO_UNIT,
          icon: getUnitIconByType(CREATE_AUDIO_UNIT),
        },
        {
          label: `${t("general.presentation")} | ${t("general.document")}`,
          value: CREATE_DOCUMENT_UNIT,
          icon: getUnitIconByType(CREATE_DOCUMENT_UNIT),
        },
        {
          label: t("general.iframe"),
          value: CREATE_IFRAME_UNIT,
          icon: getUnitIconByType(CREATE_IFRAME_UNIT),
          subMenu: [],
        },
      ],
    },
    // Learning activities Units
    {
      label: t("general.learningActivities"),
      description: t("units.descriptions.learningActivities"),
      icon: getUnitIconByType(CREATE_LEARNING_MENU),
      subMenu: [
        {
          label: t("general.test"),
          value: CREATE_TEST_UNIT,
          icon: getUnitIconByType(CREATE_TEST_UNIT),
        },
        {
          label: t("general.survey"),
          value: CREATE_SURVEY_UNIT,
          icon: getUnitIconByType(CREATE_SURVEY_UNIT),
        },
        {
          label: t("general.assignment"),
          value: CREATE_ASSIGNMENT_UNIT,
          icon: getUnitIconByType(CREATE_ASSIGNMENT_UNIT),
        },
        {
          label: t("general.instructorLedTraining"),
          value: CREATE_ILT_UNIT,
          icon: getUnitIconByType(CREATE_ILT_UNIT),
        },
        {
          label: `${t("general.scorm")} | ${t("general.xapi")} | ${t("general.cmi5")}`,
          value: CREATE_SCORM_UNIT,
          icon: getUnitIconByType(CREATE_SCORM_UNIT),
        },
      ],
    },
    // More options
    {
      label: t("general.more"),
      description: t("units.descriptions.more"),
      icon: getUnitIconByType(CREATE_MORE_MENU),
      subMenu: [
        {
          label: t("general.section"),
          value: CREATE_SECTION,
          icon: <QuoteLeftSVG height={32} />,
        },
        {
          label: t("units.cloneFromCourse"),
          value: CLONE_FROM_COURSE,
          icon: <CopySolidSVG height={32} />,
        },
        {
          label: t("units.linkFromCourse"),
          value: LINK_FROM_COURSE,
          icon: <LinkedUnitSVG height={32} />,
        },
      ],
    },
  ];

  return options;
};
