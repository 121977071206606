import React from "react";
import {
  AlarmSVG,
  AlarmExclamationSVG,
  LearningPathSVG,
  UserLevelSVG,
  AssignmentNotPassedAnswer,
  AssignmentPassedAnswer,
  PlayUnitSVG,
  ShowIconSVG,
  UnitILTSVG,
} from "@epignosis_llc/gnosis/icons";

import { differenceInCalendarDays, isToday } from "date-fns";

import { CourseProgress } from "types/entities";
import { formatUtcDate, hasDatePassed } from "@utils/helpers";
import { t } from "@utils/i18n";
import { CourseCardHoverInfo, CourseCardRestrictions } from "./types";
import { DropdownItem, Text } from "@epignosis_llc/gnosis";
import { Restriction } from "@components/CourseOverview/components/CourseHeader/types";

type Color = "primary" | "secondary" | "success" | "danger";

export const getButtonColor = (completion_status: CourseProgress["completion_status"]): Color => {
  switch (completion_status) {
    case "completed":
      return "success";
    case "failed":
      return "secondary";
    default:
      return "primary";
  }
};

export const getCourseCardHoverInfo = (
  isAvailable: boolean,
  isCompleted: boolean,
  isNotStarted: boolean,
): CourseCardHoverInfo => {
  if (!isAvailable || isCompleted) return { text: "general.view", icon: ShowIconSVG };
  else if (isNotStarted) return { text: "general.start", icon: PlayUnitSVG };

  return { text: "general.continue", icon: PlayUnitSVG };
};

export const getExpirationStatus = (date: string): string => {
  const expirationDate = new Date(date);

  if (isToday(expirationDate)) {
    return t("general.expiresToday");
  } else {
    const daysRemaining = differenceInCalendarDays(expirationDate, new Date());
    return t("general.expiresInDay", { count: daysRemaining });
  }
};

export const getFooterIconByCompletion = (
  status: CourseProgress["completion_status"],
): JSX.Element => {
  const iconSize = 20;

  switch (status) {
    case "failed":
      return <AssignmentNotPassedAnswer height={iconSize} />;
    case "completed":
    default:
      return <AssignmentPassedAnswer height={iconSize} />;
  }
};

export const getIltText = (date: string): string => {
  return hasDatePassed(date)
    ? t("general.instructorLedTrainingIncluded")
    : t("general.instructorLedTrainingOn", { date: formatUtcDate(date) });
};

export const isDateExpiringSoon = (date: string): boolean => {
  const SEVEN_DAYS_MS = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  const now = new Date().getTime();
  const expirationDate = new Date(date).getTime();

  return expirationDate - now < SEVEN_DAYS_MS;
};

export const getCourseCardRestrictions = ({
  unlocksOnHigherLevel,
  level,
  hasPrerequisites,
  startsAt,
  userAccessExpiresAt,
  hasIlt,
  iltSessionDate,
}: CourseCardRestrictions): DropdownItem[] => {
  const options: DropdownItem[] = [];

  const hasExpired = Boolean(userAccessExpiresAt && hasDatePassed(userAccessExpiresAt));
  const showTimeOptionsGrouped =
    startsAt && userAccessExpiresAt && !hasExpired && !hasDatePassed(startsAt);

  if (showTimeOptionsGrouped) {
    options.push({
      label: (
        <div>
          <Text fontSize={"2xs"} as="div">
            {t("restrictions.startsOn", {
              startsOn: startsAt && formatUtcDate(startsAt),
            })}
          </Text>
          <Text fontSize="2xs" as="div">
            {t("restrictions.expires", {
              expiresAt: userAccessExpiresAt && formatUtcDate(userAccessExpiresAt),
            })}
          </Text>
        </div>
      ),
      value: Restriction.StartsAt,
      icon: <AlarmSVG height={32} />,
      id: "restriction-with-start-and-end-date",
      originalText: t("a11y.courses.courseStartsOnAndEndsOn", {
        startDate: startsAt && formatUtcDate(startsAt),
        endDate: userAccessExpiresAt && formatUtcDate(userAccessExpiresAt),
      }),
    });
  }

  if (startsAt && !hasExpired && !hasDatePassed(startsAt) && !showTimeOptionsGrouped) {
    options.push({
      label: t("restrictions.startsOn", {
        startsOn: startsAt && formatUtcDate(startsAt),
      }),
      value: Restriction.StartsAt,
      icon: <AlarmSVG height={32} />,
      id: "restriction-with-start-date",
    });
  }

  if (userAccessExpiresAt && !showTimeOptionsGrouped) {
    const isExpiringSoon =
      userAccessExpiresAt.length > 0 ? isDateExpiringSoon(userAccessExpiresAt) : false;

    const labelText = hasExpired
      ? t("restrictions.expired", {
          date: formatUtcDate(userAccessExpiresAt),
        })
      : isExpiringSoon
        ? getExpirationStatus(userAccessExpiresAt)
        : t("restrictions.expires", {
            expiresAt: formatUtcDate(userAccessExpiresAt),
          });

    const optionClassName = hasExpired
      ? "expired-icon"
      : isExpiringSoon
        ? "about-to-be-expired"
        : "";

    options.push({
      label: labelText,
      value: Restriction.ExpiresAt,
      icon: hasExpired ? <AlarmExclamationSVG height={32} /> : <AlarmSVG height={32} />,
      className: optionClassName,
      id: "restriction-with-end-date",
    });
  }

  if (hasPrerequisites) {
    options.push({
      label: t("restrictions.thereArePrerequisites"),
      value: Restriction.Prerequisites,
      icon: <LearningPathSVG height={32} />,
      id: "restriction-with-prerequisites",
    });
  }

  if (unlocksOnHigherLevel) {
    options.push({
      label: t("restrictions.requiresLevel", { level }),
      value: Restriction.Level,
      icon: <UserLevelSVG height={32} />,
      id: "restriction-with-level",
    });
  }

  if (hasIlt) {
    options.push({
      label:
        iltSessionDate === null
          ? t("general.instructorLedTrainingIncluded")
          : getIltText(iltSessionDate),
      value: Restriction.ExpiresAt,
      icon: <UnitILTSVG height={32} />,
      id: "includes-ilt-unit",
    });
  }

  return options;
};
