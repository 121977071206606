import { css } from "@emotion/react";

export const catalogCardFooterContainer = css`
  padding: 0.5rem 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const cardPriceContainer = ({ courseCard }) => css`
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;

  .previous-price,
  .price {
    width: fit-content;
  }

  .price {
    background: ${courseCard.cardFooter.price.currentPriceBackground};
  }

  .previous-price {
    position: absolute;
    bottom: 1.625rem;
    background: ${courseCard.cardFooter.price.previousPriceBackground};

    &.show-underline {
      text-decoration: line-through;
    }
  }
`;

export const catalogCardFooterStyles = ({ courseCard }) => css`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;

  .footer-info {
    background: ${courseCard.cardFooter.background};
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100%;
  }
`;
