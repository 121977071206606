// Packages or third-party libraries
import React, { useState, FC } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Grid, InputError, Label, Select } from "@epignosis_llc/gnosis";
import { PreviewIconSVG } from "@epignosis_llc/gnosis/icons";
import { Controller, UseFormReturn } from "react-hook-form";
import { AxiosError } from "axios";
import { getDate, getMonth, set } from "date-fns";

// Styles
import { certificateOptionStyles } from "./styles";

// Components
import { DateInput, RangeInput } from "@components";
import TemplatePreview from "./TemplatePreview";
import DurationTypeSelect from "./DurationTypeSelect";
import ReassignWhenSelect from "./ReassignWhenSelect";

// Utils, hooks
import { capitalize, getDomainDateFormatWithoutYears } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { handlePreviewCertificateTemplateErrors } from "@errors";

// Other imports
import { getCertificateTemplatePreview, getCertificateTemplates } from "@api/certificates";
import { SelectOption } from "types/common";
import { CertificateDuration, Course } from "types/entities";
import { EditCourseData } from "@views/CourseEdit/api";
import queryKeys from "@constants/queryKeys";
import { DURATION_DAYS_DEFAULT_VALUE } from "../constants";

type CertificateOptionProps = {
  course: Course;
  form: UseFormReturn<EditCourseData>;
};

const CertificateOption: FC<CertificateOptionProps> = ({ course, form }) => {
  const { t } = useApplyTranslations();
  const { certificate } = course;

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = form;

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const { data: certificateTemplates = [] } = useQuery(
    [queryKeys.certificates.templates],
    getCertificateTemplates,
    {
      select: (res) => {
        const data = res._data;

        if (certificate?.id && !data.find((t) => t.id === certificate.id)) {
          data.push({
            id: certificate.id,
            name: certificate?.name ?? "",
          });
        }

        return data;
      },
    },
  );

  const certificateTemplateOptions: SelectOption[] = certificateTemplates.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }));

  const certificateWatch = watch("certificate");
  const {
    id: certificateId,
    duration_type: durationType,
    expiration_day: expirationDay,
    expiration_month: expirationMonth,
  } = certificateWatch ?? {};

  const durationDaysLabel = capitalize(t("general.day", { count: 2 }));
  const hasSelectedType = Boolean(certificateId);
  const hasSelectedDuration = Boolean(durationType);
  const hasForeverDuration = hasSelectedDuration && durationType === CertificateDuration.Forever;
  const hasCustomDuration = hasSelectedDuration && durationType === CertificateDuration.Custom;
  const hasDateDuration = hasSelectedDuration && durationType === CertificateDuration.Date;
  const showReassignWhen = hasSelectedType && hasSelectedDuration && !hasForeverDuration;
  const hasExpirationDate = expirationDay && expirationMonth;
  const expirationDate = hasExpirationDate
    ? set(new Date(), { month: expirationMonth - 1, date: expirationDay })
    : null;

  const handleTypeChange = (typeId: number): void => {
    setValue("certificate.id", typeId);

    // Set duration to one month if duration_type has not a value
    if (!certificateWatch?.duration_type) {
      setValue("certificate.duration_type", CertificateDuration.Forever);
    }

    // Should always send reassign_when
    if (!certificateWatch?.reassign_when) {
      setValue("certificate.reassign_when", null);
    }
  };

  const handleExpirationDateChange = (date: Date | null): void => {
    setValue("certificate.expiration_day", date ? getDate(date) : undefined);
    setValue("certificate.expiration_month", date ? getMonth(date) + 1 : undefined);

    // Trigger validation manually because there aren't controlled field for these properties
    trigger(["certificate.expiration_day", "certificate.expiration_month"]);
  };

  const handlePreviewClick = (): void => {
    if (certificateId) {
      certificateTemplatePreviewMutation(certificateId.toString());
    }
  };

  const {
    mutate: certificateTemplatePreviewMutation,
    isLoading: isLoadingCertificateTemplatePreview,
  } = useMutation([queryKeys.certificates.templatePreview], getCertificateTemplatePreview, {
    onSuccess: (res) => {
      setPreviewUrl(res._data.preview_url);
    },
    onError: (err) => {
      const error = err as AxiosError;
      handlePreviewCertificateTemplateErrors(error);
    },
  });

  return (
    <Grid templateColumns={[1, 1, 1, 2]} rowGap={1} columnGap={1} css={certificateOptionStyles}>
      <Grid.Item colSpan={1}>
        <Controller
          name="certificate"
          control={control}
          render={({ field: { value, onChange } }): JSX.Element => {
            const selectedTemplate = certificateTemplateOptions.find(
              (template) => template.value === value?.id?.toString(),
            );

            return (
              <Select
                label={t("general.type")}
                aria-label={t("general.type")}
                placeholder={t("certificates.selectType")}
                options={certificateTemplateOptions}
                value={selectedTemplate}
                isClearable
                onChange={(option): void => {
                  const { value } = (option as SelectOption) ?? {};

                  // On clear selection reset the whole certificate object
                  !value ? onChange(null) : handleTypeChange(Number(value));

                  // Reset preview on change
                  setPreviewUrl(null);
                }}
              />
            );
          }}
        />
      </Grid.Item>

      <Grid.Item colSpan={1} className="preview-btn-grid-item">
        {hasSelectedType && (
          <Button
            variant="ghost"
            iconBefore={PreviewIconSVG}
            noGutters
            isLoading={isLoadingCertificateTemplatePreview}
            className="preview-btn"
            onClick={handlePreviewClick}
          >
            {t("general.preview")}
          </Button>
        )}
      </Grid.Item>

      {previewUrl && (
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <TemplatePreview url={previewUrl} onClose={(): void => setPreviewUrl(null)} />
        </Grid.Item>
      )}

      {hasSelectedType && (
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <Grid templateColumns={[1, 1, 1, 2]} gap={1}>
            <Grid.Item colSpan={1}>
              <DurationTypeSelect form={form} />
            </Grid.Item>

            {hasDateDuration && (
              <Grid.Item colSpan={1}>
                <DateInput
                  label={t("general.expirationDate")}
                  dateFormat={getDomainDateFormatWithoutYears()}
                  value={expirationDate}
                  hideYears
                  tooltipContent={t("certificates.expirationTooltip")}
                  onChange={(selectedDate: Date | null): void => {
                    handleExpirationDateChange(selectedDate);
                  }}
                />
                {errors?.certificate?.expiration_month && (
                  <InputError>{errors?.certificate?.expiration_month.message}</InputError>
                )}
              </Grid.Item>
            )}
          </Grid>
        </Grid.Item>
      )}

      {hasCustomDuration && (
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <Label>{t("courseEdit.setDuration")}</Label>
          <Controller
            name="certificate.duration_days"
            control={control}
            render={({ field: { value, onChange } }): JSX.Element => (
              <RangeInput
                id="custom-duration-range-input"
                name="custom-duration-range-input"
                min={1}
                max={4015}
                value={value ?? DURATION_DAYS_DEFAULT_VALUE}
                label={durationDaysLabel}
                onChange={onChange}
              />
            )}
          />
        </Grid.Item>
      )}

      {showReassignWhen && <ReassignWhenSelect form={form} />}
    </Grid>
  );
};

export default CertificateOption;
