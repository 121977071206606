import React, {
  InputHTMLAttributes,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useRef,
  FormEvent,
} from "react";
import { Tooltip, Text, Loader } from "@epignosis_llc/gnosis";
import { AttachmentSVG } from "@epignosis_llc/gnosis/icons";
import classNames from "classnames";
import { SerializedStyles } from "@emotion/react";
import { fileInputContainer } from "./styles";
import { t } from "@utils/i18n";
import {
  addDotPrefix,
  convertBytesToSizeUnit,
  getFileType,
  getGroupedMimeTypes,
  validateSelectedFiles,
} from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { ExtendableProps } from "types/utils";
import { FileValidationsObj, MimeType } from "types/entities";
import { IconType } from "types/common";
import SelectedFiles from "./components/SelectedFiles";

export type FileInputProps = ExtendableProps<
  InputHTMLAttributes<HTMLInputElement>,
  {
    id: string;
    name: string;
    maxFiles: number;
    mimeTypeAndFilesizeValidations: FileValidationsObj;
    acceptedFileExtensions?: string[];
    selectedFiles?: File[];
    addedFiles: FileList | null;
    tooltipPlacement?: "top" | "bottom" | "left" | "right";
    isLoading?: boolean;
    isLoadingText?: string;
    icon?: IconType;
    title?: string;
    subtitle?: string;
    isReadonly?: boolean;
    hideBorder?: boolean;
    onFileError: (error: string) => void;
    onFilesChange: (files: File[]) => void;
  }
>;

const selectFilesWrapperClassesNames = (maxFilesReached: boolean): string =>
  classNames("select-files-wrapper", {
    disabled: maxFilesReached,
  });

const getTooltipText = (validationTexts: JSX.Element[], maxFilesReached: boolean): JSX.Element => {
  if (maxFilesReached) return <div className="tooltip">{t("files.maxFilesReached")}</div>;

  return (
    <div className="tooltip">
      <div>{t("general.acceptedFiles")}</div>
      {validationTexts}
    </div>
  );
};

const FileInput: ForwardRefRenderFunction<HTMLInputElement, FileInputProps> = (props, ref) => {
  const { t } = useApplyTranslations();

  const {
    id,
    name,
    maxFiles,
    selectedFiles = [], // The total selected files
    addedFiles, // Files given to component in order to added
    mimeTypeAndFilesizeValidations,
    acceptedFileExtensions = [], // Validate files by their extension insted of mime type
    tooltipPlacement = "top",
    isLoading = false,
    isLoadingText = t("general.uploadingFile", { count: maxFiles }),
    icon: Icon = AttachmentSVG,
    title = t("files.selectFiles", { count: maxFiles }),
    subtitle = t("files.dragAndDrop", { count: maxFiles }),
    isReadonly = false,
    hideBorder = false,
    onFileError,
    onFilesChange,
    children,
    ...rest
  } = props;
  const fileInput = useRef<HTMLElement | null>();
  const maxFilesReached = selectedFiles.length >= maxFiles;
  const groupedMimeTypes = getGroupedMimeTypes(mimeTypeAndFilesizeValidations);

  const acceptedDottedFileExtensions = addDotPrefix(acceptedFileExtensions); // Convert accepted file extensions to include dot prefix for file selection validation
  const acceptedFileTypes = [
    ...acceptedDottedFileExtensions,
    ...Object.keys(mimeTypeAndFilesizeValidations),
  ];
  const acceptedFileTypesString = acceptedFileTypes.join(", ");

  const validationTexts = Object.keys(groupedMimeTypes).map((fileSizeStr) => {
    const fileSize = parseInt(fileSizeStr);

    const extensions = groupedMimeTypes[fileSizeStr].map((mimeType: MimeType) =>
      getFileType(mimeType),
    );

    // Remove duplicate extensions
    const extensionsStr = [...new Set(extensions)].join(", ");

    return (
      <p key={fileSizeStr}>{` ${extensionsStr} (${convertBytesToSizeUnit(fileSize, "MB")} MB)`}</p>
    );
  });

  const tooltipText = getTooltipText(validationTexts, maxFilesReached);

  // Handle click on attach file button
  const handleBrowseButtonClick = (e: FormEvent): void => {
    if (!maxFilesReached && !isReadonly) {
      e.preventDefault();
      fileInput.current?.click();
    }
  };

  // Remove selected file
  const removeFile = (index: number): void => {
    if (index >= 0) {
      const currentFiles: File[] = [...selectedFiles];
      currentFiles.splice(index, 1);
      onFilesChange(currentFiles);
    }
  };

  // Remove all selected files
  const removeFiles = (): void => {
    onFilesChange([]);
  };

  // Handle the files selected from browing using the file input
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files: FileList | null = e.target.files;

    // Call function to handle selected files
    if (files?.length) {
      const newFiles = validateSelectedFiles({
        files,
        selectedFiles,
        maxFiles,
        mimeTypeAndFilesizeValidations,
        acceptedFileExtensions,
        onFileError,
      });

      // Add new files to the selected files
      const updatedFiles = [...selectedFiles, ...newFiles];
      if (!updatedFiles.length) return;

      onFilesChange(updatedFiles);
    }

    // Reset input value to trigger file input change every time
    e.target.value = "";
  };

  useEffect(() => {
    // Validate given files and add them to the selected files
    if (addedFiles?.length) {
      const newFiles = validateSelectedFiles({
        files: addedFiles,
        selectedFiles,
        maxFiles,
        mimeTypeAndFilesizeValidations,
        acceptedFileExtensions,
        onFileError,
      });

      // Add new files to the selected files

      const updatedFiles = [...selectedFiles, ...newFiles];
      if (!updatedFiles.length) return;

      onFilesChange(updatedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedFiles]);

  useEffect(() => {
    fileInput.current = document.getElementById(id);
  }, [id]);

  return (
    <div
      css={(theme): SerializedStyles => fileInputContainer(theme, { hideBorder })}
      className="file-input"
    >
      <div className="label-container">
        {children ? (
          <div className="children-wrapper" onClick={handleBrowseButtonClick}>
            <Tooltip content={tooltipText} placement={tooltipPlacement} disabled={isReadonly}>
              <>{children}</>
            </Tooltip>
          </div>
        ) : (
          <div className={selectFilesWrapperClassesNames(maxFilesReached)}>
            {isLoading ? (
              <div className="loading-wrapper">
                <Text fontSize="md" weight="700">
                  {isLoadingText}
                </Text>
                <Loader />
              </div>
            ) : (
              <Tooltip content={tooltipText} placement={tooltipPlacement} disabled={isReadonly}>
                <div className="select-files-container" onClick={handleBrowseButtonClick}>
                  <Icon height={32} />
                  <Text fontSize="sm" weight="700" className="title">
                    {title}
                  </Text>
                  <Text
                    fontSize="sm"
                    className="subtitle"
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </div>

      {selectedFiles.length > 0 && (
        <SelectedFiles
          selectedFiles={selectedFiles}
          removeFile={removeFile}
          removeFiles={removeFiles}
        />
      )}

      <input
        id={id}
        accept={acceptedFileTypesString}
        type="file"
        name={name}
        ref={ref}
        aria-describedby={`${id}-${name}`}
        onChange={handleOnChange}
        multiple={maxFiles > 1}
        {...rest}
      />
    </div>
  );
};

export default forwardRef(FileInput);
