import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import {
  FormCustomFields,
  UnitType,
  CertificateDuration,
  CertificateReassignWhen,
} from "types/entities";
import {
  CoursePrerequisitesRes,
  ExportUnitRes,
  FilesRes,
  MyUnitRes,
  SmartTagsRes,
} from "types/responses";
import { CourseRulesEditData, cloneCourseUnitData, linkCourseUnitData } from "./types";
import { marketoQueryParam } from "@constants/queryParameters";
import { AIJobsRes } from "@views/Skills/types";

export type Certificate = {
  id: number;
  duration_type: CertificateDuration;
  duration_days?: number; // Min 1, nullable, Required if duration_type === custom
  expiration_month?: number; // Min 1, max 12, nullable, Required if duration_type === date
  expiration_day?: number; // Min 1, max 31, nullable, Required if duration_type === date
  reassign_when?: CertificateReassignWhen | null; // Nullable  // Required if duration_type !== forever
  // If duration_type === forever the only acceptable value is null
};

export type EditCourseData = {
  name?: string; // Max length 100
  description?: string | null; // Max length 5000, nullable
  code?: string | null; // Max length 20, nullable
  price?: number | null; // Nullable
  capacity?: number | null; // Min 1, nullable
  is_hidden_from_catalog?: boolean;
  is_active?: boolean;
  intro_video_url?: string | null; // Max 255, nullable
  time_limit?: number | null; // Min 1, nullable, this cannot be set if expires_at is not null
  retain_access_after_completion?: boolean; // This cannot be true if the course has time_limit == null or expires_at == null
  starts_at?: string | null; // Nullable, this cannot be after expires_at
  expires_at?: string | null; // Nullable, this cannot be before expires_at, this cannot be set if time_limit is not null
  certificate?: Certificate | null;
  category_id?: number | null; // Min 1, nullable
  level?: number | null; // Min 1, max 20, nullable
  custom_fields?: FormCustomFields | null;
  category?: {
    id: number | null; // Id and name cannot be null at the same time
    name: string | null;
  } | null;
  is_locked?: boolean;
};

export type CreateUnitData = {
  course_id: number;
  type: UnitType;
  primary_color?: string | undefined;
  name?: string;
};

export type SharedNotificationData = {
  emails: string[];
  subject: string;
  message: string;
};

/** *** COURSE endpoints *****/
export const postSharedNotification = async (
  courseId: string,
  data: SharedNotificationData,
): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courses.sharedNotification(courseId), data);
};

export const publishCourse = async (courseId: string): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.publish(courseId));
};

export const shareCourse = async (courseId: string, shared: boolean): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.shared(courseId), { shared });
};

export const editCourse = async (
  courseId: string,
  data: EditCourseData,
  shouldTrackMarketo?: boolean,
): Promise<void> => {
  // By calling this method, a Marketo event will be automatically triggered.
  const queryParams = shouldTrackMarketo ? `?${marketoQueryParam}` : "";

  await HttpClient.patch(`${ENDPOINTS.courses.edit(courseId)}${queryParams}`, data);
};

/** *** COURSE IMAGE endpoints *****/

export const postCourseImage = async (file: File, courseId: string): Promise<FilesRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("course_image", file);

  const res = await HttpClient.post(ENDPOINTS.courses.image(courseId), bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const deleteCourseImage = async (courseId: string): Promise<FilesRes> => {
  const res = await HttpClient.delete(ENDPOINTS.courses.image(courseId));

  return res.data;
};

export const generateCourseImage = async (courseId: string, aiPrompt: string): Promise<void> => {
  const res = await HttpClient.post(ENDPOINTS.courses.generateImage(courseId), {
    prompt: aiPrompt,
  });
  return res.data;
};

/** *** COURSE UNITS endpoints *****/

export const createUnit = async (data: CreateUnitData): Promise<MyUnitRes> => {
  // By calling this method, a Marketo event will be automatically triggered.
  const res = await HttpClient.post(`${ENDPOINTS.courses.unitCreate}?${marketoQueryParam}`, data);

  return res.data;
};

export const getUnitSmartTags = async (): Promise<SmartTagsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.unitSmartTags);

  return res.data;
};

export const createSection = async (courseId: number): Promise<void> => {
  const data = { course_id: courseId };
  await HttpClient.post(ENDPOINTS.courses.sectionCreate, data);
};

export const cloneUnit = async ({ unitId, targetCourseId }: cloneCourseUnitData): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courses.unitClone(unitId), {
    target_course_id: targetCourseId,
  });
};

export const linkUnit = async ({ unitId, targetCourseId }: linkCourseUnitData): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courses.unitLink(unitId), {
    target_course_id: targetCourseId,
  });
};

export const putUnitsOrder = async (courseId: string, unitIds: number[]): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.unitsOrder(courseId), {
    unit_ids: unitIds,
  });
};

export const deleteUnit = async (
  unitId: string,
  shouldDeleteLinkedUnits?: boolean,
): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.courses.unitDelete(unitId, shouldDeleteLinkedUnits));
};

export const exportUnit = async (unitId: string): Promise<ExportUnitRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.unitExport(unitId));

  return res.data;
};

/** *** COURSE RULES endpoints *****/

export const getCoursePrerequisiteOptions = async (
  courseId: string,
): Promise<CoursePrerequisitesRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.prerequisiteOptions(courseId));

  return res.data;
};

export const putCourseRules = async (
  courseId: string,
  data: CourseRulesEditData,
): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.rulesEdit(courseId), data);
};

export const getCourseAIJobs = async (
  courseId: string,
  signal?: AbortSignal,
): Promise<AIJobsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.aiJobs(courseId), { signal });
  return res.data;
};
