// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Button, Grid, Modal, Select, Text } from "@epignosis_llc/gnosis";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";

// Styles
import { modalFooter } from "./styles";

// Components
import { InfiniteAsyncFilterSelect } from "@components/ReusableComponents";

// Utils, hooks
import {
  buildPaginatedSearchQuery,
  generalNotification,
  mapTableToSelectSorting,
  unitsToOptions,
} from "@utils/helpers";
import { isSectionItem } from "@views/CourseEdit/helpers";
import { useApplyTranslations, usePaginatedStateReducer } from "@hooks";
import { useLinkCourseUnit } from "@views/CourseEdit/hooks";
import { handleUnitErrors } from "@errors";
import { linkUnitValidationSchema } from "@views/CourseEdit/validations";
import { flatsUnitsReducer } from "@views/CourseEdit/reducers";

// Other imports
import { getMyCourseUnits } from "@api/courses";
import { SelectOption } from "types/common";
import { MyUnit } from "types/entities";
import queryKeys from "@constants/queryKeys";
import { linkUnitCoursesDefaultState } from "@views/CourseEdit/constants";

type LinkModalProps = {
  isOpen: boolean;
  targetCourseId: string;
  onClose: () => void;
};

type LinkCourseUnitData = {
  unitId: string | null;
  targetCourseId: string;
};

const LinkModal: FC<LinkModalProps> = ({ isOpen, targetCourseId, onClose }) => {
  const { t, i18n } = useApplyTranslations();
  const isRtl = i18n.dir() === "rtl";

  const queryClient = useQueryClient();
  const [courseUnits, setCourseUnits] = useState<MyUnit[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<SelectOption | null>(null);
  const [coursesState] = usePaginatedStateReducer(linkUnitCoursesDefaultState);
  const { pagination, sorting: tableSorting, filters } = coursesState;
  const sorting = tableSorting?.column ? [mapTableToSelectSorting(tableSorting)] : [];
  const searchQuery = `${buildPaginatedSearchQuery({
    pagination,
    sorting,
    filters,
  })}&filter[course_id]=${targetCourseId}`;

  const {
    control,
    watch,
    formState: { isValid },
    setValue,
    trigger,
    handleSubmit,
    reset,
  } = useForm<LinkCourseUnitData>({
    mode: "onChange",
    resolver: yupResolver(linkUnitValidationSchema),
  });

  const selectedCourseId = watch("targetCourseId");

  const { mutate: getCourseUnitsMutation, isLoading: getCourseUnitsLoading } = useMutation(
    [queryKeys.units, selectedCourseId],
    (courseId: string) => getMyCourseUnits(courseId),
    {
      onSuccess: (res) => {
        const units = res._data
          .reduce(flatsUnitsReducer, [])
          .filter(
            (section) => !isSectionItem(section) && Boolean(section.policies?.can_be_linked),
          ) as MyUnit[];

        setCourseUnits(units);
      },
    },
  );

  const { mutate: linkUnitMutation, isLoading: linkUnitLoading } = useLinkCourseUnit({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.units, targetCourseId]);
        generalNotification("success", t("courseEdit.unitLinkedSuccessfully"));
        reset();
        setSelectedCourse(null);
      },
      onError: (err): void => {
        const error = err as AxiosError;
        handleUnitErrors(error);
      },
    },
  });

  const unitOptions = unitsToOptions(courseUnits);

  const handleCourseChange = (courseId: string): void => {
    setValue("unitId", null);
    trigger("unitId");
    getCourseUnitsMutation(courseId);
  };

  const handleLink = ({ unitId }: LinkCourseUnitData): void => {
    if (unitId && targetCourseId) {
      linkUnitMutation({ unitId, targetCourseId });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>{t("units.linkFromCourse")}</Modal.Header>
      <Modal.Body>
        <Grid templateColumns={1} gap={1.5}>
          <Grid.Item colSpan={1}>
            <Text fontSize="xs">{t("units.linkFromCourseText")}</Text>
          </Grid.Item>
          <Grid.Item colSpan={1}>
            <Controller
              name="targetCourseId"
              control={control}
              render={({ field: { onChange } }): JSX.Element => {
                return (
                  <InfiniteAsyncFilterSelect
                    id="clone-course-select"
                    filterType="courses"
                    label={t("general.course")}
                    customSearchQuery={searchQuery}
                    onChange={(option): void => {
                      const updatedValues = (option as SelectOption) ?? null;
                      const courseId = updatedValues?.value;

                      onChange(courseId);
                      handleCourseChange(courseId);
                      setSelectedCourse(updatedValues);
                    }}
                    placeholder={t("general.selectACourse")}
                    value={selectedCourse}
                    isSearchable
                    isRtl={isRtl}
                    noOptionsMessage={(): string => t("general.noMatchesFound")}
                  />
                );
              }}
            />
          </Grid.Item>
          <Grid.Item colSpan={1}>
            <Controller
              name="unitId"
              control={control}
              render={({ field: { value, onChange } }): JSX.Element => {
                const selectedValue = value
                  ? unitOptions.find((option) => option.value === value)
                  : null;

                return (
                  <Select
                    id="link-course-unit-select"
                    label={t("general.unit")}
                    aria-label={t("general.unit")}
                    placeholder={t("general.selectUnits", { count: 1 })}
                    options={unitOptions}
                    value={selectedValue}
                    isDisabled={!selectedCourseId || getCourseUnitsLoading}
                    onChange={(newValue): void => {
                      const { value: unitId } = newValue as SelectOption;
                      onChange(unitId);
                    }}
                    noOptionsMessage={(): string => t("general.noMatchesFound")}
                  />
                );
              }}
            />
          </Grid.Item>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <div css={modalFooter}>
          <Button
            color="primary"
            disabled={!isValid}
            isLoading={linkUnitLoading}
            onClick={handleSubmit(handleLink)}
          >
            {t("general.link")}
          </Button>
          <Button color="secondary" onClick={onClose}>
            {t("general.cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LinkModal;
