// Packages or third-party libraries
import React, { FC } from "react";
import { useQuery } from "react-query";
import { useTheme } from "@emotion/react";
import { Text } from "@epignosis_llc/gnosis";
import { ContentIcon } from "@epignosis_llc/gnosis/icons";

// Styles
import { linkedUnitsStyles } from "./styles";

// Components
import { LinkStyled } from "@components/ReusableComponents";
import { ActionDrawer, Skeletons } from "@components";

// Hooks & Utils
import { useApplyTranslations } from "@hooks";

// Constants
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";

// Other imports
import { getLinkedUnits } from "@views/UnitEdit/api";

type LinkDrawerProps = {
  unitId: string;
  isDrawerOpen: boolean;
  unitName: string;
  toggleDrawer: () => void;
};

const LinkDrawer: FC<LinkDrawerProps> = ({ unitName, isDrawerOpen, toggleDrawer, unitId }) => {
  const { t } = useApplyTranslations();
  const { icon } = useTheme();

  const {
    data: linkedUnits = [],
    status,
    error,
  } = useQuery([queryKeys.courses.getLinkedUnits, unitId], () => getLinkedUnits(unitId), {
    refetchOnWindowFocus: false,
  });

  return (
    <ActionDrawer
      isOpen={isDrawerOpen}
      headerTitle={t("courseEdit.linkedUnitsModalHeaderTitle", { unitName })}
      onClose={toggleDrawer}
      size="md"
      showFooter={false}
    >
      <Skeletons.Loader status={status} error={error}>
        <section css={linkedUnitsStyles}>
          <Text as="span" fontSize="xs">
            {t("courseEdit.linkedUnitUtilization", { count: linkedUnits?.length ?? 0 })}
          </Text>
          <div className="linked-units-container">
            {linkedUnits.map((unit) => {
              const { name, course_name, policies, course_id } = unit;
              const courseId = course_id.toString();
              const { can_view: canView } = policies;
              const redirectUrl = canView ? URLS.courses.getCourseEditLink({ courseId }) : "#";

              return (
                <div key={unit.id}>
                  <div className="linked-unit-title">
                    <Text as="span" fontSize="sm" weight="700">
                      {course_name}
                    </Text>
                  </div>
                  <div className="linked-unit-course-link">
                    <ContentIcon height={32} fill={canView ? icon.primary : icon.secondary} />
                    <LinkStyled to={redirectUrl} readonly={!canView}>
                      {name}
                    </LinkStyled>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </Skeletons.Loader>
    </ActionDrawer>
  );
};

export default LinkDrawer;
