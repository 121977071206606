import { css } from "@emotion/react";

export const ImageSelectorContainerStyles = (
  { imageSelector, colors },
  {
    aspectRatio = 3 / 2,
    height = "150px",
    minHeight = "150px",
    imageHeight = "150px",
    imageWidth = "150px",
    disabled = false,
    actionButtonsInline = false,
    isLoading = false,
  },
) => css`
  position: relative;
  width: 100%;
  height: ${height};
  min-height: ${minHeight};
  aspect-ratio: ${aspectRatio};
  overflow: hidden;
  z-index: 2;
  background: ${colors.secondary.lighter};

  .image-preview {
    &-fit {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-center {
      height: ${imageHeight};
      width: ${imageWidth};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .input-field {
    display: none;
  }

  .upload-overlay {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    color: white;
    background-color: ${imageSelector.backgroundOverlayHoverColor};
    opacity: ${isLoading ? 1 : 0};
    transition: opacity 0.1s linear;
    ${ActionButtonsContainerStyles({ disabled, actionButtonsInline })}

    &:hover {
      opacity: ${disabled ? 0 : 1};
    }

    .input-field {
      position: absolute;
      appearance: initial;
      opacity: 0;
    }

    .action-button {
      ${ActionButtonStyles({ inline: actionButtonsInline, isLoading })}
    }
  }
`;

export const ActionButtonStyles = ({ inline, isLoading = false }) => css`
  width: ${inline ? "4rem" : "4.625rem"};
  height: ${inline ? "4rem" : "4.625rem"};
  opacity: ${isLoading ? 0 : 1};
`;

export const ActionButtonsContainerStyles = ({ disabled, actionButtonsInline }) => css`
  display: ${disabled ? "none" : "flex"};
  gap: ${actionButtonsInline ? "2rem" : "0.625rem"};
  justify-content: center;
  align-items: center;
  flex-direction: ${actionButtonsInline ? "row" : "column"};
`;

export const GenerateImageButtonStyles = ({ inline, isLoading = false }) => css`
  & #primary-button {
    ${ActionButtonStyles({ inline, isLoading })}
  }
`;

export const AIActivityIndicatorStyles = () => css`
  position: absolute;
  top: 16px;
  left: 16px;
`;
