// Packages or third-party libraries
import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { LockIconSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { linkContainerStyles } from "./styles";

// Components
import { LinkStyled } from "@components";
import SidebarIcon from "./SidebarIcon";

// Hooks
import { useApplyTranslations } from "@hooks";

// Other imports
import { TALENT_LMS_HELP_LINKS } from "@constants/staticLinks";

type LockedIconProps = {
  isLocked: boolean;
};

const LockedIcon: FC<LockedIconProps> = ({ isLocked }) => {
  const { t } = useApplyTranslations();

  if (!isLocked) return null;

  return (
    <SidebarIcon
      icon={LockIconSVG}
      tooltipContent={
        <Text fontSize="2xs" as="div" css={linkContainerStyles}>
          <span>{t("courseEdit.lockCourseTooltip")}</span>
          <LinkStyled to={TALENT_LMS_HELP_LINKS.COURSE_LOCKED} target="_blank" isUnderlined>
            {t("general.learnMore")}.
          </LinkStyled>
        </Text>
      }
    />
  );
};

export default LockedIcon;
