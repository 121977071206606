// Packages or third-party libraries
import React, { FC } from "react";
import { Tooltip, ToggleSwitch } from "@epignosis_llc/gnosis";
import { PreviewIconSVG } from "@epignosis_llc/gnosis/icons";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

// Components
import { ToC } from "@components";
import PublishButton from "./components/PublishButton";

// Utils, hooks, stores
import { getFlatUnits, getUnitIdToContinue, getUnitLink } from "@utils/helpers";
import { isSectionItem } from "@views/CourseEdit/helpers";
import { handleUserCoursesErrors } from "@errors/errors";
import { useApplyTranslations } from "@hooks";
import { useUIStore } from "@stores";

// Other imports
import { getMyCourseUnits, initPreview } from "@api/courses";
import { Course, MyUnit, Section } from "types/entities";

type TocTopProps = {
  course: Course;
  flatUnits: (Section | MyUnit)[];
};

const ToCTop: FC<TocTopProps> = ({ course, flatUnits }) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();
  const { showUnitSidebar, toggleUnitSidebar } = useUIStore();

  const { policies, id } = course;
  const { can_preview_content = false } = policies ?? {};
  const courseId = id.toString();
  const units = flatUnits.reduce<MyUnit[]>((array, item) => {
    if (!isSectionItem(item)) array.push(item);
    return array;
  }, []);
  const hasAtLeastOneActiveUnit = units.some((unit) => unit.is_active);
  const shouldShowPreview = can_preview_content && hasAtLeastOneActiveUnit;

  const handlePreviewClick = (): void => {
    initPreviewMutation();
  };

  const { mutate: initPreviewMutation, isLoading: isResetLoading } = useMutation(
    () => initPreview(courseId),
    {
      onError: (error: AxiosError) => {
        handleUserCoursesErrors(error);
      },
      onSuccess: async () => {
        const units = await getMyCourseUnits(courseId, true);
        const flatUnits = getFlatUnits(units._data);
        const hasUnits = Boolean(flatUnits.length);
        const continueUnitId = hasUnits ? getUnitIdToContinue(flatUnits) : null;

        const link = getUnitLink({
          courseId,
          unitId: continueUnitId?.toString() ?? "",
          isPublic: false,
          isPreview: true,
        });

        navigate(link);
      },
    },
  );

  return (
    <ToC.Top isOpen={showUnitSidebar} toggleTocMenu={toggleUnitSidebar}>
      <>
        <div className="top-start">
          <PublishButton course={course} flatUnits={flatUnits} />
        </div>

        {shouldShowPreview && (
          <div className="top-end">
            <Tooltip content={t("general.preview")}>
              <ToggleSwitch
                role="switch"
                aria-label={t("a11y.courseEdit.previewCourseToggleSwitch")}
                aria-checked={false}
                defaultChecked={false}
                size="md"
                variant="success"
                onChange={handlePreviewClick}
                InternalIcon={<PreviewIconSVG height={32} />}
                isDisabled={isResetLoading}
              />
            </Tooltip>
          </div>
        )}
      </>
    </ToC.Top>
  );
};

export default ToCTop;
