// Packages or third-party libraries
import React, { FC } from "react";
import { Dropdown, DropdownItem, Tooltip } from "@epignosis_llc/gnosis";
import { GearIconSVG } from "@epignosis_llc/gnosis/icons";

// Components
import { ActionSecondaryButton } from "@components/ReusableComponents";

// Utils, hooks
import { getCourseDropdownOptions } from "../helpers";
import { useApplyTranslations } from "@hooks";

type CourseOptionsDropdownProps = {
  onListItemSelect: (item: DropdownItem) => void;
};

const CourseOptionsDropdown: FC<CourseOptionsDropdownProps> = ({ onListItemSelect }) => {
  const { t } = useApplyTranslations();
  const dropdownOptions = getCourseDropdownOptions();

  return (
    <Dropdown
      list={dropdownOptions}
      placement="bottom-end"
      fixPlacement
      onListItemSelect={onListItemSelect}
      onClick={(e): void => e.stopPropagation()}
    >
      <Tooltip content={t("courseEdit.courseOptions")}>
        <ActionSecondaryButton
          data-testid="course-options-button"
          aria-label={t("a11y.courseEdit.courseOptionsButton")}
        >
          <GearIconSVG height={32} />
        </ActionSecondaryButton>
      </Tooltip>
    </Dropdown>
  );
};

export default CourseOptionsDropdown;
