import { Button, Tooltip } from "@epignosis_llc/gnosis";
import React, { ComponentProps, FC } from "react";
import { SparklesSolidSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";
import { GenerateImageButtonStyles } from "./styles";
import { SerializedStyles } from "@emotion/react";

type GenerateImageButtonProps = {
  tooltipPlacement?: ComponentProps<typeof Tooltip>["placement"];
  onClick?: () => void;
  overrideStyles?: SerializedStyles;
};

export const GenerateImageButton: FC<GenerateImageButtonProps> = ({
  tooltipPlacement: placement = "bottom",
  onClick,
  overrideStyles,
}) => {
  const { t } = useApplyTranslations();

  return (
    <div css={[GenerateImageButtonStyles, overrideStyles]}>
      <Tooltip content={t("ai.generateImageButtonTooltip")} placement={placement}>
        <Button rounded onClick={onClick} id="primary-button">
          <SparklesSolidSVG height={32} />
        </Button>
      </Tooltip>
    </div>
  );
};
