import { DraggableProvided } from "@hello-pangea/dnd";
import { ScoreRule } from "types/entities/Courses";
import { ReorderActions } from "./constants";
import { Course, MyUnit, Section } from "types/entities";

export type CourseEditRouterParams = {
  courseId: string;
};

export type SidebarContentProps = {
  course: Course;
  sections: Section[];
};

export type CourseEditProps = {
  course: Course;
  sections: Section[];
};

export type SectionReorderState = {
  selectedSection: Section | null;
  selectedUnits: number[];
};

export type ReorderActionType =
  | { type: ReorderActions.setSection; payload: SectionReorderState }
  | { type: ReorderActions.unsetSection; payload: SectionReorderState }
  | { type: ReorderActions.addUnit; payload: { unitId: number } }
  | { type: ReorderActions.removeUnit; payload: { unitId: number } };

export type ReorderDispatch = (action: ReorderActionType) => void;

export type UnitListItemProps = {
  course: Course;
  unit: Section | MyUnit;
  canBeReordered: boolean;
  isReordered: boolean;
  sectionReorderState: SectionReorderState;
  reorderDispatch: ReorderDispatch;
  provided: DraggableProvided;
  isDragging: boolean;
};

export type DeleteParams = {
  unitId: string;
  shouldDeleteLinkedUnits?: boolean;
};

/** *** COURSE rules types *****/

export enum TraversalRule {
  Any = "any",
  Sequential = "sequential",
}

export enum CompletionRule {
  AllUnits = "all_units",
  UnitPercentage = "unit_percentage",
  CertainUnits = "certain_units",
  PassedTest = "passed_test",
}

export type ScoreUnit = {
  id: number;
  weight: number; // Min 1, max 20,
};

export type CourseRulesEditData = {
  traversal_rule: TraversalRule;
  completion_rule: CompletionRule;
  completion_percentage?: number | null; // Min 0.1, max 99.9, nullable, required if CompletionRule.UnitPercentage
  completion_unit_ids?: number[] | null; // Nullable required if CompletionRule.CertainUnits or CompletionRule.PassedTest
  score_rule: ScoreRule;
  score_units?: ScoreUnit[] | null;
  learning_paths?: number[][] | null; // Max 4
};

export type CourseRulesFormData = Omit<CourseRulesEditData, "learning_paths"> & {
  learning_paths_obj?: LearningPathObj[] | null; // Max 4
};

export type LearningPathObj = {
  rule_set: number;
  courses: number[];
};

export type cloneCourseUnitData = {
  unitId: string;
  targetCourseId?: string;
};

export type linkCourseUnitData = {
  unitId: string;
  targetCourseId?: string;
};

export type CourseEditAIState = {
  isAIImageGenerationAvailable: boolean;
  isAIDescriptionAvailable: boolean;
  isImagePromptOpen: boolean;
  setIsImagePromptOpen: (isOpen: boolean) => void;
  isImageGenerationStarting: boolean;
  isImageWorking: boolean;
  setIsImageWorking: (isImageWorking: boolean) => void;
  isDescriptionWorking: boolean;
  setIsDescriptionWorking: (isDescriptionWorking: boolean) => void;
  generateImage: (prompt: string) => void;
  generateImageAndDescription: (prompt: string) => void;
};
