import React, { FC, PropsWithChildren, RefObject, useCallback, useEffect, useState } from "react";

type TrailingContentViewProps = { contentRef?: RefObject<HTMLDivElement> } & PropsWithChildren;

/**
 * A component that always positions its children at the end of the content of a contentEditable component, whose ref is
 * provided via the `contentRef` prop. ContentTrailingView's children are guaranteed to trail the last line of content,
 * regardless of length and language orientation.
 *
 * Requirements:
 * - This component MUST be a SIBLING of the contentEditable component.
 * - Their parent element MUST have a CSS position value of: `absolute`, `relative` OR `fixed`.
 *
 * Note:
 * In order for this component to work reliably for all text directionalities, a hidden view needs to be injected
 * at the end of the contentEditable's content.
 *
 * @example
 * ```typescript
 * const contentRef = useRef<HTMLDivElement>(null);
 *
 * return <>
 *   <div contentEditable ref={contentRef}></div>
 *   <ContentTrailingView contentRef={contentRef}>Trailing Content</div>
 * </>
 * ```
 */
const ContentTrailingView: FC<TrailingContentViewProps> = ({ children, contentRef }) => {
  const [position, setPosition] = useState<{ x: number; y: number } | null>(null);
  const isRTL = contentRef?.current && getComputedStyle(contentRef.current).direction === "rtl";

  const createTrailingView = (): HTMLSpanElement => {
    const trailingView: HTMLSpanElement = document.createElement("span");
    trailingView.id = "trailing-view";
    trailingView.contentEditable = "false";
    trailingView.style.display = "inline-block";
    trailingView.style.minWidth = "1px";
    trailingView.style.minHeight = "1px";
    trailingView.style.verticalAlign = "top";
    return trailingView;
  };

  // Ensures that the last element inside the content is always the returned trailing view.
  const ensureTrailingView = useCallback(() => {
    const divRef = contentRef?.current;
    if (!divRef) return;

    const trailingView: HTMLSpanElement =
      divRef.querySelector("#trailing-view") ?? createTrailingView();
    if (divRef.lastChild !== trailingView) {
      divRef.appendChild(trailingView);
    }

    return trailingView;
  }, [contentRef]);

  // Removes any <br> tags inserted by the browser. This happens when deleting the entire content at once.
  const cleanUpBrTags = useCallback(() => {
    const divRef = contentRef?.current;
    if (!divRef) {
      return;
    }

    for (const node of divRef.childNodes) {
      if (node.nodeName === "BR") {
        divRef.removeChild(node);
      }
    }
  }, [contentRef]);

  // Ensures the trailingView is injected into the content and uses its position to calculate the current position.
  const updatePosition = useCallback(() => {
    const trailingView = ensureTrailingView();
    if (!trailingView) {
      setPosition(null);
      return;
    }

    const parentRect = trailingView.parentElement?.parentElement?.getBoundingClientRect();
    if (!parentRect) {
      setPosition(null);
      return;
    }

    const rect = trailingView.getBoundingClientRect();
    setPosition({ x: rect.x - parentRect.x, y: rect.y - parentRect.y });
    cleanUpBrTags();
  }, [cleanUpBrTags, ensureTrailingView]);

  useEffect(() => {
    ensureTrailingView();
    cleanUpBrTags();

    const divRef = contentRef?.current;
    if (!divRef) {
      return undefined;
    }

    updatePosition();

    // Required to preserve trailing white-space while typing.
    divRef.style.whiteSpace = "pre-wrap";

    const observer = new MutationObserver(updatePosition);
    observer.observe(divRef, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    window.addEventListener("resize", updatePosition);

    return () => {
      observer?.disconnect();
      window.removeEventListener("resize", updatePosition);
    };
  }, [cleanUpBrTags, contentRef, ensureTrailingView, updatePosition, isRTL]);

  return (
    <>
      {position && (
        <div
          style={{
            position: "absolute",
            top: position.y,
            left: position.x,
            // When in RTL mode, ensure trailing-edge alignment for children & content.
            transform: isRTL ? "translateX(-100%)" : "",
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default ContentTrailingView;
