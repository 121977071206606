import React, { FC } from "react";
import { Link } from "react-router-dom";

import { BrandLogoSVG } from "@assets/images";
import { mainHeaderContainer } from "./styles";

import { HamburgerButton } from "@layouts/components";
import { TopBarMenu } from "./TopBarMenu";
import { SkipLink } from "@components";

import { useConfigurationStore, useUIStore } from "@stores";
import { useApplyTranslations } from "@hooks";
import { useResponsive } from "ahooks";
import { URLS } from "@constants/urls";

type MainHeaderProps = {
  hideContent?: boolean;
};

const MainHeader: FC<MainHeaderProps> = ({ hideContent = false }) => {
  const { t } = useApplyTranslations();
  const { mainDrawer, toggleCollapsedMainNav, showMainDrawer, hideMainDrawer } = useUIStore(
    (state) => state,
  );
  const { sm, md } = useResponsive();
  const { domainSettings } = useConfigurationStore();
  const logo = domainSettings?.logo?.default;

  const handleDrawer = (): void => {
    if (md) {
      if (mainDrawer.show) {
        hideMainDrawer();
      }

      toggleCollapsedMainNav();
      return;
    }

    mainDrawer.show ? hideMainDrawer() : showMainDrawer("mainMenu");
  };

  return (
    <header css={mainHeaderContainer} id="main-header" role="banner">
      <SkipLink focusId="scroll-container" text={t("a11y.skipLink")} />
      <div className="left-header-block">
        <HamburgerButton onClick={(): void => handleDrawer()} />
        <Link data-testid="logo" to={URLS.dashboard} className="logo" aria-label="TalentLMS logo">
          {logo ? <img src={logo} alt="platform logo" /> : <BrandLogoSVG height={sm ? 25 : 20} />}
        </Link>
      </div>

      <div className="middle-header-block">
        {!hideContent && (
          <div className="main-header-content">
            {/* Toolbar content exists in page component */}
            <div id="toolbar" data-testid="toolbar" />
          </div>
        )}
      </div>

      <div className="right-header-block">
        <TopBarMenu />
      </div>
    </header>
  );
};

export default MainHeader;
