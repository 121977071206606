import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";
import { COLORS } from "@styles";

export const courseHeaderStyles = ({ courseOverview }, { isSticky }) => css`
  display: flex;
  gap: 2rem;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;

  .action-buttons {
    position: absolute;
    top: 1rem;
    inset-inline-start: 1rem;
    display: flex;
    gap: 0.5rem;
  }

  .start-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    color: ${courseOverview.headerColor};
    min-height: 2.5rem;
    flex: 1;

    .additional-info-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .restrictions-wrapper {
        align-items: center;
        display: flex;
        gap: 1rem;
      }

      .course-acquired-tag {
        gap: 0.25rem;
        padding: 0.325rem 0.5rem;
      }

      .provider-information-subtext {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .end-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    min-width: 400px;
    width: 400px;
    color: ${courseOverview.headerColor};
    min-height: 2.5rem;
    align-items: ${isSticky ? "end" : "normal"};

    .thumbnail-image {
      width: 100%;
      min-height: 275px;
      aspect-ratio: 3/2;
      object-fit: fill;
    }
  }

  .clickable-text {
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
  }
`;

export const titleContainerStyles = ({ courseEdit: { error } }) => css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .editable-name-container {
    position: relative;
    z-index: 1;

    .ai-generate {
      border-radius: 50%;
      margin-inline-start: 0.5rem;

      svg {
        color: ${COLORS.primary};
      }
    }
  }

  .name-error {
    color: ${error.color};
    background-color: ${error.backgroundColor};
    width: fit-content;
    margin: 0.5rem 0 0;
  }
  .name {
    cursor: default;
  }
`;

export const courseCodeStyles = (theme, { canEditOptions }) => css`
  width: fit-content;
  cursor: ${canEditOptions ? "pointer" : "default"};

  &:hover {
    text-decoration: ${canEditOptions ? "underline" : "none"};
  }
`;

export const categoryTextStyles = (theme, { canEditOptions }) => css`
  width: fit-content;
  margin-bottom: 1rem;
  cursor: ${canEditOptions ? "pointer" : "default"};

  .category {
    word-break: break-word;
    font-style: italic;

    &:hover {
      text-decoration: ${canEditOptions ? "underline" : "none"};
    }
  }
`;

export const infoTagStyles = ({ button: { primaryLight } }, { shouldShowEdit }) => css`
  gap: 2px;
  color: inherit;
  width: fit-content;
  padding: 0 0.5rem 0 0;
  cursor: ${shouldShowEdit ? "pointer" : "default"};
  background-color: ${primaryLight.default.background};
  transition: background-color 0.2s ease-in;
  height: 2rem;

  &:hover {
    background-color: ${shouldShowEdit
      ? primaryLight.hover.background
      : primaryLight.default.background};
  }
`;

export const ratingContainer = ({ courseOverview }) => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-inline-start: -0.5rem;

  &.average-rating-wrapper {
    flex-direction: row;
    gap: 0.5rem;
  }

  ${mq["sm"]} {
    flex-direction: row;
    gap: 0;
  }

  .rating-info-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    justify-content: center;

    ${mq["sm"]} {
      justify-content: flex-start;
    }
  }

  .average-rating {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .upper-dot::after {
    content: "";
    position: absolute;
    background-color: ${courseOverview.mobileDot};
    width: 2px;
    height: 2px;
    border-radius: 50%;

    ${mq["sm"]} {
      background-color: ${courseOverview.headerColor};
    }
  }

  .reset-btn {
    height: auto;

    &:hover {
      color: ${courseOverview.headerColor};
    }
  }
`;

export const introVideoModalBody = css`
  .intro-video {
    aspect-ratio: 3/2;
  }
`;

export const restrictionsStyles = (
  { button: { primaryLight }, courseOverview },
  { hasOneRestriction },
) => css`
  height: 2rem;

  &.mobile-view {
    border: 1px solid ${courseOverview.bundlesBorder};
    border-radius: 5px;
    padding: 12px;
    height: 100%;
  }

  .restriction-list-item {
    display: flex;
    align-items: center;
  }

  .restriction-item {
    display: flex;
    align-items: center;
    justify-content: ${hasOneRestriction ? "center" : "initial"};
    gap: 0.5rem;
    transition: background-color 0.2s ease-in;

    ${mq["sm"]} {
      justify-content: initial;
    }

    &.clickable-item {
      cursor: pointer;

      &:hover {
        text-decoration: underline;

        .restriction-item-icon {
          background-color: ${primaryLight.hover.background};
        }
      }
    }

    .restriction-item-icon {
      height: 2rem;
      width: 2rem;
      border-radius: 5px;

      ${mq["sm"]} {
        background-color: ${primaryLight.default.background};
      }
    }

    button {
      height: 2rem;
      min-width: 2rem;
      padding: 0;
      cursor: initial;
    }
  }

  .restrictions-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      display: ${hasOneRestriction ? "none" : "initial"};
      margin: 0;
    }
  }

  .dropdown-list-item.readonly {
    color: initial;
  }

  .text-container {
    display: flex;
    flex-direction: column;
  }
`;

export const startButtonStyles = ({ courseOverview }) => css`
  width: 100%;

  button.mobile-button {
    .price,
    .previous-price {
      color: inherit !important;
    }
  }

  button.start-button {
    width: 100%;
    background-color: ${courseOverview.startButton.background};
    font-weight: 700;
    border-color: ${courseOverview.startButton.background};

    &:not(.disabled):hover {
      color: ${courseOverview.startButton.hoverColor};
      background-color: ${courseOverview.startButton.background};
    }

    &.read-only:hover {
      color: ${courseOverview.startButton.color};
    }

    &:hover {
      border-color: ${courseOverview.startButton.background};
    }

    &.read-only {
      cursor: initial;
    }

    .price {
      color: inherit;
    }
  }
`;

export const bundlesStyles = () => css`
  .dropdown-list-item svg {
    min-width: 2rem;
  }

  .clickable-text {
    cursor: pointer;
    font-weight: 700;
  }
`;

export const mobileBundlesStyles = ({ courseOverview }) => css`
  border: 1px solid ${courseOverview.bundlesBorder};
  border-radius: 5px;
  padding: 12px;

  .bundle-list-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .bundle-icon {
      height: 32px;
    }

    .bundle-label {
      word-break: break-all;
    }
  }
`;

export const courseHeaderMobileStyles = ({ courseOverview }) => css`
  min-height: 300px;

  .back-link-container {
    .back-button {
      position: absolute;
      top: 1rem;
      inset-inline-start: 1rem;
    }
  }

  .back-button-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    position: fixed;
    z-index: 20;
    height: 56px;
    width: 100%;
    top: 0;
    background: ${courseOverview.background};
    padding: 0 1rem;

    .course-name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .mobile-cover-image {
    width: 100%;
    margin-top: 1rem;
    padding: 0 1rem 1rem 1rem;

    img {
      object-fit: fill;
      margin: auto;
      width: 100%;
    }
  }

  .mobile-title {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 72px;
    padding: 0 1rem;

    .category {
      font-style: italic;
    }

    .code {
      margin-top: 0.5rem;
    }
  }
`;
