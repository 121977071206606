import React, { FC, useState } from "react";
import { SerializedStyles, useTheme } from "@emotion/react";
import { Input, Label, Tooltip } from "@epignosis_llc/gnosis";
import { InfoCircledSVG } from "@epignosis_llc/gnosis/icons";

import { editableInputContainer } from "./styles";

export type EditableInputProps = {
  label: string;
  required?: boolean;
  className?: string;
  placeholder?: string;
  tooltipContent?: string;
  defaultValue?: string;
  showLabel?: boolean;
  onChange?: (value: string) => void;
};

const EditableInput: FC<EditableInputProps> = ({
  label,
  required = false,
  placeholder,
  defaultValue,
  tooltipContent,
  className,
  showLabel = true,
  onChange,
}) => {
  const { autocompleteInput } = useTheme();
  const [inputValue, setInputValue] = useState(defaultValue);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setInputValue(value);
    onChange && onChange(value);
  };

  return (
    <div
      css={(): SerializedStyles => editableInputContainer(autocompleteInput, { required })}
      className={className}
    >
      {showLabel && (
        <div className="label-container">
          {label && <Label>{label}</Label>}
          {tooltipContent && (
            <Tooltip content={tooltipContent} parentProps={{ "aria-label": tooltipContent }}>
              <InfoCircledSVG height={20} />
            </Tooltip>
          )}
        </div>
      )}

      <Input
        id="editable-input"
        placeholder={placeholder}
        required={required}
        value={inputValue}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default EditableInput;
