// Packages or third-party libraries
import React, { FC } from "react";
import { Tooltip } from "@epignosis_llc/gnosis";

// Styles
import { sidebarIconStyles } from "./styles";

// Types
import { IconType } from "types/common";

type SidebarIconProps = {
  icon: IconType;
  tooltipContent: string | JSX.Element;
};

const SidebarIcon: FC<SidebarIconProps> = ({ icon: Icon, tooltipContent }) => {
  return (
    <div css={sidebarIconStyles}>
      <Tooltip content={tooltipContent} parentProps={{ "aria-label": tooltipContent }}>
        <div className="icon-container">
          <Icon height={32} />
        </div>
      </Tooltip>
    </div>
  );
};

export default SidebarIcon;
