import React, { FC } from "react";
import FooterActions from "./FooterActions";
import { Course } from "types/entities";
import { myCourseCardFooterContainer } from "./styles";
import LeftActions from "../../components/LeftActions";

export type MyCourseCardFooterProps = {
  course: Course;
  children?: never;
};

const MyCourseCardFooter: FC<MyCourseCardFooterProps> = ({ course }) => {
  const isCourseAvailable = Boolean(course.availability?.is_available);
  const hideMaxItems = !isCourseAvailable && course.availability?.reason !== "cancelled";

  return (
    <div css={myCourseCardFooterContainer}>
      <LeftActions course={course} hideMaxItems={hideMaxItems} />

      <FooterActions course={course} />
    </div>
  );
};

export default MyCourseCardFooter;
