import { AxiosError } from "axios";
import { HandledError, handledApiError } from "./errors";
import { t } from "@utils/i18n";

// Error messages

export const skillErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.skill_not_found",
    errorMsg: "skills.errors.skillNotAvailable",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

// Error handlers

export const handleSkillErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    skillErrors,
    hideNotification,
    t("skills.errors.skillNotAvailable"),
    handleError,
  );
};
