// Packages or third-party libraries
import React, { FC, ReactNode, useEffect, useState } from "react";
import { Button, Text, Tooltip } from "@epignosis_llc/gnosis";
import { CourseExitSVG, AssignmentPassedAnswer, CourseTypeSVG } from "@epignosis_llc/gnosis/icons";
import { Link, useNavigate } from "react-router-dom";
import { SerializedStyles } from "@emotion/react";

// Styles
import { courseHeaderStyles } from "../../styles";

// Components
import CoverImage from "../../CoverImage";
import InfoTag from "../../InfoTag";
import IntoVideo from "../../IntoVideo";
import TitleContainer from "../../TitleContainer";
import CourseButton from "./CourseButton";
import Restrictions from "./Restrictions";

// Utils, hooks
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore, useUnitStore } from "@stores";

// Other imports
import { Course, CourseCategory } from "types/entities";
import { getCourseCategoryText } from "../../helpers";
import { OtherProvidersCourse, OtherProvidersCourses } from "types/entities/Courses";
import { isOtherProviderCourse } from "@components/Cards/course/CatalogCard/helpers";
import OtherProvidersCourseButton from "./OtherProvidersCourseButton";
import { URLS } from "@constants/urls";

type CourseHeaderProps = {
  course: Course | OtherProvidersCourse;
  categoryTree: CourseCategory[];
  backLink?: string;
  isTalentLibrary?: boolean;
  providerCourses?: OtherProvidersCourses[];
  setIsEnrollDrawerOpen?: (isOpen: boolean) => void;
};

const CourseStoreCourseHeader: FC<CourseHeaderProps> = ({
  course,
  categoryTree,
  backLink,
  isTalentLibrary,
  providerCourses,
  setIsEnrollDrawerOpen,
}) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();
  const { isCourseHeaderSticky } = useUnitStore();
  const { domainSettings } = useConfigurationStore();
  const { features_allowed_in_plan, is_in_trial: isTrialEnabled } = domainSettings ?? {};
  const { talent_library: isTalentLibraryEnabled } = features_allowed_in_plan ?? {};
  const [filteredProviderCourses, setFilteredProviderCourses] = useState<
    OtherProvidersCourses[] | undefined
  >([]);

  const { name, category, code, cover_image, id: courseId } = course;

  const { categoryText, allCategoryParent } = getCourseCategoryText({ category, categoryTree });
  const showAdditionalInfo = !isCourseHeaderSticky;
  const isCourseAcquired = !isOtherProviderCourse(course)
    ? Boolean(course.mapped_course_id)
    : Boolean(course.has_course);
  const introVideo = course.intro_video;
  const otherProviderCoursePrice = isOtherProviderCourse(course) ? course.price?.amount : null;

  const shouldShowInfoTag = isCourseAcquired && (isTalentLibrary || otherProviderCoursePrice);

  useEffect(() => {
    setFilteredProviderCourses(
      providerCourses?.filter((providerCourse) => {
        return providerCourse.id !== courseId;
      }),
    );
  }, [courseId, providerCourses]);

  const renderTooltipContent = (): ReactNode | undefined => {
    return filteredProviderCourses?.map((providerCourse) => {
      const link = URLS.courseStore.createOtherProviderCourseLink({
        courseId: providerCourse.id?.toString(),
      });
      return (
        <Text
          key={providerCourse.id}
          fontSize="sm"
          as="div"
          className="tooltip-item"
          style={{ padding: "0.75rem 0.25rem" }}
        >
          <Link to={link} state={{ isTalentLibrary: false }} style={{ color: "inherit" }}>
            <CourseTypeSVG height={16} style={{ marginRight: "0.5rem", color: "inherit" }} />
            {providerCourse.name}
          </Link>
        </Text>
      );
    });
  };

  return (
    <div
      css={(theme): SerializedStyles =>
        courseHeaderStyles(theme, { isSticky: isCourseHeaderSticky })
      }
    >
      {backLink && (
        <Button
          noGutters
          color="primaryLight"
          className="back-button"
          aria-label={t("a11y.courses.courseBackButton")}
          onClick={(): void => navigate(backLink)}
        >
          <CourseExitSVG height={32} />
        </Button>
      )}

      <div className="start-container">
        <TitleContainer
          category={category}
          categoryText={categoryText}
          allCategoryParent={allCategoryParent}
          name={name}
          code={code}
        />

        {showAdditionalInfo && (
          <div className="additional-info-container">
            {Boolean(shouldShowInfoTag) && (
              <InfoTag className="course-acquired-tag">
                <AssignmentPassedAnswer height={16} />
                {isTalentLibrary
                  ? t("courseCatalog.youHaveCourse")
                  : t("courseCatalog.licensesAvailable", { count: course.remaining_licenses })}
              </InfoTag>
            )}
            <div className="restrictions-wrapper">
              {!isTalentLibraryEnabled && isTalentLibrary && <Restrictions />}
            </div>
            {!isTalentLibrary && isOtherProviderCourse(course) && (
              <div className="other-provider-course-info">
                <Text fontSize="sm" as="span">
                  {t("courseStore.courseProviderText", {
                    name: course.provider.name,
                    count: filteredProviderCourses?.length ?? 0,
                  })}
                </Text>
                {Boolean(filteredProviderCourses?.length) && (
                  <Tooltip content={renderTooltipContent()} as="span" placement="bottom-start">
                    <Text fontSize="sm" as="span" className="provider-information-subtext">
                      {t("courseStore.courseProviderTextCheckOut")}
                    </Text>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="end-container">
        <CoverImage coverImage={cover_image} courseName={name} />
        {isTalentLibrary && !isOtherProviderCourse(course) ? (
          <CourseButton
            courseId={courseId.toString()}
            mappedCourseId={course.mapped_course_id?.toString()}
            isCourseAcquired={isCourseAcquired}
            isTalentLibraryEnabled={isTalentLibraryEnabled}
            isTrialEnabled={isTrialEnabled}
          />
        ) : (
          <OtherProvidersCourseButton
            isCourseAcquired={isCourseAcquired}
            coursePrice={course.price}
            mappedCourseId={course.mapped_course_id?.toString()}
            setIsEnrollDrawerOpen={setIsEnrollDrawerOpen}
          />
        )}
        {introVideo && <IntoVideo introVideo={introVideo} name={name} />}
      </div>
    </div>
  );
};

export default CourseStoreCourseHeader;
