import { AxiosError } from "axios";
import { HandledError, handledApiError } from "./errors";
import { t } from "@utils/i18n";
import { genericErrors } from "./errorMessages";

// Error messages

export const unitErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.unit_not_available",
    errorMsg: "errors.units.notAvailable",
  },
  {
    status: 404,
    id: "not_found.unit_not_found",
    errorMsg: "errors.units.notFound",
  },
  {
    status: 409,
    id: "conflict.course_traversal_not_allowed",
    errorMsg: "errors.units.traversalNotAllowed",
  },
  {
    status: 409,
    id: "conflict.course_has_not_started_yet",
    errorMsg: "errors.units.courseNotStarted",
  },
  {
    status: 409,
    id: "conflict.prerequisites_not_completed",
    errorMsg: "errors.units.prerequisitesError",
  },
  {
    status: 409,
    id: "conflict.course_is_not_active",
    errorMsg: "errors.units.courseNotActive",
  },
  {
    status: 409,
    id: "conflict.course_level_has_not_been_reached",
    errorMsg: "errors.units.levelNotReached",
  },
  {
    status: 409,
    id: "conflict.unit_completion_not_allowed.incorrectanswers",
    errorMsg: "notifications.incorrectAnswer",
  },
  // Uncomment when the API removes status 500 and replace it with 409
  //   {
  //     Status: 500,
  //     Id: "server_error",
  //     ErrorMsg: "errors.units.accessExpired",
  //   },
];

export const testUnitExportErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.unit_not_found",
    errorMsg: "errors.units.notFound",
  },
  {
    status: 409,
    id: "conflict.unit_type_invalid",
    errorMsg: "errors.units.invalidUnitType",
  },
];

export const iltUnitErrors: HandledError[] = [
  ...genericErrors,
  {
    status: 400,
    id: "bad_request",
    errorMsg: "ilt.errors.badRequest",
  },
  {
    status: 404,
    id: "not_found.unit_not_found",
    errorMsg: "errors.units.notFound",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
  {
    status: 404,
    id: "not_found.calendar_event_not_found",
    errorMsg: "errors.units.sessionNotFound",
  },
];

// Error handlers

export const handleUnitErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    unitErrors,
    hideNotification,
    t("general.somethingWentWrongPleaseTryAgain"),
    handleError,
  );
};

export const handleTestUnitExportError = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    testUnitExportErrors,
    hideNotification,
    t("errors.units.notFound"),
    handleError,
  );
};

export const handleIltUnitErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    iltUnitErrors,
    hideNotification,
    t("general.somethingWentWrongPleaseTryAgain"),
    handleError,
  );
};
