import { css } from "@emotion/react";
import { typeScale } from "@epignosis_llc/gnosis";

export const creditsContainer = ({ payments }) => css`
  .price-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .price-value {
      align-items: baseline;
    }

    .price,
    .previous-price,
    .credits-text {
      font-size: ${typeScale["2xl"]};
      font-weight: 400;
      color: ${payments.priceColor};
    }

    .previous-price {
      font-size: ${typeScale["lg"]};
      text-decoration: line-through;
      color: ${payments.prevPriceColor};
    }
  }

  .available-credits-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;

    .info-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .processor-btn {
      height: auto;
    }
  }

  .credits-num {
    color: ${payments.creditsValid};

    &.not-enough-credits {
      color: ${payments.creditsInvalid};
    }
  }
`;

export const paymentHeaderContainer = ({ payments }) => css`
  display: flex;
  align-items: flex-start;

  .course-img,
  .bundle-img {
    max-width: 110px;
    margin-inline-end: 0.75rem;
  }

  .course-img {
    border-radius: 3px;
  }

  .bundle-img {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 5px;
    background: ${payments.bundleBackground};
    padding: 0.5rem;
  }

  .course-info {
    .course-code {
      color: ${payments.code};
    }

    .course-category,
    .course-code,
    .course-name {
      word-break: break-all;
    }
  }
`;

export const couponContainer = ({ payments }) => css`
  .input-field-wrapper {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
  }

  .input-container {
    max-width: 195px;
  }

  .invalid-coupon-text,
  .valid-coupon-text {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    svg {
      margin-inline-end: 0.5rem;
    }
  }

  .valid-coupon-text {
    margin-inline-start: 0.25rem;
    color: ${payments.couponValid};

    svg {
      color: ${payments.couponValid};
    }
  }

  .add-coupon-btn {
    padding: 0;
    height: auto;
  }
`;

export const paymentDrawerStyles = ({ payments }) => css`
  .section-divider {
    margin: 2rem 0;
    height: 1px;
    color: ${payments.sectionDivider};
    background-color: ${payments.sectionDivider};
    border: none;
  }

  .invalid-coupon-container {
    margin-bottom: 1rem;
  }
`;

export const paymentMethodsContainer = ({ subscription }) => css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 0.5rem;

  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .card-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border: 2px ${subscription.headerBackground} solid;
    border-radius: 5px;
    width: 100%;
    max-height: 48px;

    svg {
      color: ${subscription.plans.textColor};
    }

    &.is-selected {
      background: ${subscription.buttonBackground};
      border: 1px ${subscription.headerText} solid;
    }
  }
`;

export const subscriptionDrawerStyles = ({ payments }) => css`
  .title {
    margin-bottom: 0.5rem;
  }

  .section-divider {
    margin: 2rem 0;
    height: 1px;
    color: ${payments.sectionDivider};
    background-color: ${payments.sectionDivider};
    border: none;
  }
`;

export const paymentFooterStyles = css`
  .redirection-info {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
`;
