import { css } from "@emotion/react";

export const container = ({ price }) => css`
  display: flex;

  .previous-price {
    margin-inline-start: 0.5rem;
    color: ${price.prevValueColor};
    text-decoration: line-through;
  }

  .price {
    color: ${price.valueColor};
  }
`;
