import HttpClient from "@api/HttpClient";
import { transformEntitiesToIds } from "./helpers";
import { formatDateForTimezone } from "@utils/helpers";

import { ENDPOINTS } from "@api/endpoints";
import {
  EditCreditsBranches,
  EditCreditsGroups,
  EditCreditsUsers,
  EcommerceSettings,
  EditCreditsSettings,
  InvoicesListRes,
  CouponsListRes,
  CouponsRes,
  EcommercePaidCoursesRes,
  CouponsSingleRes,
} from "./types";
import { TableCSVExportRes } from "types/responses";

export const getEcommerceSettings = async (): Promise<EcommerceSettings> => {
  const res = await HttpClient.get(ENDPOINTS.accountAndSettings.ecommerce.settings);

  return res.data._data;
};

export const patchEcommerceSettings = async (
  ecommerceSettings: EcommerceSettings,
): Promise<EcommerceSettings> => {
  const res = await HttpClient.patch(
    ENDPOINTS.accountAndSettings.ecommerce.settings,
    ecommerceSettings,
  );

  return res.data._data;
};

export const getEcommerceUsers = async (queryStr = ""): Promise<EditCreditsUsers> => {
  const res = await HttpClient.get(`${ENDPOINTS.accountAndSettings.ecommerce.users}${queryStr}`);

  return res.data;
};

export const getEcommerceGroups = async (queryStr = ""): Promise<EditCreditsGroups> => {
  const res = await HttpClient.get(`${ENDPOINTS.accountAndSettings.ecommerce.groups}${queryStr}`);

  return res.data;
};

export const getEcommerceBranches = async (queryStr = ""): Promise<EditCreditsBranches> => {
  const res = await HttpClient.get(`${ENDPOINTS.accountAndSettings.ecommerce.branches}${queryStr}`);

  return res.data;
};

export const postEcommerceCredits = async (
  giveEcommerceCredits: EditCreditsSettings,
): Promise<EditCreditsSettings> => {
  const { mode, entity_id, amount } = giveEcommerceCredits;

  const config = {
    params: {
      mode,
      entity_id,
      amount,
    },
  };

  const res = await HttpClient.post(
    ENDPOINTS.accountAndSettings.ecommerce.credits,
    giveEcommerceCredits,
    config,
  );
  return res.data;
};

export const deleteEcommerceCredits = async (
  removeEcommerceCredits: EditCreditsSettings,
): Promise<EditCreditsSettings> => {
  const { mode, entity_id, amount } = removeEcommerceCredits;

  const config = {
    params: {
      mode,
      entity_id,
      amount,
    },
  };

  const res = await HttpClient.delete(ENDPOINTS.accountAndSettings.ecommerce.credits, config);
  return res.data;
};

export const getEcommerceInvoices = async (searchQuery = ""): Promise<InvoicesListRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.accountAndSettings.ecommerce.invoices}${searchQuery}`,
  );
  return res.data;
};

export const exportInvoices = async (queryStr = ""): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(`${ENDPOINTS.tableExports.invoicesListExport}${queryStr}`);
  return res.data;
};

export const downloadInvoice = async (invoiceId: string): Promise<string> => {
  const res = await HttpClient.get(
    ENDPOINTS.accountAndSettings.ecommerce.downloadInvoice(invoiceId),
    {
      responseType: "arraybuffer",
      responseEncoding: "binary",
    },
  );

  return res.data;
};

export const getEcommerceCoupons = async (searchQuery = ""): Promise<CouponsListRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.accountAndSettings.ecommerce.coupons}${searchQuery}`,
  );
  return res.data;
};

export const postNewEcommerceCoupon = async (
  { valid_from, valid_to, redemptions, ...rest }: CouponsRes,
  timezone: string,
): Promise<CouponsRes> => {
  const adjustedData = {
    ...rest,
    valid_from: formatDateForTimezone(valid_from, timezone),
    valid_to: formatDateForTimezone(valid_to, timezone),
    redemptions: redemptions?.max === 0 ? null : redemptions,
  };
  const res = await HttpClient.post(ENDPOINTS.accountAndSettings.ecommerce.coupons, adjustedData);
  return res.data;
};

export const deleteEcommerceCoupon = async (couponId: string): Promise<void> => {
  const res = await HttpClient.delete(
    `${ENDPOINTS.accountAndSettings.ecommerce.singleCoupon(couponId)}`,
  );
  return res.data;
};

export const getEcommerceSingleCoupon = async (couponId: number): Promise<CouponsSingleRes> => {
  const res = await HttpClient.get(
    ENDPOINTS.accountAndSettings.ecommerce.singleCoupon(couponId.toString()),
  );
  return res.data;
};

export const getEcommercePaidCourses = async (
  searchQuery = "",
): Promise<EcommercePaidCoursesRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.accountAndSettings.ecommerce.courses}${searchQuery}`,
  );
  return res.data;
};

export const getEcommercePaidCategories = async (
  searchQuery = "",
): Promise<EcommercePaidCoursesRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.accountAndSettings.ecommerce.categories}${searchQuery}`,
  );
  return res.data;
};

export const getEcommercePaidGroups = async (
  searchQuery = "",
): Promise<EcommercePaidCoursesRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.accountAndSettings.ecommerce.groups}${searchQuery}`,
  );
  return res.data;
};

export const putEditEcommerceCoupon = async (
  { valid_from, valid_to, categories, courses, groups, ...rest }: CouponsRes,
  couponId: number,
  timezone: string,
): Promise<void> => {
  const adjustedData = {
    ...rest,
    categories: transformEntitiesToIds(categories),
    courses: transformEntitiesToIds(courses),
    groups: transformEntitiesToIds(groups),
    valid_from: formatDateForTimezone(valid_from, timezone),
    valid_to: formatDateForTimezone(valid_to, timezone),
  };
  await HttpClient.put(
    ENDPOINTS.accountAndSettings.ecommerce.singleCoupon(couponId.toString()),
    adjustedData,
  );
};

export const exportCoupons = async (queryStr = ""): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(`${ENDPOINTS.tableExports.ecommerceCouponsExport}${queryStr}`);
  return res.data;
};
