import React, { FC, useCallback, useEffect, useState } from "react";
import { Text, Button, Color } from "@epignosis_llc/gnosis";
import { CloseModalSVG } from "@epignosis_llc/gnosis/icons";

import { dialogBoxStyles, overlayStyles } from "./DialogBox.styles";

import { TextWithIcon } from "@components";
import { IconType } from "types/common";

type ActionButtonProps = {
  onClick: () => void;
  text: string;
  isLoading?: boolean;
  color?: Color;
  variant?: "solid" | "outline" | "ghost" | "link";
};

type WithDialogBoxProps = {
  title: string;
  show: boolean | undefined;
  children: React.ReactNode;
  icon?: IconType;
  primaryButton?: ActionButtonProps;
  secondaryButton?: ActionButtonProps;
  onClose?: () => void;
};

const DialogBox: FC<WithDialogBoxProps> = ({
  show = false,
  title,
  icon: Icon,
  children,
  primaryButton,
  secondaryButton,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    text: primaryButtonText,
    color: primaryButtonColor = "primary",
    isLoading: primaryButtonLoading,
    onClick: onPrimaryButtonClick,
  } = primaryButton ?? {};

  const {
    text: secondaryButtonText,
    isLoading: secondaryButtonLoading,
    variant: secondaryButtonVariant = "link",
    onClick: onSecondaryButtonClick,
  } = secondaryButton ?? {};

  const hasActionButtons = primaryButton || secondaryButton;

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const handleOnClose = useCallback((): void => {
    setIsOpen(false);
    onClose?.();
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <>
      <div
        css={overlayStyles}
        className="overlay"
        onClick={handleOnClose}
        data-testid="dialog-box-overlay"
      />
      <div
        css={dialogBoxStyles}
        className="dialog-box-container"
        data-testid="dialog-box-container"
      >
        <div className="dialog-box-header">
          <TextWithIcon
            className="dialog-box-title"
            label={
              <Text as="p" weight="700" fontSize="md">
                {title}
              </Text>
            }
            icon={Icon && <Icon height={32} />}
          />

          <span className="close-btn" data-testid="dialog-box-close-btn" onClick={handleOnClose}>
            <CloseModalSVG height="32" />
          </span>
        </div>

        <div className="dialog-box-body" data-testid="dialog-box-body">
          {children}
        </div>
        {hasActionButtons && (
          <div className="action-buttons">
            {primaryButton && (
              <Button
                color={primaryButtonColor}
                onClick={onPrimaryButtonClick}
                isLoading={primaryButtonLoading}
              >
                {primaryButtonText}
              </Button>
            )}
            {secondaryButton && (
              <Button
                variant={secondaryButtonVariant}
                onClick={onSecondaryButtonClick}
                isLoading={secondaryButtonLoading}
              >
                {secondaryButtonText}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DialogBox;
