import React, { ReactElement } from "react";
import { Breadcrumbs as BreadcrumbsGnosis } from "@epignosis_llc/gnosis";
import { useLocation } from "react-router-dom";
import { BreadcrumbsWrapperStyles } from "./Breadcrumbs.styles";
import useBreadcrumbs from "./useBreadcrumbs";
import { useApplyTranslations } from "@hooks";

const Breadcrumbs = (): ReactElement | null => {
  const location = useLocation();
  // In case there's a search query, we need to include it in the path (mostly for accounts & settings)
  const currentPath = location.pathname + location.search;
  const breadcrumbItems = useBreadcrumbs(currentPath);
  const { t } = useApplyTranslations();

  if (!breadcrumbItems.length) return null;

  return (
    <div css={BreadcrumbsWrapperStyles} className="breadcrumbs-wrapper">
      <BreadcrumbsGnosis
        navAriaLabel={t("breadcrumbs.breadcrumbs")}
        linkAriaLabel={(link: string): string => t("breadcrumbs.goTo", { link })}
        items={breadcrumbItems}
      />
    </div>
  );
};

export default Breadcrumbs;
