// Packages or third-party libraries
import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import classNames from "classnames";

// Styles
import { startButtonStyles } from "../styles";

// Components
import { Price } from "@components";

// Other imports
import { useUIStore } from "@stores";
import { Course } from "types/entities";
import { courseOptionsIds, TabKey } from "@views/CourseEdit/components/CourseOptions";

type CourseEditButtonProps = {
  course: Course;
  canEditOptions?: boolean;
};

const CourseEditButton: FC<CourseEditButtonProps> = ({ course, canEditOptions = false }) => {
  const classnames = classNames("start-button", { "read-only": !canEditOptions });

  const { price, discounted_price } = course;
  const hasPrice = price && price.amount !== 0;

  const { setCourseOptionsState } = useUIStore();

  const handlePriceClick = (): void => {
    if (!canEditOptions) return;

    setCourseOptionsState({
      isOpen: true,
      activeTab: TabKey.Info,
      scrollId: courseOptionsIds.priceOptions,
    });
  };

  const getButtonText = (): JSX.Element | null => {
    if (!hasPrice) return null;

    return (
      <Price
        value={discounted_price ? discounted_price.amount : price.amount}
        prevValue={discounted_price ? price.amount : undefined}
      />
    );
  };

  const buttonText = getButtonText();
  if (!buttonText) return null;

  return (
    <div css={startButtonStyles} onClick={handlePriceClick}>
      <Button color="primaryDarker" variant="outline" className={classnames}>
        {buttonText}
      </Button>
    </div>
  );
};

export default CourseEditButton;
