const DEFAULT_FOCUSABLE_SELECTORS = ["a", "button", "input", "textarea", "select", "[tabindex]"];

/**
 * Helper function to update the tabindex and aria-hidden attributes.
 * Ensures that an element is non-focusable and hidden from assistive technologies.
 * It only sets the attributes if they are not already set to avoid unnecessary DOM updates.
 *
 * @param {Element} element - The element for which the tabindex and aria-hidden attributes will be updated.
 */

const updateAccessibilityAttributes = (element: Element): void => {
  const currentTabIndex = element.getAttribute("tabindex");
  const currentAriaHidden = element.getAttribute("aria-hidden");

  // Only set tabindex="-1" if it's not already set
  if (currentTabIndex !== "-1") {
    element.setAttribute("tabindex", "-1");
  }

  // Only set aria-hidden="true" if it's not already set
  if (currentAriaHidden !== "true") {
    element.setAttribute("aria-hidden", "true");
  }
};

/**
 * Updates tabindex and aria-hidden attributes for slick-slide elements and their focusable child elements.
 * For any element with aria-hidden="true", both the slide and its focusable child elements will be made non-focusable
 * and hidden from assistive technologies by setting tabindex="-1" and aria-hidden="true".
 *
 * @param {string} selector - The CSS selector used to target slick-slide elements.
 * @param {string[]} focusableSelectors - An array of selectors to identify focusable child elements inside each slick-slide (defaults to common focusable elements).
 */

export const updateTabindexForSlickSlides = (
  selector: string,
  focusableSelectors: string[] = DEFAULT_FOCUSABLE_SELECTORS, // Default value here
): void => {
  // Get all slick-slide elements based on the selector
  const elements = document.querySelectorAll(selector);

  elements.forEach((element) => {
    const isAriaHidden = element.getAttribute("aria-hidden") === "true";

    // If the slide is hidden (aria-hidden="true"), make it and its children non-focusable
    if (isAriaHidden) {
      // Update tabindex and aria-hidden for the element itself
      updateAccessibilityAttributes(element);

      // Select and update all focusable elements inside the element
      const focusableElements = element.querySelectorAll(focusableSelectors.join(", "));
      focusableElements.forEach(updateAccessibilityAttributes);
    }
  });
};
