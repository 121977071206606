// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Button, Grid, Label, Radio } from "@epignosis_llc/gnosis";
import { AxiosError } from "axios";

// Components
import { ConfirmationModal, RangeInput } from "@components";

// Utils, stores, hooks
import { useApplyTranslations } from "@hooks";
import { useMutation, useQueryClient } from "react-query";
import { handleUnitErrors } from "@errors";
import queryKeys from "@constants/queryKeys";

// Other imports
import { Section } from "types/entities";
import { SetDelayData, setDelayAvailability } from "@views/UnitEdit/api";
import { formContainer } from "@components/ReusableDrawers/styles";
import { maxDurationDays, maxDurationTime } from "./constants";

type SectionDelayModalProps = {
  isOpen: boolean;
  section: Section;
  onClose: () => void;
};

type DefaultValues = {
  defaultDelay: number;
  defaultMode: string;
  defaultMaxDuration: number;
};

const SectionDelayModal: FC<SectionDelayModalProps> = ({ isOpen, section, onClose }) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();

  const { id, availability } = section;

  const { defaultDelay, defaultMode, defaultMaxDuration } = calculateDefaultValues();

  const [delay, setDelay] = useState<number>(defaultDelay);
  const [delayMode, setDelayMode] = useState<string>(defaultMode);
  const [maxDuration, setMaxDuration] = useState<number>(defaultMaxDuration);

  const sectionId = id.toString();

  const { mutateAsync: setDelayAvailabilityMutation, isLoading: setDelayAvailabilityLoading } =
    useMutation(
      [queryKeys.courses.unitUpdate, sectionId],
      (data: SetDelayData): Promise<void> => {
        return setDelayAvailability(sectionId, data);
      },
      {
        onSettled: () => onClose(),
        onSuccess: () => {
          queryClient.invalidateQueries([queryKeys.units]);
        },
        onError: (err): void => {
          const error = err as AxiosError;
          handleUnitErrors(error);
        },
      },
    );

  function calculateDefaultValues(): DefaultValues {
    let defaultDelay = 0;
    let defaultMode = "hours";
    let defaultMaxDuration = maxDurationTime;

    if (availability && availability.absolute_delay) {
      const delayInHours = availability.absolute_delay / 60 / 60;
      const hoursOverMaxLimit = delayInHours > maxDurationTime;

      defaultMode = hoursOverMaxLimit ? "days" : "hours";
      defaultMaxDuration = hoursOverMaxLimit ? maxDurationDays : maxDurationTime;
      defaultDelay = hoursOverMaxLimit ? delayInHours / 24 : delayInHours;
    }
    return { defaultDelay, defaultMode, defaultMaxDuration };
  }

  function handleModeChange(
    e: React.ChangeEvent<HTMLInputElement>,
    maxDuration: React.SetStateAction<number>,
  ): void {
    const value = e.target.value;
    setDelay(0);
    setDelayMode(value);
    setMaxDuration(maxDuration);
  }

  function handleSaveDelay(): Promise<void> {
    return setDelayAvailabilityMutation({
      mode: delayMode,
      hours: delayMode === "hours" ? delay : undefined,
      days: delayMode === "days" ? delay : undefined,
    });
  }

  return (
    <ConfirmationModal
      id={sectionId}
      isOpen={isOpen}
      header={t("general.delayAvailability")}
      bodyTitle={
        <section css={formContainer}>
          <Grid templateColumns={[1, 1]} rowGap={1.5} columnGap={1} className="grid-container">
            <Grid.Item colSpan={1}>
              <Label> {t("general.setTime")}</Label>
              <div className="range-input-wrapper">
                <RangeInput
                  aria-label={t("general.delayAvailability")}
                  id="delayTime"
                  name="delayTime"
                  min={0}
                  max={maxDuration}
                  value={delay}
                  step={1}
                  data-testid="delay-availability-duration"
                  onChange={(value): void => {
                    setDelay(Number(value));
                  }}
                />
                <Radio
                  name="hours_mode"
                  key="hours_mode"
                  id="hours_mode"
                  checked={delayMode === "hours"}
                  value="hours"
                  label={t("general.hours")}
                  inline
                  data-testid="hours-mode-availability"
                  onChange={(e): void => handleModeChange(e, maxDurationTime)}
                />
                <Radio
                  name="days_mode"
                  key="days_mode"
                  id="days_mode"
                  checked={delayMode === "days"}
                  value="days"
                  label={t("general.dayCapital_other")}
                  inline
                  data-testid="days-mode-availability"
                  onChange={(e): void => handleModeChange(e, maxDurationDays)}
                />
              </div>
            </Grid.Item>
          </Grid>
        </section>
      }
      onClose={onClose}
      footerButton={
        <Button isLoading={setDelayAvailabilityLoading} onClick={handleSaveDelay}>
          {t("courseEdit.saveDelay")}
        </Button>
      }
    />
  );
};

export default SectionDelayModal;
