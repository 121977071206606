// Packages or third-party libraries
import React, { FC } from "react";
import { Button, InputError, Select, Tooltip } from "@epignosis_llc/gnosis";
import { TrashAltSVG } from "@epignosis_llc/gnosis/icons";

// Components
import { PathItem } from "@components/ReusableComponents";

// Hooks
import { useApplyTranslations } from "@hooks";

// Other imports
import { GroupedOption, SelectOption } from "types/common";

type LearningPathSelectProps = {
  id: string;
  placeholder: string;
  options: GroupedOption[];
  value?: SelectOption[];
  error?: string;
  canDelete?: boolean;
  hideLine?: boolean;
  onChange: (selectedIds: number[]) => void;
  onDelete?: () => void;
};

const LearningPathSelect: FC<LearningPathSelectProps> = ({
  id,
  placeholder,
  options,
  value,
  error,
  canDelete = false,
  hideLine = false,
  onChange,
  onDelete,
}) => {
  const { t } = useApplyTranslations();

  return (
    <PathItem>
      <PathItem.Separator hideLine={hideLine} />
      <PathItem.Content>
        <div className="learning-path-content-container">
          <div>
            <Select
              aria-label={t("a11y.courses.selectLearningPath")}
              id={id}
              className="learning-path-select"
              placeholder={placeholder}
              options={options}
              value={value}
              isSearchable
              isMulti
              minWidth="100%"
              onChange={(options): void => {
                const selectedOptions = options as SelectOption[];
                const selectedIds = selectedOptions.map((option) => Number(option.value));
                onChange(selectedIds);
              }}
            />
            {error && <InputError>{error}</InputError>}
          </div>

          {canDelete && (
            <Tooltip content={t("courseEdit.learningPath.delete")}>
              <Button
                variant="ghost"
                aria-label="Delete learning path"
                noGutters
                title={t("courseEdit.learningPath.delete")}
                onClick={(): void => onDelete && onDelete()}
              >
                <TrashAltSVG height={14} />
              </Button>
            </Tooltip>
          )}
        </div>
      </PathItem.Content>
    </PathItem>
  );
};

export default LearningPathSelect;
