import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { generalNotification } from "@utils/helpers";
import { t } from "@utils/i18n";
import queryKeys from "@constants/queryKeys";
import {
  deleteCourseImage,
  generateCourseImage,
  getCourseAIJobs,
  postCourseImage,
} from "@views/CourseEdit/api";
import { AIJobFilters } from "@views/CourseEdit/constants";
import { filterAIJobs, useAIJobPolling } from "@hooks/useAIJobPolling";
import { AxiosError } from "axios";

type UseEditImageProps = { postImage: (file: File) => void; deleteImage: () => void };

export const useEditImage = (courseId: string): UseEditImageProps => {
  const queryClient = useQueryClient();

  const { mutate: postImage } = useMutation(
    [queryKeys.courses.postImage, courseId],
    (file: File) => postCourseImage(file, courseId),
    {
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
      },
      onSuccess: () => {
        generalNotification("success", <p>{t("courseEdit.postCourseImageSuccess")}</p>);
      },
      onError: () => {
        generalNotification("error", <p>{t("courseEdit.postCourseImageError")}</p>);
      },
    },
  );

  const { mutate: deleteImage } = useMutation(() => deleteCourseImage(courseId), {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
    },
    onSuccess: () => {
      generalNotification("success", <p>{t("courseEdit.deleteCourseImageSuccess")}</p>);
    },
    onError: () => {
      generalNotification("error", <p>{t("courseEdit.deleteCourseImageError")}</p>);
    },
  });

  return {
    postImage: (file: File): void => postImage(file),
    deleteImage: (): void => deleteImage(),
  };
};

type UseGenerateCourseImageProps = {
  isGeneratingAIImage: boolean;
  isRequestingImageGeneration: boolean;
  generateCourseImage: (aiPrompt: string) => void;
};

export const useGenerateCourseImage = (
  courseId: string,
  onRequestImageGenerationSuccess?: () => void,
): UseGenerateCourseImageProps => {
  const queryClient = useQueryClient();
  const [isGeneratingAIImage, setIsGeneratingAIImage] = useState(false);
  const [isRequestingAIImageGeneration, setIsRequestingAIImageGeneration] = useState(false);

  const { course_image: courseImageJob, startPolling } = useAIJobPolling(
    false,
    (signal) => getCourseAIJobs(courseId, signal),
    AIJobFilters,
  );

  const { mutate: generateCourseImageMutation } = useMutation(
    (aiPrompt: string) => generateCourseImage(courseId, aiPrompt),
    {
      onError: (error: AxiosError) => {
        if (error?.response?.status === 429) {
          generalNotification("warning", <p>{t("ai.errors.imageGenerationAlreadyInProgress")}</p>);
        } else {
          generalNotification("error", <p>{t("ai.errors.imageGenerationFailed")}</p>);
        }

        setIsGeneratingAIImage(false);
      },
      onSuccess: () => {
        onRequestImageGenerationSuccess?.();
        setIsGeneratingAIImage(true);
      },
      onSettled: () => {
        setIsRequestingAIImageGeneration(false);
      },
      onMutate: () => {
        setIsRequestingAIImageGeneration(true);
      },
    },
  );

  // Get the intial state of AI job(s)
  useQuery(
    [queryKeys.courseAIJobs, courseId, JSON.stringify(AIJobFilters)],
    () => getCourseAIJobs(courseId),
    {
      onSuccess: (res) => {
        const { course_image } = filterAIJobs(AIJobFilters, res._data ?? []);
        setIsGeneratingAIImage(course_image?.status === "working");
      },
    },
  );

  useEffect(() => {
    if (isGeneratingAIImage) {
      startPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeneratingAIImage]);

  useEffect(() => {
    if (!courseImageJob) {
      return;
    }

    if (courseImageJob?.status === "failed") {
      setIsGeneratingAIImage(false);
      return;
    }

    if (courseImageJob?.status === "working") {
      return;
    }

    if (courseImageJob?.status === "completed") {
      queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
      setIsGeneratingAIImage(false);
      return;
    }
  }, [queryClient, courseId, courseImageJob]);

  return {
    isGeneratingAIImage,
    isRequestingImageGeneration: isRequestingAIImageGeneration,
    generateCourseImage: generateCourseImageMutation,
  };
};
