// Packages or third-party libraries
import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { DurationIconSVG } from "@epignosis_llc/gnosis/icons";

// Components
import InfoTag from "./InfoTag";

// Utils, hooks
import { formatUtcDate } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { useUIStore } from "@stores";

// Other imports
import { courseOptionsIds, TabKey } from "@views/CourseEdit/components/CourseOptions";

type ExpiresDateProps = {
  expiresAt: string | null;
  isEdit?: boolean;
  canEditOptions?: boolean;
  tagClassName?: string;
};

const shouldShowDate = (expiresAt: string | null, isEdit: boolean): boolean => {
  // On course edit show date if there is set an expire date
  if (isEdit) return Boolean(expiresAt);

  const today = Date.parse(new Date().toISOString());
  return expiresAt ? Date.parse(expiresAt) >= today : false;
};

const ExpiresDate: FC<ExpiresDateProps> = ({
  expiresAt,
  isEdit = false,
  canEditOptions = false,
  tagClassName,
}) => {
  const { t } = useApplyTranslations();
  const { setCourseOptionsState } = useUIStore();

  const handleDateClicked = (): void => {
    if (!canEditOptions) return;

    setCourseOptionsState({
      isOpen: true,
      activeTab: TabKey.Limits,
      scrollId: courseOptionsIds.timeOptions,
    });
  };

  const showDate = shouldShowDate(expiresAt, isEdit);
  if (!showDate || !expiresAt) return null;

  return (
    <InfoTag onClick={handleDateClicked} canEditOptions={canEditOptions} className={tagClassName}>
      <DurationIconSVG height={32} />
      <Text
        fontSize="sm"
        as="div"
        dangerouslySetInnerHTML={{
          __html: t("restrictions.expires", {
            expiresAt: `<strong>${formatUtcDate(expiresAt)}</strong>`,
          }),
        }}
      />
    </InfoTag>
  );
};

export default ExpiresDate;
