import { Button, Card, Text } from "@epignosis_llc/gnosis";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useResponsive } from "ahooks";
import { hoverWrapper } from "../styles";
import CardActions, { Actions } from "../../components/CardActions";
import { images } from "@constants/assets";
import { Course } from "types/entities";
import { IconType } from "types/common";
import { OtherProvidersCourses } from "types/entities/Courses";
import { useApplyTranslations } from "@hooks";

type CardHeaderProps = {
  course: Course | OtherProvidersCourses;
  actions: Actions[];
  allowCourseUnitsView?: boolean;
  isCustomHomePage?: boolean;
  courseLink: string;
  icon: IconType;
  isDisabled?: boolean;
  routeState?: {
    isCustomHomePage: boolean;
    pathname: string;
  };
  hoverText?: string;
};

const CardHeader: FC<CardHeaderProps> = (props) => {
  const { sm } = useResponsive();
  const { t } = useApplyTranslations();
  const { Thumbnail, Hover } = Card;

  const {
    course,
    actions,
    allowCourseUnitsView = true,
    routeState = { isCustomHomePage: false, pathname: "" },
    courseLink,
    icon: Icon,
    isDisabled = false,
    hoverText,
  } = props;

  return (
    <>
      {!sm && allowCourseUnitsView ? (
        <Link to={courseLink}>
          <Thumbnail
            src={course.cover_image?.default ?? images.cover}
            alt={t("a11y.courses.thumbnailForCourse", { name: course.name })}
            data-testid="course-image"
          />
        </Link>
      ) : (
        <Thumbnail
          src={course.cover_image?.default ?? images.cover}
          alt={t("a11y.courses.thumbnailForCourse", { name: course.name })}
          data-testid="course-image"
        />
      )}

      {sm && !isDisabled && (
        <Hover>
          <div css={hoverWrapper}>
            <CardActions actions={actions} />

            {allowCourseUnitsView && (
              <div className="hover-info-container">
                <Button
                  rounded
                  as={Link}
                  to={courseLink}
                  state={routeState}
                  aria-label={`Play ${course.name}`}
                  className="hover-btn"
                  data-testid="action-button"
                >
                  <Icon height={38} />
                </Button>

                {hoverText && (
                  <Text fontSize={"lg"} weight="700" as="div" className="hover-text">
                    {hoverText}
                  </Text>
                )}
              </div>
            )}
          </div>
        </Hover>
      )}
    </>
  );
};

export default CardHeader;
