// Packages or third-party libraries
import React, { FC } from "react";
import { Button, Select, Tooltip } from "@epignosis_llc/gnosis";
import { DownloadIconSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { TabContainer } from "../styles";

// Components
import { AnswerPreview } from "@components";

// Utils, hooks
import { formatUtcDate, downloadFile } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

// Other imports
import { Answer, SingleSubmission } from "types/entities";
import { SelectOption } from "types/common";
import { GradeFormData } from "types/entities/Assignments";

type SubmissionTabProps = {
  submission: SingleSubmission;
  selectedAnswer: Answer | null;
  handleAnswerSelect: (answer: Answer) => void;
  handleValueChange: (name: keyof GradeFormData, value: string) => void;
};

const SubmissionTab: FC<SubmissionTabProps> = ({
  selectedAnswer,
  submission,
  handleAnswerSelect,
  handleValueChange,
}): JSX.Element => {
  const { t } = useApplyTranslations();

  const { submissions: leanerAnswers } = submission ?? {};
  const { file, text } = selectedAnswer ?? {};

  const options = leanerAnswers?.map((sub, index, array) => {
    const value = sub.id.toString();
    const newIndex = array.length - index - 1;

    const label = `${t("gradingHub.submission")} #${newIndex + 1}, ${formatUtcDate(
      sub.date,
      "datetime",
    )}`;

    return { value, label };
  });

  const handleDownloadFile = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    const { url, name, id } = file ?? {};
    if (!url) return;

    e.preventDefault();
    await downloadFile({ fileUrl: url, fileName: name ?? "", fileId: id });
  };

  const fileUrl = file?.url;
  const defaultValue = options?.find((option) => option.value === selectedAnswer?.id.toString());

  return (
    <div css={TabContainer}>
      <Select
        name="submission"
        label={t("gradingHub.submission")}
        aria-label={t("gradingHub.submission")}
        options={options}
        defaultValue={defaultValue}
        value={defaultValue}
        onChange={(option): void => {
          const { value } = option as SelectOption;
          const selectedAnswer = leanerAnswers?.find(
            (sub) => sub.id.toString() === value,
          ) as Answer;
          handleAnswerSelect(selectedAnswer);
          handleValueChange("comments", selectedAnswer?.instructor_comment?.text ?? null);
        }}
      />

      <AnswerPreview textAnswer={text} file={file} />

      {fileUrl && (
        <div className="download-button">
          <Tooltip content={t("general.download")}>
            <Button
              variant="ghost"
              noGutters
              onClick={handleDownloadFile}
              className="export-to-csv-btn"
            >
              <DownloadIconSVG height={32} />
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default SubmissionTab;
