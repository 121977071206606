import React, { FC } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { CheckCircledSVG, ExclamationCircledSVG } from "@epignosis_llc/gnosis/icons";
import classNames from "classnames";
import { useConfigurationStore } from "@stores";
import { Course, Bundle } from "types/entities";
import { useApplyTranslations } from "@hooks";
import { Processors } from "types/entities/Catalog";
import { getTabTitle } from "../helpers";

export type AvailableCreditsProps = {
  data: Course | Bundle;
  processors: Processors[];
  handleButtonClick: () => void;
};

const creditsClassNames = (hasEnoughCredits: boolean): string =>
  classNames({
    "credits-num": true,
    "not-enough-credits": !hasEnoughCredits,
  });

const AvailableCredits: FC<AvailableCreditsProps> = ({ data, processors, handleButtonClick }) => {
  const { t } = useApplyTranslations();
  const { userProfileData } = useConfigurationStore();
  const { price, discounted_price } = data;
  const { credits = 0 } = userProfileData ?? {};
  const hasEnoughCredits = discounted_price
    ? discounted_price.amount <= credits
    : (price?.amount as number) <= credits;

  const translationText =
    credits === 0 ? "general.creditsAvailable" : "general.creditsAvailableStrong";

  const showOtherProcessorsText = !hasEnoughCredits && processors.length > 1;

  return (
    <div className="available-credits-info-wrapper">
      <div className="info-item">
        {hasEnoughCredits ? (
          <CheckCircledSVG height={16} className={creditsClassNames(true)} />
        ) : (
          <ExclamationCircledSVG height={16} className={creditsClassNames(false)} />
        )}
        <Text
          fontSize="sm"
          as="div"
          className={creditsClassNames(hasEnoughCredits)}
          dangerouslySetInnerHTML={{
            __html: t(translationText, { count: userProfileData?.credits ?? 0 }),
          }}
        />
      </div>

      {showOtherProcessorsText && (
        <div className="info-item">
          <ExclamationCircledSVG height={16} />
          <div>
            <Text fontSize="sm">{t("payments.youMayAlsoPayWith")}</Text>
            <Button noGutters variant="link" className="processor-btn" onClick={handleButtonClick}>
              {getTabTitle(processors[1])}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableCredits;
