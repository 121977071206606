import { RichText } from "types/entities";

export const checkTextValue = (announcements: string | RichText): string => {
  const textValue =
    typeof announcements === "string"
      ? announcements
      : announcements.html !== announcements.text
        ? announcements.html
        : announcements.text;

  return textValue;
};
