import React, { FC } from "react";
import {
  AISpinnerContainerStyles,
  AIActivityIndicatorStyles,
  PromptContainerStyles,
} from "./styles";
import { AISpinner } from "../AISpinner/AISpinner";
import { SerializedStyles } from "@emotion/react";

type AIActivityIndicatorProps = {
  text?: string;
  overrideStyles?: SerializedStyles;
};

export const AIActivityIndicator: FC<AIActivityIndicatorProps> = ({ text, overrideStyles }) => {
  return (
    <div
      id="ai-activity-indicator"
      css={[(theme): SerializedStyles => AIActivityIndicatorStyles(theme), overrideStyles]}
    >
      <div
        id="ai-activity-indicator-spinner"
        css={(theme): SerializedStyles => AISpinnerContainerStyles(theme)}
      >
        <AISpinner />
      </div>

      <div id="ai-activity-indicator-prompt" css={PromptContainerStyles}>
        {text}
      </div>
    </div>
  );
};
