import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import {
  CustomUserFieldRes,
  LanguageOverrideRes,
  LanguageOverridesCountRes,
  LanguageOverridesDownloadRes,
  LanguageOverridesRes,
  SCIMKeyRes,
  SSOSettings,
  SSOSettingsRes,
  TestConfigSettingsDataRes,
  TestConfigSettingsRes,
  TestConfigType,
  UsersSettings,
  UsersSettingsRes,
} from "./types";
import { LanguageOverride } from "types/entities/Domain";
import { CustomFieldRes, TableCSVExportRes } from "types/responses";
import { CustomField } from "types/entities";

/** * Users Settings  ***/

export const getUsersSettings = async (): Promise<UsersSettingsRes> => {
  const res = await HttpClient.get(ENDPOINTS.accountAndSettings.usersSettings.usersSettings);
  return res.data;
};

export const updateUsersSettings = async (
  usersSettings: UsersSettings,
): Promise<UsersSettingsRes> => {
  const res = await HttpClient.patch(
    ENDPOINTS.accountAndSettings.usersSettings.usersSettings,
    usersSettings,
  );

  return res.data;
};

/** * Custom user fields ***/

export const getCustomUserField = async (
  customUserFieldId: string,
): Promise<CustomUserFieldRes> => {
  const res = await HttpClient.get(`
    ${ENDPOINTS.accountAndSettings.usersSettings.customUserFieldsId(customUserFieldId)}`);
  return res.data;
};

export const getCustomUserFields = async (searchQuery = ""): Promise<CustomFieldRes> => {
  const res = await HttpClient.get(`
    ${ENDPOINTS.accountAndSettings.usersSettings.customUserFields}${searchQuery}`);
  return res.data;
};

export const addCustomUserField = async (customUserField: CustomField): Promise<CustomFieldRes> => {
  const res = await HttpClient.post(
    ENDPOINTS.accountAndSettings.usersSettings.customUserFields,
    customUserField,
  );

  return res.data;
};

export const editCustomUserField = async (
  customUserField: CustomField,
  customUserFieldId: string,
): Promise<CustomFieldRes> => {
  const res = await HttpClient.post(
    ENDPOINTS.accountAndSettings.usersSettings.customUserFieldsId(customUserFieldId),
    customUserField,
  );

  return res.data;
};

export const editCustomUserFieldOrder = async (
  customUserFieldsOrder: number[],
): Promise<CustomFieldRes> => {
  const res = await HttpClient.post(
    ENDPOINTS.accountAndSettings.usersSettings.customUserFieldsReorder,
    { ids: customUserFieldsOrder },
  );

  return res.data;
};

export const deleteCustomUserField = async (customUserFieldId: string): Promise<void> => {
  const res = await HttpClient.delete(
    ENDPOINTS.accountAndSettings.usersSettings.customUserFieldsId(customUserFieldId),
  );

  return res.data;
};

export const getCustomUserFieldsExports = async (queryStr = ""): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(`${ENDPOINTS.tableExports.customUserFields}${queryStr}`);
  return res.data;
};

/** * SSO Settings  ***/

export const getSSOSettings = async (): Promise<SSOSettingsRes> => {
  const res = await HttpClient.get(ENDPOINTS.accountAndSettings.usersSettings.ssoSettings);
  return res.data;
};

export const generateSCIMKey = async (): Promise<SCIMKeyRes> => {
  const res = await HttpClient.get(ENDPOINTS.accountAndSettings.usersSettings.generateSCIMKey);
  return res.data;
};

export const updateSSOSettings = async (ssoSettings: SSOSettings): Promise<SSOSettingsRes> => {
  const res = await HttpClient.patch(
    ENDPOINTS.accountAndSettings.usersSettings.ssoSettings,
    ssoSettings,
  );

  return res.data;
};

export const testSSOConfig = async (
  testConfigSettings: TestConfigType,
): Promise<TestConfigSettingsRes> => {
  const res = await HttpClient.post(
    ENDPOINTS.accountAndSettings.usersSettings.ssoConfiguration,
    testConfigSettings,
  );

  return res.data;
};

export const getTestSSOConfigData = async (token: string): Promise<TestConfigSettingsDataRes> => {
  const res = await HttpClient.get(
    ENDPOINTS.accountAndSettings.usersSettings.ssoConfigurationData(token),
  );
  return res.data;
};

/** * Language Overrides  ***/

export const getLanguageOverrides = async (
  locale: string,
  searchQuery = "",
): Promise<LanguageOverridesRes> => {
  const res = await HttpClient.get(
    ENDPOINTS.accountAndSettings.usersSettings.languageOverridesCode(`${locale}${searchQuery}`),
  );
  return res.data;
};

export const addLanguageOverride = async (
  languageOverride: LanguageOverride,
  locale: string,
): Promise<LanguageOverrideRes> => {
  const res = await HttpClient.post(
    ENDPOINTS.accountAndSettings.usersSettings.languageOverridesCode(locale),
    languageOverride,
  );

  return res.data;
};

export const editLanguageOverride = async (
  languageOverride: LanguageOverride,
  id: string,
): Promise<LanguageOverrideRes> => {
  const res = await HttpClient.put(
    ENDPOINTS.accountAndSettings.usersSettings.languageOverridesId(id),
    languageOverride,
  );

  return res.data;
};

export const deleteLanguageOverride = async (id: string): Promise<void> => {
  const res = await HttpClient.delete(
    ENDPOINTS.accountAndSettings.usersSettings.languageOverridesId(id),
  );

  return res.data;
};

export const downloadLanguageOverrides = async (
  locale: string,
): Promise<LanguageOverridesDownloadRes> => {
  const res = await HttpClient.get(
    ENDPOINTS.accountAndSettings.usersSettings.languageOverridesCodeExport(locale),
  );

  return res.data;
};

export const getLanguageOverridesCount = async (
  locale: string,
  searchQuery = "",
): Promise<LanguageOverridesCountRes> => {
  const res = await HttpClient.get(
    ENDPOINTS.accountAndSettings.usersSettings.languageOverridesCodeCount(
      `${locale}${searchQuery}`,
    ),
  );
  return res.data;
};

export const getLanguageOverridesExports = async (
  languageCode: string,
  queryStr = "",
): Promise<TableCSVExportRes> => {
  const res = await HttpClient.post(
    `${ENDPOINTS.tableExports.languageOverridesExport(languageCode)}${queryStr}`,
  );
  return res.data;
};
