import { TableProps } from "@epignosis_llc/gnosis";
import { useUserPreferencesStore } from "@stores";
import { QueryFilter } from "types/common";
import { defineSortOrder } from "./view";
import { Pagination as IPagination } from "types/responses/index";
import { SKILL_NAME_FILTER_KEY } from "@views/Skills/constants";

export type DefaultStateType = {
  pagination: IPagination;
  filters: QueryFilter[];
  sorting: TableProps["sorting"];
  searchValue?: string;
  url?: string;
};

const filterSearch = (filters: QueryFilter[]): string => {
  const filterSearch = filters.find(
    (item) =>
      item.key === "[keyword][like]" ||
      item.key === "[name][like]" ||
      item.key === "[description][like]" ||
      item.key === SKILL_NAME_FILTER_KEY,
  );

  return filterSearch ? filterSearch.value : "";
};

const handleTableState = ({
  sorting,
  filters,
  pagination,
  url,
}: DefaultStateType): DefaultStateType => {
  if (!url) {
    return {
      pagination: {
        number: pagination.number,
        size: pagination.size,
      },
      filters,
      sorting,
      searchValue: filterSearch(filters),
      url,
    };
  }

  const userStore = useUserPreferencesStore;
  const { getState: getUserPreferencesState } = userStore();
  const { settings } = getUserPreferencesState() ?? {};

  const sortSetting = settings[url]?.sorting?.[0];
  const sortOrder = sortSetting && defineSortOrder(sortSetting);

  // Build default values from user preferences
  const defaultSorting = sortOrder
    ? {
        column: sortOrder.str,
        isDescending: sortOrder.hasMinus,
      }
    : sorting;

  const filtersTable = settings[url]?.filters as QueryFilter[];
  const defaultFilters = filtersTable?.length ? filtersTable : filters;
  const defaultPagination = settings[url]?.pagination ?? pagination;

  return {
    pagination: {
      number: defaultPagination.number,
      size: defaultPagination.size,
    },
    filters: defaultFilters,
    sorting: defaultSorting,
    searchValue: filterSearch(defaultFilters),
    url,
  };
};

export default handleTableState;
