// Packages or third-party libraries
import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { PlusIconSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { addNewLearningPathContentStyles } from "./styles";

// Components
import { PathItem } from "@components/ReusableComponents";

// Hooks
import { useApplyTranslations } from "@hooks";

type AddNewLearningPathProps = {
  disabled: boolean;
  onAddNew: () => void;
};

const AddNewLearningPath: FC<AddNewLearningPathProps> = ({ disabled, onAddNew: handleAddNew }) => {
  const { t } = useApplyTranslations();
  return (
    <PathItem>
      <PathItem.Separator hideLine isEmpty />
      <PathItem.Content css={addNewLearningPathContentStyles}>
        <Button
          variant="ghost"
          noGutters
          iconBefore={PlusIconSVG}
          disabled={disabled}
          onClick={handleAddNew}
        >
          {t("courseEdit.learningPath.addAlternative")}
        </Button>
      </PathItem.Content>
    </PathItem>
  );
};

export default AddNewLearningPath;
