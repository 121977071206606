import React, { FC } from "react";
import { SerializedStyles } from "@emotion/react";
import { cardProgressBarContainer } from "./styles";
import { Text } from "@epignosis_llc/gnosis";

export type CardProgressBarProps = {
  percent: number;
};

const CardProgressBar: FC<CardProgressBarProps> = ({ percent }) => {
  return (
    <div css={(theme): SerializedStyles => cardProgressBarContainer(theme, { percent })}>
      <div className="percentage-bar" />

      <Text
        dangerouslySetInnerHTML={{
          __html: `${percent}&rlm;%`,
        }}
        fontSize="xs"
        weight="400"
        className="label"
        data-testid="percentage"
      />
    </div>
  );
};

export default CardProgressBar;
