import { Option } from "./AutocompleteInput";

export const stringToOptionArray = (
  inputValue: string,
  shouldTrimWhiteSpace: boolean,
): Option[] => {
  const valueString = shouldTrimWhiteSpace
    ? inputValue
        .split(" ") // Remove white spaces
        .join("")
    : inputValue;

  return valueString
    ? valueString
        .split(";")
        .filter((label) => label.trim() !== "") // Filter out items with empty value
        .map((label) => ({ label: label, value: label }))
    : [];
};

export const optionArrayToString = (options: Option[]): string => {
  return options.map((obj) => obj.label).join(";");
};
