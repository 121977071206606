import React, { FC } from "react";
import { Text, Heading, Tooltip, Button } from "@epignosis_llc/gnosis";
import { StarNoViewboxSVG } from "@epignosis_llc/gnosis/icons";

import { Course } from "types/entities";
import { useConfigurationStore } from "@stores";
import { OtherProvidersCourses } from "types/entities/Courses";
import { getCardBodyActionButtonProperties } from "../CatalogCard/helpers";
import { featureFlags } from "@config";

export type CardBodyProps = React.HTMLAttributes<HTMLElement> & {
  course: Course | OtherProvidersCourses;
  isCatalogView?: boolean;
  showActionButton?: boolean;
  shouldShowReverseTrialTooltip?: boolean;
  isCourseAcquired?: boolean;
  showSubscriptionOnCard?: boolean;
  isOtherProviderCourse?: boolean;
  handleActionButtonClick?: (course: Course | OtherProvidersCourses) => void;
};

const CardBody: FC<CardBodyProps> = ({
  course,
  isCatalogView = false,
  showActionButton = false,
  shouldShowReverseTrialTooltip = false,
  isCourseAcquired = false,
  showSubscriptionOnCard = false,
  isOtherProviderCourse = false,
  handleActionButtonClick,
  ...rest
}) => {
  const { domainSettings, catalogSettings, userProfileData } = useConfigurationStore();
  const showTooltip = course.name.length > 95;
  const isRequestEnabled = false && featureFlags.enrollmentRequests; // TODO: replace with variable from course schema
  const requestStatus = "pending"; // TODO: replace with variable from course schema

  const handleClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    handleActionButtonClick?.(course);
  };

  const showLicenses = isOtherProviderCourse && course.price?.amount !== 0;
  const hasUserActiveSubscription = userProfileData?.subscription?.status === "active";

  const hasActiveSubscription =
    isCatalogView && catalogSettings?.subscription.enabled && hasUserActiveSubscription;

  const showActiveSubscription = Boolean(hasActiveSubscription) && course.price?.amount !== 0;

  const { actionButtonText, actionButtonIcon, actionBtnColor, className } =
    getCardBodyActionButtonProperties(
      isCourseAcquired,
      showSubscriptionOnCard,
      showLicenses,
      showActiveSubscription,
      shouldShowReverseTrialTooltip,
      isRequestEnabled,
      requestStatus,
    );

  const tooltipContent = (
    <Text fontSize="xs" dangerouslySetInnerHTML={{ __html: actionButtonText }} />
  );

  return (
    <div {...rest}>
      {isCatalogView && showActionButton && (
        <div className="catalog-action-btn">
          <Tooltip
            hideOnClick={!shouldShowReverseTrialTooltip}
            content={tooltipContent}
            placement="bottom"
          >
            <Button
              aria-label={actionButtonText}
              data-testid="card-action-btn"
              rounded
              size="sm"
              onClick={(e: React.MouseEvent<HTMLElement>): void => handleClick(e)}
              className={`action-btn ${className ?? ""}`}
              color={actionBtnColor}
            >
              {actionButtonIcon}
            </Button>
          </Tooltip>
        </div>
      )}

      {isCatalogView && domainSettings?.course_rating && course.rating?.average && (
        <div className="rating-container">
          <StarNoViewboxSVG className="rating-star" height={16} />

          <Text fontSize="sm" as="div" weight="700">
            {course.rating.average}
          </Text>
        </div>
      )}

      <Tooltip content={course.name} disabled={!showTooltip} placement="bottom">
        <Heading as="h2" size="md" className="card-title">
          {course.name}
        </Heading>
      </Tooltip>

      <Text fontSize="sm" as="div" className="code">
        {course.code}
      </Text>
    </div>
  );
};

export default CardBody;
