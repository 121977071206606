import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card } from "@epignosis_llc/gnosis";
import { ShowIconSVG, PlayUnitSVG } from "@epignosis_llc/gnosis/icons";
import { bodyWrapper } from "../styles";
import { CardHeader, CardBody, CardFooter } from "../components";
import CatalogCourseCardFooter from "./components/CatalogCourseCardFooter";
import { Course } from "types/entities";
import CourseStoreCardFooter from "./components/CourseStoreCardFooter";
import { getCourseLink, isOtherProviderCourses } from "./helpers";
import { OtherProvidersCourses } from "types/entities/Courses";
import { OpenSesameCourse } from "@views/CourseStore/OpenSesame/types";
import { catalogCardContainerStyles } from "./styles";
import { useConfigurationStore } from "@stores";
import { isCourseButtonDisabled } from "@components/CourseOverview/components/CourseHeader/CourseButton/helpers";
import { useApplyTranslations } from "@hooks";
import { CourseStoreTabs } from "@views/CourseStore/constants";

export type CatalogCardProps = {
  tabKey?: CourseStoreTabs;
  course: Course | OtherProvidersCourses | OpenSesameCourse; // TODO: fix this
  link?: string;
  isStore?: boolean;
  isCustomHomePage?: boolean;
  isDisabled?: boolean;
  isOtherProviderCourse?: boolean;
  handleActionButtonClick?: (course: Course | OtherProvidersCourses) => void;
};

const CatalogCard: FC<CatalogCardProps> = ({
  tabKey,
  isCustomHomePage = false,
  course,
  isStore = false,
  link,
  isDisabled = false,
  isOtherProviderCourse = false,
  handleActionButtonClick,
}) => {
  const { pathname } = useLocation();
  const { t } = useApplyTranslations();
  const { domainSettings, catalogSettings, userProfileData, gamificationSettings } =
    useConfigurationStore();
  const isTalentLibrary = tabKey === CourseStoreTabs.TALENT_LIBRARY;
  const { features_allowed_in_plan, is_in_trial: isInTrial } = domainSettings ?? {};
  const { talent_library: isTalentLibraryEnabled } = features_allowed_in_plan ?? {};
  const { id: courseId, price, availability, level, role_in_course } = course;
  const hasPrice = price && price.amount !== 0;

  const { Header, Body } = Card;

  // When availability is null, means that the user is not enrolled to the course
  const isUserEnrolled = !isOtherProviderCourses(course)
    ? Boolean(course.availability)
    : course.has_course;

  const isCourseAcquired = (): boolean => {
    switch (tabKey) {
      case CourseStoreTabs.TALENT_LIBRARY:
      case CourseStoreTabs.OPEN_SESAME:
        return Boolean(course.mapped_course_id);
      case CourseStoreTabs.OTHER_PROVIDERS:
        return Boolean((course as OtherProvidersCourses).has_course);
      default:
        // When the tabKey is not provided, we assume that the user is navigating as a learner at Course Catalog
        return Boolean(course.availability);
    }
  };

  const icon = isTalentLibrary ? (isUserEnrolled ? PlayUnitSVG : ShowIconSVG) : ShowIconSVG;
  const courseLink = getCourseLink({
    link,
    courseId: courseId.toString(),
  });

  // Users can be redirected to the course overview page from various places.
  // We need to store the link they came from, using router state params.
  const routeState = {
    isCustomHomePage: isCustomHomePage,
    pathname: pathname ?? "",
  };
  const isLevelEnabled = Boolean(gamificationSettings?.levels?.enabled);

  const isGamificationEnabled = Boolean(gamificationSettings?.enabled);

  const isSubscriptionEnabled = Boolean(catalogSettings?.subscription.enabled);
  const userLevel = userProfileData?.level ?? 0;
  const courseLevel = level ?? 0;
  const unlocksOnHigherLevel =
    isLevelEnabled && isGamificationEnabled ? userLevel < courseLevel : false;

  const isButtonDisabled = isCourseButtonDisabled({
    isSubscriptionEnabled,
    isCatalogCourseView: true,
    isLevelRestricted: unlocksOnHigherLevel,
    availability,
    roleInCourse: role_in_course,
  });

  const hasCourse = isTalentLibrary ? isCourseAcquired : Boolean(isUserEnrolled);
  const showActionButton = isCustomHomePage
    ? false
    : isTalentLibrary
      ? isTalentLibraryEnabled
      : hasCourse
        ? true
        : !isButtonDisabled;

  const showSubscriptionOnCard =
    !isTalentLibrary &&
    !isOtherProviderCourse &&
    catalogSettings?.subscription.enabled &&
    userProfileData?.subscription?.status !== "active" &&
    hasPrice;

  return (
    <Link to={isDisabled ? "#" : courseLink} state={routeState} css={catalogCardContainerStyles}>
      <Card hasBorder>
        <Header ratio={[3, 2]}>
          <CardHeader
            routeState={routeState}
            course={course}
            isDisabled={isDisabled}
            actions={[]}
            isCustomHomePage={isCustomHomePage}
            courseLink={courseLink}
            icon={icon}
            hoverText={t("general.view")}
          />
        </Header>

        <div css={bodyWrapper} data-testid="card-body">
          <Body>
            <CardBody
              course={course}
              isCatalogView
              className="body-container"
              showActionButton={showActionButton}
              isCourseAcquired={isCourseAcquired()}
              shouldShowReverseTrialTooltip={isTalentLibrary && isInTrial}
              showSubscriptionOnCard={Boolean(showSubscriptionOnCard)}
              handleActionButtonClick={handleActionButtonClick}
              isOtherProviderCourse={isOtherProviderCourse}
            />
          </Body>

          <CardFooter>
            {isStore ? (
              <CourseStoreCardFooter course={course} isTalentLibrary={isTalentLibrary} />
            ) : (
              !isOtherProviderCourses(course) && <CatalogCourseCardFooter course={course} />
            )}
          </CardFooter>
        </div>
      </Card>
    </Link>
  );
};

export default CatalogCard;
