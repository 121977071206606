// Packages or third-party libraries
import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { LockIconSVG } from "@epignosis_llc/gnosis/icons";
import { SerializedStyles } from "@emotion/react";
import { useNavigate } from "react-router-dom";

// Styles
import { restrictionsStyles } from "../../styles";

// Utils, hooks
import permissions from "@utils/permissions";
import { useApplyTranslations } from "@hooks";

// Other import
import { URLS } from "@constants/urls";

const Restrictions: FC = () => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();
  const { canAccessSubscription } = permissions.accountPermissions;
  const canUpgradePlan = canAccessSubscription();

  return (
    <div css={(theme): SerializedStyles => restrictionsStyles(theme, { hasOneRestriction: true })}>
      <div className="restriction-item">
        <LockIconSVG height={32} className="restriction-item-icon" />
        <Text fontSize="sm">
          <div className="text-container">
            <span> {t("courseStore.getThisCourseTooltip")}</span>
            {canUpgradePlan && (
              <span
                className="clickable-text"
                onClick={(): void => navigate(URLS.subscription.subscription)}
              >
                {t("general.upgradeNow")}
              </span>
            )}
          </div>
        </Text>
      </div>
    </div>
  );
};

export default Restrictions;
