// React and third-party imports
import React, { FC } from "react";

// Components
import { ConfirmationModal } from "@components";

// Hooks and Utils
import { useApplyTranslations } from "@hooks";
import { getFormattedUserName } from "@utils/helpers";

// Other imports
import { IltSession, SubmissionListing } from "types/entities";

type ResetModalProps = {
  selectedSession: SubmissionListing | IltSession | null;
  isResetModalOpen: boolean;
  type: "assignment" | "session";
  closeResetModal: () => void;
  handleOnConfirmResetModal: () => void;
};

const ResetModal: FC<ResetModalProps> = ({
  selectedSession,
  isResetModalOpen,
  type,
  handleOnConfirmResetModal,
  closeResetModal,
}) => {
  const { t } = useApplyTranslations();
  const isSession = type === "session";

  const user = selectedSession?.user;

  const { name = "", surname = "", login = "" } = user ?? {};

  const userName = getFormattedUserName({
    name,
    surname,
    login,
  });

  if (!selectedSession || !isResetModalOpen) return <></>;

  return (
    <ConfirmationModal
      id={selectedSession.id}
      isOpen={isResetModalOpen}
      header={t("assignment.resetStatus")}
      bodyTitle={
        <span
          dangerouslySetInnerHTML={{
            __html: t(
              isSession
                ? "assignment.resetStatusWarningSession"
                : "assignment.resetStatusWarningAssignment",
              {
                name: userName,
              },
            ),
          }}
        />
      }
      bodyText={t("assignment.resetStatusBody")}
      footerButton={t("general.actions.reset")}
      confirmationButtonColor="primary"
      onConfirm={handleOnConfirmResetModal}
      onClose={closeResetModal}
    />
  );
};

export default ResetModal;
