import { css } from "@emotion/react";

export const courseCardWrapperStyles = css`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

export const myCourseCardFooterContainer = ({ courseCard }) => css`
  padding: 0.5rem 1rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .completion-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background: ${courseCard.cardFooter.background};
    height: 2rem;
    border-radius: 5px;

    .icon-container {
      height: 32px;
    }
  }

  .expiration-info {
    display: flex;
    height: 32px;
    background: ${courseCard.cardFooter.background};
    border-radius: 5px;
    width: 32px;

    &.about-to-be-expired {
      background: ${courseCard.cardFooter.aboutToExpireBackground};
    }
  }

  .footer-info {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
`;
