// Packages or third-party libraries
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@epignosis_llc/gnosis";

// Hooks
import { useApplyTranslations } from "@hooks";

// Constants
import { URLS } from "@constants/urls";

type CustomActionButtonsProps = { onClose: () => void; shouldShowUpgradeButton: boolean };

const CustomActionButtons: FC<CustomActionButtonsProps> = ({
  onClose,
  shouldShowUpgradeButton = true,
}) => {
  const navigate = useNavigate();
  const { t } = useApplyTranslations();

  return (
    <>
      {shouldShowUpgradeButton && (
        <Button onClick={(): void => navigate(URLS.subscription.subscription)}>
          {t("general.upgrade")}
        </Button>
      )}

      <Button color="secondary" onClick={onClose}>
        {t("general.cancel")}
      </Button>
    </>
  );
};

export default CustomActionButtons;
