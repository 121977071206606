import { css } from "@emotion/react";

export const sectionList = css`
  dl {
    margin: 0;
    padding: 0;
    display: inline;
    position: relative;
  }

  .section-title {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.25rem;
  }
`;

export const unitListItem = ({ courseToc }) => css`
  margin: 0;

  .list-item-link {
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: ${courseToc.hoverBackground};
    }
  }

  &.active .list-item-content,
  &.readonly .list-item-content {
    color: ${courseToc.color};

    svg {
      fill: ${courseToc.color};
    }
  }

  .list-item-content {
    display: flex;
    align-items: center;
    word-break: break-word;
    gap: 0.5rem;
    color: ${courseToc.disabled};
    padding: 1rem 0 1rem 0.5rem;

    svg {
      min-width: 2rem;
      fill: ${courseToc.disabled};
    }

    .sample-unit-tag,
    .mandatory-unit-tag {
      padding: 0.125rem 0.5rem;
      font-size: 8px;
      font-weight: 700;
    }

    .sample-unit-tag {
      background: ${courseToc.sampleTagBackground} !important;
      color: ${courseToc.sampleTagColor} !important;
    }

    .mandatory-unit-tag {
      background: ${courseToc.mandatoryUnit.background} !important;
      color: ${courseToc.mandatoryUnit.color} !important;
      border: 2px solid ${courseToc.mandatoryUnit.border};
    }
  }
`;
