import { AISpinnerStyles } from "./styles";
import React, { FC } from "react";
import { AILoaderSVG } from "@epignosis_llc/gnosis/icons";
import { SerializedStyles } from "@emotion/react";

export const AISpinner: FC = () => {
  return (
    <div css={(theme): SerializedStyles => AISpinnerStyles(theme)}>
      <AILoaderSVG height={32} title={"Loading..."} />
    </div>
  );
};
