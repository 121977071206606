import React, { FC, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { userHasRequiredPermissions } from "@utils/permissions";
import authService from "@utils/services/AuthService";

import userRoles from "@constants/userRoles";
import { URLS } from "@constants/urls";

import { useConfigurationStore } from "@stores";
import { getIsLearnerUrl } from "./helpers";
import { generalNotification } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

type PermissionsRouteProps = {
  permissions: string[];
  roles?: string[];
  requireAll?: boolean;
  canAccess?: boolean;
  redirectLink?: string;
};
type UserRole = "administrator" | "instructor" | "learner";

const PermissionsRoute: FC<PermissionsRouteProps> = ({
  roles = ["administrator", "instructor", "learner"],
  permissions,
  requireAll = true,
  canAccess = true,
  redirectLink = URLS.dashboard,
}) => {
  const location = useLocation();
  const { t } = useApplyTranslations();
  let userRole: UserRole = authService.getDefaultRole() as UserRole;
  // Temporarily we give administrator permissions to Theming page so learners can access it
  if (location.pathname === "/my/profile/customize-theming") {
    userRole = userRoles.ADMINISTRATOR as UserRole;
  }

  const userProfileData = useConfigurationStore((state) => state.userProfileData);
  const userPermissions = userProfileData?.permissions[userRole] || [];
  const hasRolePermissions = roles?.includes(userRole);
  const isLearnerURL = getIsLearnerUrl(location.pathname);
  const isLearner = authService.getDefaultRole() === userRoles.LEARNER;
  const isPreviewURL = !isLearner && location.pathname.split("/").pop() === "preview"; // Do now show notification when an admin or instr accesses a preview URL

  const hasPermissionsAndAccess =
    hasRolePermissions &&
    canAccess &&
    userHasRequiredPermissions(permissions, userPermissions, requireAll);

  useEffect(() => {
    // If a user that is admin or instr, tries to access a learner url, show warning notification
    if (!isLearner && isLearnerURL && !isPreviewURL) {
      generalNotification("warning", t("general.learnerPageAccess"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return hasPermissionsAndAccess ? (
    <Outlet />
  ) : (
    <Navigate to={redirectLink} state={{ from: location }} replace />
  );
};

export default PermissionsRoute;
