import { css } from "@emotion/react";

export const introVideoOptionsStyles = css`
  .clear-intro-video-button {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
  }

  .intro-video-type-button {
    width: 100%;
  }

  .intro-video-wrapper {
    .intro-video {
      aspect-ratio: 16/9;
    }

    .intro-video-status {
      padding-top: 2rem; /* Player ratio: 100 / (1280 / 720) */
    }
  }

  .video-note {
    margin-bottom: 0.5rem;
  }
`;
