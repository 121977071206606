import { MotionStyle } from "framer-motion";
import { ResponseEnvelope } from "./responses";

// Common
export type CallbackFn<T> = (args?: T) => void;
export type TypedCallbackFn<T, S> = (args?: T) => S;
export type Map<T> = { [key: string]: T };
export type IconType = React.FC<React.SVGProps<SVGSVGElement>>;
export type HTMLWrapperElement = "div" | "article" | "section";

// Utility types
export type {
  PropsOf,
  ExtendableProps,
  InheritableElementProps,
  PolymorphicComponentProps,
  ValueOf,
} from "./utils";

// TODO: remove this
// Gnosis types
type NumberOrNull = number | null;

export type ResponsiveValues =
  | [
      NumberOrNull,
      NumberOrNull,
      NumberOrNull?,
      NumberOrNull?,
      NumberOrNull?,
      NumberOrNull?,
      NumberOrNull?,
    ]
  | number;

export type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  showMask?: boolean;
  placement?: "left" | "right";
  width?: string;
  dialogStyles?: MotionStyle;
  dialogClassName?: string;
};

export type QueryFilter = { key: string; value: string; label?: string };

export type HighlithedText = {
  firstPart: string;
  highlithedPart: string;
  lastPart: string;
};

export type SelectOption = {
  label: string;
  value: string;
  disabled?: boolean;
  hint?: string;
};

export type CreatableSelectOption = SelectOption & {
  __isNew__?: boolean;
};

export type GroupedOption = {
  label: string;
  options: SelectOption[];
};

export type MassActionResult = {
  processed: number;
  skipped: number;
};

export type NestedSelectOption = SelectOption & {
  level: number;
  id?: string;
  courses_count?: number;
};

export type SignInSearchQueryFilters = {
  error?: string;
  redirect?: string;
};

export type RadioGroupOption = {
  label: string;
  value: string;
};

export enum PopoverPlacement {
  Top = "top",
  Bottom = "bottom",
}

export type DrawerSize = "xs" | "sm" | "md" | "lg";

export type GenericFilter = {
  id: number;
  name: string;
  code?: string;
};

export type GenericFilterRes = ResponseEnvelope<GenericFilter[]>;

export type MyCoursesCompletionStatus = {
  open: number;
  closed: number;
};

export type PlacementOptions = "bottom-start" | "bottom-end" | "top-start" | "top-end" | "end-top";

export type UnitRouterParams = {
  courseId: string;
  unitId: string;
};
