// Packages or third-party libraries
import React, { FC } from "react";

// Styles
import { courseContentStyles } from "./styles";

// Components
import CustomFields from "./CustomFields";
import Description from "./Description";
import Certificate from "./Certificate";
import CourseTabs from "./CourseTabs";
import Prerequisites from "./Prerequisites";
import Footer from "./Footer";
import OtherProvidersDescription from "@views/CourseStore/OtherProviders/OtherProvidersDescription";

// Other imports
import { Course, Section } from "types/entities";
import { OtherProvidersCourse } from "types/entities/Courses";

type CourseContentProps = {
  course: Course | OtherProvidersCourse;
  sections: Section[];
  isCatalogCourseView?: boolean;
  isAdminOverview?: boolean;
  isTalentLibrary?: boolean;
  isOtherProviders?: boolean;
};

const CourseContent: FC<CourseContentProps> = ({
  course,
  sections,
  isCatalogCourseView = false,
  isAdminOverview = false,
  isTalentLibrary = false,
  isOtherProviders = false,
}) => {
  const { custom_fields = [], description, certificate, progress, rules } = course;
  const { prerequisites } = rules;
  const { certificate: progressCertificate = null } = progress ?? {};
  const hasCertificate = Boolean(certificate);
  const hasPrerequisites = prerequisites.length > 0 && !isTalentLibrary;

  return (
    <div css={courseContentStyles}>
      <div className="content-wrapper">
        <div className="description-container has-max-width">
          <CustomFields customFields={custom_fields} />
          {isOtherProviders ? (
            <OtherProvidersDescription description={description} />
          ) : (
            <Description description={description} />
          )}
        </div>
        {hasCertificate && <Certificate acquiredCertificate={progressCertificate} />}
        <CourseTabs
          course={course}
          sections={sections}
          isCatalogCourseView={isCatalogCourseView}
          isAdminOverview={isAdminOverview}
          isTalentLibrary={isTalentLibrary}
          isOtherProviders={isOtherProviders}
        />
        {hasPrerequisites && (
          <Prerequisites prerequisites={prerequisites} isOtherProviders={isOtherProviders} />
        )}
      </div>
      <Footer
        course={course}
        isCatalogCourseView={isCatalogCourseView}
        isCourseStore={isOtherProviders || isTalentLibrary}
      />
    </div>
  );
};

export default CourseContent;
