import { css } from "@emotion/react";

export const branchesContainer = (canViewBranch) => css`
  .link {
    cursor: ${!canViewBranch ? "default" : "pointer"} !important;
  }
`;

export const branchFormContainer = () => css`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const branchPropertiesContainer = () => css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: auto;
  max-width: 62.5rem;

  .form-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
  }
`;

export const signUpContainer = () => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const toggleSwitchDescription = ({ accountAndSettings }) => css`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .description-container {
    padding-top: 0.5rem;
  }

  .description {
    padding-left: 2.625rem;
    color: ${accountAndSettings.settingToggle.labelText};

    a {
      text-decoration: underline;
    }
  }

  .sub-description {
    padding-left: 2.625rem;
  }

  .subscription-fee {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;

    .subscription-input {
      width: 50%;
    }
  }

  .restricted-domains-input {
    width: 50%;
  }
`;

export const ecommerceContainer = ({ accountAndSettings }) => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .description {
    color: ${accountAndSettings.settingToggle.labelText};
  }
`;

export const tosContainer = () => css`
  .enforce-container {
    margin-top: 1rem;
  }
`;

export const alertContainer = () => css`
  padding-bottom: 2rem;
`;

export const upsellFooter = ({ upsell }) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  .reverse-trial-link {
    display: inline !important;
  }

  .button-like-link {
    border: none;
    padding: 0;
    background: transparent;
    color: ${upsell.link};

    &:hover {
      color: ${upsell.linkHover};
    }
  }
`;
