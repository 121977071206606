// Packages or third-party libraries
import React, { FC } from "react";
import { Grid, InputError, Select } from "@epignosis_llc/gnosis";
import { Controller, UseFormReturn } from "react-hook-form";

// Utils, hooks
import { useApplyTranslations } from "@hooks";

// Other imports
import { SelectOption } from "types/common";
import { CertificateReassignWhen } from "types/entities";
import { EditCourseData } from "@views/CourseEdit/api";

type ReassignWhenSelectProps = {
  form: UseFormReturn<EditCourseData>;
};

const ReassignWhenSelect: FC<ReassignWhenSelectProps> = ({ form }) => {
  const { t } = useApplyTranslations();
  const {
    control,
    formState: { errors },
  } = form;

  const reassignWhenOptions: SelectOption[] = [
    { value: CertificateReassignWhen.OnExpiration, label: t("certificates.reassign.onExpiration") },
    {
      value: CertificateReassignWhen.OneWeekBeforeExpiration,
      label: t("certificates.reassign.oneWeekBefore"),
    },
    {
      value: CertificateReassignWhen.OneMonthBeforeExpiration,
      label: t("certificates.reassign.oneMonthBefore"),
    },
  ];

  return (
    <Grid.Item colSpan={[1, 1, 1, 2]}>
      <Grid templateColumns={[1, 1, 1, 2]} gap={1}>
        <Grid.Item colSpan={1}>
          <Controller
            name="certificate.reassign_when"
            control={control}
            render={({ field: { value, onChange } }): JSX.Element => {
              const selectedOption = reassignWhenOptions.find(
                (template) => template.value === value,
              );

              return (
                <Select
                  label={t("certificates.reassign.toUser")}
                  aria-label={t("certificates.reassign.toUser")}
                  placeholder={t("general.selectTimeFrame")}
                  options={reassignWhenOptions}
                  value={selectedOption}
                  isClearable
                  onChange={(option): void => {
                    const { value } = (option as SelectOption) ?? {};
                    onChange(value ?? null);
                  }}
                />
              );
            }}
          />
          {errors?.certificate?.reassign_when && (
            <InputError>{errors?.certificate?.reassign_when.message}</InputError>
          )}
        </Grid.Item>
      </Grid>
    </Grid.Item>
  );
};

export default ReassignWhenSelect;
