// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Tooltip } from "@epignosis_llc/gnosis";
import { DownloadIconSVG, PreviewIconSVG, TrashAltSVG } from "@epignosis_llc/gnosis/icons";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

// Styles
import { fileActionsStyles } from "../styles";

// Components
import { ConfirmationModal } from "@components";
import { actionsClassNames } from "@components/CustomTable";
import { ActionGhostButton } from "@components/ReusableComponents";

// Utils, hooks
import { downloadFile, generalNotification } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

// Other imports
import { CourseFile } from "types/entities";
import { deleteCourseFile } from "@api/courses";
import queryKeys from "@constants/queryKeys";

type FileActionsProps = {
  file: CourseFile;
  isRowHovered: boolean;
  courseId?: string;
  isPreviewable: boolean;
  onPreview: (file: CourseFile) => void;
  onDelete?: () => void;
};

const FileActions: FC<FileActionsProps> = ({
  file,
  isRowHovered,
  courseId,
  isPreviewable,
  onPreview,
  onDelete,
}) => {
  const { t } = useApplyTranslations();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const { policies, id: fileId, url, name } = file;
  const { can_delete: canDelete } = policies ?? {};

  const handlePreview = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    if (isPreviewable) {
      onPreview(file);
    }
  };

  const handleDownloadFile = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    await downloadFile({ fileUrl: url ?? "", fileName: name ?? "", fileId });
  };

  const { mutate: deleteFileMutation } = useMutation(
    [queryKeys.courses.deleteFiles],
    ({ courseId, fileId }: { courseId: string; fileId: string }) =>
      deleteCourseFile(courseId, fileId),
    {
      onSuccess: () => {
        generalNotification("success", <p>{t("courseEdit.fileDeletedSuccessfully")}</p>);
        queryClient.invalidateQueries([queryKeys.courses.files]);
        onDelete?.();
      },
      onError: (error: AxiosError) => {
        generalNotification("error", <p>{error?.response?.data._errors[0].title}</p>);
      },
    },
  );

  return (
    <>
      <div css={fileActionsStyles}>
        <div className={actionsClassNames(isRowHovered)}>
          {isPreviewable && (
            <Tooltip
              content={t("general.preview")}
              parentProps={{ "aria-label": t("general.preview") }}
            >
              <ActionGhostButton onClick={handlePreview} aria-label={t("general.preview")}>
                <PreviewIconSVG height={32} />
              </ActionGhostButton>
            </Tooltip>
          )}

          {url && (
            <Tooltip
              content={t("general.download")}
              parentProps={{ "aria-label": t("general.download") }}
            >
              <ActionGhostButton onClick={handleDownloadFile} aria-label={t("general.download")}>
                <DownloadIconSVG height={32} />
              </ActionGhostButton>
            </Tooltip>
          )}

          {canDelete && courseId && (
            <Tooltip
              content={t("general.delete")}
              parentProps={{ "aria-label": t("general.delete") }}
            >
              <ActionGhostButton
                onClick={(): void => setIsConfirmationModalOpen(true)}
                aria-label={t("general.delete")}
              >
                <TrashAltSVG height={16} />
              </ActionGhostButton>
            </Tooltip>
          )}
        </div>

        {/* Delete  */}
        <ConfirmationModal
          id={fileId}
          header={t("courseEdit.deleteFile")}
          bodyTitle={
            <div
              dangerouslySetInnerHTML={{
                __html: t("courseEdit.confirmDeleteFiles", {
                  title: name,
                }),
              }}
            />
          }
          footerButton={t("general.delete")}
          isOpen={isConfirmationModalOpen}
          onClose={(): void => setIsConfirmationModalOpen(false)}
          onConfirm={(): void => {
            if (courseId) {
              deleteFileMutation({ courseId, fileId: fileId.toString() });
            }
          }}
        />
      </div>
    </>
  );
};

export default FileActions;
