import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { t } from "@utils/i18n";
import PaymentInfo from "./PaymentInfo";
import { Course } from "types/entities";
import { Bundle, Processors } from "types/entities/Catalog";
import { paymentMethodsContainer } from "./styles";
import { getPaymentMethodIcon, getTabTitle } from "../helpers";
import classNames from "classnames";

export type PaymentMethodsProps = {
  data: Course | Bundle;
  processors: Processors[];
  activeTab: number;
  setActiveTab: (activeTab: number) => void;
};

const getPaymentComponent = (
  data: Course | Bundle,
  processor: string,
  processors: Processors[],
  handleButtonClick: () => void,
): JSX.Element => {
  switch (processor) {
    case "credits":
    case "stripe":
    case "paypal":
      return (
        <PaymentInfo
          data={data}
          processor={processor}
          processors={processors}
          handleButtonClick={handleButtonClick}
        />
      );
    default:
      return <div className="payments-info-text">{t("general.paymentsInfo")}</div>;
  }
};

const methodClassNames = (isSelected: boolean): string =>
  classNames({
    "card-box": true,
    "is-selected": isSelected,
  });

const PaymentMethods: FC<PaymentMethodsProps> = (props) => {
  const { processors, data, activeTab, setActiveTab } = props;

  const handleButtonClick = (): void => {
    setActiveTab(1);
  };

  if (processors?.length === 1)
    return getPaymentComponent(data, processors[0], processors, handleButtonClick);

  return (
    <div css={paymentMethodsContainer}>
      <div className="buttons-wrapper">
        {processors.map((processor, index) => {
          const icon = getPaymentMethodIcon(processor);

          return (
            <button
              key={processor}
              className={methodClassNames(activeTab === index)}
              onClick={(): void => setActiveTab(index)}
            >
              <Text fontSize={"sm"}>{getTabTitle(processor)}</Text>
              {icon}
            </button>
          );
        })}
      </div>

      {getPaymentComponent(data, processors[activeTab], processors, handleButtonClick)}
    </div>
  );
};

export default PaymentMethods;
