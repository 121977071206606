import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import { CourseFilesRes, CourseRes, OtherProviderCourseRes, UnitsRes } from "types/responses";
import {
  OtherProviderCoursePriceEstimation,
  OtherProviderCourseSubscription,
} from "types/entities/Courses";

export const getCourse = async (courseId: string): Promise<CourseRes> => {
  const res = await HttpClient.get(ENDPOINTS.courseStore.course(courseId), {});

  return res.data;
};

export const getCourseUnits = async (courseId: string): Promise<UnitsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courseStore.units(courseId), {});

  return res.data;
};

export const acquireCourse = async (courseId: string): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courseStore.acquire(courseId));
};

export const acquireOtherProviderCourse = async (courseId: string): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courseStore.otherProvidersAcquire(courseId));
};

export const estimateOtherProviderCourseCharges = async (
  courseId: string,
  licenses: number,
): Promise<{ _data: OtherProviderCoursePriceEstimation }> => {
  const res = await HttpClient.post(ENDPOINTS.courseStore.otherProvidersEstimateCharges(courseId), {
    licenses,
  });
  return res.data;
};

export const purchaseOtherProviderCourse = async (
  courseId: string,
  postData: OtherProviderCourseSubscription,
): Promise<{ _data: { redirect_url: string } }> => {
  const res = await HttpClient.post(
    ENDPOINTS.courseStore.otherProvidersPurchase(courseId),
    postData,
  );

  return res.data;
};

export const getOtherProviderCourse = async (courseId: string): Promise<OtherProviderCourseRes> => {
  const res = await HttpClient.get(ENDPOINTS.courseStore.otherProvidersCourse(courseId));

  return res.data;
};

export const getOtherProviderCourseUnits = async (courseId: string): Promise<UnitsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courseStore.otherProvidersUnits(courseId));

  return res.data;
};

export const getOtherProviderCourseFiles = async (courseId: string): Promise<CourseFilesRes> => {
  const res = await HttpClient.get(ENDPOINTS.courseStore.otherProvidersCourseFiles(courseId));

  return res.data;
};
