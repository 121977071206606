import { css } from "@emotion/react";

export const textWithIconContainer = ({ textWithIcon, icon }, { iconColor, noGap }) => css`
  display: flex;
  gap: ${noGap ? "0" : "0.5rem"};
  align-items: center;

  .prefix-icon {
    display: flex;
    align-items: center;

    svg {
      color: ${iconColor === "green" ? textWithIcon.descriptionGreen : undefined};
    }
  }

  .text-with-icon-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .heading {
      &-black {
        color: ${textWithIcon.headingBlack};
      }

      &-grey {
        color: ${textWithIcon.headingGrey};
      }
    }

    .description-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .description-success {
        color: ${textWithIcon.descriptionGreen};
      }

      .tooltip-content {
        display: flex;
        color: ${icon.primary};
      }
    }
  }
`;
