import { RichText } from "./Common";
import { CourseProgress } from "./Courses";
import { CourseFile, File, MimeType } from "./Files";
import { WebinarType } from "./Ilts";
import { Question, ResultsQuestion } from "./Question";
import { SurveyOptions } from "./Survey";
import { TestOptions, TestStatistics } from "./Test";
import { UserProfile } from "./User";

export type Section = {
  id: number;
  name: string | null;
  availability: Availability | null;
  units: MyUnit[] | null;
  policies?: {
    can_delete: boolean;
    can_edit_name_status: boolean;
    can_view: boolean;
    can_set_delay: boolean;
  };
  pending_submissions?: number;
};

export type PosterImage = {
  id: number;
  name: string;
  mime_type: MimeType;
  url: string;
  uploaded_at: string;
  size_in_bytes: number;
  type: string;
  status: "ready" | "processing" | "ready";
};

export type MyUnit = {
  id: number;
  name: string;
  type: UnitType;
  content: string | null;
  secondary_content: string | null;
  completion_method: CompletionMethod | null;
  status: "failed" | "processing" | "ready";
  url: string | null;
  embed_type: EmbedType | null;
  media_options: MediaOptions | null;
  survey_options: SurveyOptions | null;
  progress: UnitProgress | null;
  scorm_data: ScormData | null;
  sample: boolean;
  captions_url: string | null;
  captions_file?: CaptionsFile | null;
  poster_image: PosterImage | null;
  size: Size | null;
  availability: Availability | null;
  test_options: TestOptions | null;
  policies?: {
    can_view: boolean;
    can_edit_content: boolean; // Check if a unit can be edited and is not a core unit
    can_edit_name_status: boolean; // Check if a unit can be edited
    can_delete: boolean;
    can_create_question: boolean;
    can_clone: boolean;
    can_add_question_from_course: boolean;
    can_remove_question: boolean;
    can_be_linked: boolean;
    can_export: boolean;
    can_migrate_unit: boolean;
    can_view_unit_versions: boolean;
    can_generate_test_questions_with_ai: boolean;
  };
  is_active: boolean;
  linked_units: number;
  file?: CourseFile;
  retain_failed_status?: boolean;
  has_unpublished_changes?: boolean;
  source_unit?: {
    id: number;
    course_id: number;
  };
  has_sessions?: boolean;
  pending_submissions?: number;
  pending_users?: number;
  maxtimeallowed?: number | null; // Used in test and assignment units
  assignment_options?: {
    due_datetime: string | null;
    reply_options: ReplyOptions;
  };
};

export type UnitType =
  | "unit"
  | "audio"
  | "document-uploaded"
  | "document-slideshare"
  | "flash"
  | "iframe"
  | "video-youtube"
  | "video-vimeo"
  | "video-uploaded"
  | "webpage"
  | "assignment"
  | "test"
  | "scorm"
  | "ilt"
  | "xapi"
  | "cmi5"
  | "survey"
  | "craft";

export type UnitMenu = "content" | "learning" | "more";

export type UploadUnitType =
  | "audio"
  | "document-uploaded"
  | "video-uploaded"
  | "scorm"
  | "xapi"
  | "cmi5";

export type CompletionMethod = {
  type: "time_limit" | "question" | "confirmation" | "instructor_accepted" | "upload";
  time: number | null;
  question: Question | null;
};

export type EmbedType = "embedded" | "popup";

export type MediaOptions = {
  auto_play: boolean | null;
  auto_pause: boolean | null;
  sync_timer: boolean | null;
  show_speed: boolean | null;
};

type Submission = {
  type: "text" | "file"; // Nullable in schema
  instructor_comment: RichText | null;
  text: RichText | null;
  file: File | null;
};

type Repetitions = {
  repetition_allowed: boolean;
  repetition_allowed_after: string | null;
  repetitions_left: number | null;
};

export type UnitProgress = {
  total_time: number;
  status: "not_attempted" | "incomplete" | "completed" | "failed" | "pending" | "unknown";
  score: number | null; // Schema only number
  completion_date: string | null;
  first_access_time: string | null;
  last_accessed_time: string | null;
  submission: Submission | null;
  repetitions: Repetitions | null;
  remaining_time: number | null;
  ilt_attendance: {
    comments: string;
    graded_at: string;
  } | null;
};

export type Size = {
  width: number | null;
  height: number | null;
};

export type Availability = {
  delay: number | null;
  available_on: string | null;
  absolute_delay: number | null;
};

// ScormUnit
export type ScormUnit = MyUnit & {
  type: "scorm";
  completion_method: null;
  url: string;
  embed_type: EmbedType;
  media_options: null;
  survey_options: null;
  scorm_data: ScormData;
  captions_url: null;
  poster_image: null;
  test_options: null;
};

type ScormData = {
  student_name: string;
  student_id: number;
  suspend_data: string;
  total_time: string;
  lesson_location: string;
  lesson_status: string;
  datafromlms: string;
  score: number;
  entry: string;
  commit_url: string; // Does not exist in swagger schema
};

type CaptionsFile = CourseFile & {
  policies?: {
    can_update: boolean;
    can_delete: boolean;
  };
};

export type ScormCommit = {
  unit_progress: UnitProgress;
  course_progress: CourseProgress;
};

export type CraftCommit = {
  unit_progress: UnitProgress;
  course_progress: CourseProgress;
};

export type UnitResults = {
  status: "completed" | "failed";
  score: number | null;
  completion_date: string;
  instructor_message: string | null;
  questions: ResultsQuestion[] | null;
  test_abandoned: boolean;
  maximum_score: number | null;
  pending_attempts: number | null; // TODO: change to remaining_attempts when fixed from API
  allow_test_repetition: boolean;
  time_for_repetition: number | null;
  statistics: TestStatistics | null;
};

export type Session = {
  owner: Pick<UserProfile, "id" | "login" | "name" | "surname" | "avatar">;
  unit_id: number;
  name: string;
  capacity: number | null;
  users_registered: number;
  is_registered: boolean;
  can_register: boolean | null;
  can_unregister: boolean | null;
  sessions: SessionItem[];
};

export type SessionItem = {
  id: number;
  type: "classroom" | "webinar";
  webinar_id: string | null;
  name: string;
  start_datetime: string;
  end_datetime: string;
  instructor: Pick<UserProfile, "id" | "login" | "name" | "surname" | "avatar"> | null;
  join_url: string | null;
  description: RichText | null;
  location: string | null;
  duration: number;
  color: string;
  join_password: string | null;
  linked: number | null;
  webinar_type: WebinarType | null;
};

export type CourseInstructors = {
  instructors: CourseInstructor[];
};

// TODO: populate with all data when API ready
export type SingleSession = {
  id: number;
};

export type CourseInstructor = {
  instructor_id: string;
  instructor_name: string;
};

export type JoinUrl = {
  join_url: string;
};

export type PlayerState = {
  isPlaying: boolean;
  isReady: boolean;
  duration: number | null;
};

export type UnitMicrostats = {
  completion_rate: number;
  completed_units: number;
  units_in_progress: number;
  units_not_passed: number;
  units_not_started: number;
  training_time: number;
};

export type CraftData = {
  status: "completed" | "incomplete";
  state: string;
};

export type CraftMessage = "state_update" | "ai_credits_consumed" | "ai_credits_inadequate";

export type CraftPublishData = {
  message: CraftMessage;
  unit: {
    state: "published" | "draft";
    has_unpublished_changes: boolean;
  };
  is_working: boolean;
};

export type LinkedUnit = {
  id: number;
  name: string;
  course_id: number;
  course_name: string;
  policies: {
    can_view: boolean;
  };
};

export type VersionHistoryUnit = {
  content: string;
  date: string;
  file?: CourseFile;
  id: number;
  secondary_content: string | null;
  url: string | null;
  captions_url?: string | null;
  media_options?: MediaOptions | null;
  poster_image?: PosterImage | null;
};

export enum ReplyOption {
  Text = "text",
  File = "file",
  VideoRecording = "video_recording",
  AudioRecording = "audio_recording",
  ScreenRecording = "screen_recording",
}

export type ReplyOptions = {
  [key in ReplyOption]: boolean;
};
