// Packages or third-party libraries
import React, { FC, ReactElement } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Checkbox, Grid, Input, InputError, Select } from "@epignosis_llc/gnosis";
import { format } from "date-fns";

// Components
import { DateInput } from "@components";

// Utils, hooks
import { dateFormatsMapping } from "@utils/helpers/date-time";
import { useConfigurationStore } from "@stores";

// Other imports
import { SelectOption } from "types/common";
import { EditCourseData } from "@views/CourseEdit/api";
import { CourseCustomFieldWithValue } from "../../../types";

type CustomFieldsOptionsProps = {
  form: UseFormReturn<EditCourseData>;
  customFields: CourseCustomFieldWithValue[] | undefined;
};

const CustomFieldsOptions: FC<CustomFieldsOptionsProps> = ({ customFields, form }) => {
  const { domainSettings } = useConfigurationStore();
  const { control } = form;
  const dateFormat = domainSettings ? dateFormatsMapping[domainSettings.date_format] : undefined;

  return (
    <Grid templateColumns={1} gap={1}>
      {customFields?.map(
        ({ id, name, type, mandatory, checked, dropdown_items, value: initialValue }) => {
          return (
            <Grid.Item key={id} colSpan={1}>
              <Controller
                name={`custom_fields.${id}`}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }): ReactElement => {
                  switch (type) {
                    case "checkbox":
                      return (
                        <Checkbox
                          id={id.toString()}
                          value={name}
                          name={name}
                          required={mandatory}
                          label={name}
                          defaultChecked={initialValue ? initialValue === "on" : Boolean(checked)}
                          onChange={(value): void => {
                            onChange(value.target.checked ? "on" : "off");
                          }}
                        />
                      );
                    case "date": {
                      return (
                        <>
                          <DateInput
                            className="date-picker"
                            id={id.toString()}
                            required={mandatory}
                            label={name}
                            value={value ? new Date(value) : null}
                            onChange={(value): void => {
                              // Set the form date to have the same format with the API response
                              onChange(value ? format(value, "yyyy-MM-dd") : "");
                            }}
                            status={error ? "error" : "valid"}
                            dateFormat={dateFormat}
                          />
                          {error && <InputError>{error?.message}</InputError>}
                        </>
                      );
                    }
                    case "dropdown": {
                      const selectOptions =
                        dropdown_items?.map(({ item }) => ({
                          value: item,
                          label: item,
                        })) ?? [];

                      const defaultValue = dropdown_items?.find(
                        (option) => option.item === initialValue,
                      )?.item;

                      const defaultOption = defaultValue
                        ? { label: defaultValue, value: defaultValue }
                        : undefined;

                      return (
                        <>
                          <Select
                            maxWidth="100rem"
                            id={id.toString()}
                            required={mandatory}
                            label={name}
                            aria-label={name}
                            placeholder="-"
                            defaultValue={defaultOption}
                            status={error ? "error" : "valid"}
                            options={selectOptions}
                            isClearable={!mandatory}
                            isSearchable
                            onChange={(option): void => {
                              const { value } = (option as SelectOption) ?? {};
                              onChange(value ?? "");
                            }}
                          />

                          {error && <InputError>{error?.message}</InputError>}
                        </>
                      );
                    }

                    default:
                      return (
                        <>
                          <Input
                            id={id.toString()}
                            required={mandatory}
                            label={name}
                            value={value ?? ""}
                            onChange={onChange}
                            data-lpignore="true"
                            status={error ? "error" : "valid"}
                          />

                          {error && <InputError>{error?.message}</InputError>}
                        </>
                      );
                  }
                }}
              />
            </Grid.Item>
          );
        },
      )}
    </Grid>
  );
};

export default CustomFieldsOptions;
