import { css } from "@emotion/react";

export const overlayWrapper = css`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .hover-btn {
    width: 4rem;
    height: 4rem;
  }
`;

export const hoverWrapper = ({ courseCard, button }) => css`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .hover-btn {
    width: 4rem;
    height: 4rem;

    &:hover {
      color: ${button.primary.default.color};
      background-color: ${button.primary.default.background};
      border-color: ${button.primary.default.borderColor};
    }
  }

  .hover-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hover-text {
    color: ${courseCard.cardFooter.textColor};
    margin-top: 0.25rem;
  }
`;

export const bodyWrapper = ({ courseCard, rating, button }) => css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: inherit;

  &:hover {
    color: inherit;
  }

  &.disabled {
    pointer-events: none;
  }

  .body-container {
    position: relative;

    .code {
      color: ${courseCard.cardBody.code};
      min-height: 1.25rem;
    }

    .catalog-action-btn {
      position: absolute;
      top: -28px;
      right: 0;

      .action-btn {
        height: 2rem;
        width: 2rem;
        border: 1px solid white;

        &.is-owned {
          &:hover {
            cursor: default;
            color: ${button.success.default.color};
            background-color: ${button.success.default.background};
          }
        }
      }
    }

    .card-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-top: 1rem;
    }

    .rating-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .rating-star {
        color: ${rating.color};
        margin-bottom: 0.25rem;
      }
    }
  }

  .price,
  .previous-price {
    color: inherit !important;
  }
`;
