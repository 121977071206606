import { Variants } from "framer-motion";
import { GlobalStyleVariables } from "@styles";
const { unitsSidebarMaxWidth } = GlobalStyleVariables;

export const asideContainerVariants: Variants = {
  expanded: (isReducedMotion) => ({
    maxWidth: unitsSidebarMaxWidth,
    minWidth: unitsSidebarMaxWidth,
    transition: {
      when: "beforeChildren",
      duration: isReducedMotion ? 0 : 0.2,
    },
  }),
  hidden: (isReducedMotion) => ({
    maxWidth: 0,
    minWidth: 0,
    transition: {
      when: "afterChildren",
      duration: isReducedMotion ? 0 : 0.2,
    },
  }),
};

export const asideContentCVariants: Variants = {
  expanded: (isReducedMotion) => ({
    height: "100%",
    visibility: "visible",
    transition: {
      when: "beforeChildren",
      duration: isReducedMotion ? 0 : 0.1,
    },
  }),
  hidden: (isReducedMotion) => ({
    height: "100%",
    visibility: "hidden",
    transition: {
      when: "afterChildren",
      duration: isReducedMotion ? 0 : 0.1,
    },
  }),
};
