import React, { FC } from "react";
import { Tooltip } from "@epignosis_llc/gnosis";
import { EllipsisSVG } from "@epignosis_llc/gnosis/icons";
import { Course } from "types/entities";
import { getCourseCardRestrictions } from "../helpers";
import { restrictionsContainer, tooltipContainerStyles } from "./styles";
import { useConfigurationStore } from "@stores";
import { useApplyTranslations } from "@hooks";
import { useResponsive } from "ahooks";

export type RestrictionsProps = {
  course: Course;
  hideMaxItems?: boolean; // Prop to hide max items functionality
  children?: never;
};

const MAX_ITEMS = 2;
const MAX_ITEMS_SMALL_SCREEN = 1;

const LeftActions: FC<RestrictionsProps> = ({ course, hideMaxItems = false }) => {
  const { gamificationSettings, userProfileData } = useConfigurationStore();
  const { t } = useApplyTranslations();
  const { xl } = useResponsive();
  const itemsToDisplay = xl ? MAX_ITEMS : MAX_ITEMS_SMALL_SCREEN;

  const {
    level,
    rules,
    starts_at,
    user_access_expires_at,
    expires_at,
    ilt_session_start_datetime = null,
    has_ilt_unit,
  } = course;
  const { prerequisites = [] } = rules ?? {};

  // Restrictions
  const isLevelEnabled = Boolean(gamificationSettings?.levels?.enabled);
  const isGamificationEnabled = Boolean(gamificationSettings?.enabled);

  const userLevel = userProfileData?.level ?? 0;
  const courseLevel = level ?? 0;
  const unlocksOnHigherLevel =
    isLevelEnabled && isGamificationEnabled ? userLevel < courseLevel : false;

  const dropdownOptions = getCourseCardRestrictions({
    unlocksOnHigherLevel,
    level: courseLevel,
    hasPrerequisites: prerequisites.length > 0,
    startsAt: starts_at,
    userAccessExpiresAt: user_access_expires_at || expires_at,
    hasIlt: Boolean(has_ilt_unit),
    iltSessionDate: ilt_session_start_datetime,
  });

  const tooltipContainer = (): JSX.Element => {
    return (
      <div css={tooltipContainerStyles}>
        {dropdownOptions.map((option) => {
          return (
            <div key={option.value} className="option-item">
              <>{option.icon}</>
              {option.label}
            </div>
          );
        })}
      </div>
    );
  };

  if (dropdownOptions.length === 0) return <div />;

  return (
    <div css={restrictionsContainer}>
      {(hideMaxItems ? dropdownOptions : dropdownOptions.slice(0, itemsToDisplay)).map(
        (option, index) => {
          const ariaLabel = typeof option.label === "string" ? option.label : option.originalText;
          return (
            <Tooltip
              key={`${option.value}-${index}`}
              content={option.label}
              placement="bottom"
              parentProps={{ "aria-label": ariaLabel }}
            >
              <div
                key={option.value}
                className={`restriction-item ${option.className ?? ""}`}
                data-testid={option.id}
              >
                <>{option.icon}</>
              </div>
            </Tooltip>
          );
        },
      )}

      {!hideMaxItems && dropdownOptions.length > itemsToDisplay && (
        <Tooltip content={tooltipContainer()} placement="bottom">
          <EllipsisSVG
            height={16}
            className="ellipsis-icon"
            aria-label={t("a11y.general.showMoreDetails")}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default LeftActions;
