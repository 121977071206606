// Packages or third-party libraries
import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";

// Styles
import { descriptionStyles } from "@components/CourseOverview/components/CourseContent/styles";

// Hooks
import { useApplyTranslations } from "@hooks";

// Other imports
import { RichText } from "types/entities";

type DescriptionProps = {
  description: RichText | null;
  customLineClamp?: number;
};

export const MAX_NUMBER_OF_CHARS = 643;

const OtherProvidersDescription: FC<DescriptionProps> = ({ description, customLineClamp = 7 }) => {
  const { t } = useApplyTranslations();
  const { html = "" } = description ?? {};

  const ref = useRef<HTMLDivElement | null>(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const showMoreBtn = isOverflow || showMore;

  const checkOverflow = (): void => {
    if (ref.current) {
      const hasOverflow = ref.current.scrollHeight > ref.current.clientHeight;
      setIsOverflow(hasOverflow);
    }
  };

  // Initial check and setup resize observer
  useEffect(() => {
    checkOverflow();

    // Only set up ResizeObserver if supported and ref exists
    const element = ref.current;
    if (element && window.ResizeObserver) {
      const resizeObserver = new ResizeObserver(checkOverflow);
      resizeObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
      };
    }

    // If ResizeObserver setup is skipped, we still need to check overflow on window resize
    const handleResize = (): void => {
      checkOverflow();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [html]);

  if (!html) return null;

  return (
    <div css={(theme): SerializedStyles => descriptionStyles(theme, { showMore, customLineClamp })}>
      <div ref={ref} className="description">
        <Text fontSize="md" dangerouslySetInnerHTML={{ __html: html }} />
      </div>

      {showMoreBtn && (
        <Button variant="link" noGutters className="" onClick={(): void => setShowMore(!showMore)}>
          {showMore ? t("general.viewLess") : t("general.viewMore")}
        </Button>
      )}
    </div>
  );
};

export default OtherProvidersDescription;
