// Packages or third-party libraries
import React, { FC } from "react";
import { Button, Modal } from "@epignosis_llc/gnosis";
import ReactPlayer from "react-player";

// Styles
import { introVideoModalBody } from "./styles";

// Utils, hooks
import { useUIStore } from "@stores";
import { useApplyTranslations } from "@hooks";

// Other imports
import { REACT_PLAYER_CONFIG } from "@components/CourseOverview/constants";
import { courseOptionsIds, TabKey } from "@views/CourseEdit/components/CourseOptions";

type IntroVideoModalProps = {
  isOpen: boolean;
  header: string;
  url: string;
  canEditOptions?: boolean;
  onClose: () => void;
};

const IntroVideoModal: FC<IntroVideoModalProps> = ({
  isOpen,
  header,
  url,
  canEditOptions = false,
  onClose,
}) => {
  const { setCourseOptionsState } = useUIStore();
  const { t } = useApplyTranslations();

  const handleEditIntroVideo = (): void => {
    onClose();
    setCourseOptionsState({
      isOpen: true,
      activeTab: TabKey.Info,
      scrollId: courseOptionsIds.introVideoOptions,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <Modal.Header>{header}</Modal.Header>
      <Modal.Body css={introVideoModalBody}>
        <ReactPlayer
          id="intro-video"
          className="intro-video"
          url={url}
          config={REACT_PLAYER_CONFIG}
          width="100%"
          height="100%"
          controls
        />
      </Modal.Body>

      <Modal.Footer>
        {canEditOptions && (
          <Button color="primary" onClick={handleEditIntroVideo}>
            {t("courseEdit.editIntroVideo")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default IntroVideoModal;
