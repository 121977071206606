import React, { FC, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import { Heading, Text, Tooltip } from "@epignosis_llc/gnosis";
import { textWithIconContainer } from "./styles";
import { InfoButtonSVG } from "@epignosis_llc/gnosis/icons";
import classNames from "classnames";

type TextWithIconProps = {
  id?: string;
  icon?: JSX.Element;
  iconColor?: string;
  label?: ReactNode;
  labelTag?: HeadingTag;
  labelSize?: string;
  labelColor?: string;
  description?: ReactNode;
  descriptionSize?: string;
  tooltipContent?: string;
  hasDescription?: boolean;
  boldDescription?: boolean;
  descriptionSuccess?: boolean;
  className?: string;
  noGap?: boolean;
};

type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const descriptionClass = (descriptionSuccess: boolean | undefined): string =>
  classNames({
    "description-success": descriptionSuccess,
  });

const TextWithIcon: FC<TextWithIconProps> = ({
  id,
  icon,
  iconColor,
  label,
  labelTag = "h2",
  labelSize = "md",
  labelColor = "black",
  description,
  descriptionSize = "md",
  descriptionSuccess,
  tooltipContent,
  hasDescription = false,
  boldDescription = false,
  noGap = false,
  ...rest
}) => {
  return (
    <div
      css={(theme): SerializedStyles => textWithIconContainer(theme, { iconColor, noGap })}
      {...rest}
    >
      {icon && <span className="prefix-icon">{icon}</span>}
      <div className="text-with-icon-content">
        {label && (
          <Heading
            as={labelTag}
            size={labelSize}
            data-testid={`${id ? id + "-label" : "label"}`}
            className={`heading-${labelColor}`}
          >
            {label}
          </Heading>
        )}
        {hasDescription && (
          <div className="description-container">
            <Text
              as="span"
              fontSize={descriptionSize}
              className={descriptionClass(descriptionSuccess)}
              weight={boldDescription ? "700" : "400"}
              data-testid={`${id ? id + "-description" : "description"}`}
            >
              {description}
            </Text>
            {tooltipContent && (
              <div data-testid={`${id ? id + "-tooltip" : "tooltip"}`}>
                <Tooltip
                  role="tooltip"
                  as="span"
                  content={tooltipContent}
                  aria-describedby="tooltip-content"
                >
                  <InfoButtonSVG
                    id="tooltip-content"
                    aria-label={tooltipContent}
                    className="tooltip-content"
                    height={16}
                    tabIndex="0"
                  />
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextWithIcon;
