import React, { ReactNode } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { ToggleSwitch } from "@epignosis_llc/gnosis";
import SettingCard from "./SettingCard";

type Mode = "boolean" | "string";

type SettingToggleProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  title?: string;
  description?: ReactNode;
  lockedFeature?: boolean;
  lockedFeatureTooltip?: ReactNode | string;
  mode?: Mode;
  isDisabled?: boolean;
  onClick?: () => void;
};

const SettingToggle = <T extends FieldValues>({
  title,
  description,
  name,
  control,
  lockedFeature = false,
  lockedFeatureTooltip,
  mode = "string",
  isDisabled = false,
  onClick,
}: SettingToggleProps<T>): JSX.Element => {
  const getChangedToggleValue = (value: string | boolean): string | boolean => {
    if (mode === "boolean") {
      return !value;
    }
    return value === "active" ? "inactive" : "active";
  };

  return (
    <SettingCard
      title={title}
      description={description}
      lockedFeature={lockedFeature}
      lockedFeatureTooltip={lockedFeatureTooltip}
      withSpaceLeft
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }): JSX.Element => (
          <div className="toggleContainer">
            <ToggleSwitch
              id={`${name}-toggle-switch`}
              defaultChecked={mode === "boolean" ? value : value === "active"}
              isDisabled={isDisabled}
              onChange={(): void => {
                onChange(getChangedToggleValue(value));
                onClick?.();
              }}
            />
          </div>
        )}
      />
    </SettingCard>
  );
};

export default SettingToggle;
