import { css } from "@emotion/react";

export const starRatingContainer = ({ rating }) => css`
  display: flex;

  button {
    background: transparent;
    border: 0;
    padding: 0;
    max-height: 2rem;
    max-width: 1.5rem;
  }

  .star {
    color: ${rating.background};
  }

  .half-star {
    path:last-of-type {
      fill: ${rating.background} !important;
    }
  }

  .active {
    .star,
    .half-star {
      color: ${rating.color};
    }
  }

  :not(.is-readonly) {
    /* prev siblings */
    &:hover button {
      .star {
        color: ${rating.hover};
      }

      .half-star {
        path {
          fill: ${rating.hover} !important;
        }
      }
    }

    /* next siblings */
    button:hover ~ button {
      .star {
        color: ${rating.background};
      }

      .half-star {
        path {
          fill: ${rating.background} !important;
        }
      }
    }
  }

  .readonly {
    cursor: auto;
  }

  .hide-button-max-width {
    max-width: initial;
  }
`;
