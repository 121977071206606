// Packages or third-party libraries
import React, { FC } from "react";
import { Grid } from "@epignosis_llc/gnosis";
import { useQuery } from "react-query";
import { Controller, UseFormReturn, useFieldArray } from "react-hook-form";

// Styles
import { LearningPathRulesStyles } from "./styles";

// Components
import { PathList } from "@components/ReusableComponents";
import AddNewLearningPath from "./AddNewLearningPath";
import LearningPathSelect from "./LearningPathSelect";
import { prerequisiteOptionsToSelectOptions } from "../../helpers";

// Utils, hooks
import { useApplyTranslations } from "@hooks";

// Other imports
import { getCoursePrerequisiteOptions } from "@views/CourseEdit/api";
import { Course } from "types/entities";
import { CourseRulesFormData } from "@views/CourseEdit/types";
import queryKeys from "@constants/queryKeys";

const MAX_LEARNING_PATHS = 4;

type LearningPathProps = {
  form: UseFormReturn<CourseRulesFormData>;
  course: Course;
};

const LearningPathRules: FC<LearningPathProps> = ({ course, form }) => {
  const { t } = useApplyTranslations();
  const { id } = course;
  const courseId = id.toString();

  const { data: prerequisites = [] } = useQuery(
    [queryKeys.courses.prerequisiteOptions],
    () => getCoursePrerequisiteOptions(courseId),
    {
      select: (res) => res._data,
    },
  );

  const { control, trigger } = form;

  const {
    fields: learningPaths,
    update: updateLearningPaths,
    remove: removeLearningPath,
    append: appendLearningPath,
  } = useFieldArray({
    control,
    name: "learning_paths_obj",
  });

  const hasNoLearningPaths = learningPaths.length === 0;
  const groupedPrerequisiteSelectOptions = prerequisiteOptionsToSelectOptions(prerequisites);
  const prerequisiteSelectOptions = groupedPrerequisiteSelectOptions
    .map((option) => option.options)
    .flat();

  const handleAddNewPath = (): void => {
    const newIndex = learningPaths.length + 1;

    if (newIndex <= MAX_LEARNING_PATHS) {
      appendLearningPath({ rule_set: newIndex, courses: [] });
    }
  };

  const handleNewPathChange = (selectedIds: number[]): void => {
    appendLearningPath({
      rule_set: learningPaths.length + 1,
      courses: [...selectedIds],
    });
  };

  const handlePathChange = (index: number, selectedIds: number[]): void => {
    updateLearningPaths(index, { ...learningPaths[index], courses: selectedIds });
  };

  const handlePathDelete = (index: number): void => {
    removeLearningPath(index);
  };

  return (
    <div css={LearningPathRulesStyles}>
      <Grid templateColumns={[1, 1, 1, 2]} rowGap={1} columnGap={1} className="grid-container">
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <PathList>
            {learningPaths.map((learningPath, index) => (
              <Controller
                name={`learning_paths_obj.${index}.courses`}
                control={control}
                key={`learning-paths-${learningPath.id}`}
                render={({ field: { value: courses }, fieldState: { error } }): JSX.Element => {
                  const selectedValues = prerequisiteSelectOptions.filter((option) =>
                    courses.includes(Number(option.value)),
                  );

                  const placeholder =
                    index === 0
                      ? t("courseEdit.learningPath.enterPrerequisiteCourses")
                      : t("courseEdit.learningPath.enterAlternativePrerequisiteCourses");

                  const hideLine = index + 1 >= MAX_LEARNING_PATHS;

                  return (
                    <>
                      <LearningPathSelect
                        id={`learning-paths-${learningPath.id}`}
                        placeholder={placeholder}
                        options={groupedPrerequisiteSelectOptions}
                        value={selectedValues}
                        error={error?.message}
                        canDelete
                        hideLine={hideLine}
                        onChange={(selectedIds): void => {
                          trigger(`learning_paths_obj.${index}`);
                          handlePathChange(index, selectedIds);
                        }}
                        onDelete={(): void => handlePathDelete(index)}
                      />
                    </>
                  );
                }}
              />
            ))}

            {hasNoLearningPaths && (
              <LearningPathSelect
                id="learning-path-new"
                placeholder={t("courseEdit.learningPath.enterPrerequisiteCourses")}
                options={groupedPrerequisiteSelectOptions}
                onChange={handleNewPathChange}
              />
            )}

            {learningPaths.length < MAX_LEARNING_PATHS && (
              <AddNewLearningPath disabled={hasNoLearningPaths} onAddNew={handleAddNewPath} />
            )}
          </PathList>
        </Grid.Item>
      </Grid>
    </div>
  );
};

export default LearningPathRules;
