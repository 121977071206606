import React, { FC } from "react";
import { sectionList } from "./styles";
import { Course, Section } from "types/entities";
import { getFlatUnits, getIncompleteUnits } from "@utils/helpers";
import CourseTocSectionItem from "./CourseTocSectionItem";
import CourseTocItems from "./CourseTocItems";
import { OtherProvidersCourse } from "types/entities/Courses";

export type CourseToCProps = {
  course: Course | OtherProvidersCourse;
  sections: Section[];
  mandatoryUnits: number[];
  isReadonly: boolean;
  isLearnerView?: boolean;
};

const CourseToC: FC<CourseToCProps> = ({
  course,
  sections,
  mandatoryUnits,
  isReadonly,
  isLearnerView = true,
}) => {
  const { rules } = course;
  const isSequentialCourse = rules?.traversal === "sequential";
  const flatUnits = getFlatUnits(sections);
  const incompleteUnits = getIncompleteUnits(isSequentialCourse, flatUnits);

  return (
    <>
      {sections.map((section) => {
        const { units = [] } = section;
        if (units?.every((unit) => !unit.is_active)) return null;

        return (
          <section key={section.id} css={sectionList} data-testid="toc-section">
            <CourseTocSectionItem section={section} isLearnerView={isLearnerView} />
            <dl>
              <CourseTocItems
                units={units}
                course={course}
                mandatoryUnits={mandatoryUnits}
                incompleteUnits={incompleteUnits}
                isReadonly={isReadonly}
                isLearnerView={isLearnerView}
                isSequentialCourse={isSequentialCourse}
              />
            </dl>
          </section>
        );
      })}
    </>
  );
};

export default CourseToC;
