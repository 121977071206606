/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from "@utils/i18n";
import { Control } from "react-hook-form";
import { BasicSettings, UserDefaultsSettings, UsersSettings } from "./types";
import { ReactNode } from "react";
import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";
import { Column } from "@epignosis_llc/gnosis";

export const guideToLanguageOverrides =
  "https://help.talentlms.com/hc/en-us/articles/9652234209436";

export const guideToAllowSocialSignUps =
  "https://help.talentlms.com/hc/en-us/articles/9652305649692";

export const guideToDefaultGroup = "https://help.talentlms.com/hc/en-us/articles/9651391053212";

export const guideToSSO = "https://help.talentlms.com/hc/en-us/articles/9652232201884";

export const guideToOidc = "https://help.talentlms.com/hc/en-us/articles/9652261417628";

export const guideToLdap = "https://help.talentlms.com/hc/en-us/articles/9652247295260";

export const guideToSaml = "https://help.talentlms.com/hc/en-us/articles/9652216640284";

export const guideToLogoutEndpoint = "https://help.talentlms.com/hc/en-us/articles/9652261417628";

export const guideToConfigurationSSO = "https://help.talentlms.com/hc/en-us/sections/9593886368668";

export const guideToCustomUserFields = "https://help.talentlms.com/hc/en-us/articles/9652321569436";

const signUpOptions = ["direct", "manual"];
const verificationOptions = ["none", "captcha", "captcha_email", "captcha_admin", "email", "admin"];
export const SSOOptions = ["none", "saml", "ldap", "oidc"];
export const SSOLoginTypeOptions = ["classic", "idp"];

export const SSOtitle = "SSO";
export const SPMetadata = "SP Metadata XML";

export const getOtherSettings = (
  descriptionCustomUserFields: ReactNode,
  control: Control<UsersSettings, any>,
): BasicSettings[] => {
  return [
    {
      title: t("accountAndSettings.usersSettings.customUserFields"),
      description: descriptionCustomUserFields,
      name: "custom_user_fields",
      control: control,
      testId: "other-custom-user-fields",
    },
  ];
};

export const getLoginSettings = (
  descriptionSSO: ReactNode,
  control: Control<UsersSettings, any>,
): BasicSettings[] => {
  return [
    {
      title: SSOtitle,
      description: descriptionSSO,
      name: "sso",
      control: control,
      testId: "login-settings-sso",
    },
    {
      title: t("accountAndSettings.usersSettings.termsOfService"),
      description: t("accountAndSettings.usersSettings.termsOfServiceInfo"),
      name: "terms_of_service",
      control: control,
      testId: "login-settings-terms-of-service",
    },
  ];
};

export const getUserDefaultsSettings = (
  descriptionLanguageOverrides: ReactNode,
  descriptionDefaultGroup: ReactNode,
  languageOptions: string[],
  userTypeOptions: string[],
  defaultGroupOptions: string[],
  defaultTimezoneOptions: string[],
  control: Control<UsersSettings, any>,
): UserDefaultsSettings[] => {
  return [
    {
      title: t("accountAndSettings.usersSettings.defaultUserType"),
      description: t("accountAndSettings.usersSettings.defaultUserTypeInfo"),
      name: "default_user_type",
      type: "select",
      options: userTypeOptions,
      control: control,
      testId: "user-defaults-default-user-type",
    },
    {
      title: t("accountAndSettings.usersSettings.defaultGroup"),
      description: descriptionDefaultGroup,
      name: "default_group",
      type: "select",
      options: defaultGroupOptions,
      control: control,
      testId: "user-defaults-default-group",
    },
    {
      title: t("accountAndSettings.usersSettings.defaultLanguage"),
      description: t("accountAndSettings.usersSettings.defaultLanguageInfo"),
      name: "default_locale",
      type: "select",
      options: languageOptions,
      control: control,
      testId: "user-defaults-default-language",
    },
    {
      title: t("accountAndSettings.usersSettings.languageOverrides"),
      description: descriptionLanguageOverrides,
      name: "language_overrides",
      options: [],
      control: control,
      testId: "user-defaults-language-overrides",
    },
    {
      title: t("accountAndSettings.usersSettings.defaultTimeZone"),
      description: "",
      type: "select",
      name: "default_timezone",
      options: defaultTimezoneOptions,
      control: control,
      testId: "user-defaults-default-timezone",
    },
  ];
};

export const getUserCreationSettings = (
  descriptionSocialMedia: ReactNode,
  control: Control<UsersSettings, any>,
): UserDefaultsSettings[] => {
  return [
    {
      title: t("accountAndSettings.usersSettings.signUp"),
      name: "signup",
      type: "select",
      options: signUpOptions,
      control: control,
      testId: "user-creation-sign-up",
    },
    {
      title: t("accountAndSettings.usersSettings.userVerification"),
      name: "user_verification",
      type: "select",
      options: verificationOptions,
      control: control,
      testId: "user-creation-user-verification",
    },
    {
      title: t("accountAndSettings.usersSettings.allowSignUps"),
      description: descriptionSocialMedia,
      name: "social_login",
      options: [],
      control: control,
      testId: "user-creation-social-media-sign-up",
    },
  ];
};

// Language overrides table
export const getLanguageOverridesColumns = (): Column[] => {
  return [
    {
      accessor: "original",
      cell: t("general.original"),
      classNames: ["original"],
      maxWidth: 200,
    },
    {
      accessor: "overridden",
      cell: t("general.overridden"),
      classNames: ["overridden"],
      maxWidth: 200,
    },
  ];
};

export const actionsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
};
export const SORTING = { column: "original", isDescending: false };

export const PAGINATION: IPagination = { number: 1, size: 10 };
export const DEFAULT_FILTERS: QueryFilter[] = [];

// SSO config tables constants

export const getSystemAttributesColumns = (): Column[] => {
  return [
    {
      accessor: "in_idp_set",
      cell: t("accountAndSettings.usersSettings.checkSSOConf.inIdpSet"),
      classNames: ["in_idp_set"],
      sortableHeader: false,
    },
    {
      accessor: "type",
      cell: t("general.type"),
      classNames: ["type"],
      sortableHeader: false,
    },
    {
      accessor: "attribute_name",
      cell: t("accountAndSettings.usersSettings.checkSSOConf.attributeName"),
      classNames: ["attribute_name"],
      maxWidth: 200,
      sortableHeader: false,
    },
    {
      accessor: "attribute_value",
      cell: t("accountAndSettings.usersSettings.checkSSOConf.attributeValue"),
      classNames: ["attribute_value"],
      maxWidth: 200,
      sortableHeader: false,
    },
  ];
};

export const getIdpAttributesColumns = (): Column[] => {
  return [
    {
      accessor: "defined_in_talentlms",
      cell: t("accountAndSettings.usersSettings.checkSSOConf.definedInTalentLMS"),
      classNames: ["defined_in_talentlms"],
      sortableHeader: false,
    },
    {
      accessor: "type",
      cell: t("general.type"),
      classNames: ["type"],
      sortableHeader: false,
    },
    {
      accessor: "attribute",
      cell: t("accountAndSettings.usersSettings.checkSSOConf.attribute"),
      classNames: ["attribute"],
      maxWidth: 400,
      sortableHeader: false,
    },
    {
      accessor: "value",
      cell: t("accountAndSettings.usersSettings.checkSSOConf.value"),
      classNames: ["value"],
      maxWidth: 400,
      sortableHeader: false,
    },
  ];
};

// Custom user fields table
export const getCustomUserFieldsColumns = (): Column[] => {
  return [
    {
      accessor: "name",
      cell: t("general.name"),
      classNames: ["name"],
      maxWidth: 200,
    },
    {
      accessor: "type",
      cell: t("general.type"),
      classNames: ["type"],
      sortOrder: "desc" as Column["sortOrder"],
      maxWidth: 200,
    },
    {
      accessor: "mandatory",
      cell: t("general.mandatory"),
      classNames: ["mandatory"],
      maxWidth: 200,
    },
    {
      accessor: "visible_on_reports",
      cell: t("general.reportVisibility"),
      classNames: ["visible_on_reports"],
      maxWidth: 200,
    },
    {
      accessor: "selective_availability",
      cell: t("general.selectiveAvailability"),
      classNames: ["selective_availability"],
      maxWidth: 200,
    },
  ];
};

export const actionsCustomUserFieldsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
};
export const SORTING_CUSTOM_USER_FIELDS = { column: "custom", isDescending: false };

export const PAGINATION_CUSTOM_USER_FIELDS: IPagination = { number: 1, size: 10 };
export const DEFAULT_FILTERS_CUSTOM_USER_FIELDS: QueryFilter[] = [];

export const customUserFieldTypeOptions = ["text", "dropdown", "checkbox", "date"];
