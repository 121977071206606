import React, { FC } from "react";
import { SerializedStyles } from "@emotion/react";
import { Text } from "@epignosis_llc/gnosis";
import { cardBody } from "./styles";
import { formatUtcDate } from "@utils/helpers";
import { HighlithedText } from "types/common";
import HighlightedText from "@components/MainSearch/HighlightedText";
import { LinkStyled } from "@components";

type FileBodyProps = {
  isReadonly: boolean;
  fileName: string | HighlithedText;
  type?: string;
  size?: string;
  uploaded_at?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const FileBody: FC<FileBodyProps> = ({
  isReadonly,
  fileName,
  type,
  size,
  uploaded_at,
  children,
  ...rest
}) => {
  return (
    <div css={(theme): SerializedStyles => cardBody(theme, { isReadonly })} {...rest}>
      <div className="name-container">
        <Text fontSize="sm" as="div" className="file-name">
          {typeof fileName === "string" ? (
            fileName
          ) : (
            <LinkStyled to="#" color="secondary" className="search-file-link">
              <HighlightedText text={fileName} />
            </LinkStyled>
          )}
        </Text>
        {children}
      </div>
      {Boolean(type || size || uploaded_at) && (
        <div className="details-container">
          {type && (
            <Text fontSize="xs" className="detail">
              {type}
            </Text>
          )}
          {size && (
            <Text fontSize="xs" className="detail">
              {size}
            </Text>
          )}
          {uploaded_at && (
            <Text fontSize="xs" className="detail">
              {formatUtcDate(uploaded_at)}
            </Text>
          )}
        </div>
      )}
    </div>
  );
};

export default FileBody;
