import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";
import {
  BranchesFilterRes,
  CategoriesFilterRes,
  CoursesFilterRes,
  CustomCourseFieldsFilterRes,
  CustomUserFieldsFilterRes,
  FormPostData,
  GroupsFilterRes,
  PostCustomReportRes,
  UserTypesFilterRes,
} from "./components/types";
import { MassActionEnum } from "@components/ReusableComponents/MassActions/types";
import { CountMassActionResponse, MassActionResultResponse, MessageRes } from "types/responses";
import { createCustomReportMessagePostData } from "./types";

export const getBranchesFilter = async (queryStr = ""): Promise<BranchesFilterRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.customReports.filterBranches}${queryStr}`);
  return res.data;
};

export const getCategoriesFilter = async (queryStr = ""): Promise<CategoriesFilterRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.customReports.filterCategories}${queryStr}`);
  return res.data;
};

export const getGroupsFilter = async (queryStr = ""): Promise<GroupsFilterRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.customReports.filterGroups}${queryStr}`);
  return res.data;
};

export const getCoursesFilter = async (queryStr = ""): Promise<CoursesFilterRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.customReports.filterCourses}${queryStr}`);
  return res.data;
};

export const getUserTypesFilter = async (queryStr = ""): Promise<UserTypesFilterRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.customReports.filterUserTypes}${queryStr}`);
  return res.data;
};

export const getUserCustomFieldsFilter = async (
  queryStr = "",
): Promise<CustomUserFieldsFilterRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.customReports.filterUserCustomFields}${queryStr}`);
  return res.data;
};

export const getCourseCustomFieldsFilter = async (
  queryStr = "",
): Promise<CustomCourseFieldsFilterRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.customReports.filterCourseCustomFields}${queryStr}`,
  );
  return res.data;
};

export const postCustomReports = async (formData: FormPostData): Promise<PostCustomReportRes> => {
  const res = await HttpClient.post(ENDPOINTS.reports.customReports, formData);
  return res.data;
};

export const putCustomReport = async (
  id: string,
  formData: FormPostData,
): Promise<PostCustomReportRes> => {
  const res = await HttpClient.put(ENDPOINTS.reports.customReport(id), formData);
  return res.data;
};

export const customReportMassActions = async (
  type: MassActionEnum,
  reportId: string,
  selectedMassActionItem?: number,
): Promise<MassActionResultResponse> => {
  let endpoint = "";
  switch (type) {
    case MassActionEnum.custom_reports_delete_users:
      endpoint = ENDPOINTS.customReports.massActions.deleteUsers(reportId);
      break;
    case MassActionEnum.activate:
      endpoint = ENDPOINTS.customReports.massActions.usersActivate(reportId);
      break;
    case MassActionEnum.deactivate:
      endpoint = ENDPOINTS.customReports.massActions.usersDeactivate(reportId);
      break;
    case MassActionEnum.add_to_branch:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.branchAdd(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.remove_from_branch:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.branchRemove(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.add_to_group:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.groupAdd(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.remove_from_group:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.groupRemove(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.add_to_course:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.courseAdd(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.remove_from_course:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.courseRemove(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.custom_reports_reset_progress:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.resetProgressOnCourse(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    default:
      break;
  }
  const res = await HttpClient.post(endpoint);
  return res.data;
};

export const customReportMassActionsCount = async (
  type: MassActionEnum,
  reportId: string,
  selectedMassActionItem?: number,
): Promise<CountMassActionResponse> => {
  let endpoint = "";

  switch (type) {
    case MassActionEnum.custom_reports_delete_users:
      endpoint = ENDPOINTS.customReports.massActions.deleteUsersCount(reportId);
      break;
    case MassActionEnum.custom_reports_reset_progress:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.resetProgressOnCourseCount(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.activate:
      endpoint = ENDPOINTS.customReports.massActions.usersActivateCount(reportId);
      break;
    case MassActionEnum.deactivate:
      endpoint = ENDPOINTS.customReports.massActions.usersDeactivateCount(reportId);
      break;
    case MassActionEnum.add_to_branch:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.branchAddCount(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.remove_from_branch:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.branchRemoveCount(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.add_to_group:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.groupAddCount(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.remove_from_group:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.groupRemoveCount(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.add_to_course:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.courseAddCount(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    case MassActionEnum.remove_from_course:
      if (selectedMassActionItem)
        endpoint = ENDPOINTS.customReports.massActions.courseRemoveCount(
          reportId,
          selectedMassActionItem.toString(),
        );
      break;
    default:
      break;
  }

  const res = await HttpClient.get(endpoint);
  return res.data;
};

export const createCustomReportMessage = async (
  newMessage: createCustomReportMessagePostData,
  reportId: string,
): Promise<MessageRes> => {
  const res = await HttpClient.post(
    ENDPOINTS.customReports.massActions.sendMessage(reportId),
    newMessage,
  );
  return res.data;
};
