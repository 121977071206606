import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";
import Color from "color";

export default (colors: PalletThemeColors): ThemePlusTypes["courseOverview"] => {
  return {
    background: colors.primary.darker,
    borderLeft: colors.white,
    bundlesBorder: colors.secondary.base,
    codeColor: colors.secondary.dark,
    completed: colors.green.base,
    completionTextHoverColor: colors.primary.base,
    category: {
      color: colors.primary.base,
      hover: colors.primary.lighter,
    },
    certificate: {
      background: colors.secondary.lighter,
    },
    customFieldTag: {
      background: colors.secondary.lighter,
      hoverBackground: colors.secondary.light,
    },
    disabled: colors.secondary.darker,
    footerBorder: colors.secondary.light,
    mobileDot: colors.black,
    headerColor: colors.white,
    prerequisites: {
      background: colors.secondary.lighter,
      hover: colors.white,
      textColor: colors.black,
      textHoverColor: colors.primary.base,
    },
    textColor: colors.black,
    thumbnailSvgColor: colors.primary.base,
    thumbnailHover: Color(colors.primary.darker).alpha(0.5).string(),
    startButton: {
      background: colors.white,
      color: colors.primary.darker,
      hoverColor: colors.primary.light,
    },
  };
};
