// Packages or third-party libraries
import React, { FC, PropsWithChildren } from "react";
import { Grid } from "@epignosis_llc/gnosis";

// Styles
import { tabWrapperStyles } from "./styles";

// Other imports
import { TAB_WRAPPER_TEMPLATE_COLUMNS } from "../constants";

const TabWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <section css={tabWrapperStyles}>
      <Grid templateColumns={TAB_WRAPPER_TEMPLATE_COLUMNS} rowGap={2} columnGap={1}>
        {children}
      </Grid>
    </section>
  );
};

export default TabWrapper;
