import { AnyObjectSchema } from "yup";
import { yup } from "./validation";

export const CategoriesSchemas = (isFloatCurrency: boolean): AnyObjectSchema =>
  yup.object().shape({
    name: yup.string().trim().max(80).required(),
    parent_category_id: yup.number().min(0).nullable(), // API doesn't accept 0, we modify this to be sent as null
    price: yup
      .number()
      .min(0.01)
      .max(9999999999)
      .nullable()
      .maxDigitsAfterDecimal(isFloatCurrency ? 2 : 0),
  });
