import { css } from "@emotion/react";
import { typeScale } from "@epignosis_llc/gnosis";

export const courseCardProgressBar = ({ courseCard }, { progress }) => css`
  .percentage-container {
    height: 2rem;
    font-size: ${typeScale.xs};
    background-color: ${courseCard.progressBar.background};

    div {
      min-width: ${progress === 0 ? 0 : null};
      color: ${progress === 0
        ? courseCard.progressBar.percentageColor
        : courseCard.progressBar.color};
      margin-inline-start: ${progress <= 20 ? "0.5rem" : 0};
      padding-inline-start: ${progress === 0 ? 0 : "1rem"};
    }

    &::after {
      min-width: ${progress === 0 ? 0 : null};
    }
  }
`;

export const cardFooterContainer = css`
  min-height: 57px;

  .footer-btn {
    height: 2rem;
    border-radius: 0;
    padding: 0.25rem 0.5rem;

    .with-icon {
      display: flex;
      gap: 0.325rem;
      align-items: center;
    }
  }

  .subscribe-btn-text {
    display: flex;
    align-items: center;
  }
`;

export const cardProgressBarContainer = ({ courseCard }, { percent }) => css`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: ${courseCard.cardFooter.background};
  height: 2rem;
  border-radius: 5px;
  width: 100%;

  .percentage-bar {
    width: ${percent}%;
    background: ${courseCard.progressBar.percentageColor};
    height: 10px;
    border-radius: 0px 6px 6px 0px;
  }

  .label {
    margin-inline-end: 0.5rem;
  }
`;

export const restrictionsContainer = ({ courseCard }) => css`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .restriction-item {
    display: flex;
    height: 32px;
    background: ${courseCard.cardFooter.background};
    border-radius: 5px;
    width: 32px;

    &.expired-icon {
      background: ${courseCard.cardFooter.expired};
      color: ${courseCard.cardFooter.textColor};
      border-radius: 5px;
    }

    &.about-to-be-expired {
      background: ${courseCard.cardFooter.aboutToExpireBackground};
    }
  }

  .ellipsis-icon {
    width: 32px;
  }
`;

export const tooltipContainerStyles = () => css`
  .option-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;
