import React, { FC, useRef } from "react";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { CourseFile, File } from "types/entities";
import { useIsOverflow } from "@hooks";

type FileNameProps = File | CourseFile;

const FileName: FC<FileNameProps> = (props) => {
  const { ...rest } = props;
  const { name } = rest;

  const ref = useRef<HTMLSpanElement | null>(null);
  const overflowActive = useIsOverflow(ref);

  return (
    <>
      <Tooltip content={name} disabled={!overflowActive}>
        <Text fontSize="sm" data-testid="file-text" className="file-name">
          <span ref={ref}>{name}</span>
        </Text>
      </Tooltip>
    </>
  );
};

export default FileName;
