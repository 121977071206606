import { css } from "@emotion/react";

export const catalogCardContainerStyles = css`
  color: inherit;
  width: 100%;

  &:hover {
    color: inherit;
  }
`;
