// Packages
import React, { FC, useState } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

// Styles
import { startButtonStyles } from "../../styles";

// Components
import { ConfirmationModal } from "@components";

// Utils
import { useApplyTranslations } from "@hooks";
import { generalNotification, getCurrencyPrice } from "@utils/helpers";
import { acquireOtherProviderCourse } from "@views/CourseStore/Course/api";

// Others
import { Price } from "types/entities/Common";
import { useConfigurationStore } from "@stores";
import queryKeys from "@constants/queryKeys";
import { AxiosError } from "axios";
import { handleCourseStoreCoursesErrors } from "@errors/errors";
import { URLS } from "@constants/urls";

type OtherProvidersCourseButtonProps = {
  isCourseAcquired: boolean;
  coursePrice: Price | null;
  mappedCourseId?: string;
  setIsEnrollDrawerOpen?: (isOpen: boolean) => void;
};

const OtherProvidersCourseButton: FC<OtherProvidersCourseButtonProps> = ({
  isCourseAcquired,
  coursePrice,
  mappedCourseId,
  setIsEnrollDrawerOpen,
}) => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();
  const { courseId } = useParams() as { courseId: string };
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { locale } = domainSettings ?? {};

  const price =
    coursePrice && getCurrencyPrice(coursePrice?.amount, coursePrice?.currency, locale ?? "");

  const { mutate: acquireCourseMutation, isLoading } = useMutation(
    [queryKeys.courseStore.otherProvidersAcquire],
    () => acquireOtherProviderCourse(courseId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.courseStore.otherProvidersCourse, courseId]);
        generalNotification("success", <p>{t("courseStore.courseAddedToPortal")}</p>);
      },
      onError: (error: AxiosError) => {
        handleCourseStoreCoursesErrors(error);
      },
    },
  );

  const redirectToCourseEdit = (): void => {
    const link = URLS.courses.getCourseEditLink({ courseId: mappedCourseId });
    navigate(link);
  };

  const handleButtonClick = async (): Promise<void> => {
    acquireCourseMutation();
  };

  //  Case 1: course is acquired  -> Buy more licenses
  if (coursePrice?.amount) {
    if (isCourseAcquired) {
      return (
        <div css={startButtonStyles}>
          <Button
            className="start-button"
            color="secondary"
            block
            onClick={(): void => setIsEnrollDrawerOpen?.(true)}
          >
            {t("courseCatalog.buyMoreLicenses", { price })}
          </Button>
        </div>
      );
    }

    //  Case 2: course not acquired -> Buy licenses
    return (
      <div css={startButtonStyles}>
        <Button
          color="secondary"
          className="start-button"
          onClick={(): void => setIsEnrollDrawerOpen?.(true)}
        >
          {t("courseCatalog.buyLicensesPrice", { price })}
        </Button>
      </div>
    );
  }

  // Case 3: course is free -> Enroll
  return (
    <>
      <div css={startButtonStyles}>
        <Button
          color="secondary"
          className="start-button"
          isLoading={isLoading}
          onClick={
            isCourseAcquired ? redirectToCourseEdit : (): void => setIsConfirmationModalOpen(true)
          }
        >
          {isCourseAcquired ? t("courseEdit.edit") : t("courseCatalog.getCourse")}
        </Button>
      </div>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          id={courseId}
          header={t("courseCatalog.getCourse")}
          bodyTitle={
            <div
              dangerouslySetInnerHTML={{
                __html: t("courseStore.massActions.activateWarningTitle_one"),
              }}
            />
          }
          footerButton={t("courseStore.getCourse")}
          confirmationButtonColor="primary"
          isOpen={isConfirmationModalOpen}
          closeOnOutsideClick
          onClose={(): void => setIsConfirmationModalOpen(false)}
          onConfirm={handleButtonClick}
        />
      )}
    </>
  );
};

export default OtherProvidersCourseButton;
