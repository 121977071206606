import { css } from "@emotion/react";

export const sidebarIconStyles = ({ courseToc }) => css`
  .icon-container {
    height: 2rem;
    width: 2rem;
    border-radius: 5px;

    svg {
      color: ${courseToc.icon.color};
    }
  }
`;

export const linkContainerStyles = css`
  a {
    margin-inline-start: 0.25rem;
  }
`;
