import { TableProps } from "@epignosis_llc/gnosis";
import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

export const fileSortingFilters = ["name", "uploaded_at", "size_in_bytes"];
export const SORTING: TableProps["sorting"] = { column: "name", isDescending: false };
export const PAGINATION: IPagination = { number: 1, size: 10 };
export const DEFAULT_FILTERS: QueryFilter[] = [{ key: "[shared]", value: "1" }];

export const DEFAULT_STATE = {
  pagination: PAGINATION,
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
};
