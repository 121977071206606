// Packages or third-party libraries
import React, { FC, useRef, useState, MouseEvent } from "react";
import { useClickAway } from "ahooks";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "@epignosis_llc/gnosis";
import { PlusIconSVG } from "@epignosis_llc/gnosis/icons";
import { SerializedStyles } from "@emotion/react";

// Styles
import { DropdownStyles, TalentCraftDisclaimer } from "./styles";

// Components
import { ConfirmationModal } from "@components";
import UnitsDropdown from "./UnitsDropdown";

// Utils, hooks
import { generalNotification, getActiveThemePrimaryColor } from "@utils/helpers";
import { getUnitsMenu } from "./helpers";
import { handleNoPermissionError } from "@errors";
import { useConfigurationStore, useUIStore } from "@stores";
import { useApplyTranslations } from "@hooks";

// Other imports
import { putCraftDisclaimer } from "@api/courses";
import { createSection, createUnit } from "../../api";
import { UnitType, Course } from "types/entities";
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";
import { CreateUnitActions } from "../../constants";

const { CREATE_SECTION, CREATE_TALENT_CRAFT_UNIT, CLONE_FROM_COURSE, LINK_FROM_COURSE } =
  CreateUnitActions;

type UnitCreateDropdownProps = {
  course: Course;
  onCloneFromCourse: () => void;
  onLinkFromCourse: () => void;
};

const UnitCreateDropdown: FC<UnitCreateDropdownProps> = ({
  course,
  onCloneFromCourse,
  onLinkFromCourse,
}) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { showUnitSidebar, toggleUnitSidebar } = useUIStore();
  const { userProfileData } = useConfigurationStore();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowTalentCraftDisclaimerModal, setTalentCraftDisclaimerModal] = useState(false);
  const { domainSettings } = useConfigurationStore();
  const { theme } = domainSettings ?? {};

  const { id } = course;
  const { has_accepted_craft_disclaimer } = userProfileData ?? {};
  const courseId = id.toString();

  useClickAway(() => {
    setIsExpanded(false);
  }, dropdownRef);

  const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleCreateUnitDropdownItemClick = async (value: string | undefined): Promise<void> => {
    setIsExpanded(false);
    if (!value) return;

    switch (value) {
      case CREATE_SECTION: {
        createSectionMutation(id);
        return;
      }
      case CREATE_TALENT_CRAFT_UNIT: {
        if (has_accepted_craft_disclaimer) {
          await createUnitMutation({
            course_id: id,
            type: value as UnitType,
            primary_color: theme ? getActiveThemePrimaryColor(theme) : undefined,
          });
          showUnitSidebar && toggleUnitSidebar();
        } else {
          setTalentCraftDisclaimerModal(true);
        }
        return;
      }
      case CLONE_FROM_COURSE: {
        onCloneFromCourse();
        return;
      }
      case LINK_FROM_COURSE: {
        onLinkFromCourse();
        return;
      }
      default:
        break;
    }

    // Handle other unit creation
    await createUnitMutation({ course_id: id, type: value as UnitType });
  };

  const { mutateAsync: createUnitMutation } = useMutation(
    [queryKeys.courses.unitCreate],
    createUnit,
    {
      onSuccess: (res) => {
        const unit = res._data;
        const unitId = unit.id.toString();
        queryClient.invalidateQueries([queryKeys.units, courseId]);
        navigate(URLS.units.getUnitEditLink({ courseId, unitId }));
      },
      onError: (error: AxiosError) => {
        handleNoPermissionError(error);
      },
    },
  );

  const { mutate: createSectionMutation } = useMutation(
    [queryKeys.courses.sectionCreate],
    createSection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.units, courseId]);
        generalNotification("success", <p>{t("courseEdit.sectionCreatedSuccessfully")}</p>);
      },
      onError: (error: AxiosError) => {
        handleNoPermissionError(error);
      },
    },
  );

  const { mutate: putDisclaimerMutation } = useMutation(
    [queryKeys.courses.sectionCreate],
    putCraftDisclaimer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.userProfile]);
      },
    },
  );

  const handleConfirm = async (): Promise<void> => {
    setTalentCraftDisclaimerModal(false);
    putDisclaimerMutation();
    await createUnitMutation({ course_id: id, type: "craft" as UnitType });
    showUnitSidebar && toggleUnitSidebar();
  };

  const handleClose = (): void => {
    setTalentCraftDisclaimerModal(false);
  };

  return (
    <div css={(theme): SerializedStyles => DropdownStyles(theme)} ref={dropdownRef}>
      <UnitsDropdown
        handleCreateUnitFn={handleCreateUnitDropdownItemClick}
        unitsMenu={getUnitsMenu()}
        isExpanded={isExpanded}
      />
      <Button
        id="add-unit-btn"
        noGutters
        className="add-unit-btn"
        iconBefore={PlusIconSVG}
        onClick={handleMenuOpen}
      >
        {t("general.add")}
      </Button>

      <ConfirmationModal
        id="talent-craft-disclaimer"
        header={t("terms.talentcraft.termsOfUse")}
        bodyTitle={
          <div css={TalentCraftDisclaimer}>
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph1") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph2") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph3") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph4") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph5") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph6") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph6half") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.paragraph7") }} />
            <p dangerouslySetInnerHTML={{ __html: t("terms.talentcraft.date") }} />
          </div>
        }
        footerButton={t("general.agreeAndContinue")}
        restrictedBody={true}
        confirmationButtonColor="primary"
        isOpen={shouldShowTalentCraftDisclaimerModal}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default UnitCreateDropdown;
