import { t } from "@utils/i18n";
import { mapUnitsToSelectOption } from "@views/CourseEdit/helpers";
import { SelectOption } from "types/common";
import { MyUnit } from "types/entities";
import { CompletionRule, ScoreUnit } from "@views/CourseEdit/types";
import { ScoreRule } from "types/entities/Courses";

const SPECIFIC_UNIT_TYPES = ["test", "assignment", "ilt", "scorm", "xapi", "cmi5"];

export const getCompletionRuleOptions = (units: MyUnit[]): SelectOption[] => {
  const completionRuleOptions: SelectOption[] = [
    { label: t("courseEdit.completionRules.allUnits"), value: CompletionRule.AllUnits },
    { label: t("courseEdit.completionRules.unitPercentage"), value: CompletionRule.UnitPercentage },
  ];

  const hasUnits = units.length > 0;
  const hasTestUnits = units.some(({ type }) => type === "test");

  if (hasUnits) {
    completionRuleOptions.push({
      label: t("courseEdit.completionRules.certainUnits"),
      value: CompletionRule.CertainUnits,
    });
  }

  if (hasTestUnits) {
    completionRuleOptions.push({
      label: t("courseEdit.completionRules.passedTest"),
      value: CompletionRule.PassedTest,
    });
  }

  return completionRuleOptions;
};

export const getPassedTestOptions = (units: MyUnit[]): SelectOption[] => {
  return mapUnitsToSelectOption(units.filter(({ type }) => type === "test"));
};

export const getScoreRuleOptions = (units: MyUnit[]): SelectOption[] => {
  const completionRuleOptions: SelectOption[] = [
    {
      label: t("courseEdit.scoreRules.allTestsAndAssignments"),
      value: ScoreRule.AllTestsAndAssignments,
    },
    {
      label: t("courseEdit.scoreRules.testsOnly"),
      value: ScoreRule.TestsOnly,
    },
  ];

  const hasUnits = units.some(({ type }) => SPECIFIC_UNIT_TYPES.includes(type));

  if (hasUnits) {
    completionRuleOptions.push({
      label: t("courseEdit.scoreRules.specificUnits"),
      value: ScoreRule.SpecificUnits,
    });
  }

  return completionRuleOptions;
};

export const getSpecificUnitsOptions = (units: MyUnit[]): SelectOption[] => {
  return mapUnitsToSelectOption(units.filter(({ type }) => SPECIFIC_UNIT_TYPES.includes(type)));
};

export const getScoreUnitsFromOptions = (
  scoreUnits: ScoreUnit[],
  options: SelectOption[],
): ScoreUnit[] => {
  return options.reduce((array, option) => {
    const scoreUnit = scoreUnits.find(({ id }) => id.toString() === option.value);

    if (scoreUnit) {
      const { id, weight } = scoreUnit;
      array.push({ id, weight });
    } else {
      array.push({ id: Number(option.value), weight: 1 });
    }
    return array;
  }, [] as ScoreUnit[]);
};
