import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { modalFooter } from "../styles";
import { useConfigurationStore, useUIStore } from "@stores";
import localStorageKeys from "@constants/localStorageKeys";
import { useParams } from "react-router-dom";
import { Bundle } from "types/entities";
import { useApplyTranslations } from "@hooks";

export type ExternalPaymentModalProps = {
  onClose: () => void;
  actionType: "enrollment" | "payment";
  activeBundle?: Bundle;
};

const ExternalFooterButtons: FC<ExternalPaymentModalProps> = ({
  onClose,
  actionType,
  activeBundle,
}) => {
  const { t } = useApplyTranslations();
  const { domainSettings, ssoDomainSettings } = useConfigurationStore();

  const { courseId } = useParams() as { courseId: string };
  const { setShowSignInModal, setShowSignUpModal } = useUIStore();
  const isSignUpEnabled = Boolean(domainSettings?.signup.enabled);

  const { login_url = "" } = ssoDomainSettings ?? {};
  const { sso } = domainSettings ?? {};
  const { disable_direct_login: isDirectLoginDisabled } = sso ?? {};
  const isSSOEnabled = Boolean(domainSettings?.sso.integration_type);

  const handleButtonClick = (type: "signup" | "login"): void => {
    onClose();

    if (isDirectLoginDisabled && isSSOEnabled) {
      window.location.replace(login_url);
    } else {
      type === "login" ? setShowSignInModal(true) : setShowSignUpModal(true);
    }

    const dataObject = {
      enrollment: actionType === "enrollment",
      courseId: courseId,
    };

    if (activeBundle) {
      dataObject["activeBundle"] = activeBundle;
    }

    localStorage.setItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP, JSON.stringify(dataObject));
  };

  return (
    <div css={modalFooter}>
      <Button
        color={isSignUpEnabled ? "primary" : "secondary"}
        onClick={isSignUpEnabled ? (): void => handleButtonClick("login") : onClose}
        className="cancel-btn"
        type="button"
      >
        {t(isSignUpEnabled ? "signIn.login" : "general.cancel")}
      </Button>
      <Button
        onClick={(): void => handleButtonClick(isSignUpEnabled ? "signup" : "login")}
        type="button"
      >
        {t(isSignUpEnabled ? "signUp.signUp" : "signIn.login")}
      </Button>
    </div>
  );
};

export default ExternalFooterButtons;
