import { AIJob, AIJobType } from "types/entities/AIJobs";
import { useQuery } from "react-query";
import { useRef, useState } from "react";
import { AIJobsRes } from "@views/Skills/types";

export type FetchJobsFunction = (signal: AbortSignal) => Promise<AIJobsRes>;

/**
 * Maps each AIJobStatus filter to a single `AIJob`, or `undefined` if no matching job exists.
 */
export type FilteredAIJobs<T extends readonly AIJobType[]> = { [K in T[number]]?: AIJob };

type UseAIJobPollingReturnType<T> = T & {
  startPolling: () => void;
  stopPolling: () => void;
};

/**
 * Hook to fetch AI jobs with optional filtering by `AIJobType` types.
 *
 * - When `filters` are provided:
 *   Returns an object, mapping each filter to a single `AIJob` (or `undefined` if no matching job is found).
 *
 * - When no filters are provided:
 *   Returns an object containing an array of all `AIJob` entities.
 *
 * @template T - A readonly array of `AIJobType` strings (filters).
 * @param shouldStartImmediately - Whether polling should start automatically, as soon as the hook is mounted.
 * @param getJobs - Function to fetch AI jobs. Accepts an `AbortSignal` and returns a promise of `AIJobsRes`.
 * @param filters - (Optional) A readonly array of job types to filter by.
 * @param pollInterval - (Optional) Polling interval in milliseconds (default: 3000).
 *
 * @returns
 * - If `filters` is provided:
 *   An object of type `FilteredAIJobs<T>` where keys are filter types and values are `AIJob` or `undefined`.
 * - If `filters` is not provided:
 *   An object containing an array of all `AIJob` entities.
 * - Regardless of the `filters` cases mentioned above, the following two functions are always returned:
 *    - `startPolling`
 *    - `stopPolling`
 *
 * @example
 * // Without filters
 * const { jobs, startPolling } = useAIJobs(true, fetchJobs);
 * console.log(jobs); // Output: AIJob[]
 *
 * @example
 * // With filters
 * const { generate_test_questions, generate_video_captions, startPolling } = useAIJobs(
 *     true,
 *     fetchJobsFunction,
 *     ["generate_test_questions", "generate_video_captions"],
 *   );
 * console.log(generate_test_questions); // Output: AIJob or undefined
 * console.log(generate_video_captions); // Output: AIJob or undefined
 */
export function useAIJobPolling<T extends readonly AIJobType[]>(
  shouldStartImmediately: boolean,
  getJobs: FetchJobsFunction,
  filters: T,
  pollInterval?: number,
): UseAIJobPollingReturnType<FilteredAIJobs<T>>;

/**
 * Overload of `useAIJobs`.
 */
export function useAIJobPolling(
  shouldStartImmediately: boolean,
  getJobs: FetchJobsFunction,
  filters?: undefined,
  pollInterval?: number,
): UseAIJobPollingReturnType<{ jobs: AIJob[] }>;

/**
 * Implementation of `useAIJobs`.
 */
export function useAIJobPolling<T extends readonly AIJobType[]>(
  shouldStartImmediately: boolean,
  getJobs: FetchJobsFunction,
  filters?: T,
  pollInterval = 3000,
): UseAIJobPollingReturnType<{ jobs: AIJob[] } | FilteredAIJobs<T>> {
  const [isPolling, setIsPolling] = useState(shouldStartImmediately);

  const [filteredJobs, setFilteredJobs] = useState<FilteredAIJobs<T>>(() =>
    filterAIJobs(filters ?? [], []),
  );

  const [allJobs, setAllJobs] = useState<AIJob[]>([]);

  const abortControllerRef = useRef<AbortController | null>(null);
  const jobsFetchErrorCount = useRef(0);

  const startPolling = (): void => {
    jobsFetchErrorCount.current = 0;
    setIsPolling(true);
  };

  const stopPolling = (): void => {
    // Cancel pending request (if any), and reset the state.
    jobsFetchErrorCount.current = 0;
    abortControllerRef.current?.abort();
    setIsPolling(false);
    clearJobs();
  };

  const clearJobs = (): void => {
    if (!filters) {
      setAllJobs([]);
    } else {
      setFilteredJobs(filterAIJobs(filters, []));
    }
  };

  useQuery<AIJobsRes>(
    ["ai-job-polling-", JSON.stringify(filters)],
    () => {
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      return getJobs(signal);
    },
    {
      cacheTime: 0,
      enabled: isPolling,
      retry: false, // Prevent retries within the same query
      refetchInterval: pollInterval,
      onSuccess: (res) => {
        jobsFetchErrorCount.current = 0;

        const allJobs = res._data;

        if (!filters) {
          setAllJobs([...allJobs]);
        } else {
          setFilteredJobs(filterAIJobs(filters, allJobs));
        }

        if (areAllJobsStopped(allJobs)) {
          setIsPolling(false);
        }
      },
      onError: () => {
        jobsFetchErrorCount.current += 1;

        // Stop polling after 3 consecutive errors.
        if (jobsFetchErrorCount.current >= 3) {
          stopPolling();
        }
      },
    },
  );

  return filters
    ? { ...filteredJobs, startPolling, stopPolling }
    : { jobs: [...allJobs], startPolling, stopPolling };
}

export function filterAIJobs<T extends readonly AIJobType[]>(
  filters: T,
  jobs: AIJob[],
): FilteredAIJobs<T> {
  const uniqueFilters = Array.from(new Set(filters)) as readonly AIJobType[];

  const filteredJobs = {} as FilteredAIJobs<T>;

  for (const filter of uniqueFilters) {
    const job = jobs.find((job) => job.type === filter);
    filteredJobs[filter] = job ? { ...job } : undefined;
  }

  return filteredJobs;
}

export function areAllJobsStopped(jobs: AIJob[]): boolean {
  const allJobsStopped = jobs.every((job) => job.status === "completed" || job.status === "failed");
  return jobs.length === 0 || allJobsStopped;
}
