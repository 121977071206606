import { DropdownItem } from "@epignosis_llc/gnosis";
import { t } from "@utils/i18n";
import { TabKey } from "@views/CourseEdit/components/CourseOptions";

export const getCourseDropdownOptions = (): DropdownItem[] => {
  return [
    {
      label: t("general.info"),
      value: TabKey.Info,
    },
    {
      label: t("general.availability"),
      value: TabKey.Availability,
    },
    {
      label: t("general.limits"),
      value: TabKey.Limits,
    },
    {
      label: t("general.completion"),
      value: TabKey.Completion,
    },
  ];
};
