import React, { FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { FormError, Text } from "@epignosis_llc/gnosis";
import { AxiosError } from "axios";

import { paymentDrawerStyles } from "./styles";

import PrerequisitesInfo from "./PrerequisitesInfo";
import CouponForm from "./CouponForm";
import PaymentMethods from "./PaymentMethods";
import PaymentFooter from "./PaymentFooter";
import { ActionDrawer } from "@components";
import PaymentHeader from "./PaymentHeader";

import { Course } from "types/entities";
import { Bundle, CatalogPurchase, CatalogSettings, Processors } from "types/entities/Catalog";
import { Price } from "types/entities/Common";

import localStorageKeys from "@constants/localStorageKeys";
import queryKeys from "@constants/queryKeys";

import { handlePaymentErrors, invalidCouponErrors } from "@errors";
import { useConfigurationStore } from "@stores";
import { postCatalogPurchase } from "@api/catalog";
import { generalNotification } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { getDependentCoursesNum } from "../helpers";
import { getBaseUrl } from "@config";
import { URLS } from "@constants/urls";

export type PaymentDrawerProps = {
  paymentData: Course | Bundle;
  isOpen: boolean;
  onClose: () => void;
  categoryText?: string;
};

export type NewPriceData = {
  newPrice: Price;
  coupon: string;
};

function isCourse(paymentData: Course | Bundle): paymentData is Course {
  return (paymentData as Course).category !== undefined;
}

const getCanEnroll = (
  processors: CatalogSettings["processors"] | undefined,
  hasEnoughCredits: boolean,
  currentProcessor: Processors | "",
  price: Price | null,
): boolean => {
  if (!processors) return false; // If no processors are available, return false
  if (!price?.amount) return true; // If we dont have a price, return true
  if (currentProcessor === "credits") return hasEnoughCredits; // If we have a credits as processors, check if we have enough credits

  return true; // If the processor is stripe or paypal return true
};

const PaymentDrawer: FC<PaymentDrawerProps> = ({ paymentData, isOpen, onClose, categoryText }) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const { catalogSettings, userProfileData } = useConfigurationStore();
  const [newPaymentData, setNewPaymentData] = useState<Course | Bundle>(paymentData);
  const [showInvalidCouponMessage, setShowInvalidCouponMessage] = useState(false);
  const [discountCoupon, setDiscountCoupon] = useState("");
  const { processors, coupons_enabled } = catalogSettings ?? {};
  const dataType = isCourse(newPaymentData) ? "course" : newPaymentData.type;
  const [activeTab, setActiveTab] = useState<number>(0);
  const hasEnoughCredits = newPaymentData.discounted_price
    ? newPaymentData.discounted_price.amount <= (userProfileData?.credits as number)
    : (newPaymentData.price?.amount as number) <= (userProfileData?.credits as number);

  const currentProcessor = processors ? processors.sort()[activeTab] : "";

  const canEnroll = getCanEnroll(
    processors,
    hasEnoughCredits,
    currentProcessor,
    newPaymentData.price,
  );
  const dependentCoursesNum =
    dataType === "course" ? getDependentCoursesNum(paymentData as Course) : 0;
  const hasMultipleDependentCourses = dependentCoursesNum === "multi";
  const isStripeOrPaypal = ["stripe", "paypal"].includes(currentProcessor);
  const showRedirectMessage = (newPaymentData.discounted_price?.amount ?? 0) > 0;
  const redirectInfoText =
    isStripeOrPaypal && showRedirectMessage
      ? t("payments.redirectSafely", {
          name: currentProcessor.charAt(0).toUpperCase() + currentProcessor.slice(1),
        })
      : "";

  const { mutate: paymentMutation, isLoading: paymentMutationLoading } = useMutation(
    [queryKeys.courseEnrollment],
    (newPaymentData: CatalogPurchase) => postCatalogPurchase(newPaymentData),
    {
      onSuccess: (res) => {
        const isCourse = dataType === "course";

        queryClient.invalidateQueries([queryKeys.userProfile]); // Invalidate user credits
        queryClient.invalidateQueries([queryKeys.catalog]);

        if (isCourse) {
          queryClient.invalidateQueries([queryKeys.course, newPaymentData.id.toString()]);
        } else {
          // Data type is bundle
          queryClient.invalidateQueries([queryKeys.bundles]);
        }

        if (isStripeOrPaypal && res._data?.redirect_url) {
          // Create a payment status local storage key in order to capture correctly the payment event from the return_url.
          localStorage.setItem(localStorageKeys.PAYMENT_STATUS, "pending");
          window.location.replace(res._data.redirect_url);
        } else {
          generalNotification(
            "success",
            t(isCourse ? "general.paymentSuccessCourse" : "general.paymentSuccessBundle", {
              name: newPaymentData.name,
            }),
          );
          handleModalClose();
        }
      },

      onError: (error: AxiosError) => {
        handlePaymentErrors(error, (foundError) => {
          // If the found error is for invalid coupon, show the error message and reset the coupon.
          setNewPaymentData(paymentData);
          if (foundError && invalidCouponErrors.includes(foundError?.id)) {
            setShowInvalidCouponMessage(true);
            setDiscountCoupon("");
          } else {
            generalNotification(
              "error",
              t(foundError?.errorMsg ?? "general.somethingWentWrongPleaseTryAgain"),
            );
          }
        });
      },
    },
  );

  const handlePayment = (): void => {
    const { id, discounted_price, price } = newPaymentData;

    const pathURL = `${getBaseUrl()}${URLS.catalog.createCourseLink({ courseId: id.toString() })}`;

    // Build the object to mutate
    const paymentPostData = {
      id,
      type: dataType,
      processor: currentProcessor,
      price: discounted_price ? discounted_price.amount : price?.amount,
      ...(catalogSettings?.global_discount && { discount: catalogSettings.global_discount }),
      ...(discountCoupon.length > 0 && { coupon: discountCoupon }),
      ...(isStripeOrPaypal && {
        // Add status and the current processor on the url to capture the payment event
        return_path: `${pathURL}?status=success&processor=${currentProcessor}&type=payment`,
        cancel_path: pathURL,
      }),
    } as CatalogPurchase;

    paymentMutation(paymentPostData);
  };

  const handleValidCoupon = (newData: NewPriceData): void => {
    const { newPrice, coupon } = newData;

    const paymentData = { ...newPaymentData, discounted_price: newPrice };
    setNewPaymentData(paymentData);
    coupon.length > 0 && setDiscountCoupon(coupon);
  };

  const handleModalClose = (): void => {
    setNewPaymentData(paymentData); // Reset the paymentData
    setDiscountCoupon("");
    setShowInvalidCouponMessage(false);
    onClose();
  };

  const handleCouponClear = (): void => {
    setNewPaymentData(paymentData); // Reset the paymentData
    setDiscountCoupon("");
    setShowInvalidCouponMessage(false);
  };

  const handleInvalidCoupon = (): void => {
    setNewPaymentData(paymentData); // Reset the paymentData
  };

  useEffect(() => {
    setNewPaymentData(paymentData);
  }, [paymentData]);

  useEffect(() => {
    if (discountCoupon.length > 0) setShowInvalidCouponMessage(false);
  }, [discountCoupon]);

  // If there is an update on the number of processors and we have no processors, close the modal
  useEffect(() => {
    if (processors?.length === 0) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processors]);

  return (
    <>
      <ActionDrawer
        isOpen={isOpen}
        headerTitle={dataType === "course" ? t("courseStore.getCourse") : t("payments.getBundle")}
        onClose={onClose}
        size="sm"
        customFooter={
          <PaymentFooter
            onApply={handlePayment}
            onClose={onClose}
            actionButtonInfo={{
              text: t("general.checkout"),
              isLoading: paymentMutationLoading,
              isDisabled: !canEnroll,
            }}
            redirectInfoText={redirectInfoText}
          />
        }
      >
        <div css={paymentDrawerStyles}>
          <PaymentHeader data={paymentData} categoryText={categoryText} />

          <hr className="section-divider" />

          {showInvalidCouponMessage && (
            <div className="invalid-coupon-container">
              <FormError>
                <Text fontSize="sm" as="div">
                  {t("payments.invalidCouponInfo")}
                </Text>
              </FormError>
            </div>
          )}

          {Boolean(dependentCoursesNum) && (
            <div className="invalid-coupon-container">
              <PrerequisitesInfo
                coursesNum={hasMultipleDependentCourses ? 0 : dependentCoursesNum}
                hasMultiPrerequisites={hasMultipleDependentCourses}
              />
            </div>
          )}

          {/* If coupons are enabled  */}
          {Boolean(coupons_enabled) && (
            <>
              <CouponForm
                id={newPaymentData.id}
                dataType={dataType}
                handleValidCoupon={handleValidCoupon}
                handleCouponClear={handleCouponClear}
                handleInvalidCoupon={handleInvalidCoupon}
              />
              <hr className="section-divider" />
            </>
          )}

          <div className="payment-method-container">
            {/* If we have more than one available payment method */}
            {processors && processors.length > 1 && (
              <Text fontSize={"sm"} weight="700">
                {t("billingAndSubscription.plansPage.selectPaymentMethod")}
              </Text>
            )}

            {processors && (
              <PaymentMethods
                data={newPaymentData}
                processors={processors}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            )}
          </div>
        </div>
      </ActionDrawer>
    </>
  );
};

export default PaymentDrawer;
