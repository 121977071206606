import { Entity } from "@views/Automations/types";
import { SelectOption } from "types/common";

export const transformEntitiesToIds = (entities: Entity[] | number[] | null): number[] | null => {
  if (!entities) {
    return null;
  }

  // Check if the array is already an array of numbers
  if (typeof entities[0] === "number") {
    return entities as number[];
  }

  // Transform array of Entity to array of ids
  return (entities as Entity[]).map((entity) => entity.id);
};

export const entitiesToSelectOptions = (data: Entity[] | null | undefined = []): SelectOption[] => {
  return (Array.isArray(data) ? data : []).map(({ id, name }: Entity) => ({
    value: id?.toString(),
    label: name,
  }));
};
