import React, { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Text } from "@epignosis_llc/gnosis";
import { AxiosError } from "axios";

import { subscriptionDrawerStyles } from "./styles";

import CouponForm from "./CouponForm";
import { ActionDrawer } from "@components";

import { Price } from "types/entities/Common";
import { CatalogSubscription } from "types/entities/Catalog";

import queryKeys from "@constants/queryKeys";
import localStorageKeys from "@constants/localStorageKeys";

import { getSubscriptionInterval } from "../helpers";
import { useConfigurationStore } from "@stores";
import { postCatalogSubscribe } from "@api/catalog";
import { getCurrencyPrice } from "@utils/helpers";
import { handleSubscriptionErrors } from "@errors";
import { useApplyTranslations } from "@hooks";
import PaymentFooter from "./PaymentFooter";
import { URLS } from "@constants/urls";
import { getBaseUrl } from "@config";

export type SubscriptionDrawerProps = {
  isOpen: boolean;
  catalogCourseId?: string;
  onClose: () => void;
};

export type NewPriceData = {
  newPrice: Price;
  coupon: string;
};

const SubscriptionDrawer: FC<SubscriptionDrawerProps> = ({ isOpen, catalogCourseId, onClose }) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const { catalogSettings, domainSettings } = useConfigurationStore();
  const { coupons_enabled, subscription } = catalogSettings ?? {};
  const { trial_enabled, interval = 1, price = null } = subscription ?? {};
  const [subscriptionPrice, setSubscriptionPrice] = useState<Price | null>(price);
  const [discountCoupon, setDiscountCoupon] = useState("");
  const { locale } = domainSettings ?? {};
  const priceText = subscriptionPrice
    ? getCurrencyPrice(
        subscriptionPrice?.amount as number,
        subscriptionPrice?.currency as string,
        locale ?? "",
      )
    : "";

  const intervalText = (
    interval === 12 ? t("general.annual") : t("general.monthly")
  ).toLocaleLowerCase();

  const descriptionText = trial_enabled
    ? t("payments.subscriptions.subscriptionInfoTrial", { interval: intervalText })
    : t("payments.subscriptions.subscriptionInfo", { interval: intervalText });

  const { mutate: subscriptionMutation, isLoading: subscriptionMutationLoading } = useMutation(
    [queryKeys.courseSubscription],
    (dataToShow: CatalogSubscription) => postCatalogSubscribe(dataToShow),
    {
      onSuccess: (res) => {
        if (catalogCourseId) queryClient.invalidateQueries([queryKeys.course, catalogCourseId]);
        queryClient.invalidateQueries([queryKeys.catalog]);
        // Create a payment status local storage key in order to capture correctly the payment event from the return_url.
        localStorage.setItem(localStorageKeys.PAYMENT_STATUS, "pending");
        window.location.replace(res._data.redirect_url ?? "");
        onClose();
      },
      onError: (error: AxiosError) => {
        handleSubscriptionErrors(error);
      },
    },
  );

  const handleSubscription = (): void => {
    const pathURL = catalogCourseId
      ? `${getBaseUrl()}${URLS.catalog.createCourseLink({ courseId: catalogCourseId })}`
      : `${getBaseUrl()}${URLS.catalog.index}`;

    const newData = {
      processor: "stripe",
      // Add status and the current processor on the url to capture the payment event
      return_path: `${pathURL}?status=success&processor=stripe&type=subscription`,
      cancel_path: pathURL,
      ...(discountCoupon.length > 0 && { coupon: discountCoupon }),
    } as CatalogSubscription;

    subscriptionMutation(newData);
  };

  const handleValidCoupon = (newData: NewPriceData): void => {
    const { newPrice, coupon } = newData;
    setSubscriptionPrice(newPrice);

    coupon.length > 0 && setDiscountCoupon(coupon);
  };

  const handleModalClose = (): void => {
    setSubscriptionPrice(price); // Reset the data
    setDiscountCoupon("");
    onClose();
  };

  const handleCouponClear = (): void => {
    setSubscriptionPrice(price); // Reset the data
    setDiscountCoupon("");
  };

  const handleInvalidCoupon = (): void => {
    setSubscriptionPrice(price); // Reset the data
  };

  return (
    <ActionDrawer
      isOpen={isOpen}
      headerTitle={t("general.subscribe")}
      onClose={handleModalClose}
      size="sm"
      customFooter={
        <PaymentFooter
          onApply={handleSubscription}
          onClose={onClose}
          actionButtonInfo={{
            text: t("general.checkout"),
            isLoading: subscriptionMutationLoading,
            isDisabled: false,
          }}
          redirectInfoText={t("payments.redirectSafely", {
            name: "Stripe",
          })}
        />
      }
    >
      <div css={subscriptionDrawerStyles}>
        <Text fontSize="md" as="div" weight="700" className="title">
          {t("payments.subscriptions.unlimitedAccess")}
        </Text>

        <Text fontSize="sm" as="div" dangerouslySetInnerHTML={{ __html: descriptionText }} />

        <hr className="section-divider" />

        {coupons_enabled && (
          <>
            <CouponForm
              dataType="subscription"
              isSubscription
              handleValidCoupon={handleValidCoupon}
              handleCouponClear={handleCouponClear}
              handleInvalidCoupon={handleInvalidCoupon}
            />

            <hr className="section-divider" />
          </>
        )}

        <div className="price-container">
          <Text fontSize="sm" as="div" weight="700">
            {t("general.price")}
          </Text>

          {trial_enabled && (
            <>
              <Text fontSize="xl" className="price-info">
                {getCurrencyPrice(0, subscriptionPrice?.currency ?? "USD", locale ?? "")}/
              </Text>
              <Text fontSize="lg" className="price-info">
                {t("payments.subscriptions.oneMonthFreeTrial")}
              </Text>
            </>
          )}

          <div>
            <Text fontSize="xl" className="price-info">
              {priceText}
            </Text>
            <Text fontSize="lg" className="price-info">
              /{getSubscriptionInterval(interval)}{" "}
              {trial_enabled ? t("general.after").toLowerCase() : ""}
            </Text>
          </div>
        </div>
      </div>
    </ActionDrawer>
  );
};

export default SubscriptionDrawer;
