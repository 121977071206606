// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { FacebookSVG, TwitterSVG } from "@epignosis_llc/gnosis/icons";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";

// Styles
import { footerStyles } from "./styles";

// Components
import { Divider } from "@components/ReusableComponents";
import { ConfirmationModal } from "@components";

// Utils, hooks
import { generalNotification, getSocialMediaShareURL } from "@utils/helpers";
import { useConfigurationStore } from "@stores";
import permissions from "@utils/permissions";
import { handleUnEnrollmentErrors } from "@errors";
import { useApplyTranslations } from "@hooks";

// Other imports
import { Course } from "types/entities";
import { putUnenrollFromCourse } from "@api/courses";
import { URLS } from "@constants/urls";
import queryKeys from "@constants/queryKeys";
import userRoles from "@constants/userRoles";
import authService from "@utils/services/AuthService";
import { OtherProvidersCourse } from "types/entities/Courses";

type FooterProps = {
  course: Course | OtherProvidersCourse;
  isCatalogCourseView: boolean;
  isCourseStore?: boolean;
};

const Footer: FC<FooterProps> = ({ course, isCatalogCourseView, isCourseStore = false }) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { catalogSettings } = useConfigurationStore();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  // Permissions
  const userRole = authService.getDefaultRole();
  const isLearner = userRole === userRoles.LEARNER;

  const { canUnenrollFromCourse } = permissions.coursesPermissions;
  const allowUnenrollFromCourse = canUnenrollFromCourse();

  const { id, enrolled, name } = course;
  const courseId = id.toString();
  const isUserEnrolled = Boolean(enrolled);
  const showUnenrollBtn = isUserEnrolled && allowUnenrollFromCourse;

  const shouldShowSocials = isLearner ? isCatalogCourseView : true;
  const canShareOnSocialMedia =
    Boolean(catalogSettings?.social_catalog) && shouldShowSocials && !isCourseStore;

  const catalogUrl = URLS.courses.getCourseShareLink({ courseId });

  const { mutate: unenrollFromCourseMutation } = useMutation(
    [queryKeys.courseUnenroll],
    (id: string) => putUnenrollFromCourse(id),
    {
      onSuccess: () => {
        generalNotification("success", <p>{t("courseCatalog.youUnenrolledSuccessfully")}</p>);
        queryClient.invalidateQueries([queryKeys.course, courseId]);
        queryClient.invalidateQueries([queryKeys.catalog]);
        queryClient.invalidateQueries([queryKeys.myFiles]);
        !isCatalogCourseView && navigate(URLS.user.courses);
      },
      onError: (error: AxiosError) => {
        handleUnEnrollmentErrors(error);
      },
    },
  );

  if (!showUnenrollBtn && !canShareOnSocialMedia) return null;

  return (
    <>
      <div css={footerStyles} className="has-max-width">
        <Divider />
        <div className="footer-container">
          <div className="footer-container-start">
            {showUnenrollBtn && (
              <Button
                variant="link"
                noGutters
                onClick={(): void => setIsConfirmationModalOpen(true)}
              >
                {t("myCourses.unenrollFromCourse")}
              </Button>
            )}
          </div>
          <div className="footer-container-end">
            {canShareOnSocialMedia && (
              <>
                <Text fontSize="sm">{t("myCourses.share")}</Text>
                <Link
                  className="social-link"
                  to={getSocialMediaShareURL("facebook", catalogUrl, course)}
                  target="_blank"
                  aria-label={t("a11y.courses.facebookShare")}
                >
                  <FacebookSVG height={32} />
                </Link>
                <Link
                  className="social-link"
                  to={getSocialMediaShareURL("twitter", catalogUrl, course)}
                  target="_blank"
                  aria-label={t("a11y.courses.twitterShare")}
                >
                  <TwitterSVG height={32} />
                </Link>
              </>
            )}
          </div>
        </div>
      </div>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          id={courseId}
          header={t("myCourses.unenrollFromCourseQuestion")}
          bodyTitle={
            <div
              dangerouslySetInnerHTML={{
                __html: t("myCourses.unenrollFromCourseConfirmation", { name }),
              }}
            />
          }
          footerButton={t("general.unenroll")}
          isOpen={isConfirmationModalOpen}
          closeOnOutsideClick
          onClose={(): void => setIsConfirmationModalOpen(false)}
          onConfirm={(id): void => unenrollFromCourseMutation(id.toString())}
        />
      )}
    </>
  );
};

export default Footer;
