// Packages or third-party libraries
import React, { FC } from "react";
import {
  UnitOrderingSVG,
  CertificateSmSVG,
  UnitCompletionSVG,
  ScoreCalculatorSVG,
} from "@epignosis_llc/gnosis/icons";

// Components
import TabWrapper from "../../components/TabWrapper";
import TabFormField from "../../components/TabFormField";
import UnitsOrdering from "./components/UnitsOrdering";
import CertificateOption from "./components/CertificateOption";
import CompletionRules from "./components/CompletionRules";

// Utils, hooks
import { flatsUnitsReducer } from "@views/CourseEdit/reducers";
import { isSectionItem } from "@views/CourseEdit/helpers";
import { useApplyTranslations } from "@hooks";

// Other imports
import { RulesTabProps } from "../../types";
import { courseOptionsIds } from "../../constants";
import { MyUnit, Section } from "types/entities";
import ScoreRules from "./components/ScoreRules";

const CompletionTab: FC<RulesTabProps> = ({ course, form, rulesForm, sections = [] }) => {
  const { t } = useApplyTranslations();
  const { policies } = course;
  const { can_view_rules = false, can_update_rules = false } = policies ?? {};
  const canUpdateRules = can_view_rules && can_update_rules;

  const flatsUnits: Array<Section | MyUnit> = sections.reduce(flatsUnitsReducer, []);
  const units = flatsUnits.filter(
    (section) => !isSectionItem(section) && section.is_active,
  ) as MyUnit[];

  return (
    <TabWrapper>
      {canUpdateRules && (
        <TabFormField
          text={t("courseEdit.unitsOrdering")}
          icon={UnitOrderingSVG}
          note={t("courseEdit.options.unitsOrderingNote")}
        >
          <UnitsOrdering form={rulesForm} />
        </TabFormField>
      )}

      {canUpdateRules && (
        <TabFormField
          text={t("courseEdit.completionRules.title")}
          icon={UnitCompletionSVG}
          note={t("courseEdit.options.completionRulesNote")}
          id={courseOptionsIds.courseCompletionRules}
        >
          <CompletionRules form={rulesForm} units={units} />
        </TabFormField>
      )}

      {canUpdateRules && (
        <TabFormField
          text={t("courseEdit.scoreRules.title")}
          icon={ScoreCalculatorSVG}
          note={t("courseEdit.options.scoreRulesNote")}
        >
          <ScoreRules form={rulesForm} units={units} />
        </TabFormField>
      )}

      <TabFormField
        text={t("certificates.certificate")}
        icon={CertificateSmSVG}
        note={t("courseEdit.options.certificateNote")}
        colSpan={2}
        id={courseOptionsIds.certificateOptions}
        hasDivider={false}
      >
        <CertificateOption course={course} form={form} />
      </TabFormField>
    </TabWrapper>
  );
};

export default CompletionTab;
