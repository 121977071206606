// Utils, hooks
import { groupBy } from "@utils/helpers";

// Other imports
import { GroupedOption, SelectOption } from "types/common";
import { CoursePrerequisite } from "types/entities";

export const prerequisiteOptionsToSelectOptions = (
  prerequisites: CoursePrerequisite[],
): GroupedOption[] => {
  const grouped = groupBy(prerequisites, ({ category }) => category?.name ?? "general");

  const options = Object.keys(grouped).reduce((array, key) => {
    const selectOptions: SelectOption[] = grouped[key].map(({ id, name }) => ({
      value: id.toString(),
      label: name,
    }));

    array.push({ label: key, options: selectOptions });

    return array;
  }, [] as GroupedOption[]);

  return options;
};
