// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Result, Tabs } from "@epignosis_llc/gnosis";
import { useQuery } from "react-query";

// Styles
import { courseTabStyles, emptyResultsStyles, tabsSectionStyles } from "./styles";

// Components
import { BorderedSection, Skeletons, CourseToC } from "@components";
import { FileList } from "@components/ReusableComponents";
import CompletionText from "./CompletionText";

// Helpers, hooks
import { buildPaginatedSearchQuery, getFlatUnits, isCourseLevelRestricted } from "@utils/helpers";
import { useApplyTranslations, useAuth, useIsPublicCourse } from "@hooks";
import permissions from "@utils/permissions";
import { getMandatoryUnits } from "@components/CourseOverview/helpers";
import authService from "@utils/services/AuthService";

// Other imports
import { Course, Section, ValidFileSortingField } from "types/entities";
import { OtherProvidersCourse } from "types/entities/Courses";
import { TabObject } from "types/entities/Common";
import { CourseFilesRes, Pagination as IPagination } from "types/responses/index";
import { getCatalogCourseFiles } from "@api/catalog";
import { getCourseFiles } from "@api/courses";
import { getOtherProviderCourseFiles } from "@views/CourseStore/Course/api";
import userRoles from "@constants/userRoles";
import queryKeys from "@constants/queryKeys";
import { TabKey } from "@components/CourseOverview/constants";

type CourseTabsProps = {
  course: Course | OtherProvidersCourse;
  sections: Section[];
  isCatalogCourseView: boolean;
  isAdminOverview?: boolean;
  isTalentLibrary?: boolean;
  isOtherProviders?: boolean;
};

const PAGINATION: IPagination = { number: 1, size: 1000 };
const SORTING: ValidFileSortingField[] = ["uploaded_at"];

const CourseTabs: FC<CourseTabsProps> = ({
  course,
  sections,
  isCatalogCourseView,
  isAdminOverview = false,
  isTalentLibrary = false,
  isOtherProviders = false,
}) => {
  const { t } = useApplyTranslations();
  const { isAuthenticated } = useAuth();
  const isPublicCourse = useIsPublicCourse();

  const [activeTab, setActiveTab] = useState(0);

  const { id, role_in_course, availability, level, enrolled, rules, policies } = course;
  const { can_view_course_files: canViewCourseFiles = false } = policies ?? {};
  const { canAccessCatalog } = permissions.catalogPermissions;

  const { completion } = rules;
  const units = getFlatUnits(sections);
  const courseId = id.toString();
  const courseLevel = level ?? 0;
  const isUserEnrolled = Boolean(enrolled);
  const isLevelRestricted = isCourseLevelRestricted(courseLevel);
  const isCourseAvailable = availability ? availability?.is_available : !isLevelRestricted;
  const isUserInstructor = role_in_course === userRoles.INSTRUCTOR;
  const isReadonlyLearnerView =
    !isUserEnrolled || !isCourseAvailable || isUserInstructor || isPublicCourse;

  // Role or role in course?
  const isAdministrator = authService.getDefaultRole() === userRoles.ADMINISTRATOR;
  const isReadonly = isAdminOverview ? isAdministrator : isReadonlyLearnerView;
  const mandatoryUnits = getMandatoryUnits(completion);

  // Files
  const filesSearchQuery = buildPaginatedSearchQuery({
    pagination: {
      number: PAGINATION.number,
      size: PAGINATION.size,
    },
    sorting: SORTING,
  });

  const getFiles = (courseId: string, queryStr?: string): Promise<CourseFilesRes> => {
    if (isOtherProviders) return getOtherProviderCourseFiles(courseId);
    if (isCatalogCourseView) return getCatalogCourseFiles(courseId, queryStr);

    return getCourseFiles(courseId, queryStr);
  };

  const canAccessFiles = (): boolean => {
    // Other providers files endpoint requires the same permissions as it's Route, so we do not need to check permissions again
    if (isOtherProviders) return true;

    if (isCatalogCourseView) {
      // If the course is a catalog course, we need to check if the user has access to the catalog only when the user is authenticated
      return isAuthenticated ? canAccessCatalog() : true;
    }

    // Do not fetch files in Admin and Talent library overview page
    if (isAdminOverview || isTalentLibrary) return false;

    return canViewCourseFiles;
  };
  const allowAccessFiles = canAccessFiles();

  const {
    data: files = [],
    status: filesStatus,
    error: filesError,
  } = useQuery(
    [queryKeys.myFiles, id, filesSearchQuery],
    () => getFiles(courseId, filesSearchQuery),
    {
      enabled: allowAccessFiles,
      select: (res) => res._data,
    },
  );

  const getTabs = (): TabObject[] => {
    const tabs: TabObject[] = [
      {
        title: t("general.content"),
        key: TabKey.Units,
        content: (
          <div css={courseTabStyles}>
            {units.length === 0 ? (
              <Result title={t("myCourses.noUnitsAvailable")} css={emptyResultsStyles} />
            ) : (
              <CourseToC
                course={course}
                sections={sections}
                mandatoryUnits={mandatoryUnits}
                isReadonly={isReadonly}
                isLearnerView={!isAdminOverview}
              />
            )}
          </div>
        ),
      },
    ];

    if (allowAccessFiles && files.length > 0) {
      tabs.push({
        title: t("general.filesUpper"),
        key: TabKey.Files,
        content: (
          <Skeletons.Loader status={filesStatus} error={filesError}>
            <div css={courseTabStyles}>
              <FileList files={files} courseId={courseId} isReadonly={isReadonly} />
            </div>
          </Skeletons.Loader>
        ),
      });
    }

    return tabs;
  };

  const tabsToShow = getTabs();
  const activeTabKey = tabsToShow[activeTab].key;

  return (
    <div css={tabsSectionStyles} className="has-max-width">
      <BorderedSection className="tabs-section">
        {activeTabKey === TabKey.Units && <CompletionText completion={completion} />}
        <Tabs
          className="tabs"
          selectedTab={activeTab}
          tabs={tabsToShow}
          onChangeTab={setActiveTab}
        />
      </BorderedSection>
    </div>
  );
};

export default CourseTabs;
