import { css, keyframes } from "@emotion/react";

const rotationAnimation = () => keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const AISpinnerStyles = ({ colors }) => css`
  z-index: 10;
  animation: ${rotationAnimation()} 1s infinite linear;
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    visibility: visible;
    color: ${colors.primary.base};
  }
`;
