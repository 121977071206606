// Packages or third-party libraries
import React, { FC, useEffect, useState, MouseEvent } from "react";
import { DropdownItem, Tooltip } from "@epignosis_llc/gnosis";
import { AllUsersSVG, FolderSVG } from "@epignosis_llc/gnosis/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";

// Components
import { ToC } from "@components";
import { ActionSecondaryButton } from "@components/ReusableComponents";
import UnitCreateDropdown from "@views/CourseEdit/components/UnitCreateDropdown/UnitCreateDropdown";
import {
  CourseOptionsDrawer,
  courseOptionsIds,
  TabKey,
} from "@views/CourseEdit/components/CourseOptions";
import CourseOptionsDropdown from "./components/CourseOptionsDropdown";
import LockedIcon from "./components/LockedIcon";
import CloneModal from "@views/CourseEdit/components/CloneModal/CloneModal";
import LinkModal from "@views/CourseEdit/components/Link/LinkModal";

// Hooks, stores
import { useApplyTranslations } from "@hooks";
import { useUIStore } from "@stores";

// Other imports
import { getCourseCustomFields } from "@api/courses";
import { Course, Section } from "types/entities";
import { CourseActiveTab } from "@views/Course/types";
import { UnitEditRouterParams } from "@views/UnitEdit/types";
import { URLS } from "@constants/urls";
import queryKeys from "@constants/queryKeys";

type LocationState = {
  openCourseOptionsDrawer: boolean;
};

type ToCHeaderProps = {
  course: Course;
  sections: Section[];
};

const ToCHeader: FC<ToCHeaderProps> = ({ course, sections }) => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();
  const { showUnitSidebar, courseOptionsState, setCourseOptionsState } = useUIStore();
  const { state } = useLocation();
  const { unitId, courseId } = useParams() as UnitEditRouterParams;

  const [shouldTriggerValidation, setShouldTriggerValidation] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);

  const { policies, is_locked = false, name } = course;
  const {
    can_edit = false,
    can_view_rules = false,
    can_update_rules = false,
    can_view_users = false,
    can_view_course_files = false,
    can_add_unit = false,
  } = policies ?? {};

  const isCourseEditView = !unitId;
  const canViewOptions = can_edit || (can_view_rules && can_update_rules);
  const { isOpen: isCourseOptionsDrawerOpen } = courseOptionsState;
  const locationState = state as LocationState;

  const { data: customFields } = useQuery([queryKeys.courses.customFields], getCourseCustomFields, {
    select: (course) => course._data,
  });

  const handleBackBtnClick = (): void => {
    navigate(URLS.courses.courses);
  };

  const handleUsersBtnClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    navigate(URLS.courses.getSingleCourse({ courseId, activeTab: CourseActiveTab.Users }));
  };

  const handleFilesBtnClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    navigate(URLS.courses.getSingleCourse({ courseId, activeTab: CourseActiveTab.Files }));
  };

  const handleCourseOptionSelect = ({ value }: DropdownItem): void => {
    setCourseOptionsState({ isOpen: true, activeTab: value as TabKey });
  };

  const handleCloseOptionsDrawer = (): void => {
    setCourseOptionsState({ isOpen: false });
    setShouldTriggerValidation(false);
  };

  const handleLockedClick = (): void => {
    if (!is_locked) return;

    setCourseOptionsState({
      isOpen: true,
      activeTab: TabKey.Info,
      scrollId: courseOptionsIds.priceOptions,
    });
  };

  // When navigating state has the "openCourseOptionsDrawer" true, open the options drawer
  // To save mandatory course fields
  useEffect(() => {
    if (locationState?.openCourseOptionsDrawer) {
      window.history.replaceState({}, "");
      setCourseOptionsState({ isOpen: true });
      setShouldTriggerValidation(true);
    }
  }, [locationState, setCourseOptionsState]);

  return (
    <>
      <ToC.Header
        isOpen={showUnitSidebar}
        isActive={isCourseEditView}
        headerIcon={
          <div onClick={handleLockedClick}>
            <LockedIcon isLocked={is_locked} />
          </div>
        }
        title={name}
        titleLink={URLS.courses.getCourseEditLink({ courseId })}
        onBackClick={handleBackBtnClick}
      >
        <>
          {/* Dropdown with all the units that can be created in the course */}
          <div className="add-action">
            {can_add_unit && (
              <UnitCreateDropdown
                course={course}
                onCloneFromCourse={(): void => setIsCloneModalOpen(true)}
                onLinkFromCourse={(): void => setIsLinkModalOpen(true)}
              />
            )}
          </div>

          <div className="actions">
            {can_view_users && (
              <Tooltip content={t("general.users")}>
                <ActionSecondaryButton
                  data-testid="course-users-button"
                  aria-label={t("a11y.courseEdit.courseUsersButton")}
                  onClick={handleUsersBtnClick}
                >
                  <AllUsersSVG height={32} />
                </ActionSecondaryButton>
              </Tooltip>
            )}

            {can_view_course_files && (
              <Tooltip content={t("general.filesUpper")}>
                <ActionSecondaryButton
                  data-testid="course-files-button"
                  aria-label={t("a11y.courseEdit.courseFilesButton")}
                  onClick={handleFilesBtnClick}
                >
                  <FolderSVG height={32} />
                </ActionSecondaryButton>
              </Tooltip>
            )}

            {canViewOptions && (
              <CourseOptionsDropdown onListItemSelect={handleCourseOptionSelect} />
            )}
          </div>
        </>
      </ToC.Header>

      {isCourseOptionsDrawerOpen && (
        <CourseOptionsDrawer
          customFields={customFields}
          course={course}
          sections={sections}
          isOptionsDrawerOpen={isCourseOptionsDrawerOpen}
          shouldTriggerValidation={shouldTriggerValidation}
          onOptionsDrawerClose={handleCloseOptionsDrawer}
        />
      )}

      {isCloneModalOpen && (
        <CloneModal
          isOpen={isCloneModalOpen}
          targetCourseId={courseId}
          onClose={(): void => setIsCloneModalOpen(false)}
        />
      )}

      {isLinkModalOpen && (
        <LinkModal
          isOpen={isLinkModalOpen}
          targetCourseId={courseId}
          onClose={(): void => setIsLinkModalOpen(false)}
        />
      )}
    </>
  );
};

export default ToCHeader;
