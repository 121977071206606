import React from "react";
import { FieldErrors } from "react-hook-form";
import { EditCourseData } from "@views/CourseEdit/api";
import { CourseRulesFormData } from "@views/CourseEdit/types";
import { TabKey } from "./types";
import { TAB_FIELDS } from "./constants";
import { useApplyTranslations } from "@hooks";

type UseTabsErrorMessageProps = {
  errors: FieldErrors<EditCourseData>;
  rulesErrors: FieldErrors<CourseRulesFormData>;
};

type UseTabsErrorMessageReturn = {
  tabsWithErrors: TabKey[];
  errorMessage: JSX.Element;
};

export const useInvalidTabsErrorMessage = ({
  errors,
  rulesErrors,
}: UseTabsErrorMessageProps): UseTabsErrorMessageReturn => {
  const { t } = useApplyTranslations();

  const getTabsWithErrors = (
    errors: FieldErrors<EditCourseData>,
    rulesErrors: FieldErrors<CourseRulesFormData>,
  ): TabKey[] => {
    return (Object.keys(TAB_FIELDS) as TabKey[]).filter((tab) =>
      TAB_FIELDS[tab].some((field) => errors[field] || rulesErrors[field]),
    );
  };

  const formatTabName = (tab: TabKey): string => {
    switch (tab) {
      case TabKey.Info:
        return t("general.info");
      case TabKey.Availability:
        return t("general.availability");
      case TabKey.Limits:
        return t("general.limits");
      default:
        return t("general.completion");
    }
  };

  const tabsWithErrors = getTabsWithErrors(errors, rulesErrors);

  const getErrorMessage = (): string => {
    if (tabsWithErrors.length === 0) return "";

    // Map tabs to readable names
    const tabNames = tabsWithErrors.map((tab) => formatTabName(tab));

    if (tabsWithErrors.length === 1) {
      return t("courseEdit.requiredFields", { count: 1, tab: `<strong>${tabNames[0]}</strong>` });
    }

    if (tabNames.length === 2) {
      return t("courseEdit.requiredFields", {
        count: 2,
        tabs: `<strong>${tabNames[0]}</strong>`,
        lastTab: `<strong>${tabNames[1]}</strong>`,
      });
    }

    const lastTab = tabNames.pop();
    const mappedTabNames = tabNames.map((tabName) => `<strong>${tabName}</strong>`);
    return t("courseEdit.requiredFields", {
      count: 2,
      tabs: mappedTabNames.join(", "),
      lastTab: `<strong>${lastTab}</strong>`,
    });
  };

  return {
    tabsWithErrors,
    errorMessage: <div dangerouslySetInnerHTML={{ __html: getErrorMessage() }}></div>,
  };
};
