// Packages or third-party libraries
import React, { FC } from "react";
import { Tag } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";

// Styles
import { customFieldsStyles } from "./styles";

// Utils
import { formatUtcDate } from "@utils/helpers";
import { t } from "@utils/i18n";
import { useUIStore } from "@stores";

// Other imports
import { CustomFieldWithValue } from "types/entities";
import { courseOptionsIds, TabKey } from "@views/CourseEdit/components/CourseOptions";

type CustomFieldsProps = {
  customFields: CustomFieldWithValue[] | null;
  canEditOptions?: boolean;
  isMobileView?: boolean;
};

const getValue = (customField: CustomFieldWithValue): string | null => {
  const { type, value } = customField;
  if (!value) return null;
  if (type === "date") return formatUtcDate(value);
  if (type === "checkbox") return value === "on" ? t("general.yes") : t("general.no");

  return value;
};

const CustomFieldsClassNames = (isMobileView: boolean): string =>
  classNames({
    "is-mobile-view": isMobileView,
  });

const CustomFields: FC<CustomFieldsProps> = ({
  customFields,
  canEditOptions = false,
  isMobileView = false,
}) => {
  const { setCourseOptionsState } = useUIStore();

  const handleTagClicked = (): void => {
    if (!canEditOptions) return;

    setCourseOptionsState({
      isOpen: true,
      activeTab: TabKey.Info,
      scrollId: courseOptionsIds.customFieldsOptions,
    });
  };

  if (!customFields || customFields.length === 0) return null;

  return (
    <div
      css={(theme): SerializedStyles => customFieldsStyles(theme, { canEditOptions })}
      className={CustomFieldsClassNames(isMobileView)}
    >
      {customFields.map((customField) => {
        const { id, name } = customField;
        const shownValue = getValue(customField);

        if (!shownValue) return null;

        return (
          <Tag key={id} className="custom-field-tag" onClick={handleTagClicked}>
            {name}: {shownValue}
          </Tag>
        );
      })}
    </div>
  );
};

export default CustomFields;
