import React, { FC } from "react";
import { Tag, Text } from "@epignosis_llc/gnosis";

import { cardPriceContainer } from "./styles";

import { useConfigurationStore } from "@stores";
import { getCurrencyPrice } from "@utils/helpers";
import { Course } from "types/entities";
import classNames from "classnames";
import { getIsFloatCurrency } from "@utils/helpers/currency";

export type CardPriceProps = {
  price: Course["price"];
  discountedPrice: Course["discounted_price"];
  isSubscription?: boolean;
  extraText?: string;
};

const previousPriceClassNames = (isSubscription: boolean): string =>
  classNames({
    "previous-price": true,
    "show-underline": !isSubscription,
  });

const CardPrice: FC<CardPriceProps> = ({
  price,
  discountedPrice,
  isSubscription = false,
  extraText,
}) => {
  const { domainSettings } = useConfigurationStore();
  const { locale = null } = domainSettings ?? {};
  const hasDiscount = Boolean(discountedPrice);
  const fixedDecimals = getIsFloatCurrency() ? 2 : 0;

  return (
    <div css={cardPriceContainer}>
      {locale && (
        <>
          {hasDiscount && (
            <Tag className={previousPriceClassNames(isSubscription)}>
              <Text fontSize={"2xs"}>{price?.amount.toFixed(fixedDecimals)}</Text>
            </Tag>
          )}

          <Tag className="price">
            <Text fontSize={"sm"} weight="700">
              {getCurrencyPrice(
                hasDiscount ? (discountedPrice?.amount ?? 0) : (price?.amount ?? 0),
                hasDiscount ? (discountedPrice?.currency ?? "USD") : (price?.currency ?? "USD"),
                locale,
              )}
            </Text>

            {extraText && <Text fontSize={"sm"}>{extraText}</Text>}
          </Tag>
        </>
      )}
    </div>
  );
};

export default CardPrice;
