import { css } from "@emotion/react";

export const AIButtonContainerStyles = css`
  /* use hardcoded values to match craft unit styles */
  border-top: 1px solid #d9d9d9;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  height: 63px;
`;

export const AIButtonStyles = css`
  width: 2.5rem !important;
  height: 2.5rem !important;

  &:hover {
    cursor: pointer;
  }
`;
