import { getCalendarExport } from "@api/calendar";
import queryKeys from "@constants/queryKeys";
import { buildCalendarExportParamsQuery, downloadFile } from "@utils/helpers";
import { useMutation } from "react-query";
import { CalendarExportParams } from "types/entities";

export const useCalendarExportMutation = (): ((params: CalendarExportParams) => void) => {
  const getIcsDownloadName = ({ exportType, type, id }: CalendarExportParams): string => {
    const hostname = window.location.hostname;
    const singleEventName = type && id ? `${type}_event_${id}` : "event";
    const filename = exportType === "ical_all" ? "calendar" : singleEventName;
    return `${hostname}_${filename}.ics`;
  };

  const { mutate: getCalendarExportMutation } = useMutation(
    [queryKeys.calendar.export],
    (params: CalendarExportParams) => getCalendarExport(buildCalendarExportParamsQuery(params)),
    {
      onSuccess: (res, { exportType, type, id }) => {
        const exportData = res._data[exportType];

        if (exportData) {
          if (["ical_all", "ical"].some((type) => type === exportType)) {
            const downloadName = getIcsDownloadName({ exportType, type, id });
            const data = new File([exportData], downloadName, { type: "text/plain" });

            downloadFile({
              fileUrl: window.URL.createObjectURL(data),
              fileName: downloadName,
              isDirectDownload: true,
            });
          } else {
            window.open(exportData);
          }
        }
      },
    },
  );

  return getCalendarExportMutation;
};

export default useCalendarExportMutation;
