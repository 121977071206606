import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { container } from "./styles";
import { getCurrencyPrice } from "@utils/helpers";
import { useConfigurationStore } from "@stores";

export type PriceProps = React.HTMLAttributes<HTMLElement> & {
  value: number;
  prevValue?: number;
  hideCurrency?: boolean;
  customCurrency?: string;
};

const Price: FC<PriceProps> = ({ value, prevValue, hideCurrency, customCurrency, ...rest }) => {
  const { domainSettings } = useConfigurationStore();
  const { locale = null, currency = null } = domainSettings ?? {};

  return (
    <div css={container} {...rest}>
      {currency && locale && (
        <>
          <Text fontSize="sm" className="price" weight="700">
            {hideCurrency
              ? value
              : customCurrency
                ? `${value} ${customCurrency}`
                : getCurrencyPrice(value, currency, locale)}
          </Text>

          {prevValue && (
            <Text fontSize="sm" className="previous-price">
              {hideCurrency
                ? prevValue
                : customCurrency
                  ? `${prevValue} ${customCurrency}`
                  : getCurrencyPrice(prevValue, currency, locale)}
            </Text>
          )}
        </>
      )}
    </div>
  );
};

export default Price;
