import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["courseStore"] => {
  return {
    countColor: colors.secondary.base,
    courseCodeColor: colors.secondary.darker,
    separatorColor: colors.secondary.lighter,
  };
};
