import React, { FC } from "react";

import LeftActions from "../../components/LeftActions";
import CardPrice from "./CardPrice";

import { catalogCardFooterContainer } from "./styles";

import { Course } from "types/entities";
import { useConfigurationStore } from "@stores";
import { getSubscriptionInterval } from "@components/Course/helpers";

export type CatalogCourseCardFooterProps = {
  course: Course;
};

const CatalogCourseCardFooter: FC<CatalogCourseCardFooterProps> = ({ course }) => {
  const { catalogSettings, userProfileData } = useConfigurationStore();
  const { price, discounted_price } = course;
  const hasPrice = price && price.amount !== 0;
  const showSubscriptionOnCard =
    catalogSettings?.subscription.enabled &&
    userProfileData?.subscription?.status !== "active" &&
    Boolean(hasPrice);

  const cardPrice = showSubscriptionOnCard ? catalogSettings?.subscription.price : price;
  const discountedCardPrice = showSubscriptionOnCard ? null : discounted_price;
  const cardExtraText = showSubscriptionOnCard
    ? `/${getSubscriptionInterval(catalogSettings.subscription.interval, true)}`
    : undefined;

  return (
    <div css={catalogCardFooterContainer}>
      <LeftActions course={course} hideMaxItems={!hasPrice} />

      {(hasPrice || showSubscriptionOnCard) && (
        <CardPrice
          price={cardPrice}
          discountedPrice={discountedCardPrice}
          isSubscription={showSubscriptionOnCard}
          extraText={cardExtraText}
        />
      )}
    </div>
  );
};

export default CatalogCourseCardFooter;
