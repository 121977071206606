import { AIEditorOptions, ToolbarButton } from "@components/FormElements/Editor/types";

export const toolbarButtons = (aiEditorOptions?: AIEditorOptions): string[] | ToolbarButton => {
  return {
    moreText: {
      buttons: [
        "bold",
        "italic",
        "underline",
        "fontFamily",
        "fontSize",
        "textColor",
        "backgroundColor",
        "formatOL",
        "formatUL",
        "align",
      ],
      buttonsVisible: 12,
    },
    ...(!aiEditorOptions?.aiEnabled
      ? {
          horizontalDivider: {
            buttons: ["horizontalDivider"],
            buttonsVisible: 1,
          },
        }
      : {}),
    moreRich: {
      buttons: [
        "paragraphFormat",
        "lineHeight",
        "outdent",
        "indent",
        "quote",
        "insertTable",
        "insertImage",
        "insertLink",
        "insertHR",
        "insertSmartTagButton",
        "html",
      ],
      buttonsVisible: 0,
    },
    ...(aiEditorOptions?.aiEnabled
      ? {
          horizontalDivider: {
            buttons: ["horizontalDivider"],
            buttonsVisible: 1,
          },
        }
      : {}),
    ...(aiEditorOptions?.aiEnabled
      ? {
          aiFeatures: {
            buttons: ["aiFeatures"],
            buttonsVisible: 1,
          },
        }
      : {}),
  };
};

export const toolbarButtonsMessage = [
  "bold",
  "italic",
  "underline",
  "formatOL",
  "formatUL",
  "align",
  "indent",
  "outdent",
  "insertLink",
  "insertImage",
];

export const toolbarButtonsMinimal = (aiEditorOptions?: AIEditorOptions): string[] => [
  "bold",
  "italic",
  "underline",
  "textColor",
  ...(aiEditorOptions?.aiEnabled ? ["aiFeatures"] : []),
];

export const COURSE_FILES_DEFAULT_STATE = {
  pagination: { number: 1, size: 10000 },
  sorting: ["name"],
  filters: [{ key: "[use_case]", value: "course.image" }],
};

export const DEFAULT_SAVE_INTERVAL = 30000;
export const COURSE_IMAGE_UPLOAD = "course-image-upload";
export const COURSE_IMAGE_UPLOAD_CLOSE = "course-image-upload-close";
export const SMART_TAG_INSERT = "smart-tag-insert";
export const SMART_TAG_CLOSE = "smart-tag-close";
