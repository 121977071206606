import { css } from "@emotion/react";

export const tableActionsStyles = (theme, { isFilterOpen }) => css`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;

  .empty-state-container {
    button {
      padding: 0;
    }
  }

  .start-actions-container {
    display: flex;
    gap: 1rem;
    align-items: flex-end;

    .reset-table-filters-button {
      white-space: nowrap;
    }

    .action-item {
      background-color: ${theme.chip.success.background};
      font-weight: bold;
      margin-inline-end: 0.5rem;
      color: ${theme.chip.textColor};

      button {
        color: currentColor;
      }

      &:last-of-type {
        margin-inline-end: 0;
      }
    }

    .filters-button {
      background-color: ${isFilterOpen
        ? theme.button.primary.default.background
        : theme.button.primary.default.color};
      color: ${isFilterOpen
        ? theme.button.primary.default.color
        : theme.button.primary.default.background};
    }

    .filter-button {
      &:hover,
      &:active,
      &:focus {
        background-color: ${theme.chip.success.background} !important;
      }

      &.disabled {
        &:hover,
        &:focus,
        &:active {
          background-color: ${theme.chip.disabled.background} !important;
        }
      }
    }

    .filters {
      display: flex;
      margin-inline-start: 0.5rem;
      gap: 0.5rem;
    }
  }

  .end-actions-container {
    display: flex;
    gap: 0.5rem;

    .dropdown-btn-import-users {
      max-width: 44px;
      border-top-left-radius: initial;
      border-bottom-left-radius: initial;
      padding: 0 1rem;

      svg {
        fill: ${theme.dropdown.backgroundColor};
      }
    }

    .dropdown-list {
      padding: 0.75rem;

      .dropdown-list-item {
        border-radius: 5px;
        padding: 0;

        &:not(:last-of-type) {
          margin-bottom: 0.5rem;
        }
      }
    }

    .action-item {
      margin-inline-start: 0.5rem;

      &:last-of-type {
        margin-inline-end: 0;
      }
    }

    .right-actions-item {
      padding: 0 1rem !important;
    }
  }
`;
