import React from "react";
import { useApplyTranslations } from "@hooks";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  Path,
} from "react-hook-form";
import { Button, Input, InputError, ToggleSwitch } from "@epignosis_llc/gnosis";
import { CheckCircledSVG } from "@epignosis_llc/gnosis/icons";

export type LdapFormProps<T extends FieldValues> = {
  control: Control<T>;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  origin?: "branch" | "portal";
  isDisabled: boolean;
  isFormValid: boolean;
  setIsTestClicked: React.Dispatch<React.SetStateAction<boolean>>;
};

const LdapForm = <T extends FieldValues>({
  control,
  register,
  errors,
  origin = "portal",
  isDisabled,
  isFormValid,
  setIsTestClicked,
}: LdapFormProps<T>): JSX.Element => {
  const { t } = useApplyTranslations();

  const idsPrefix = origin === "branch" ? "sso.ldap" : "ldap";
  const errorPath = origin === "branch" ? errors?.sso?.["ldap"] : errors?.ldap;

  return (
    <div className="form-container">
      <div className="single-line-container">
        <div className="input-error-container">
          <Input
            {...register(`${idsPrefix}.server` as Path<T>)}
            id="ldap.server"
            placeholder={t("general.typeHere")}
            label={t("accountAndSettings.usersSettings.ldap.ldapServer")}
            status={errorPath?.server ? "error" : "valid"}
            data-lpignore="true"
            disabled={isDisabled}
          />
          {errorPath?.server && <InputError>{errorPath?.server?.message}</InputError>}
        </div>
        <div className="input-error-container right-container">
          <Input
            {...register(`${idsPrefix}.port` as Path<T>)}
            id="ldap.port"
            placeholder={t("general.typeHere")}
            label={t("accountAndSettings.usersSettings.ldap.serverPort")}
            status={errorPath?.port ? "error" : "valid"}
            data-lpignore="true"
            disabled={isDisabled}
          />
          {errorPath?.port && <InputError>{errorPath?.port?.message}</InputError>}
        </div>
      </div>

      <div className="form-item">
        <Controller
          name={`${idsPrefix}.tls` as Path<T>}
          control={control}
          render={({ field: { onChange, value } }): JSX.Element => (
            <div className="toggle-container">
              <ToggleSwitch
                labelAfter={t("accountAndSettings.usersSettings.ldap.sslEnabled")}
                defaultChecked={value}
                isDisabled={isDisabled}
                onChange={(): void => {
                  onChange(!value);
                }}
              />
            </div>
          )}
        />
      </div>

      <div className="single-line-container">
        <div className="input-error-container">
          <Input
            {...register(`${idsPrefix}.bind_dn` as Path<T>)}
            id="ldap.bind_dn"
            placeholder={t("general.typeHereOptional")}
            label={t("accountAndSettings.usersSettings.ldap.bindDN")}
            status={errorPath?.bind_dn ? "error" : "valid"}
            data-lpignore="true"
            disabled={isDisabled}
          />
          {errorPath?.bind_dn && <InputError>{errorPath?.bind_dn?.message}</InputError>}
        </div>
        <div className="input-error-container right-container">
          <Input
            {...register(`${idsPrefix}.bind_password` as Path<T>)}
            id="ldap.bind_password"
            type="password"
            placeholder={t("general.typeHereOptional")}
            label={t("accountAndSettings.usersSettings.ldap.bindPassword")}
            status={errorPath?.bind_password ? "error" : "valid"}
            data-lpignore="true"
            disabled={isDisabled}
          />
          {errorPath?.bind_password && <InputError>{errorPath?.bind_password?.message}</InputError>}
        </div>
      </div>

      <div className="form-item">
        <Input
          {...register(`${idsPrefix}.dn_pattern` as Path<T>)}
          id="ldap.dn_pattern"
          placeholder={t("general.typeHere")}
          label={t("accountAndSettings.usersSettings.ldap.DNPattern")}
          status={errorPath?.dn_pattern ? "error" : "valid"}
          data-lpignore="true"
          disabled={isDisabled}
        />
        {errorPath?.dn_pattern && <InputError>{errorPath?.dn_pattern.message}</InputError>}
      </div>

      <div className="form-item">
        <Input
          {...register(`${idsPrefix}.username` as Path<T>)}
          id="ldap.username"
          placeholder={t("general.typeHere")}
          label={t("general.username")}
          status={errorPath?.username ? "error" : "valid"}
          data-lpignore="true"
          disabled={isDisabled}
        />
        {errorPath?.username && <InputError>{errorPath?.username.message}</InputError>}
      </div>

      <div className="form-item">
        <Input
          {...register(`${idsPrefix}.full_name` as Path<T>)}
          id="ldap.full_name"
          placeholder={t("general.typeHere")}
          label={t("general.fullname")}
          status={errorPath?.full_name ? "error" : "valid"}
          data-lpignore="true"
          disabled={isDisabled}
        />
        {errorPath?.full_name && <InputError>{errorPath?.full_name.message}</InputError>}
      </div>

      <div className="form-item">
        <Input
          {...register(`${idsPrefix}.email` as Path<T>)}
          id="ldap.email"
          placeholder={t("general.typeHere")}
          label={t("general.email")}
          status={errorPath?.email ? "error" : "valid"}
          data-lpignore="true"
          disabled={isDisabled}
        />
        {errorPath?.email && <InputError>{errorPath?.email.message}</InputError>}
      </div>

      <Button
        variant="outline"
        className="check-configuration-button"
        iconBefore={CheckCircledSVG}
        onClick={(e: React.MouseEvent<HTMLElement>): void => {
          e.preventDefault();
          setIsTestClicked(true);
        }}
        disabled={!isFormValid}
      >
        {t("accountAndSettings.usersSettings.oidc.saveAndCheckConfiguration")}
      </Button>
    </div>
  );
};

export default LdapForm;
