// Packages or third-party libraries
import React, { FC } from "react";
import { Input, InputError } from "@epignosis_llc/gnosis";
import { LearningPathSVG, UserLevelSVG } from "@epignosis_llc/gnosis/icons";
import { Controller } from "react-hook-form";

// Components
import TabFormField from "../../components/TabFormField";
import TabWrapper from "../../components/TabWrapper";
import LearningPathRules from "./components/LearningPath/LearningPathRules";

// Utils, hooks
import { useApplyTranslations } from "@hooks";

// Other imports
import { RulesTabProps } from "../../types";
import { courseOptionsIds } from "../../constants";
import TimeOptions from "./components/TimeOptions";

const LimitsTab: FC<RulesTabProps> = ({ course, form, rulesForm }) => {
  const { t } = useApplyTranslations();
  const { policies } = course;
  const {
    can_update_level: canUpdateLevel = false,
    can_view_rules = false,
    can_update_rules = false,
  } = policies ?? {};
  const canUpdateRules = can_view_rules && can_update_rules;

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <TabWrapper>
      <TimeOptions form={form} course={course} hasDivider={canUpdateRules || canUpdateLevel} />

      {canUpdateRules && (
        <TabFormField
          text={t("general.prerequisites")}
          icon={LearningPathSVG}
          note={t("courseEdit.options.prerequisitesNote")}
          id={courseOptionsIds.learningPathRules}
          colSpan={2}
          hasDivider={canUpdateLevel}
        >
          <LearningPathRules form={rulesForm} course={course} />
        </TabFormField>
      )}

      {canUpdateLevel && (
        <TabFormField
          text={t("general.level")}
          icon={UserLevelSVG}
          note={t("courseEdit.options.levelNote")}
          id={courseOptionsIds.levelOptions}
          hasDivider={false}
        >
          <>
            <Controller
              name="level"
              control={control}
              render={({ field: { onChange, value } }): JSX.Element => (
                <Input
                  id="level"
                  aria-label={t("general.level")}
                  type="number"
                  value={value ?? undefined}
                  min={1}
                  max={20}
                  step={1}
                  placeholder={t("general.level")}
                  status={errors.level ? "error" : "valid"}
                  onChange={(e): void => {
                    const level = e.target.value;
                    onChange(level ? level : null);
                  }}
                />
              )}
            />
            {errors.level && <InputError>{errors.level.message}</InputError>}
          </>
        </TabFormField>
      )}
    </TabWrapper>
  );
};

export default LimitsTab;
