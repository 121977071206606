import { PalletThemeColors } from "@styles/utils";
import { ThemePlusTypes } from "../../types";

export default (colors: PalletThemeColors): ThemePlusTypes["textWithIcon"] => {
  return {
    headingBlack: colors.black,
    headingGrey: colors.secondary.darker,
    descriptionGreen: colors.green.base,
    descriptionBlack: colors.black,
  };
};
