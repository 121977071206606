import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { StartArrowFilledSVG, CanceledFilledSVG } from "@epignosis_llc/gnosis/icons";
import { Course } from "types/entities";
import { useApplyTranslations } from "@hooks";
import CardProgressBar from "../../components/CardProgressBar";
import { getFooterIconByCompletion } from "../../helpers";

export type MyCourseCardFooterProps = {
  course: Course;
  children?: never;
};

enum CompletionButtonText {
  completed = "general.progress.completed",
  failed = "general.progress.notPassed",
}

const FooterActions: FC<MyCourseCardFooterProps> = ({ course }) => {
  const { t } = useApplyTranslations();
  const { progress, availability } = course;
  const isCompleted =
    progress?.completion_status === "completed" || progress?.completion_status === "failed";
  const isCourseAvailable = Boolean(availability?.is_available);
  const completionIcon = getFooterIconByCompletion(progress?.completion_status ?? "completed");
  const isNotStarted = progress?.completion_status === "not_attempted";

  if (!isCourseAvailable) {
    if (availability?.reason === "cancelled")
      return (
        <div className="completion-info">
          <CanceledFilledSVG height={20} />
          <Text fontSize={"sm"}>{t("general.cancelled")}</Text>
        </div>
      );

    return null;
  }

  if (isNotStarted) {
    return (
      <div className="completion-info">
        <StartArrowFilledSVG height={20} />
        <Text fontSize={"sm"}>{t("general.start")}</Text>
      </div>
    );
  }

  return (
    <div className="footer-info">
      {isCompleted ? (
        <div className="completion-info">
          {completionIcon}
          <Text as="div" fontSize={"sm"}>
            {t(CompletionButtonText[progress.completion_status])}
          </Text>
        </div>
      ) : (
        <CardProgressBar percent={progress?.completion_percentage ?? 0} />
      )}
    </div>
  );
};

export default FooterActions;
