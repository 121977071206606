// Packages or third-party libraries
import React, { FC } from "react";
import { Grid, Input, InputError, Select } from "@epignosis_llc/gnosis";
import { PercentageSVG } from "@epignosis_llc/gnosis/icons";
import { Controller, UseFormReturn } from "react-hook-form";

// Utils, hooks
import { mapUnitsToSelectOption } from "@views/CourseEdit/helpers";
import { getCompletionRuleOptions, getPassedTestOptions } from "../helpers";
import { useApplyTranslations } from "@hooks";

// Other imports
import { SelectOption } from "types/common";
import { MyUnit } from "types/entities";
import { CompletionRule, CourseRulesFormData } from "@views/CourseEdit/types";

type CompletionRulesProps = {
  form: UseFormReturn<CourseRulesFormData>;
  units: MyUnit[];
};

const CompletionRules: FC<CompletionRulesProps> = ({ form, units }) => {
  const { t } = useApplyTranslations();
  const { control, watch, setValue } = form;
  const completionRuleWatch = watch("completion_rule");
  const isUnitPercentageRuleSelected = completionRuleWatch === CompletionRule.UnitPercentage;
  const isCertainUnitsRuleSelected = completionRuleWatch === CompletionRule.CertainUnits;
  const isPassedTestRuleSelected = completionRuleWatch === CompletionRule.PassedTest;

  const completionRuleOptions = getCompletionRuleOptions(units);
  const certainUnitsOptions = mapUnitsToSelectOption(units);
  const passedTestOptions = getPassedTestOptions(units);

  return (
    <Grid templateColumns={1} rowGap={1} columnGap={1}>
      <Grid.Item colSpan={1}>
        <Controller
          name="completion_rule"
          control={control}
          render={({ field: { onChange, value } }): JSX.Element => {
            const selectedValue = completionRuleOptions.find((option) => option.value === value);

            return (
              <Select
                id="completion-rule"
                value={selectedValue}
                options={completionRuleOptions}
                label={t("courseEdit.completionRules.completedWhen")}
                aria-label={t("courseEdit.completionRules.completedWhen")}
                onChange={(option): void => {
                  const { value } = option as SelectOption;
                  onChange(value);

                  // Reset fields when completion rule changes
                  setValue("completion_percentage", undefined);
                  setValue("completion_unit_ids", undefined);
                }}
              />
            );
          }}
        />
      </Grid.Item>

      {isUnitPercentageRuleSelected && (
        <Grid.Item colSpan={1}>
          <Controller
            name="completion_percentage"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => (
              <>
                <Input
                  id="completion-percentage"
                  label={t("general.percentage")}
                  type="number"
                  value={value ?? undefined}
                  min={0.1}
                  max={99.9}
                  step={0.1}
                  status={error ? "error" : "valid"}
                  iconAfter={PercentageSVG}
                  onChange={(e): void => {
                    const completionPercentage = e.target.value;
                    onChange(completionPercentage ? completionPercentage : null);
                  }}
                />
                {error && <InputError>{error.message}</InputError>}
              </>
            )}
          />
        </Grid.Item>
      )}

      {isCertainUnitsRuleSelected && (
        <Grid.Item colSpan={1}>
          <Controller
            name="completion_unit_ids"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => {
              const selectedValues = value
                ? certainUnitsOptions.filter((option) => value.includes(Number(option.value)))
                : [];

              return (
                <>
                  <Select
                    id="certain-units"
                    value={selectedValues}
                    options={certainUnitsOptions}
                    label={t("general.units")}
                    aria-label={t("general.units")}
                    placeholder={t("general.selectUnits", { count: 2 })}
                    isSearchable
                    isMulti
                    onChange={(options): void => {
                      const values = options as SelectOption[];
                      const value = values.map((option) => Number(option.value));
                      onChange(value);
                    }}
                  />
                  {error && <InputError>{error.message}</InputError>}
                </>
              );
            }}
          />
        </Grid.Item>
      )}

      {isPassedTestRuleSelected && (
        <Grid.Item colSpan={1}>
          <Controller
            name="completion_unit_ids"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }): JSX.Element => {
              const selectedValue = value && value.length > 0 ? value[0].toString() : undefined;
              const selectedOption = passedTestOptions.find(
                (option) => option.value === selectedValue,
              );

              return (
                <>
                  <Select
                    id="passed-test"
                    value={selectedOption}
                    options={passedTestOptions}
                    label={t("general.test")}
                    aria-label={t("general.test")}
                    placeholder={t("general.selectTests", { count: 1 })}
                    onChange={(option): void => {
                      const { value } = option as SelectOption;
                      onChange([Number(value)]);
                    }}
                  />
                  {error && <InputError>{error.message}</InputError>}
                </>
              );
            }}
          />
        </Grid.Item>
      )}
    </Grid>
  );
};

export default CompletionRules;
