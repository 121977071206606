import { css } from "@emotion/react";

export const AIActivityIndicatorStyles = ({ colors }) => css`
  transition: opacity 0.5s;
  transition-delay: 0.2s;
  height: 40px;
  width: fit-content;
  max-width: calc(100% - 32px);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 48px;
  color: ${colors.white};
  user-select: none;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.4);
  background-clip: padding-box;
  border: solid 2px ${colors.orange.base};
  z-index: 2;

  cursor: wait;
`;

export const AISpinnerContainerStyles = ({ colors }) => css`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${colors.secondary.lighter};
  border-radius: 50%;
  font-size: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const PromptContainerStyles = () => css`
  font-size: 14px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;
