// Packages or third-party libraries
import React, { FC, HTMLAttributes } from "react";
import { Drawer } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { m, useReducedMotion } from "framer-motion";
import { SerializedStyles } from "@emotion/react";

// Styles
import {
  mobileTocContainerStyles,
  tocContainerStyles,
  mobileTocDrawerBodyContainer,
} from "./styles";

// Components
import Trigger from "./Trigger";

// Utils, stores
import { i18n } from "@utils/i18n";
import { useUIStore } from "@stores";

// Constants
import { asideContainerVariants, asideContentCVariants } from "./constants";

const ToCContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
  const { lg } = useResponsive();
  const { showUnitSidebar, toggleUnitSidebar } = useUIStore();
  const isReducedMotion = useReducedMotion();
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "right" : "left";

  if (!lg) {
    return (
      <div css={mobileTocContainerStyles}>
        <Drawer isOpen={showUnitSidebar} placement={placement} onClose={toggleUnitSidebar}>
          <Drawer.Body>
            <div css={mobileTocDrawerBodyContainer} {...rest}>
              {children}
            </div>
          </Drawer.Body>
        </Drawer>
        {!showUnitSidebar && <Trigger isOpen={showUnitSidebar} toggleTocMenu={toggleUnitSidebar} />}
      </div>
    );
  }

  return (
    <>
      <m.aside
        css={(theme): SerializedStyles => tocContainerStyles(theme, { isOpen: showUnitSidebar })}
        initial="expanded"
        animate={showUnitSidebar ? "expanded" : "hidden"}
        variants={asideContainerVariants}
        custom={isReducedMotion}
      >
        <m.div variants={asideContentCVariants} custom={isReducedMotion} className="aside-content">
          {children}
        </m.div>
      </m.aside>
    </>
  );
};

export default ToCContainer;
