import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { paymentHeaderContainer } from "./styles";
import { images } from "@constants/assets";
import { Bundle, Course } from "types/entities";

export type PaymentHeaderProps = {
  data: Course | Bundle;
  categoryText?: string;
};

function isCourse(data: Course | Bundle): data is Course {
  return (data as Course).category !== undefined;
}

const PaymentHeader: FC<PaymentHeaderProps> = ({ data, categoryText }) => {
  const { cover_image, code } = data as Course;
  const { name } = data;

  const isCourseType = isCourse(data);

  return (
    <div css={paymentHeaderContainer}>
      {isCourseType && (
        <img
          src={cover_image?.default ?? images.cover}
          alt={`${name} cover image.`}
          className="course-img"
        />
      )}

      <div className="course-info">
        {code && (
          <Text fontSize="sm" weight="700" as="div" className="course-code">
            {code}
          </Text>
        )}

        <Text fontSize={isCourseType ? "sm" : "md"} as="div" weight="700" className="course-name">
          {name}
        </Text>

        {categoryText && (
          <Text fontSize="sm" as="div" className="course-category">
            {categoryText}
          </Text>
        )}
      </div>
    </div>
  );
};

export default PaymentHeader;
