import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";

import { creditsContainer } from "./styles";

import AvailableCredits from "./AvailableCredits";
import Price from "@components/Price/Price";

import { Bundle, Course } from "types/entities";
import { Processors } from "types/entities/Catalog";

import { useApplyTranslations } from "@hooks";

export type PaymentInfoProps = {
  data: Course | Bundle;
  processor: Processors;
  processors: Processors[];
  handleButtonClick: () => void;
};

const PaymentInfo: FC<PaymentInfoProps> = ({ data, processor, processors, handleButtonClick }) => {
  const { t } = useApplyTranslations();
  const { price, discounted_price } = data;
  const isCreditsProcessor = processor === "credits";
  const value = discounted_price ? discounted_price.amount : (price?.amount ?? 0);
  const prevValue = discounted_price ? (price?.amount ?? 0) : undefined;
  const customCurrency = isCreditsProcessor ? t("general.credits") : undefined;

  return (
    <div css={creditsContainer}>
      <div className="credits-info">
        {price && (
          <>
            <Text fontSize="sm" weight="700" as="div">
              {t("general.price")}
            </Text>

            <div className="price-wrapper">
              <Price
                value={value}
                prevValue={prevValue}
                customCurrency={customCurrency}
                className="price-value"
              />
            </div>
          </>
        )}

        {/* If the current processor is credits, then we show a text which contains information about
        the amount of users available credits */}
        {isCreditsProcessor && (
          <AvailableCredits
            data={data}
            processors={processors}
            handleButtonClick={handleButtonClick}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentInfo;
