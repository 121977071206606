import { AxiosError } from "axios";
import queryKeys from "@constants/queryKeys";
import { handleCourseErrors, handleGroupsErrors } from "@errors";
import { handleBranchesErrors, handleUserTypesErrors } from "@errors/errors";
import { GenericFilterRes, SelectOption } from "types/common";
import {
  getCoursesFilter,
  getGroupsFilter,
  getBranchesFilter,
  getUserTypesFilter,
} from "@views/Reports/CustomReports/api";
import {
  getEcommerceUsers,
  getEcommerceGroups,
  getEcommerceBranches,
} from "@views/AccountAndSettings/EcommerceSettings/api";

export const resolveDefaultValues = (labels: LabelMap[]): SelectOption[] => {
  const updatedLabels = labels.map((obj) => {
    return {
      value: obj.id.toString(),
      label: obj.name,
    };
  });

  return updatedLabels;
};

export const resolveDefaultValue = (entity?: LabelMap): SelectOption | undefined => {
  if (!entity || entity.id === undefined || entity.name === undefined) {
    return undefined;
  }

  return {
    value: entity.id.toString(),
    label: entity.name,
  };
};

type LabelMap = {
  name: string;
  id: number;
};

export const updateSearchQuery = (
  filterType: string,
  baseSearchQuery: string,
  searchValue = "",
  pageParam = 1,
): string => {
  const searchQueryWithPageNumber = baseSearchQuery.replace(
    /(page\[number\]=)\d+/,
    `$1${pageParam}`,
  );

  if (
    filterType === "ecommerce-user" ||
    filterType === "ecommerce-group" ||
    filterType === "ecommerce-branch"
  ) {
    return searchQueryWithPageNumber + `&filter[keyword][like]=${encodeURIComponent(searchValue)}`;
  }

  return searchQueryWithPageNumber + `&filter[name][like]=${encodeURIComponent(searchValue)}`;
};

export const fetchData = (
  filterType: string,
  updatedQueryString: string,
): Promise<GenericFilterRes> => {
  switch (filterType) {
    case "courses":
      return getCoursesFilter(updatedQueryString);

    case "groups":
      return getGroupsFilter(updatedQueryString);

    case "branches":
      return getBranchesFilter(updatedQueryString);

    case "userTypes":
      return getUserTypesFilter(updatedQueryString);

    case "ecommerce-user":
      return getEcommerceUsers(updatedQueryString);

    case "ecommerce-group":
      return getEcommerceGroups(updatedQueryString);

    case "ecommerce-branch":
      return getEcommerceBranches(updatedQueryString);

    default:
      return getCoursesFilter(updatedQueryString);
  }
};

export const getQueryKey = (filterType: string): string => {
  switch (filterType) {
    case "courses":
      return queryKeys.filters.courses;
    case "groups":
      return queryKeys.filters.groups;
    case "branches":
      return queryKeys.filters.branches;
    case "userTypes":
      return queryKeys.filters.userTypes;
    case "ecommerce-user":
      return queryKeys.filters.ecommerce.user;
    case "ecommerce-group":
      return queryKeys.filters.ecommerce.group;
    case "ecommerce-branch":
      return queryKeys.filters.ecommerce.branch;
    default:
      return "";
  }
};

export const handleError = (filterType: string, error: AxiosError): void => {
  switch (filterType) {
    case "courses":
      handleCourseErrors(error);
      break;
    case "groups":
      handleGroupsErrors(error);
      break;
    case "branches":
      handleBranchesErrors(error);
      break;
    case "userTypes":
      handleUserTypesErrors(error);
      break;
    default:
      handleCourseErrors(error);
  }
};
