// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import ReactPlayer from "react-player";
import { Button, Grid, Input, InputError } from "@epignosis_llc/gnosis";
import { TrashSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { introVideoOptionsStyles } from "./styles";

// Components
import { DragAndDropArea, FileFailed, FileInput, FileProcessing } from "@components";
import { ActionSecondaryButton } from "@components/ReusableComponents";
import Note from "../../../components/Note";

// Hooks, utils
import {
  getFileValidations,
  buildFileValidations,
  errorNotification,
  isValidYoutubeOrVimeoUrl,
} from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

// Other imports
import { EditCourseData } from "@views/CourseEdit/api";
import { Course } from "types/entities";
import { CourseIntroVideoStatus } from "types/entities/Courses";
import { VideoType } from "../../../types";
import { MAX_ALLOWED_FILES, REACT_PLAYER_CONFIG } from "../../../constants";

type IntroVideoOptionsProps = {
  videoPreviewFile: File | null;
  course: Course;
  form: UseFormReturn<EditCourseData>;
  handleFileChanged: (file: File | null) => void;
  setShouldDeleteVideo: (shouldDelete: boolean) => void;
};

const IntroVideoOptions: FC<IntroVideoOptionsProps> = ({
  course,
  form,
  handleFileChanged,
  videoPreviewFile,
  setShouldDeleteVideo,
}) => {
  const { t } = useApplyTranslations();
  const { intro_video } = course ?? {};
  const { url, type, status } = intro_video ?? {};
  const initialCustomUrl = type == "file" ? url : undefined;
  const initialYoutubeUrl = type == "youtube" ? url : undefined;

  const [videoType, setVideoType] = useState<VideoType | undefined>(
    (type as VideoType.Youtube) ?? VideoType.Youtube,
  );
  const [youtubeUrl, setYoutubeUrl] = useState<string | undefined>(initialYoutubeUrl);
  const [customUrl, setCustomUrl] = useState<string | undefined>(initialCustomUrl);
  const [droppedAttachments, setDroppedAttachments] = useState<FileList | null>(null);
  const selectedFiles = videoPreviewFile ? [videoPreviewFile] : [];

  const {
    setValue,
    setError,
    control,
    formState: { errors },
  } = form;
  const isCustomFileVideo = videoType == VideoType.File;
  const isYoutubeVideo = videoType == VideoType.Youtube;

  const validations = getFileValidations(["course.intro_video"]);
  const mimeTypeAndFilesizeValidations = { ...buildFileValidations(validations) };
  const showClearButton = Boolean(
    (youtubeUrl || customUrl || videoPreviewFile) && status !== CourseIntroVideoStatus.Failed,
  );
  const showVideoUploadArea = isCustomFileVideo && status !== CourseIntroVideoStatus.Processing;
  const showVideoStatus =
    !isYoutubeVideo &&
    (status === CourseIntroVideoStatus.Processing || status === CourseIntroVideoStatus.Failed);

  const handleClick = (type: VideoType): void => {
    setVideoType(type);
  };

  const handleFilesChanged = (files: File[]): void => {
    setCustomUrl(files.length ? undefined : initialCustomUrl);
    handleFileChanged(files[0]);
    setShouldDeleteVideo(false);
  };

  const handleFileError = (error: string): void => {
    errorNotification(`${error}`);
  };

  const shouldShowPreview = (): boolean => {
    if (isYoutubeVideo) {
      return Boolean(isValidYoutubeOrVimeoUrl(youtubeUrl ?? ""));
    }

    if (isCustomFileVideo) {
      return type === "file" && Boolean(customUrl) && status === CourseIntroVideoStatus.Ready;
    }

    return false;
  };

  const clearAll = (): void => {
    setVideoType(undefined);
    handleFileChanged(null);
    setYoutubeUrl(undefined);
    setCustomUrl(undefined);
    setValue("intro_video_url", null);
    setShouldDeleteVideo(true);
  };

  const handleFilesDrop = (files: FileList): void => {
    setDroppedAttachments(files);
  };

  return (
    <Grid templateColumns={[1, 1, 1, 2]} rowGap={1} columnGap={1} css={introVideoOptionsStyles}>
      {showClearButton && (
        <Button
          variant="ghost"
          aria-label="clear-intro-video"
          iconBefore={TrashSVG}
          className="clear-intro-video-button"
          onClick={clearAll}
        >
          {t("general.clear")}
        </Button>
      )}

      <Grid.Item colSpan={1}>
        <ActionSecondaryButton
          data-testid="intro-youtube-video"
          className="intro-video-type-button"
          isPressed={isYoutubeVideo}
          onClick={(): void => handleClick(VideoType.Youtube)}
        >
          {t("courseEdit.youtubeVideo")}
        </ActionSecondaryButton>
      </Grid.Item>
      <Grid.Item colSpan={1}>
        <ActionSecondaryButton
          data-testid="intro-custom-video"
          className="intro-video-type-button"
          isPressed={isCustomFileVideo}
          onClick={(): void => handleClick(VideoType.File)}
        >
          {t("courseEdit.customVideo")}
        </ActionSecondaryButton>
      </Grid.Item>

      {isYoutubeVideo && (
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <Note text={t("courseEdit.options.videoYoutubeNote")} className="video-note" />
          <Controller
            name="intro_video_url"
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => (
              <Input
                id="intro-video-url"
                type="text"
                label="URL"
                size="md"
                value={value ?? ""}
                placeholder={t("general.insertURL")}
                onChange={(e): void => {
                  const newValue = e.target.value;
                  onChange(newValue);
                  setYoutubeUrl(newValue);
                  setShouldDeleteVideo(false);
                }}
              />
            )}
          />
          {errors.intro_video_url && <InputError>{errors.intro_video_url.message}</InputError>}
        </Grid.Item>
      )}

      {showVideoUploadArea && (
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <Note text={t("courseEdit.options.videoCustomNote")} className="video-note" />
          <DragAndDropArea
            className="upload-prompt-area"
            maxFiles={MAX_ALLOWED_FILES}
            mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
            preventDrop={selectedFiles.length >= MAX_ALLOWED_FILES}
            onFilesDrop={handleFilesDrop}
          >
            <FileInput
              id="upload-course-file"
              name="files"
              accept="video/*"
              maxFiles={MAX_ALLOWED_FILES}
              mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
              selectedFiles={selectedFiles}
              addedFiles={droppedAttachments}
              onFileError={handleFileError}
              onFilesChange={handleFilesChanged}
            />
          </DragAndDropArea>
        </Grid.Item>
      )}

      {shouldShowPreview() && (
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <div className="intro-video-wrapper">
            <ReactPlayer
              id="intro-video"
              className="intro-video"
              url={isYoutubeVideo ? youtubeUrl : customUrl}
              config={REACT_PLAYER_CONFIG}
              width="100%"
              height="100%"
              onError={(): void => {
                if (isYoutubeVideo) {
                  setError("intro_video_url", {
                    type: "manual",
                    message: t("courseEdit.validationMessages.invalidIntroVideoLink"),
                  });
                }
              }}
              controls
            />
          </div>
        </Grid.Item>
      )}

      {showVideoStatus && (
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <div className="intro-video-wrapper">
            <div className="intro-video-status">
              {status === CourseIntroVideoStatus.Processing && (
                <FileProcessing info={t("errors.units.fileIsProcessingSubtitle")} />
              )}
              {status === CourseIntroVideoStatus.Failed && (
                <FileFailed info={t("errors.units.fileProcessingSubtitle")} />
              )}
            </div>
          </div>
        </Grid.Item>
      )}
    </Grid>
  );
};

export default IntroVideoOptions;
