import React, { FC, useEffect, useState } from "react";
import { useResponsive } from "ahooks";
import { TopBarMenuStyles } from "./TopBarMenu.styles";
import { useQuery } from "react-query";

// Components
import { ProfileMenu } from "./components/ProfileMenu";
import { MessagesMenu } from "./components/MessagesMenu";
import { BranchesMenu } from "./components/BranchesMenu";
import { DiscussionsMenu } from "./components/DiscussionsMenu";
import { ImpersonationBackButton } from "./components/ImpersonationBackButton";
import { StartReverseTrialButton } from "./components/StartReverseTrialButton";
import { StartReverseTrialModal } from "@components/ReverseTrial/StartReverseTrialModal";
import { DaysRemaining } from "@components/ReverseTrial/DaysRemaining/DaysRemaining";
import { UpgradeNowButton } from "./components/ProfileMenu/components/ReverseTrialUpgradeButtons/UpgradeNowButton";

// Stores
import { useUIStore, useConfigurationStore } from "@stores";

// Hooks, utils, etc.
import permissions from "@utils/permissions";
import authService from "@utils/services/AuthService";
import { getReverseTrialStats } from "@api/reverseTrial";
import { useCanSeeUpgradeButton } from "@hooks/useCanSeeUpgradeButton";

// Constants
import { staleTimeConfig } from "@config";
import queryKeys from "@constants/queryKeys";
import userRoles from "@constants/userRoles";
import { TrialEndedModal } from "@components/ReverseTrial/TrialEndedModal/TrialEndedModal";
import { shouldShowTrialEndingModal } from "@components/ReverseTrial/helpers";
import { TrialEndingModal } from "@components/ReverseTrial/TrialEndingModal/TrialEndingModal";

const TopBarMenu: FC = () => {
  // Responsive breakpoint. sm means until 576px.
  const { md, lg } = useResponsive();
  const { userProfileData, domainSettings } = useConfigurationStore();

  const [isTrialEndingModalOpen, setIsTrialEndingModalOpen] = useState(false);
  const [isTrialEndedModalOpen, setIsTrialEndedModalOpen] = useState(false);
  const [isTrialStartedModalOpen, setIsTrialStartedModalOpen] = useState(false);

  const toggleTrialEndingModal = (): void => setIsTrialEndingModalOpen((prev) => !prev);
  const toggleTrialEndedModal = (): void => setIsTrialEndedModalOpen((prev) => !prev);
  const toggleTrialStartedModal = (): void => setIsTrialStartedModalOpen((prev) => !prev);
  const { showReverseTrialModal, setShowReverseTrialModal } = useUIStore();

  const showImpersonationBackButton = userProfileData?.impersonated;
  const canEnableReverseTrial = userProfileData?.policies.can_enable_reverse_trial;
  const isOnFreePlan = domainSettings?.isFreePlan;
  const shouldShowEndTrialModal = userProfileData?.should_show_end_trial_modal && isOnFreePlan;
  const { canAccessDiscussions } = permissions.discussionsPermissions;
  const { canAccessMessages } = permissions.messagesPermissions;
  const showDiscussions = canAccessDiscussions() && md;
  const showMessages = canAccessMessages() && md;
  const showBranches = md;
  const isInTrial = domainSettings?.is_in_trial;
  const showTrialStartedModal = domainSettings?.show_trial_started_modal ?? false;
  const { canAccessSubscription } = permissions.accountPermissions;
  const userRole = authService.getDefaultRole();
  const isAdmin = userRole === userRoles.ADMINISTRATOR;

  const toggleReverseTrialModal = (): void => {
    setShowReverseTrialModal(!showReverseTrialModal);
  };

  const { data: reverseTrialStats } = useQuery(
    [queryKeys.reverseTrial.stats],
    () => getReverseTrialStats(),
    {
      enabled: isInTrial,
      select: (data) => data?._data,
      staleTime: staleTimeConfig[queryKeys.reverseTrial.stats],
    },
  );

  const { days_left: daysLeft = 0, total_days: totalDays = 0 } = reverseTrialStats || {};
  const canSeeTrialRemainingDays =
    isInTrial && reverseTrialStats && canAccessSubscription() && isAdmin;

  const canSeeUpgradeButton = useCanSeeUpgradeButton() && lg;

  useEffect(() => {
    if (daysLeft && shouldShowTrialEndingModal(daysLeft)) {
      setIsTrialEndingModalOpen(true);
    } else {
      setIsTrialEndingModalOpen(false);
    }
  }, [reverseTrialStats?.days_left, daysLeft]);

  useEffect(() => {
    if (shouldShowEndTrialModal) {
      setIsTrialEndedModalOpen(true);
    } else {
      setIsTrialEndedModalOpen(false);
    }
  }, [shouldShowEndTrialModal]);

  useEffect(() => {
    setIsTrialStartedModalOpen(showTrialStartedModal);
  }, [showTrialStartedModal]);

  return (
    <section data-testid="topbar-menu" css={TopBarMenuStyles}>
      {canEnableReverseTrial && lg && <StartReverseTrialButton onClick={toggleReverseTrialModal} />}
      {canSeeUpgradeButton && <UpgradeNowButton />}
      {canSeeTrialRemainingDays && <DaysRemaining remainingDays={daysLeft} maxDays={totalDays} />}
      {showMessages && <MessagesMenu />}
      {showDiscussions && <DiscussionsMenu />}
      {showBranches && <BranchesMenu />}
      {md && showImpersonationBackButton && <ImpersonationBackButton />}
      <ProfileMenu
        isImpersonated={showImpersonationBackButton}
        toggleTrialModal={toggleReverseTrialModal}
        reverseTrialStats={reverseTrialStats}
      />
      {showReverseTrialModal && (
        <StartReverseTrialModal
          isOpen={showReverseTrialModal}
          toggleModal={toggleReverseTrialModal}
        />
      )}
      {isTrialEndingModalOpen && (
        <TrialEndingModal isOpen={isTrialEndingModalOpen} toggleModal={toggleTrialEndingModal} />
      )}
      {isTrialEndedModalOpen && (
        <TrialEndedModal isOpen={isTrialEndedModalOpen} toggleModal={toggleTrialEndedModal} />
      )}
      {isTrialStartedModalOpen && (
        <StartReverseTrialModal
          isOpen={isTrialStartedModalOpen}
          toggleModal={toggleTrialStartedModal}
          automaticallyStarted={true}
        />
      )}
    </section>
  );
};

export default TopBarMenu;
