import { createNavigateOnClick } from "./utils";
import { URLS } from "@constants/urls";
import { MySkillsActiveTab, SkillsActiveTab } from "@views/Skills/types";

export interface BreadcrumbItem {
  label: string;
  href: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export interface BreadcrumbsPath {
  regex: RegExp;
  breadcrumbs: BreadcrumbItem[];
}

// Here are all the breadcrumbs paths for the different pages in the application
// The regex property is used to match the current path and the breadcrumbs property is an array of BreadcrumbItem objects
// Each BreadcrumbItem object has a label, href and onClick properties
const reportsBreadcrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  {
    regex: /^\/reports\/courses(\?tab=courseReports)?$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
    ],
  },
  {
    regex: /^\/reports\/courses\/.*$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
      {
        label: "mainMenu.courses",
        href: URLS.reports.courseReports,
        onClick: createNavigateOnClick(navigate, URLS.reports.courseReports),
      },
    ],
  },
  {
    regex: /^\/reports\/users(\?tab=userReports)?$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
    ],
  },
  {
    regex: /^\/reports\/users\/.*$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
      {
        label: "mainMenu.users",
        href: URLS.reports.userReports,
        onClick: createNavigateOnClick(navigate, URLS.reports.userReports),
      },
    ],
  },
  {
    regex: /^\/reports\/groups(\?tab=groupReports)?$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
    ],
  },
  {
    regex: /^\/reports\/groups\/.*$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
      {
        label: "mainMenu.groups",
        href: URLS.reports.groupReports,
        onClick: createNavigateOnClick(navigate, URLS.reports.groupReports),
      },
    ],
  },
  {
    regex: /^\/reports\/branches(\?tab=branchReports)?$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
    ],
  },
  {
    regex: /^\/reports\/branches\/.*$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
      {
        label: "mainMenu.branches",
        href: URLS.reports.branchReports,
        onClick: createNavigateOnClick(navigate, URLS.reports.branchReports),
      },
    ],
  },
  {
    regex: /^\/reports\/learning-activities(\?tab=learningActivities)?$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
    ],
  },
  {
    regex: /^\/reports\/learning-activities\/.*$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
      {
        label: "mainMenu.reportsSubmenu.learningActivities",
        href: URLS.reports.learningActivities,
        onClick: createNavigateOnClick(navigate, URLS.reports.learningActivities),
      },
    ],
  },
  {
    regex: /^\/reports\/custom(\?tab=customReports)?$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
    ],
  },
  {
    regex: /^\/reports\/custom\/.*$/,
    breadcrumbs: [
      {
        label: "mainMenu.reports",
        href: URLS.reports.reports,
        onClick: createNavigateOnClick(navigate, URLS.reports.reports),
      },
      {
        label: "reports.pageTitle.customReports",
        href: URLS.reports.customReports,
        onClick: createNavigateOnClick(navigate, URLS.reports.customReports),
      },
    ],
  },
];

const usersBreadcrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  {
    regex: /^\/users\/.*$/,
    breadcrumbs: [
      {
        label: "mainMenu.users",
        href: URLS.users.users,
        onClick: createNavigateOnClick(navigate, URLS.users.users),
      },
    ],
  },
];

const coursesBreadcrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  {
    regex: /^\/courses\/.*$/,
    breadcrumbs: [
      {
        label: "mainMenu.courses",
        href: URLS.courses.courses,
        onClick: createNavigateOnClick(navigate, URLS.courses.courses),
      },
    ],
  },
];

const branchesBreadcrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  {
    regex: /^\/branches\/.*$/,
    breadcrumbs: [
      {
        label: "mainMenu.branches",
        href: URLS.branches.branches,
        onClick: createNavigateOnClick(navigate, URLS.branches.branches),
      },
    ],
  },
];

const groupsBreadcrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  {
    regex: /^\/groups\/.*$/,
    breadcrumbs: [
      {
        label: "mainMenu.groups",
        href: URLS.groups.groups,
        onClick: createNavigateOnClick(navigate, URLS.groups.groups),
      },
    ],
  },
];

const subscriptionsBreadcrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  {
    regex: /^\/subscription\/options$/,
    breadcrumbs: [
      {
        label: "general.subscription",
        href: URLS.subscription.subscription,
        onClick: createNavigateOnClick(navigate, URLS.subscription.subscription),
      },
    ],
  },
];

const messagesBreadcrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  {
    regex: /^\/my\/messages\/new$/,
    breadcrumbs: [
      {
        label: "mainMenu.messages",
        href: URLS.user.messages,
        onClick: createNavigateOnClick(navigate, URLS.user.messages),
      },
    ],
  },
  {
    regex: /^\/my\/messages\/\d+\/sent$/,
    breadcrumbs: [
      {
        label: "mainMenu.messages",
        href: URLS.user.messages,
        onClick: createNavigateOnClick(navigate, URLS.user.messages),
      },
    ],
  },
  {
    regex: /^\/my\/messages\/\d+\/inbox$/,
    breadcrumbs: [
      {
        label: "mainMenu.messages",
        href: URLS.user.messages,
        onClick: createNavigateOnClick(navigate, URLS.user.messages),
      },
    ],
  },
];
const discussionsBreacrrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  {
    regex: /^\/my\/discussions\/new$/,
    breadcrumbs: [
      {
        label: "mainMenu.discussions",
        href: URLS.user.discussions,
        onClick: createNavigateOnClick(navigate, URLS.user.discussions),
      },
    ],
  },
  {
    regex: /^\/my\/discussions\/\d+$/,
    breadcrumbs: [
      {
        label: "mainMenu.discussions",
        href: URLS.user.discussions,
        onClick: createNavigateOnClick(navigate, URLS.user.discussions),
      },
    ],
  },
  {
    regex: /^\/my\/discussions\/edit\/\d+$/,
    breadcrumbs: [
      {
        label: "mainMenu.discussions",
        href: URLS.user.discussions,
        onClick: createNavigateOnClick(navigate, URLS.user.discussions),
      },
    ],
  },
];

const settingsBreadcrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  // {
  //   Regex: /^\/account\?tab=portalSettings$/,
  //   Breadcrumbs: [
  //     {
  //       Label: "general.accountAndSettings",
  //       Href: "",
  //     },
  //   ],
  // },

  {
    regex: /^\/account\/custom-domain$/,
    breadcrumbs: [
      {
        label: "general.accountAndSettings",
        href: "",
      },
      {
        label: "general.portal",
        href: "/account?tab=portalSettings",
        onClick: createNavigateOnClick(navigate, "/account?tab=portalSettings"),
      },
    ],
  },
  {
    regex: /^\/account\/customize-theming$/,
    breadcrumbs: [
      {
        label: "general.accountAndSettings",
        href: "",
      },
      {
        label: "general.portal",
        href: "/account?tab=portalSettings",
        onClick: createNavigateOnClick(navigate, "/account?tab=portalSettings"),
      },
    ],
  },
  {
    regex: /^\/account\/customize-badges$/,
    breadcrumbs: [
      {
        label: "general.accountAndSettings",
        href: "",
      },
      {
        label: "general.gamification",
        href: "/account?tab=gamificationSettings",
        onClick: createNavigateOnClick(navigate, "/account?tab=gamificationSettings"),
      },
    ],
  },
  {
    regex: /^\/account\/custom-user-fields$/,
    breadcrumbs: [
      {
        label: "general.accountAndSettings",
        href: "",
      },
      {
        label: "general.users",
        href: "/account?tab=usersSettings",
        onClick: createNavigateOnClick(navigate, "/account?tab=usersSettings"),
      },
    ],
  },
  {
    regex: /^\/account\/certificates$/,
    breadcrumbs: [
      {
        label: "general.accountAndSettings",
        href: "",
      },
      {
        label: "general.courses",
        href: "/account?tab=coursesSettings",
        onClick: createNavigateOnClick(navigate, "/account?tab=coursesSettings"),
      },
    ],
  },
  {
    regex: /^\/account\/custom-course-fields$/,
    breadcrumbs: [
      {
        label: "general.accountAndSettings",
        href: "",
      },
      {
        label: "general.courses",
        href: "/account?tab=coursesSettings",
        onClick: createNavigateOnClick(navigate, "/account?tab=coursesSettings"),
      },
    ],
  },
  {
    regex: /^\/account\/invoices$/,
    breadcrumbs: [
      {
        label: "general.accountAndSettings",
        href: "",
      },
      {
        label: "mainMenu.accountsAndSettings.ecommerce",
        href: "/account?tab=ecommerceSettings",
        onClick: createNavigateOnClick(navigate, "/account?tab=ecommerceSettings"),
      },
    ],
  },
  {
    regex: /^\/account\/coupons$/,
    breadcrumbs: [
      {
        label: "general.accountAndSettings",
        href: "",
      },
      {
        label: "mainMenu.accountsAndSettings.ecommerce",
        href: "/account?tab=ecommerceSettings",
        onClick: createNavigateOnClick(navigate, "/account?tab=ecommerceSettings"),
      },
    ],
  },

  // {
  //   Regex: /^\/account\?tab=usersSettings$/,
  //   Breadcrumbs: [
  //     {
  //       Label: "general.accountAndSettings",
  //       Href: "",
  //     },
  //   ],
  // },
  // {
  //   Regex: /^\/account\?tab=userTypeSettings$/,
  //   Breadcrumbs: [
  //     {
  //       Label: "general.accountAndSettings",
  //       Href: "",
  //     },
  //   ],
  // },
  // {
  //   Regex: /^\/account\?tab=coursesSettings$/,
  //   Breadcrumbs: [
  //     {
  //       Label: "general.accountAndSettings",
  //       Href: "",
  //     },
  //   ],
  // },
  // {
  //   Regex: /^\/account\?tab=categoriesSettings$/,
  //   Breadcrumbs: [
  //     {
  //       Label: "general.accountAndSettings",
  //       Href: "",
  //     },
  //   ],
  // },
  // {
  //   Regex: /^\/account\?tab=gamificationSettings$/,
  //   Breadcrumbs: [
  //     {
  //       Label: "general.accountAndSettings",
  //       Href: "",
  //     },
  //   ],
  // },
  // {
  //   Regex: /^\/account\?tab=ecommerceSettings$/,
  //   Breadcrumbs: [
  //     {
  //       Label: "general.accountAndSettings",
  //       Href: "",
  //     },
  //   ],
  // },
  // {
  //   Regex: /^\/account\?tab=integrationSettings$/,
  //   Breadcrumbs: [
  //     {
  //       Label: "general.accountAndSettings",
  //       Href: "",
  //     },
  //   ],
  // },
  // {
  //   Regex: /^\/account\?tab=securitySettings$/,
  //   Breadcrumbs: [
  //     {
  //       Label: "general.accountAndSettings",
  //       Href: "",
  //     },
  //   ],
  // },
  // {
  //   Regex: /^\/account\?tab=importExportSettings$/,
  //   Breadcrumbs: [
  //     {
  //       Label: "general.accountAndSettings",
  //       Href: "",
  //     },
  //   ],
  // },
];

const jobPathfinderBreadcrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  {
    regex: /^\/mySkills\/myJobRole\/.*/,
    breadcrumbs: [
      {
        label: "skills.mySkills.tabs.skillsFinder",
        href: `${URLS.skills.mySkills}?tab=${MySkillsActiveTab.RoleSkills}`,
        onClick: createNavigateOnClick(
          navigate,
          `${URLS.skills.mySkills}?tab=${MySkillsActiveTab.RoleSkills}`,
        ),
      },
    ],
  },
];

const talentPoolBreadcrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  {
    regex: /^\/skills\/jobRole\/.*/,
    breadcrumbs: [
      {
        label: "skills.tabs.talentPool",
        href: `${URLS.skills.skills}?tab=${SkillsActiveTab.TalentPool}`,
        onClick: createNavigateOnClick(
          navigate,
          `${URLS.skills.skills}?tab=${SkillsActiveTab.TalentPool}`,
        ),
      },
    ],
  },
];

export const allBreadcrumbsPaths = (navigate: (path: string) => void): BreadcrumbsPath[] => [
  ...reportsBreadcrumbsPaths(navigate),
  ...usersBreadcrumbsPaths(navigate),
  ...coursesBreadcrumbsPaths(navigate),
  ...branchesBreadcrumbsPaths(navigate),
  ...subscriptionsBreadcrumbsPaths(navigate),
  ...messagesBreadcrumbsPaths(navigate),
  ...discussionsBreacrrumbsPaths(navigate),
  ...groupsBreadcrumbsPaths(navigate),
  ...settingsBreadcrumbsPaths(navigate),
  ...jobPathfinderBreadcrumbsPaths(navigate),
  ...talentPoolBreadcrumbsPaths(navigate),
];
