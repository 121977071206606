// Packages or third-party libraries
import React, { FC } from "react";
import { Button, Grid, Tag, Text } from "@epignosis_llc/gnosis";
import { CloseSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { UnitWeightsStyles } from "./styles";

// Components
import { ActionGhostButton, Divider } from "@components/ReusableComponents";
import { RangeInput } from "@components";

// Hooks
import { useApplyTranslations } from "@hooks";

// Other imports
import { SelectOption } from "types/common";
import { ScoreUnit } from "@views/CourseEdit/types";

type UnitWeightsProps = {
  scoreUnits: ScoreUnit[];
  specificUnitsOptions: SelectOption[];
  onChange: (scoreUnits: ScoreUnit[]) => void;
  onClose: () => void;
};

const calculateOverallWeight = (scoreUnits: ScoreUnit[]): number => {
  return scoreUnits.reduce((overall, { weight }) => overall + weight, 0);
};

const UnitWeights: FC<UnitWeightsProps> = ({
  scoreUnits,
  specificUnitsOptions,
  onChange,
  onClose,
}) => {
  const { t } = useApplyTranslations();
  const overallWeight = calculateOverallWeight(scoreUnits);

  const handleClearAll = (): void => {
    const newScoreUnits = [...scoreUnits].map((scoreUnit) => ({ ...scoreUnit, weight: 1 }));
    onChange(newScoreUnits);
  };

  const handleClose = (): void => {
    onClose();
  };

  const handleWeightChange = (value: number, index: number): void => {
    const newScoreUnits = [...scoreUnits];
    newScoreUnits[index].weight = value;
    onChange(newScoreUnits);
  };

  return (
    <Grid templateColumns={1} rowGap={0.75} columnGap={0.75} css={UnitWeightsStyles}>
      <Grid.Item colSpan={1} className="btns-container">
        <Button variant="ghost" noGutters onClick={handleClearAll}>
          {t("general.resetToDefault")}
        </Button>

        <ActionGhostButton aria-label="close unit weights" onClick={handleClose}>
          <CloseSVG height={16} />
        </ActionGhostButton>
      </Grid.Item>

      <Grid.Item colSpan={1}>
        {scoreUnits.map(({ id, weight }, index) => {
          const unitOption = specificUnitsOptions.find(({ value }) => id.toString() === value);
          const weightPercentage = ((weight / overallWeight) * 100).toFixed(2);
          const isLastUnit = index === scoreUnits.length - 1;

          return (
            <div key={id} className="score-unit-container">
              <RangeInput
                id={`range-input-${id}`}
                name={`range-input-${id}`}
                min={1}
                max={20}
                value={weight}
                label={unitOption?.label ?? ""}
                onChange={(value): void => handleWeightChange(value as number, index)}
              >
                <Tag className="weight-percentage">{weightPercentage} %</Tag>
              </RangeInput>

              {!isLastUnit && <Divider />}
            </div>
          );
        })}
      </Grid.Item>

      <Grid.Item colSpan={1}>
        <Text fontSize="sm" weight="700" className="overall">
          {t("general.overall")}: {overallWeight}
        </Text>
      </Grid.Item>
    </Grid>
  );
};

export default UnitWeights;
