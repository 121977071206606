// Packages or third-party libraries
import React, { FC, useEffect, useState } from "react";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { InfoButtonSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import { creditBreakdownTableStyles } from "./styles";

// Components
import { CustomTable } from "@components";

// Utils
import { TableStatus } from "@components/CustomTable/types";
import { AICatalogItem } from "types/entities/AICredits";
import { useApplyTranslations } from "@hooks";

// Constants
import { getAICreditBreakdownColumns, SORTING } from "./constants";

type CreditBreakdownTableProps = {
  tableStatus: TableStatus;
  creditsBreakdown?: AICatalogItem[];
};

const ITEMS_WITH_TOOLTIP = [
  "importing_from_document_high",
  "importing_from_document_medium",
  "importing_from_document_low",
  "generating_ai",
];

const CreditBreakdownTable: FC<CreditBreakdownTableProps> = ({ tableStatus, creditsBreakdown }) => {
  const { t } = useApplyTranslations();

  const [sortedCredits, setSortedCredits] = useState(creditsBreakdown);
  const [isOrderDes, setIsOrderDes] = useState(SORTING?.isDescending);

  useEffect(() => {
    if (creditsBreakdown) {
      setSortedCredits(creditsBreakdown);
    }
  }, [creditsBreakdown]);

  const handleSortingChanged = (): void => {
    const newCredits = creditsBreakdown?.slice().sort((a, b) => {
      if (isOrderDes) {
        return a.credits - b.credits;
      } else {
        return b.credits - a.credits;
      }
    });
    setIsOrderDes((v) => !v);
    setSortedCredits(newCredits);
  };

  const rows =
    sortedCredits?.map((item) => {
      const { credits_display, message, key } = item;
      const tooltipContent = ITEMS_WITH_TOOLTIP.includes(key)
        ? t("AICredits.actionCostTooltip", { range: credits_display })
        : null;

      return {
        action: (): JSX.Element => (
          <Text fontSize="sm" as="div" className="credits-message has-overflow">
            {message}
            {tooltipContent && (
              <Tooltip
                as="span"
                content={tooltipContent}
                parentProps={{ "aria-label": tooltipContent }}
              >
                <InfoButtonSVG className="tooltip-icon" height={16} />
              </Tooltip>
            )}
          </Text>
        ),
        credits: (): JSX.Element => (
          <Text fontSize="sm" as="div" className="has-overflow">
            {credits_display}
          </Text>
        ),
        id: message,
      };
    }) ?? [];

  const tableProps = {
    rows,
    columns: getAICreditBreakdownColumns(),
    emptyState: { info: "", title: "" },
    sorting: SORTING,
    onSortingChanged: handleSortingChanged,
  };

  return (
    <div css={creditBreakdownTableStyles}>
      <CustomTable tableProps={tableProps} tableStatus={tableStatus} selectable />
    </div>
  );
};

export default CreditBreakdownTable;
