// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { CirclePlaySolidSVG } from "@epignosis_llc/gnosis/icons";

// Components
import IntroVideoModal from "./IntroVideoModal";

// Hooks, stores
import { useApplyTranslations } from "@hooks";
import { useUnitStore } from "@stores";

// Other imports
import { Course } from "types/entities";

type IntoVideoProps = {
  introVideo: Course["intro_video"];
  name: Course["name"];
  canEditOptions?: boolean;
  isMobileView?: boolean;
};

const IntoVideo: FC<IntoVideoProps> = ({
  introVideo,
  name,
  canEditOptions = false,
  isMobileView = false,
}) => {
  const { t } = useApplyTranslations();
  const { isCourseHeaderSticky } = useUnitStore();
  const showIntroVideoBtn = introVideo && !isCourseHeaderSticky;

  const [showIntroVideoModal, setShowIntroVideoModal] = useState(false);

  const openIntroVideoModal = (): void => setShowIntroVideoModal(true);
  const closeIntroVideoModal = (): void => setShowIntroVideoModal(false);

  const buttonColor = isMobileView ? "primary" : "primaryLight";
  const buttonVariant = isMobileView ? "outline" : "solid";

  return (
    <>
      {showIntroVideoBtn && (
        <Button
          color={buttonColor}
          variant={buttonVariant}
          iconBefore={CirclePlaySolidSVG}
          disabled={showIntroVideoModal}
          onClick={openIntroVideoModal}
          block
        >
          {t("general.introVideo")}
        </Button>
      )}

      {introVideo && showIntroVideoModal && (
        <IntroVideoModal
          isOpen={showIntroVideoModal}
          header={name}
          url={introVideo.url}
          onClose={closeIntroVideoModal}
          canEditOptions={canEditOptions}
        />
      )}
    </>
  );
};

export default IntoVideo;
