// Packages or third-party libraries
import React, { FC, useRef, useState } from "react";
import { DropdownItem } from "@epignosis_llc/gnosis";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import classNames from "classnames";
import { useToggle } from "ahooks";

// Styles
import { unitListItemStyles } from "./styles";

// Components
import { ToC } from "@components";
import UnitDeleteModal from "../../UnitDeleteModal";
import UnitRenameModal from "../../UnitRenameModal";
import LinkDrawer from "../../../../Link/LinkDrawer";
import UnitListIcon from "./UnitListIcon";
import UnitListActions from "./UnitListActions";
import UnitListTitle from "./UnitListTitle";
import UnitListTitleActions from "./UnitListTitleActions";

// Utils, stores, hooks
import { generalNotification } from "@utils/helpers";
import { isSectionItem, parentHasClassname } from "../../../../../helpers";
import { getSectionDropdownOptions, getUnitDropdownOptions, getUnitIcon } from "../../../helpers";
import { handleNoPermissionError, handleUnitErrors } from "@errors";
import { handleTestUnitExportError } from "@errors/unitErrors";
import { useUnitStore } from "@stores";
import { useApplyTranslations, useClickOutside } from "@hooks";
import { useUpdateUnit } from "@views/UnitEdit";
import { usePublishUnitMutation } from "@views/UnitEdit/hooks";
import { useCloneCourseUnit } from "@views/CourseEdit/hooks";

// Other imports
import { DeleteParams, UnitListItemProps } from "../../../../../types";
import { UnitEditRouterParams } from "@views/UnitEdit/types";
import { UnitActions } from "../../../types";
import { deleteUnit, exportUnit } from "../../../../../api";
import { ReorderActions } from "../../../../../constants";
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";
import SectionDelayModal from "../../SectionDelayModal";

const listItemClasses = ({
  isSelected,
  isHovered,
  isReordered,
}: {
  isSelected: boolean;
  isHovered: boolean;
  isReordered: boolean;
}): string =>
  classNames({
    "unit-list-item": true, // Used to find active unit in scrollToActiveItem function
    "is-active": isSelected,
    "is-hovered": isHovered,
    "is-reordered": isReordered,
  });

const listItemWrapperClasses = (isReorderingSectionItem: boolean): string =>
  classNames("list-item-text-wrapper", {
    "reordering-section-item": isReorderingSectionItem,
  });

export type RenameState = {
  isOpen: boolean;
  type: UnitActions.SectionRename | UnitActions.UnitRename | null;
};

const UnitListItem: FC<UnitListItemProps> = ({
  course,
  unit,
  canBeReordered,
  isReordered,
  sectionReorderState,
  reorderDispatch,
  provided,
  isDragging,
}) => {
  const { t } = useApplyTranslations();
  // UnitIdParam will have value only in unit edit view
  const { unitId: unitIdParam } = useParams() as UnitEditRouterParams;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setCanAutoSaveUnit, canPublishUnit } = useUnitStore();
  const { availability, name, id, policies, pending_submissions } = unit;

  const unitId = id.toString();
  const { can_view = false } = policies ?? {};

  const { selectedSection, selectedUnits } = sectionReorderState;

  const [isHovered, setIsHovered] = useState(false);
  const [isUnitDeleteModalOpen, setIsUnitDeleteModalOpen] = useState(false);
  const [isSetDelayModalOpen, setSetDelayModalOpen] = useState(false);
  const [isLinkedUnitsDrawerOpen, { toggle: toggleLinkedUnitsDrawer }] = useToggle(false);
  const [renameModalState, setRenameModalState] = useState<RenameState>({
    isOpen: false,
    type: null,
  });
  const reorderingSectionItemRef = useRef<HTMLDivElement>(null);

  // Variables related with the unit
  const isSection = isSectionItem(unit);
  const linkedUnits = isSection ? 0 : unit.linked_units;
  const sourceUnit = isSection ? undefined : unit.source_unit;
  const unitIsActive = isSection ? true : unit.is_active;
  const hasUnpublishedChanges = Boolean(isSection ? false : unit.has_unpublished_changes);
  const unitType = isSection ? null : unit.type;
  const courseId = course.id.toString();
  const courseIsActive = course.status === "active";
  const unitEditLink = !isSection ? URLS.units.getUnitEditLink({ courseId, unitId }) : "#";
  const isSelected = unitId === unitIdParam;
  const isDisabled = !isSection && !can_view;
  const courseIsLocked = Boolean(course.is_locked);
  const roleInCourse = course?.role_in_course;
  const pendingUsers = !isSection ? unit.pending_users : undefined;

  // Can reorder units and the unit is either being reordered or
  // Is being hovered and full section reorder is not active
  const showReorderIcon = canBeReordered && (isReordered || (isHovered && !selectedSection));
  const unitIcon = getUnitIcon({ showReorderIcon, unitType });

  // Is unit the selected section for reordering
  const isReorderingSection = Boolean(isSection && selectedSection?.id === id);

  // Ids of unit belong to the reordering section
  const isSelectedSectionUnit = Boolean(
    selectedSection?.units?.map((unit) => unit.id).includes(id),
  );

  // Reordering section or unit
  const isReorderingSectionItem = isReorderingSection || isSelectedSectionUnit;

  // Unit reordering along with the section
  const isSectionReorderUnit = selectedUnits.includes(id);

  // Unit actions dropdown
  const dropdownOptions = isSection
    ? getSectionDropdownOptions({
        coursePolicies: course.policies,
        sectionPolicies: unit.policies,
        isReorderingSection,
      })
    : getUnitDropdownOptions({
        unitPolicies: unit.policies,
        courseIsActive,
        unitIsActive,
        unitType,
        hasUnpublishedChanges,
        canPublishUnit,
        isSelected,
        courseIsLocked,
        roleInCourse,
      });

  const dropdownDisabled = !isSection && isSelectedSectionUnit;

  const handleDropdownItemClick = (item: DropdownItem): void => {
    const value = item.value as UnitActions;

    switch (value) {
      case UnitActions.SectionReorder: {
        if (isSection) {
          reorderDispatch({
            type: ReorderActions.setSection,
            payload: {
              selectedSection: unit,
              selectedUnits: unit.units?.map((unit) => unit.id) ?? [],
            },
          });
        }
        break;
      }
      case UnitActions.UnitUnPublish: {
        if (!isSection && unitIsActive) {
          deactivationMutation({ is_active: false });
        }
        break;
      }
      case UnitActions.Delete: {
        setIsUnitDeleteModalOpen(true);
        break;
      }
      case UnitActions.UnitClone: {
        cloneUnitMutation({ unitId });
        break;
      }
      case UnitActions.UnitOptions: {
        // Navigate to unit edit page and open options drawer
        navigate(URLS.units.getUnitEditLink({ courseId, unitId }), {
          state: { showOptions: true },
        });
        break;
      }
      case UnitActions.UnitPublish: {
        publishUnitMutation(unitId);
        break;
      }
      case UnitActions.UnitPrint: {
        handleUnitExport(unitId);
        break;
      }
      case UnitActions.UnitVersionHistory: {
        navigate(URLS.units.getUnitEditLink({ courseId, unitId }), {
          state: { showVersionHistory: true },
        });
        break;
      }
      case UnitActions.Delay:
        {
          setSetDelayModalOpen(true);
        }
        break;
      default: {
        if ([UnitActions.SectionRename, UnitActions.UnitRename].includes(value)) {
          setRenameModalState({ isOpen: true, type: value as RenameState["type"] });
        }
        break;
      }
    }
  };

  // Handle close full section reordering
  const handleClose = (): void => {
    reorderDispatch({
      type: ReorderActions.unsetSection,
      payload: {
        selectedSection: null,
        selectedUnits: [],
      },
    });
  };

  // Handle click on section reorder unit checkbox
  const handleSelectedUnitClick = (): void => {
    if (selectedSection) {
      // Toggle unit from the selected units for full section reordering
      reorderDispatch({
        type: isSectionReorderUnit ? ReorderActions.removeUnit : ReorderActions.addUnit,
        payload: { unitId: id },
      });
    }
  };

  useClickOutside(reorderingSectionItemRef, (event: MouseEvent) => {
    const targetElement = event.target as Element;
    const className = "reordering-section-item";

    // Check if click was made in a reordering section or unit, or in a child of it
    const isReorderingItem =
      targetElement.classList.contains(className) || parentHasClassname(targetElement, className);

    if (!isReorderingItem) {
      reorderDispatch({
        type: ReorderActions.unsetSection,
        payload: {
          selectedSection: null,
          selectedUnits: [],
        },
      });
      setIsHovered(false);
    }
  });

  const { mutate: deactivationMutation } = useUpdateUnit({
    unitId,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
        queryClient.invalidateQueries([queryKeys.units, courseId]);
      },
      onError: (err) => {
        const error = err as AxiosError;
        handleNoPermissionError(error);
      },
    },
  });

  const handleUnitDelete = (unitId: string, shouldDeleteLinkedUnits?: boolean): void => {
    setIsUnitDeleteModalOpen(false);
    deleteUnitMutation({ unitId, shouldDeleteLinkedUnits });
  };

  const { mutateAsync: exportUnitMutation } = useMutation(
    [queryKeys.unitTestExport],
    ({ unitId }: { unitId: string }) => exportUnit(unitId),
    {
      onError: (error: AxiosError) => {
        handleTestUnitExportError(error);
      },
    },
  );

  const handleUnitExport = async (unitId: string): Promise<void> => {
    // Open a new window before the async request to avoid popup blockers.
    // The new window's URL is set after the async request completes.
    // More info: https://stackoverflow.com/questions/2587677/avoid-browser-popup-blockers
    const w = window.open();
    w?.document.write("");
    const res = await exportUnitMutation({ unitId });

    if (w) {
      w.location.href = res._data.export_url ?? "";
    }
  };

  const { mutate: deleteUnitMutation } = useMutation(
    [queryKeys.courses.unitDelete],
    ({ unitId, shouldDeleteLinkedUnits }: DeleteParams) =>
      deleteUnit(unitId, shouldDeleteLinkedUnits),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.units, course.id.toString()]);

        // Deleted the unit currently rendered
        if (unitId === unitIdParam) {
          // Set autosave to false in order to no trigger autosave on unit component unmount
          setCanAutoSaveUnit(false);

          navigate(URLS.courses.getCourseEditLink({ courseId }));
        }

        const successMessage = isSection
          ? t("courseEdit.sectionDeletedSuccessfully")
          : t("courseEdit.unitDeletedSuccessfully");
        generalNotification("success", <p>{successMessage}</p>);
      },
      onError: (error: AxiosError) => {
        handleNoPermissionError(error);
      },
    },
  );

  const { mutate: cloneUnitMutation } = useCloneCourseUnit({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.units, course.id.toString()]);
        generalNotification("success", t("courseEdit.unitClonedSuccessfully"));
      },
      onError: (err): void => {
        const error = err as AxiosError;
        handleUnitErrors(error);
      },
    },
  });

  const { mutate: publishUnitMutation } = usePublishUnitMutation({
    courseId,
    unitId,
    isActive: unitIsActive,
  });

  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    const { className } = e.target as HTMLDivElement;
    const classnames = ["list-item-text", "list-item-content", "list-item-wrapper"];

    // Click only in empty space or list item text should trigger navigation
    if (isDisabled || !classnames.includes(className)) return;

    navigate(unitEditLink);
  };

  const handlePendingUsersClick = (): void => {
    // Navigate to unit edit page and open manage users drawer
    navigate(URLS.units.getUnitEditLink({ courseId, unitId }), {
      state: { manageUsers: true },
    });
  };

  const handlePendingSubmissionsClick = (): void => {
    // Navigate to unit edit page and open submissions drawer
    navigate(URLS.units.getUnitEditLink({ courseId, unitId }), {
      state: { showSubmissions: true },
    });
  };

  if (!name) return null;

  return (
    <>
      <div
        id={`${unitId}-${isSectionReorderUnit ? "checked" : "unchecked"}`}
        className={listItemClasses({ isSelected, isHovered, isReordered })}
        onMouseEnter={(): void => setIsHovered(true)}
        onMouseLeave={(): void => setIsHovered(false)}
        ref={isReorderingSectionItem ? reorderingSectionItemRef : null}
        css={unitListItemStyles}
        onClick={handleClick}
      >
        <dl>
          <div className={listItemWrapperClasses(isReorderingSectionItem)}>
            <ToC.ListItem
              text={<UnitListTitle name={name} isSection={isSection} availability={availability} />}
              active={isSelected}
              disabled={isDisabled}
              readonly={isDragging}
              icon={
                <UnitListIcon
                  provided={provided}
                  unitId={unitId}
                  name={name}
                  isSelectedSectionUnit={isSelectedSectionUnit}
                  isSectionReorderUnit={isSectionReorderUnit}
                  unitIcon={unitIcon}
                  onClick={handleSelectedUnitClick}
                />
              }
              actions={
                <UnitListTitleActions
                  pendingSubmissions={pending_submissions}
                  linkedUnits={linkedUnits}
                  sourceUnit={sourceUnit}
                  showDraftTag={!unitIsActive}
                  showUnpublishedTag={courseIsActive && unitIsActive && hasUnpublishedChanges}
                  pendingUsers={pendingUsers}
                  toggleLinkedUnitsDrawer={toggleLinkedUnitsDrawer}
                  onPendingUsersClick={handlePendingUsersClick}
                  onPendingSubmissionsClick={handlePendingSubmissionsClick}
                />
              }
            >
              <UnitListActions
                isReorderingSection={isReorderingSection}
                dropdownOptions={dropdownOptions}
                isDropdownDisabled={dropdownDisabled}
                onListItemSelect={handleDropdownItemClick}
                onClose={handleClose}
              />
            </ToC.ListItem>
          </div>
        </dl>
      </div>

      {isUnitDeleteModalOpen && (
        <UnitDeleteModal
          isOpen={isUnitDeleteModalOpen}
          isSection={isSection}
          isLinkedUnit={linkedUnits > 0}
          title={name}
          unitId={unitId}
          onClose={(): void => setIsUnitDeleteModalOpen(false)}
          onDelete={({ unitId, shouldDeleteLinkedUnits }: DeleteParams): void =>
            handleUnitDelete(unitId, shouldDeleteLinkedUnits)
          }
        />
      )}

      {renameModalState.isOpen && (
        <UnitRenameModal
          {...renameModalState}
          unit={unit}
          onClose={(): void => {
            setRenameModalState({ isOpen: false, type: null });
          }}
        />
      )}

      {isLinkedUnitsDrawerOpen && (
        <LinkDrawer
          unitName={name}
          unitId={unitId}
          isDrawerOpen={isLinkedUnitsDrawerOpen}
          toggleDrawer={toggleLinkedUnitsDrawer}
        />
      )}

      {isSetDelayModalOpen && isSection && (
        <SectionDelayModal
          isOpen={isSetDelayModalOpen}
          section={unit}
          onClose={(): void => setSetDelayModalOpen(false)}
        />
      )}
    </>
  );
};
export default UnitListItem;
