import React, { FC, RefObject, useCallback, useMemo } from "react";
import ContentTrailingView from "@components/ReusableComponents/TrailingContentView/ContentTrailingView";
import { Button, Tooltip } from "@epignosis_llc/gnosis";
import { SparklesSolidSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";
import { useCourseEditAI } from "@views/CourseEdit/CourseEditAIContext";

type AIImageAndDescriptionButtonProps = {
  prompt: string;
  contentRef: RefObject<HTMLDivElement>;
};

/**
 * A button that always appears at the end of the Course title's content and allows the user to generate both
 * the Course image and description using AI, from the Course title, with a single click.
 *
 * NOTE:
 * This component depends on the `CourseEditAIContext` and MUST be nested inside a `CourseEditAIProvider`.
 */
const AICourseTitleButton: FC<AIImageAndDescriptionButtonProps> = ({ prompt, contentRef }) => {
  const { t } = useApplyTranslations();
  const {
    isAIDescriptionAvailable,
    isAIImageGenerationAvailable,
    isImageGenerationStarting,
    isImageWorking,
    isDescriptionWorking,
    generateImageAndDescription,
  } = useCourseEditAI();

  const MIN_PROMPT_LENGTH = 2;

  const newCourseTitle = "New course";

  // Calculates all reduced versions of the default course name.
  // For example, for "New course", it will calculate: "New cours", "New cour" etc.
  // This is a best-effort approach to hide the button until the user actually provides a proper Course name.
  const reducedPlaceholderStrings = useMemo(() => {
    const result: string[] = [];
    if (newCourseTitle.length < MIN_PROMPT_LENGTH) {
      return result;
    }

    for (let i = newCourseTitle.length; i >= MIN_PROMPT_LENGTH; i--) {
      result.push(newCourseTitle.substring(0, i));
    }

    return result;
  }, [newCourseTitle]);

  const isButtonAvailable =
    isAIDescriptionAvailable &&
    isAIImageGenerationAvailable &&
    !isDescriptionWorking &&
    !isImageGenerationStarting &&
    !isImageWorking &&
    prompt?.length >= MIN_PROMPT_LENGTH &&
    !reducedPlaceholderStrings.includes(prompt);
  const startGenerating = useCallback(() => {
    generateImageAndDescription(prompt);
  }, [generateImageAndDescription, prompt]);

  return !isButtonAvailable ? (
    <></>
  ) : (
    <ContentTrailingView contentRef={contentRef}>
      <Tooltip content={t("courseEdit.aiButtonTooltip")}>
        <Button
          onClick={startGenerating}
          as={"button"}
          noGutters
          color="secondary"
          className={"ai-generate"}
        >
          <SparklesSolidSVG height={32} />
        </Button>
      </Tooltip>
    </ContentTrailingView>
  );
};

export default AICourseTitleButton;
