import { useMemo } from "react";
import { allBreadcrumbsPaths } from "./constants";
import { useApplyTranslations } from "@hooks";
import { useNavigate } from "react-router-dom";

interface Breadcrumb {
  label: string;
  href: string;
}

const useBreadcrumbs = (currentPath: string): Breadcrumb[] => {
  const { t } = useApplyTranslations();
  const navigate = useNavigate();

  const translateBreadcrumbs = (breadcrumbs: Breadcrumb[]): Breadcrumb[] => {
    return breadcrumbs.map((breadcrumb) => ({
      ...breadcrumb,
      label: t(breadcrumb.label),
    }));
  };

  return useMemo(() => {
    for (const route of allBreadcrumbsPaths(navigate)) {
      if (route.regex.test(currentPath)) {
        return translateBreadcrumbs(route.breadcrumbs);
      }
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);
};

export default useBreadcrumbs;
