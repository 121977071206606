import { Button, Text, Heading, Tooltip } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive } from "ahooks";
import React, { FC } from "react";
import { Link } from "react-router-dom";

// Styles, hooks and utils
import { tableHeaderContainer } from "./styles";
import { useApplyTranslations } from "@hooks";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { isString } from "types/assertions";

type TableHeaderProps = {
  title: string | JSX.Element;
  subtitle?: string;
  backLink?: string;
  children?: React.ReactNode;
  secondaryActions?: JSX.Element | null;
  hideBackButton?: boolean;
  tooltipIcon?: {
    icon: JSX.Element;
    tooltipText: string;
  };
  className?: string;
};

const PageHeader: FC<TableHeaderProps> = (props) => {
  const {
    title,
    subtitle,
    backLink,
    secondaryActions,
    tooltipIcon,
    hideBackButton = false,
    className,
  } = props;
  const { t } = useApplyTranslations();
  const { md } = useResponsive();

  return (
    <div css={tableHeaderContainer} className={className ?? ""}>
      <div className="title-wrapper" data-testid={isString(title) ? `${title}-title` : ""}>
        {!hideBackButton && !md && (
          <Button
            color="secondary"
            variant="link"
            as={Link}
            to={backLink ?? ""}
            aria-label={t("general.back")}
            noGutters
          >
            <ArrowLeftSVG height={28} />
          </Button>
        )}
        {md && <Breadcrumbs />}
        <Heading as="h1" size="2xl">
          {title}
        </Heading>
        {subtitle && (
          <Text className="subtitle" fontSize="sm" data-testid="page-header-subtitle">
            {subtitle}
          </Text>
        )}
        {tooltipIcon && <Tooltip content={tooltipIcon.tooltipText}>{tooltipIcon.icon}</Tooltip>}
      </div>

      {secondaryActions && <div className="right-block">{secondaryActions}</div>}
    </div>
  );
};

export default PageHeader;
