import React, { FC, ReactNode } from "react";
import Slider from "react-slick";
import { SerializedStyles } from "@emotion/react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carousel } from "./styles";

import { i18n } from "@utils/i18n";
import { updateTabindexForSlickSlides } from "@utils/helpers";

type CarouselProps = {
  children?: ReactNode;
  customSettings?: Record<string, unknown>;
};

const Carousel: FC<CarouselProps> = ({ children, customSettings }) => {
  const isRtl = i18n.dir() === "rtl";

  const sharedCallbacks = {
    afterChange: (): void => {
      updateTabindexForSlickSlides(".slick-slide");
    },
    onInit: (): void => {
      updateTabindexForSlickSlides(".slick-slide");
    },
  };

  const settings = customSettings
    ? {
        ...customSettings,
        ...sharedCallbacks,
      }
    : {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: true,
        ...sharedCallbacks,
      };

  return (
    <Slider {...settings} css={(theme): SerializedStyles => carousel(theme, { isRtl })} rtl={isRtl}>
      {children}
    </Slider>
  );
};

export default Carousel;
