import {
  RatingExcellentSVG,
  RatingGoodSVG,
  RatingOkaySVG,
  RatingPoorSVG,
} from "@epignosis_llc/gnosis/icons";
import { IconType, SelectOption } from "types/common";
import {
  OptionsType,
  OtherOptionSettings,
  PlanBasicInformation,
  PlanName,
  PlanState,
} from "./types";
import { t } from "@utils/i18n";
import { Column } from "@epignosis_llc/gnosis";

const RATING_PREFIX = "billingAndSubscription.cancellationSurvey.ratings";
const REASONS_PREFIX = "billingAndSubscription.cancellationSurvey.reasons";
const OPTIONS_PREFIX = "billingAndSubscription.optionsPage";
export const CANCELLATION_SURVEY_PREFIX = "billingAndSubscription.cancellationSurvey";
const PLANS_PREFIX = "billingAndSubscription.plansPage";

export const CUSTOM_PLAN_CREDITS = 1000000;

export enum CancellationModalView {
  LANDING = "landing",
  SURVEY = "survey",
  FREE_PLAN = "downgrade",
  SUPPORT = "support",
}

export const getCancellationReason = (): SelectOption[] => {
  return [
    { label: t(`${REASONS_PREFIX}.missingFeatures`), value: "missing_features" },
    { label: t(`${REASONS_PREFIX}.price`), value: "price" },
    { label: t(`${REASONS_PREFIX}.budget`), value: "budget" },
    { label: t(`${REASONS_PREFIX}.competition`), value: "competition" },
    {
      label: t(`${REASONS_PREFIX}.seasonalTraining`),
      value: "seasonal_training",
    },
    { label: t(`${REASONS_PREFIX}.currentlyUnnecessary`), value: "currently_unnecessary" },
    { label: t(`${REASONS_PREFIX}.other`), value: "other" },
  ];
};

export const getSupportOptions = (): {
  label: string;
  value: number;
  icon: IconType;
}[] => {
  return [
    { label: t(`${RATING_PREFIX}.poor`), value: 1, icon: RatingPoorSVG },
    { label: t(`${RATING_PREFIX}.okay`), value: 2, icon: RatingOkaySVG },
    { label: t(`${RATING_PREFIX}.good`), value: 3, icon: RatingGoodSVG },
    { label: t(`${RATING_PREFIX}.excellent`), value: 4, icon: RatingExcellentSVG },
  ];
};

export const getOtherOptionsSettings = (): OtherOptionSettings[] => {
  return [
    {
      title: t(`${OPTIONS_PREFIX}.cancellation`),
      description: t(`${OPTIONS_PREFIX}.cancellationInfo`),
      buttonText: t("billingAndSubscription.cancellationSurvey.cancelSubscription"),
      action: "cancel",
    },
    {
      title: t(`${OPTIONS_PREFIX}.transitionToFree`),
      description: t(`${OPTIONS_PREFIX}.transitionToFreeInfo`),
      buttonText: t("billingAndSubscription.cancellationSurvey.switchToFree"),
      action: "switch",
    },
  ];
};

export const getLandingPageOptions = (): OptionsType => {
  return [
    {
      question: `${CANCELLATION_SURVEY_PREFIX}.contactSupportQuestion`,
      action: CancellationModalView.SUPPORT,
      buttonLabel: `${CANCELLATION_SURVEY_PREFIX}.contactSupportButton`,
    },
    {
      question: `${CANCELLATION_SURVEY_PREFIX}.switchToFreePlanQuestion`,
      action: CancellationModalView.FREE_PLAN,
      buttonLabel: `${CANCELLATION_SURVEY_PREFIX}.switchToFreePlanButton`,
    },
    {
      question: `${CANCELLATION_SURVEY_PREFIX}.cancelSubscriptionOptionQuestion`,
      action: CancellationModalView.SURVEY,
      buttonLabel: `${CANCELLATION_SURVEY_PREFIX}.cancelSubscription`,
    },
  ];
};

export const getInvoicesColumns = (): Column[] => {
  return [
    {
      accessor: "period",
      cell: t("general.period"),
      sortableHeader: false,
    },
    {
      accessor: "plan",
      cell: t("general.plan"),
      headerWidth: 200,
      sortableHeader: false,
    },
    {
      accessor: "amount",
      cell: t("general.amount"),
      sortOrder: "desc",
      sortableHeader: false,
    },
    {
      accessor: "actions",
      cell: "",
      classNames: ["hidden"],
      sortableHeader: false,
    },
  ];
};

export const USER_VALUES_PER_PLAN: Record<Exclude<PlanName, "enterprise">, number[]> = {
  core: [40, 70, 100, 150, 200, 300, 400, 500, 600, 800, 1000],
  grow: [70, 100, 150, 200, 300, 400, 500, 600, 800, 1000],
  pro: [70, 100, 150, 200, 300, 400, 500, 600, 800, 1000],
};

export const PLAN_NAMES = ["core", "grow", "pro"];

export const FREE_PLAN_NAME = "Free";
export const FREE_PLAN_KEY = "free";

export const REVERSE_TRIAL_PLAN_KEY = "reverse-trial";

export const defaultState: Record<PlanName, PlanState> = {
  core: { userNumber: 40, interval: 12, hasFlex: false, hasLibrary: false },
  grow: { userNumber: 70, interval: 12, hasFlex: false, hasLibrary: false },
  pro: { userNumber: 70, interval: 12, hasFlex: false, hasLibrary: false },
  enterprise: { userNumber: 100, interval: 12, hasFlex: false, hasLibrary: false },
};

export const withSoonTag = ["linkedin_learning"];
export const hiddenFeatures = ["talentlibrary"];

export const getBasicPlanInfo = (): { [key: string]: PlanBasicInformation } => {
  return {
    core: {
      id: "core",
      name: "Core",
      description: t(`${PLANS_PREFIX}.descriptions.core`),
      maxUsers: 100,
    },
    grow: {
      id: "grow",
      name: "Grow",
      description: t(`${PLANS_PREFIX}.descriptions.grow`),
      maxUsers: 500,
    },
    pro: {
      id: "pro",
      name: "Pro",
      description: t(`${PLANS_PREFIX}.descriptions.pro`),
      maxUsers: 1000,
    },
    enterprise: {
      id: "enterprise",
      name: "Enterprise",
      description: t(`${PLANS_PREFIX}.descriptions.enterprise`),
      maxUsers: null,
    },
  };
};

export const EXTRA_FEATURES: { [key: string]: string[] } = {
  free: ["unlimited_email_support"],
  core: ["unlimited_courses", "unlimited_email_support"],
  grow: ["unlimited_courses", "unlimited_email_support"],
  pro: ["unlimited_courses", "unlimited_email_support"],
  enterprise: ["unlimited_courses", "unlimited_email_support"],
};

export const EXCLUDED_FROM_REGULAR_MONTHLY_PLANS: string[] = [
  "consultation_call",
  "onboarding",
  "customer_success_manager",
];

export const EXTRA_FEATURES_ON_PLAN_CARD: { [key: string]: string[] } = {
  free: ["unlimited_email_support"],
  core: ["unlimited_courses"],
  grow: [],
  pro: [],
  enterprise_placeholder: [
    "unlimited_branches",
    "talentcraft_ai_replenishing",
    "account_manager",
    "phone_support",
  ],
  enterprise: [],
};

export const EXTRA_FEATURES_ALL_FEATURES_DRAWER: { [key: string]: string[] } = {
  core: ["unlimited_courses", "unlimited_email_support"],
  grow: [],
  pro: [],
  enterprise: ["unlimited_branches", "talentcraft_ai_replenishing", "phone_support"],
};

export const LABELS_ORDER: { [key: string]: string[] } = {
  reverseTrial: [],
  free: ["unlimited_email_support"],
  core: ["talentcraft_ai_once_off", "unlimited_courses", "custom_homepage", "sso", "custom_domain"],
  grow: ["talentcraft_ai_replenishing", "unlimited_courses", "custom_reports"],
  pro: [
    "talentcraft_ai_replenishing",
    "unlimited_courses",
    "white_label",
    "automated_actions",
    "live_chat",
    "priority_email_support",
  ],
  enterprise: [
    "unlimited_branches",
    "talentcraft_ai_replenishing",
    "unlimited_courses",
    "phone_support",
  ],
};

export const FLEX_INFO =
  "https://help.talentlms.com/hc/en-us/articles/9652402239516-How-TalentLMS-subscription-plans-work";
export const LIBRARY_INFO = "https://www.talentlms.com/library/";
export const BRANCHES_INFO =
  "https://help.talentlms.com/hc/en-us/articles/360014656833-How-to-work-with-branches";
export const TALENT_LIBRARY_TEXT = "TalentLibrary"; // Not translatable
export const TALENT_CRAFT_AI_TEXT = "TalentCraft AI"; // Not translatable
export const TALENT_CRAFT_AI_TEXT_PREMIUM = "TalentCraft AI premium"; // Not translatable
export const FLEX_TEXT = "Flex"; // Not translatable
export const SKILLS_TEXT = "Skills"; // Not translatable
export const SKILLS_INFO = "https://www.talentlms.com/skills";
