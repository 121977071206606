// Packages or third-party libraries
import React, { FC, MouseEvent } from "react";
import { Tag, Text } from "@epignosis_llc/gnosis";
import { ChevronArrowRightSVG, ChevronArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive } from "ahooks";
import classNames from "classnames";

// Utils, hooks
import { i18n } from "@utils/i18n";
import { useApplyTranslations } from "@hooks";

// Types
import { UnitMenu } from "./types";

type UnitsDropdownDesktopProps = {
  isExpanded: boolean;
  unitsMenu: UnitMenu[];
  handleCreateUnitFn: (unitType: string) => Promise<void>;
};

const DropdownClassnames = (isMedium: boolean): string =>
  classNames("create-unit-dropdown", {
    mobile: !isMedium,
  });

const ActiveClassnames = (index: number, activeMenu: number | null): string =>
  classNames("main-item", {
    active: activeMenu === index,
  });

const UnitsDropdown: FC<UnitsDropdownDesktopProps> = ({
  isExpanded,
  unitsMenu,
  handleCreateUnitFn: handleCreateUnitDropdownItemClick,
}) => {
  const { t } = useApplyTranslations();
  const { lg: isMedium } = useResponsive();

  const [activeMenu, setActiveMenu] = React.useState<number | null>(null);
  const isRtl = i18n.dir() === "rtl";

  const handleOnMouseLeave = (event: React.MouseEvent<HTMLLIElement>): void => {
    if (!isMedium) return;

    const { className } = event?.relatedTarget as HTMLDivElement;

    if (typeof className !== "string" || className.includes("main-menu")) return;

    setActiveMenu(null);
  };

  React.useEffect(() => {
    if (!isExpanded) setActiveMenu(null);
  }, [isExpanded]);

  return (
    <>
      {isExpanded && (
        <div className={DropdownClassnames(isMedium)}>
          <ul className="main-menu">
            {unitsMenu.map((item, index) => {
              const hasSubMenu = typeof item.subMenu !== "undefined" && item.subMenu.length > 0;
              const isFeatured = item.isFeatured!;

              return (
                <li
                  key={index}
                  className={ActiveClassnames(index, activeMenu)}
                  onClick={(): void => setActiveMenu(index)}
                  onMouseEnter={(): void | null => (isMedium ? setActiveMenu(index) : null)}
                  onMouseLeave={handleOnMouseLeave}
                  onBlur={(): void | null => (isMedium ? setActiveMenu(null) : null)}
                >
                  {item.icon}

                  <div
                    className="menu-item"
                    onClick={(e: MouseEvent<HTMLDivElement>): void => {
                      e.stopPropagation();
                      item.value && handleCreateUnitDropdownItemClick(item.value);
                    }}
                  >
                    <Text fontSize="sm" weight="700" as="span" className="menu-title">
                      {item.label}
                    </Text>
                    <Text fontSize="sm" weight="400" as="p" className="menu-description">
                      {item.description}
                    </Text>
                  </div>
                  {isFeatured && <Tag className="new-tag">{t("general.new").toUpperCase()}</Tag>}
                  {hasSubMenu && (
                    <>
                      <ul className="sub-menu">
                        {!isMedium && (
                          <li className="sub-item back-item">
                            <div className="back-container">
                              <button
                                onClick={(e): void => {
                                  e.stopPropagation();
                                  setActiveMenu(null);
                                }}
                              >
                                {isRtl ? <ChevronArrowRightSVG /> : <ChevronArrowLeftSVG />}
                                {t("general.back")}
                              </button>
                            </div>
                          </li>
                        )}
                        {typeof item.subMenu !== "undefined" &&
                          item.subMenu.map((subItem, subIndex) => (
                            <li
                              key={subIndex}
                              className="sub-item"
                              onClick={(e: MouseEvent<HTMLLIElement>): void => {
                                e.stopPropagation();
                                setActiveMenu(null);
                                subItem.value && handleCreateUnitDropdownItemClick(subItem.value);
                              }}
                            >
                              <div className="menu-item">
                                <Text fontSize="sm" weight="400" as="span" className="menu-title">
                                  {subItem.icon}
                                  {subItem.label}
                                </Text>
                              </div>
                            </li>
                          ))}
                      </ul>
                      {isRtl ? <ChevronArrowLeftSVG /> : <ChevronArrowRightSVG />}
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default UnitsDropdown;
