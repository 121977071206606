import React, { ReactElement, useState } from "react";
import { ActionDrawer } from "@components";
import { useApplyTranslations } from "@hooks";
import { Controller, FieldValues, Path, PathValue } from "react-hook-form";
import { BranchDrawersProps, BranchPropertiesNames } from "@views/Branches/types";
import { ToggleSwitch, Text, Input, InputError } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { toggleSwitchDescription } from "@views/Branches/styles";

const RestrictedUsersDrawer = <T extends FieldValues>({
  control,
  isDisabled,
  watch,
  resetField,
  toggleDrawer,
  errors,
  isOpen,
  setValue,
}: BranchDrawersProps<T>): JSX.Element => {
  const { t } = useApplyTranslations();

  const isEnabled = watch?.(
    BranchPropertiesNames.BRANCH_ENABLED_RESTRICT_REGISTERED_USERS as Path<T>,
  );
  const restrictTotalRegisteredUsers = watch?.(
    BranchPropertiesNames.BRANCH_RESTRICT_REGISTERED_USERS as Path<T>,
  );

  const tempValues = {
    isEnabled,
    restrictTotalRegisteredUsers,
  };

  const [tempValuesState, setTempValues] = useState(tempValues);

  const headerTitle = t("accountAndSettings.security.restrictRegistration");
  const handleClose = (): void => {
    const resetEnabledValue = tempValuesState.isEnabled
      ? { defaultValue: tempValuesState.isEnabled }
      : undefined;
    const resetRestrictTotalRegisteredUsersValue = tempValuesState.restrictTotalRegisteredUsers
      ? { defaultValue: tempValuesState.restrictTotalRegisteredUsers }
      : undefined;

    resetField?.(
      BranchPropertiesNames.BRANCH_ENABLED_RESTRICT_REGISTERED_USERS as Path<T>,
      resetEnabledValue,
    );
    resetField?.(
      BranchPropertiesNames.BRANCH_RESTRICT_REGISTERED_USERS as Path<T>,
      resetRestrictTotalRegisteredUsersValue,
    );

    toggleDrawer();
  };
  const actionButton = {
    text: t("general.back"),
    isDisabled: isDisabled,
  };
  const onApply = (): void => {
    setTempValues(tempValues);
    toggleDrawer();
  };

  return (
    <ActionDrawer
      isOpen={isOpen}
      onClose={handleClose}
      showFooter={true}
      size="md"
      headerTitle={headerTitle}
      actionButton={actionButton}
      onApply={onApply}
    >
      <div css={(theme): SerializedStyles => toggleSwitchDescription(theme)}>
        <Controller
          name={BranchPropertiesNames.BRANCH_ENABLED_RESTRICT_REGISTERED_USERS as Path<T>}
          control={control}
          render={({ field: { onChange, value } }): JSX.Element => (
            <div>
              <ToggleSwitch
                labelAfter={t("general.enabled")}
                defaultChecked={Boolean(value)}
                isDisabled={isDisabled}
                onChange={(): void => {
                  onChange(!value);
                  setValue?.(
                    BranchPropertiesNames.BRANCH_RESTRICT_REGISTERED_USERS as Path<T>,
                    null as PathValue<T, Path<T>>,
                  );
                }}
              />
              <Text as="div" fontSize="sm" className="description">
                {t("branches.properties.restrictRegisteredUsers.description")}
              </Text>
            </div>
          )}
        />
        <div className="restricted-domains-input">
          <Controller
            name={BranchPropertiesNames.BRANCH_RESTRICT_REGISTERED_USERS as Path<T>}
            control={control}
            render={({ field: { onChange, value } }): ReactElement => {
              return (
                <>
                  <Input
                    label={t("accountAndSettings.security.restrictRegistrationToUsers")}
                    type="number"
                    id={BranchPropertiesNames.BRANCH_RESTRICT_REGISTERED_USERS}
                    status={errors?.restrict_total_registered_users ? "error" : "valid"}
                    disabled={!isEnabled || isDisabled}
                    value={value ?? ""}
                    onChange={(totalUsers): void => {
                      onChange(totalUsers);
                    }}
                  />
                  {errors && errors.restrict_total_registered_users && (
                    <InputError>{errors.restrict_total_registered_users?.message}</InputError>
                  )}
                </>
              );
            }}
          />
        </div>
      </div>
    </ActionDrawer>
  );
};

export default RestrictedUsersDrawer;
