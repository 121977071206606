import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { StatusTag, StatusTagColors, Tooltip } from "@epignosis_llc/gnosis";
import { textWithStatusContainer } from "./style";

type TextWithStatusProps = {
  content: string | JSX.Element;
  tagText?: string;
  variant?: "failed" | "inactive" | "info" | "processing" | "success" | "warning" | "ready";
  tooltipContent?: string | JSX.Element;
  isHtml?: boolean;
  maxWidth?: number;
  children?: React.ReactNode;
};

// Adjust the key types for the map to ensure TypeScript can infer them correctly.
type Variants = "ready" | "failed" | "inactive" | "info" | "processing" | "success" | "warning";

// Create a mapping from variants to colors.
const mapVariantsToTagColors: Record<Variants, StatusTagColors> = {
  ready: "neutral",
  failed: "red",
  inactive: "inactive",
  info: "neutral",
  processing: "neutral",
  success: "positive",
  warning: "warning",
};

const TextWithStatus: FC<TextWithStatusProps> = ({
  content,
  tagText,
  variant = "ready",
  children,
  tooltipContent,
  maxWidth = 350,
  isHtml = false,
}) => {
  const componentRef = useRef<HTMLSpanElement | null>(null);
  const overflowRef = useRef<HTMLElement | null>(null);
  const [isOverflowActive, setIsOverflowActive] = useState(false);
  const color = mapVariantsToTagColors[variant];

  useLayoutEffect(() => {
    if (componentRef.current) {
      overflowRef.current = componentRef.current.querySelector(".overflow-text");
    }
  }, []);

  useEffect(() => {
    const el = overflowRef.current;

    if (el) {
      setIsOverflowActive(el.offsetWidth < el.scrollWidth);
    }
  }, [overflowRef]);

  return (
    <div css={textWithStatusContainer}>
      <div className="column-description">
        <Tooltip
          content={tooltipContent ?? content}
          disabled={!isOverflowActive}
          maxWidth={maxWidth}
        >
          <span ref={componentRef} style={{ display: "flex" }}>
            {content}
            {isHtml && isOverflowActive && "..."}
          </span>
        </Tooltip>
        {children}
        {tagText && variant != "ready" && (
          <div className="status-tag">
            <StatusTag text={tagText} color={color} size="md" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TextWithStatus;
