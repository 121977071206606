import React, { StrictMode, Suspense, FC, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Container, createRoot } from "react-dom/client";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider as GnosisThemeProvider, Loader } from "@epignosis_llc/gnosis";
import { ThemeProvider, SerializedStyles } from "@emotion/react";
import { I18nextProvider } from "react-i18next";
import { Query, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "focus-visible";
import { pdfjs } from "react-pdf";

// Global Styles
import globalStyles from "@styles/global";

// Components
import Routes from "./Routes/Routes";
import { ErrorFallbackComponent } from "@components/ReusableComponents";

// Utils, helpers, hooks...
import "./channels/notifications";
import { errorBoundaryHandler } from "@errors";
import { useAuth, usePrevious } from "@hooks";
import { useUIStore } from "@stores";
import { i18n } from "@utils/i18n";
import { getErrorRetries } from "@utils/helpers";

// Constants
import { config, getBaseUrl, featureFlags } from "@config";
import queryKeys from "@constants/queryKeys";

const queryClient = new QueryClient();

if (config.isStaging() || config.isProd()) {
  Sentry.init({
    dsn: "https://13b17cb9c5e149389fe4d93bdec0605a@o1417853.ingest.sentry.io/6776415",
    integrations: [new Sentry.BrowserTracing()],
    environment: config.isProd() ? "production" : "staging",
    // Set tracesSampleRate to 1.0 to capture 100%
    // Of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

// The version of pdfjs-dist should be in sync with the version used in react-pdf
// See node_modules/react-pdf/package.json
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const App: FC = () => {
  const { isAuthenticated } = useAuth();
  const previousIsAuthenticated = usePrevious(isAuthenticated);
  const { theme } = useUIStore((state) => state);
  const { getCollapsedMainNav } = useUIStore();
  const twoMinutesInterval = 1000 * 60 * 2;

  // Set the preferred query client default settings
  queryClient.setDefaultOptions({
    queries: {
      retry: (failureCount, error) => {
        const { response } = error as AxiosError;
        return isAuthenticated ? getErrorRetries(failureCount, Number(response?.status)) : false;
      },
      refetchOnWindowFocus: true,
      staleTime: isAuthenticated && featureFlags.fetchOnWindowFocus ? twoMinutesInterval : 0,
    },
  });

  // Get main nav from locale storage
  useEffect(() => {
    getCollapsedMainNav();
  }, [getCollapsedMainNav]);

  // Update the preferred query client default settings when isAuthenticated changes
  useEffect(() => {
    // Cancel queries only when isAuthenticated changes from true to false
    if (previousIsAuthenticated && !isAuthenticated) {
      queryClient.cancelQueries({
        predicate: (query: Query): boolean => query.queryKey !== queryKeys.domainSettings,
      });
    }

    queryClient.setDefaultOptions({
      queries: {
        retry: isAuthenticated ? 3 : false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <StrictMode>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <GnosisThemeProvider
            theme={theme}
            globalStyles={globalStyles as unknown as SerializedStyles}
          >
            <Router basename={getBaseUrl()}>
              <ErrorBoundary
                FallbackComponent={ErrorFallbackComponent}
                onError={errorBoundaryHandler}
              >
                <Suspense fallback={<Loader fullScreen />}>
                  <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                    <Routes />
                  </QueryClientProvider>
                </Suspense>
              </ErrorBoundary>
            </Router>
          </GnosisThemeProvider>
        </ThemeProvider>
      </I18nextProvider>
    </StrictMode>
  );
};

const container: Container = document.getElementById("app")!;
const root = createRoot(container);

root.render(<App />);
