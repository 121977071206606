// Packages or third-party libraries
import React, { FC } from "react";
import { Select } from "@epignosis_llc/gnosis";
import { Controller, UseFormReturn } from "react-hook-form";

// Hooks
import { useApplyTranslations } from "@hooks";

// Other imports
import { SelectOption } from "types/common";
import { CourseRulesFormData, TraversalRule } from "@views/CourseEdit/types";

type UnitsOrderingProps = {
  form: UseFormReturn<CourseRulesFormData>;
};

const UnitsOrdering: FC<UnitsOrderingProps> = ({ form }) => {
  const { t } = useApplyTranslations();
  const { control } = form;

  const traversalRuleOptions: SelectOption[] = [
    { label: t("courseEdit.anyOrder"), value: TraversalRule.Any },
    { label: t("courseEdit.sequentialOrder"), value: TraversalRule.Sequential },
  ];

  return (
    <Controller
      name="traversal_rule"
      control={control}
      render={({ field: { onChange, value } }): JSX.Element => {
        const selectedValue = traversalRuleOptions.find((option) => option.value === value);

        return (
          <Select
            id="traversal-rule"
            value={selectedValue}
            options={traversalRuleOptions}
            label={t("courseEdit.showUnits")}
            aria-label={t("courseEdit.showUnits")}
            onChange={(option): void => {
              const { value } = option as SelectOption;
              onChange(value);
            }}
          />
        );
      }}
    />
  );
};

export default UnitsOrdering;
