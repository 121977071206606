import React from "react";
import { CreditCardSVG, SavingsSVG, PaypalCardSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "@utils/i18n";
import { capitalize, groupBy } from "@utils/helpers";
import { Course } from "types/entities";
import { Subscription } from "types/entities/Catalog";

enum Processor {
  Credits = "credits",
  Stripe = "stripe",
  PayPal = "paypal",
}

export const getPaymentNotificationTranslation = (
  type: string,
  isUserEnrolled: boolean,
  processor: string,
  name: string,
): string => {
  if (type === "payment") {
    return isUserEnrolled
      ? t("payments.paymentSuccess", { name })
      : t("payments.paymentProcessing", { processor: capitalize(processor), name });
  }

  return t("payments.subscriptions.subscribedSuccessfully");
};

export const getSubscriptionInterval = (
  interval: Subscription["interval"],
  showShort = false,
): string => {
  const monthText = showShort
    ? t("billingAndSubscription.plansPage.monthShort")
    : t("general.month", { count: 1 });

  const yearText = showShort ? t("general.yearShort") : t("general.yearLowercase", { count: 1 });

  if (interval === 1) return monthText;

  return yearText;
};

export const getSubscriptionInterval2 = (interval: Subscription["interval"]): string => {
  if (interval === 1) return t("general.monthly", { count: 1 });

  return t("general.annually", { count: 1 });
};

export const getDependentCoursesNum = (course: Course): number | "multi" => {
  if (course.rules.prerequisites.length === 0) return 0;

  const groupedSets = groupBy(
    course.rules.prerequisites,
    (prerequisiteItem) => prerequisiteItem.rule_set,
  );

  const filteredSets = Object.values(groupedSets).map((groupedSet) =>
    groupedSet.filter((item) => !item.completion_status),
  );

  // If we only have one set of prerequisites
  if (filteredSets.length === 1) return filteredSets[0].length;

  // If any of the sets have zero length
  if (filteredSets.some((item) => item.length === 0)) return 0;

  return "multi";
};

export const getTabTitle = (processor: string): string => {
  switch (processor) {
    case Processor.Credits:
      return t("general.creditsUpper");
    case Processor.Stripe:
      return t("general.creditCard");
    case Processor.PayPal:
      return "Paypal";
    default:
      return "";
  }
};

export const getPaymentMethodIcon = (processor: string): JSX.Element => {
  switch (processor) {
    case Processor.Credits:
      return <SavingsSVG height={32} />;
    case Processor.PayPal:
      return <PaypalCardSVG height={32} />;
    case Processor.Stripe:
    default:
      return <CreditCardSVG height={32} />;
  }
};
