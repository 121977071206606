// Packages or third-party libraries
import React, { FC } from "react";
import { Button, Drawer, Heading, Text, Tooltip } from "@epignosis_llc/gnosis";
import { InfoButtonSVG, ArrowLeftSVG, CloseSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive } from "ahooks";
import { SerializedStyles, Theme } from "@emotion/react";
import { Placement } from "tippy.js";

// Styles
import { ActionDrawerContainer } from "./styles";

// Utils
import { i18n } from "@utils/i18n";
import { useApplyTranslations } from "@hooks";
import { DrawerSize } from "types/common";

export type ActionDrawerProps = {
  isOpen: boolean;
  headerTitle: string;
  headerSubtitle?: string;
  showFooter?: boolean;
  hasBackButton?: boolean;
  fullscreen?: boolean;
  size?: DrawerSize;
  id?: string;
  onClose: () => void;
  onApply?: () => void;
  actionButton?: {
    text?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    tooltipContent?: string;
  };
  customFooter?: JSX.Element;
  tooltipContent?: string;
  tooltipPlacement?: Placement;
  children?: React.ReactNode;
  headerTItleSize?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  footerPadding?: string;
};

const widthDimensions = {
  xs: "24rem",
  sm: "28rem",
  md: "48rem",
  lg: "72rem",
};

const DEFAULT_SIZE = "xs";

const ActionDrawer: FC<ActionDrawerProps> = ({
  isOpen,
  onClose,
  onApply,
  headerTitle,
  headerSubtitle,
  showFooter = true,
  hasBackButton = false,
  fullscreen = false,
  size = DEFAULT_SIZE,
  id,
  children,
  actionButton = {
    text: "",
    isDisabled: false,
    isLoading: false,
    tooltipContent: "",
  },
  customFooter,
  tooltipContent,
  tooltipPlacement = "bottom",
  headerTItleSize = "h3",
  footerPadding,
}) => {
  const { t } = useApplyTranslations();
  const { lg } = useResponsive();
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "left" : "right";
  const width = lg ? widthDimensions[size] : widthDimensions[DEFAULT_SIZE];
  const {
    isDisabled,
    isLoading,
    text: actionButtonLabel,
    tooltipContent: actionButtonTooltipContent,
  } = actionButton;

  const drawerWidth = fullscreen ? "100%" : width;
  const actionButtonText =
    actionButtonLabel && actionButtonLabel.length > 0 ? actionButtonLabel : t("general.apply");
  const showCloseButton = !showFooter || !lg;
  const headingDataTestId = `${id ? id : "drawer"}-header`;

  return (
    <Drawer
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      placement={placement}
      width={drawerWidth}
      css={(theme: Theme): SerializedStyles =>
        ActionDrawerContainer(theme, { hasSubtitle: Boolean(headerSubtitle), footerPadding })
      }
    >
      <section aria-label={headerTitle}>
        <Drawer.Header closable={false}>
          <div className="back-title-container">
            {hasBackButton && (
              <Button
                color="secondary"
                variant="link"
                onClick={onClose}
                noGutters
                className="back-button"
                aria-label={t("general.back")}
              >
                <ArrowLeftSVG height={28} />
              </Button>
            )}
            <div className="header-content">
              <Heading as={headerTItleSize} data-testid={headingDataTestId}>
                <div className="header-container">
                  {headerTitle}
                  {tooltipContent && (
                    <Tooltip as="span" content={tooltipContent} placement={tooltipPlacement}>
                      <InfoButtonSVG
                        className="tooltip-icon"
                        height={16}
                        aria-label={tooltipContent}
                      />
                    </Tooltip>
                  )}
                </div>
              </Heading>
              {headerSubtitle && <Text fontSize="xs">{headerSubtitle}</Text>}
            </div>
          </div>
          {/* Show the close button when there is no footer or we are below lg screen */}
          {showCloseButton && (
            <Button
              aria-label={t("a11y.general.closeDrawer")}
              color="secondary"
              variant="link"
              onClick={onClose}
              noGutters
              className="close-button"
            >
              <CloseSVG height={20} />
            </Button>
          )}
        </Drawer.Header>
      </section>
      <Drawer.Body>
        <section className="action-drawer-body">{children}</section>
      </Drawer.Body>
      {showFooter && (
        <section aria-label={`${headerTitle} footer`}>
          <Drawer.Footer>
            {customFooter ?? (
              <div className="action-buttons">
                <Tooltip
                  content={
                    <Text
                      fontSize="sm"
                      dangerouslySetInnerHTML={{ __html: actionButtonTooltipContent ?? "" }}
                    />
                  }
                  disabled={!actionButtonTooltipContent}
                >
                  <Button
                    onClick={onApply ? onApply : onClose}
                    disabled={isDisabled}
                    isLoading={isLoading}
                    className="left-aligned"
                    data-testid="drawer-save"
                  >
                    {actionButtonText}
                  </Button>
                </Tooltip>
                <Button color="secondary" data-testid="drawer-cancel" onClick={onClose}>
                  {t("general.cancel")}
                </Button>
              </div>
            )}
          </Drawer.Footer>
        </section>
      )}
    </Drawer>
  );
};

export default ActionDrawer;
