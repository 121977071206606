// Packages or third-party libraries
import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { CourseExitSVG, AssignmentPassedAnswer } from "@epignosis_llc/gnosis/icons";
import { SerializedStyles } from "@emotion/react";

// Styles
import { courseHeaderStyles } from "../../styles";

// Components
import CoverImage from "../../CoverImage";
import InfoTag from "../../InfoTag";
import IntoVideo from "../../IntoVideo";
import TitleContainer from "../../TitleContainer";

// Utils, hooks
import { useApplyTranslations } from "@hooks";
import { useUnitStore } from "@stores";

// Other imports
import { Course, CourseCategory } from "types/entities";
import { getCourseCategoryText } from "../../helpers";
import Restrictions from "../../Restrictions";
import Rating from "../../Rating";

type CourseHeaderProps = {
  course: Course;
  categoryTree: CourseCategory[];
  backLinkCb?: () => void;
};

const AdminCatalogCourseHeader: FC<CourseHeaderProps> = ({ course, categoryTree, backLinkCb }) => {
  const { t } = useApplyTranslations();

  const { isCourseHeaderSticky } = useUnitStore();

  const {
    name,
    level,
    category,
    code,
    cover_image,
    intro_video,
    mapped_course_id,
    starts_at,
    rules: { prerequisites },
  } = course;
  const { categoryText, allCategoryParent } = getCourseCategoryText({ category, categoryTree });
  const showAdditionalInfo = !isCourseHeaderSticky;

  const isCourseAcquired = Boolean(mapped_course_id);

  return (
    <>
      <div
        css={(theme): SerializedStyles =>
          courseHeaderStyles(theme, { isSticky: isCourseHeaderSticky })
        }
      >
        {backLinkCb && (
          <Button
            noGutters
            color="primaryLight"
            className="back-button"
            aria-label={t("a11y.courses.courseBackButton")}
            onClick={backLinkCb}
          >
            <CourseExitSVG height={32} />
          </Button>
        )}

        <div className="start-container">
          <TitleContainer
            category={category}
            categoryText={categoryText}
            allCategoryParent={allCategoryParent}
            name={name}
            code={code}
          />

          {showAdditionalInfo && (
            <div className="additional-info-container">
              {isCourseAcquired && (
                <InfoTag className="course-acquired-tag">
                  <AssignmentPassedAnswer height={16} />
                  {t("courseCatalog.youHaveCourse")}
                </InfoTag>
              )}
              <Restrictions
                level={level ?? 0}
                isLevelRestricted={false}
                prerequisites={prerequisites}
                startsAt={starts_at}
                userAccessExpiresAt={null}
              />
              <Rating course={course} />
            </div>
          )}
        </div>

        <div className="end-container">
          <CoverImage coverImage={cover_image} courseName={name} />
          <IntoVideo introVideo={intro_video} name={name} />
        </div>
      </div>
    </>
  );
};

export default AdminCatalogCourseHeader;
