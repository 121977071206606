import Color from "color";
import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const carousel = ({ carousel }, { isRtl }) => css`
  width: 100%;
  height: 100%;

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
      display: flex;

      > div {
        display: flex;
        width: 100%;
      }
    }
  }

  .slick-list {
    padding-bottom: 1rem;

    ${mq["sm"]} {
      padding-bottom: 2rem;
    }

    .slick-slide {
      text-align: start;
      direction: ${isRtl ? "rtl" : "ltr"};
    }
  }

  .slick-dots {
    position: relative;
    bottom: 0;

    li {
      width: 0.75rem;
      height: 0.75rem;
      opacity: 1;
      background-color: ${Color(carousel.dot.inactiveBackground).alpha(0.6).string()};
      margin: 0 0.5rem;
      border-radius: 50%;

      button {
        opacity: 0;
      }

      &.slick-active {
        background-color: ${carousel.dot.activeBackground};
      }
    }
  }
`;
