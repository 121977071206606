import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@epignosis_llc/gnosis";
import classNames from "classnames";
import { publicHeaderContainer } from "./styles";
import { BrandLogoSVG } from "@assets/images";
import { URLS } from "@constants/urls";
import useConfigurationStore from "@stores/configuration";
import { useApplyTranslations } from "@hooks";
import { useScrollPosition } from "@hooks/useScrollPosition";

type PublicHeaderProps = {
  hideExternalCatalog?: boolean;
  transparent?: boolean;
  sticky?: boolean;
  isLogoLinkDisabled?: boolean;
};

const PublicHeader: FC<PublicHeaderProps> = ({
  hideExternalCatalog = false,
  transparent = false,
  sticky = false,
  isLogoLinkDisabled = false,
}) => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();
  const logo = domainSettings?.logo?.default ?? null;
  const isExternalCatalogEnabled = !!domainSettings?.external_catalog && !hideExternalCatalog;

  const navigate = useNavigate();
  const scrollPosition = useScrollPosition();
  const isStickyHeader = sticky && scrollPosition > 200;

  const headerClasses = classNames({
    "show-catalog": isExternalCatalogEnabled,
    transparent: transparent,
    "sticky-header": sticky,
    "sticky-header white": isStickyHeader,
  });

  const logoClasses = classNames("logo", {
    "cursor-default": isLogoLinkDisabled,
  });

  const handleLogoClick = (): void => {
    if (!isLogoLinkDisabled) {
      navigate(URLS.root);
    }
  };

  return (
    <header css={publicHeaderContainer} className={headerClasses}>
      <div className={logoClasses} onClick={handleLogoClick}>
        {logo ? <img src={logo} alt="logo" /> : <BrandLogoSVG height={28} />}
      </div>

      {isExternalCatalogEnabled && (
        <Button variant="link" as={Link} to={URLS.catalog.index}>
          {t("courseCatalog.browseCourseCatalog")}
        </Button>
      )}
    </header>
  );
};

export default PublicHeader;
