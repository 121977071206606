import { useEffect } from "react";

// Reset location state on page unload
const useResetLocationState = (): void => {
  const resetLocation = (): void => {
    window.history.replaceState({}, "");
  };

  useEffect(() => {
    window.addEventListener("beforeunload", resetLocation);

    return () => {
      window.removeEventListener("beforeunload", resetLocation);
    };
  }, []);
};

export default useResetLocationState;
