import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { LoginSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

export type LoginActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  disabled?: boolean;
  onClick: (e: MouseEvent) => void;
};

const LoginAction: FC<LoginActionProps> = ({
  tooltipContent,
  icon: Icon = LoginSVG,
  disabled,
  onClick,
  ...rest
}) => {
  const { t } = useApplyTranslations();

  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("users.tooltip.loginAs")}
      onClick={onClick}
      data-testid="login-action"
      aria-label={t("users.tooltip.loginAs")}
      disabled={disabled}
      {...rest}
    >
      <Icon height={30} />
    </ActionButton>
  );
};

export default LoginAction;
