import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card } from "@epignosis_llc/gnosis";

import CardBody from "../components/CardBody";
import CardFooter from "../components/CardFooter";
import CardHeader from "../components/CardHeader";
import MyCourseCardFooter from "./components/MyCourseCardFooter";

import { bodyWrapper } from "../styles";

import userRoles from "@constants/userRoles";
import { URLS } from "@constants/urls";

import permissions from "@utils/permissions";
import { getCatalogCourseOverviewLink } from "@utils/helpers";
import authService from "@utils/services/AuthService";

import { Course } from "types/entities";
import { useConfigurationStore } from "@stores";
import { useApplyTranslations, useAuth } from "@hooks";
import { getCourseCardHoverInfo } from "../helpers";
import { courseCardWrapperStyles } from "./components/styles";

const { Header, Body } = Card;

export type CourseCardProps = {
  course: Course;
  isCustomHomePageView?: boolean;
};

const CourseCard: FC<CourseCardProps> = ({ course, isCustomHomePageView = false }) => {
  const { t } = useApplyTranslations();
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const { domainSettings } = useConfigurationStore();
  const { is_hidden_from_catalog } = course;
  const userRole = authService.getDefaultRole();
  const isLearner = userRole === userRoles.LEARNER;
  // Permissions related
  const { canAccessCourseUnits } = permissions.coursesPermissions;
  const isAvailableInCatalog = !is_hidden_from_catalog && domainSettings?.external_catalog;
  const allowCourseUnitsView =
    canAccessCourseUnits() ||
    (isCustomHomePageView && isAvailableInCatalog && !is_hidden_from_catalog);
  const showCourseInfoPage =
    Boolean(domainSettings?.show_course_info_page && allowCourseUnitsView) || isCustomHomePageView;
  // This code seems shady. TODO: rethink this
  const isDisabled = isCustomHomePageView && is_hidden_from_catalog;
  const unitContinueLink = URLS.user.createUnitLink({
    courseId: course.id.toString(),
    unitId: "continue",
  });
  const routeState = {
    isCustomHomePage: isCustomHomePageView,
    pathname: pathname ?? "",
  };

  // Custom home page should point to different routes based on role and authentication:
  // Administrator: Admin course overview
  // Instructor: Admin course overview
  // Learner: Catalog course overview
  // Public: External-catalog course overview
  const customHomePageLink =
    isLearner || !isAuthenticated
      ? getCatalogCourseOverviewLink({
          courseId: course.id.toString(),
          isPublic: !isAuthenticated,
          isAvailableInCatalog: Boolean(isAvailableInCatalog),
        })
      : URLS.catalog.adminCourseOverviewLink({ courseId: course.id.toString() });

  const courseLink = isCustomHomePageView
    ? customHomePageLink
    : showCourseInfoPage || !course.availability?.is_available
      ? URLS.user.createCourseLink({ courseId: course.id.toString() })
      : unitContinueLink;

  const { progress, availability } = course;
  const isCompleted =
    progress?.completion_status === "completed" || progress?.completion_status === "failed";
  const isCourseAvailable = Boolean(availability?.is_available);
  const isNotStarted = progress?.completion_status === "not_attempted";

  const { text: hoverText, icon: hoverIcon } = getCourseCardHoverInfo(
    isCourseAvailable,
    isCompleted,
    isNotStarted,
  );

  return (
    <Link
      to={allowCourseUnitsView ? courseLink : "#"}
      state={routeState}
      css={courseCardWrapperStyles}
    >
      <Card className="card-container" hasBorder>
        <Header ratio={[3, 2]}>
          <CardHeader
            course={course}
            actions={[]}
            routeState={routeState}
            allowCourseUnitsView={allowCourseUnitsView}
            courseLink={courseLink}
            icon={hoverIcon}
            isDisabled={isDisabled}
            hoverText={t(hoverText)}
          />
        </Header>
        <div
          css={bodyWrapper}
          className={!allowCourseUnitsView || isDisabled ? "disabled" : ""}
          data-testid="card-body"
        >
          <Body>
            <CardBody course={course} className="body-container" />
          </Body>

          <CardFooter>
            <MyCourseCardFooter course={course} />
          </CardFooter>
        </div>
      </Card>
    </Link>
  );
};

export default CourseCard;
