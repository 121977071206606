import React, { FC } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { LockIconSVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";
import { ActionDrawerProps } from "@components/Drawers/ActionDrawer";
import { paymentFooterStyles } from "./styles";

export type PaymentFooterProps = {
  redirectInfoText?: string;
  actionButtonInfo: ActionDrawerProps["actionButton"];
  onClose: () => void;
  onApply: () => void;
};

const PaymentFooter: FC<PaymentFooterProps> = ({
  actionButtonInfo,
  redirectInfoText = "",
  onClose,
  onApply,
}) => {
  const { t } = useApplyTranslations();

  return (
    <div css={paymentFooterStyles}>
      <div className="plan-info">
        {/* If we have payment with stripe or paypal, show the redirection information */}
        {redirectInfoText.length > 0 && (
          <div className="redirection-info">
            <LockIconSVG height={32} />
            <Text fontSize="sm">{redirectInfoText}</Text>
          </div>
        )}
      </div>
      <div className="footer-btns">
        <Button
          type="submit"
          onClick={onApply}
          isLoading={actionButtonInfo?.isLoading}
          disabled={actionButtonInfo?.isDisabled}
        >
          {actionButtonInfo?.text}
        </Button>
        <Button onClick={onClose} color="secondary">
          {t("general.cancel")}
        </Button>
      </div>
    </div>
  );
};

export default PaymentFooter;
