// Packages or third-party libraries
import React, { FC, useEffect, useState } from "react";
import { Button, Tabs, Tooltip } from "@epignosis_llc/gnosis";
import { useIsMutating, useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { courseOptionsDrawerFooterStyles } from "./styles";

// Components
import { ActionDrawer, ConfirmationModal } from "@components";
import InfoTab from "./Tabs/InfoTab/InfoTab";
import AvailabilityTab from "./Tabs/AvailabilityTab/AvailabilityTab";
import LimitsTab from "./Tabs/LimitsTab/LimitsTab";
import CompletionTab from "./Tabs/CompletionTab/CompletionTab";

// Utils, hooks
import { generalNotification } from "@utils/helpers";
import { CourseRulesOptionsSchemas } from "@utils/validation";
import {
  getDefaultValues,
  getRulesDefaultValues,
  getValidationSchema,
  mapFormDataToSubmitData,
  mapRulesFormDataToSubmitData,
} from "./helpers";
import { useApplyTranslations } from "@hooks";
import { useEditCourseDetails } from "@views/CourseEdit/hooks";
import { useInvalidTabsErrorMessage } from "./hooks";
import { useUIStore } from "@stores";
import { handleCourseErrors, HandledError } from "@errors";
import { handleUploadFilesErrors } from "@errors/errors";

// Other imports
import { deleteCourseIntroVideo, postCourseIntroVideo } from "@api/courses";
import { Course, Section } from "types/entities";
import { CustomField, TabObject } from "types/entities/Common";
import { CourseCustomFieldWithValue, TabKey } from "./types";
import { EditCourseData, putCourseRules } from "@views/CourseEdit/api";
import { CourseRulesEditData, CourseRulesFormData } from "@views/CourseEdit/types";
import queryKeys from "@constants/queryKeys";
import { URLS } from "@constants/urls";

type CourseOptionsDrawerProps = {
  course: Course;
  sections: Section[];
  customFields?: CustomField[];
  isOptionsDrawerOpen: boolean;
  shouldTriggerValidation: boolean;
  onOptionsDrawerClose: () => void;
};

const CourseOptionsDrawer: FC<CourseOptionsDrawerProps> = ({
  course,
  sections,
  customFields = [],
  isOptionsDrawerOpen,
  shouldTriggerValidation,
  onOptionsDrawerClose,
}) => {
  const { t } = useApplyTranslations();
  const queryClient = useQueryClient();
  const { courseOptionsState } = useUIStore();
  const navigate = useNavigate();
  const courseShareIsMutating = useIsMutating({ mutationKey: [queryKeys.courses.share] });

  const { activeTab: courseOptionsActiveTab, scrollId } = courseOptionsState;
  const { id, policies } = course;
  const { can_view_rules = false, can_update_rules = false } = policies ?? {};
  const courseId = id.toString();
  const canUpdateRules = can_view_rules && can_update_rules;

  const [videoPreviewFile, setVideoPreviewFile] = useState<File | null>(null);
  const [shouldDeleteVideo, setShouldDeleteVideo] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const customFieldsWithValues: CourseCustomFieldWithValue[] | undefined = customFields?.map(
    (customField) => {
      const value = course?.custom_fields?.find(({ id }) => id === customField.id)?.value ?? "";
      return { ...customField, value };
    },
  );

  const defaultValues = getDefaultValues(course, customFields);
  const validationSchema = getValidationSchema(customFieldsWithValues);
  const form = useForm<EditCourseData>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const {
    formState: { isValid, errors },
    trigger,
  } = form;

  const rulesDefaultValues = getRulesDefaultValues(course);
  const rulesForm = useForm<CourseRulesFormData>({
    mode: "onChange",
    resolver: yupResolver(CourseRulesOptionsSchemas),
    defaultValues: rulesDefaultValues,
  });

  const {
    formState: { isValid: isRulesValid, errors: rulesErrors },
  } = rulesForm;

  const { tabsWithErrors, errorMessage } = useInvalidTabsErrorMessage({ errors, rulesErrors });
  const isFormValid = isValid && isRulesValid;

  const getTabsToShow = (): TabObject[] => {
    return [
      {
        title: t("general.info"),
        key: TabKey.Info,
        id: "info-tab",
        content: (
          <InfoTab
            course={course}
            form={form}
            customFieldsWithValues={customFieldsWithValues}
            videoPreviewFile={videoPreviewFile}
            handleFileChanged={setVideoPreviewFile}
            setShouldDeleteVideo={setShouldDeleteVideo}
          />
        ),
      },
      {
        title: t("general.availability"),
        key: TabKey.Availability,
        id: "availability-tab",
        content: <AvailabilityTab course={course} form={form} />,
      },
      {
        title: t("general.limits"),
        key: TabKey.Limits,
        id: "limits-tab",
        content: <LimitsTab course={course} form={form} rulesForm={rulesForm} />,
      },
      {
        title: t("general.completion"),
        key: TabKey.Completion,
        id: "completion-tab",
        content: (
          <CompletionTab course={course} form={form} rulesForm={rulesForm} sections={sections} />
        ),
      },
    ];
  };
  const tabsToShow = getTabsToShow();
  const startingTabIndex = tabsToShow.findIndex((tab) => tab.key === courseOptionsActiveTab);
  const [activeTab, setActiveTab] = useState(Math.max(0, startingTabIndex));

  const { mutateAsync: editCourseDetailsAsyncMutation, isLoading: isLoadingEditCourseDetails } =
    useEditCourseDetails({
      courseId,
      shouldTrackMarketo: true,
      options: {
        onError: (err) => {
          const error = err as AxiosError;
          const handleError = (): void => {
            navigate(URLS.courses.courses);
          };

          handleCourseErrors(error, false, handleError);
        },
      },
    });

  const { mutateAsync: postIntroVideoMutation, isLoading: isLoadingIntroVideo } = useMutation(
    [queryKeys.courses.postFile, courseId],
    (file: File) => postCourseIntroVideo({ courseId, file }),
    {
      onError: (error: AxiosError) => {
        const handleError = (error: HandledError | null): void => {
          if (error?.id === "not_found.course_not_found") {
            navigate(URLS.courses.courses);
          }
        };
        handleUploadFilesErrors(error, false, handleError);
      },
    },
  );

  const { mutateAsync: deleteIntroVideoMutation } = useMutation(
    [queryKeys.courses.deleteIntroVideo, courseId],
    () => deleteCourseIntroVideo({ courseId }),
    {
      onError: (error: AxiosError) => {
        const handleError = (error: HandledError | null): void => {
          if (error?.id === "not_found.course_not_found") {
            navigate(URLS.courses.courses);
          }
        };
        handleUploadFilesErrors(error, false, handleError);
      },
    },
  );

  const { mutateAsync: editCourseRulesMutation, isLoading: isLoadingEditCourseRules } = useMutation(
    [queryKeys.courses.rulesEdit],
    (data: CourseRulesEditData) => putCourseRules(courseId, data),
    {
      onError: (err) => {
        const error = err as AxiosError;
        const handleError = (): void => {
          navigate(URLS.courses.courses);
        };

        handleCourseErrors(error, false, handleError);
      },
    },
  );

  const handleApply = (): void => {
    const data = form.getValues();

    if (course.is_prerequisite && !data.is_active) {
      setIsModalOpen(true);
      return;
    }

    handleSave();
  };

  const handleSave = async (): Promise<void> => {
    await handleDataSave();
    if (canUpdateRules) await handleRulesDataSave();
    handleSuccess();
  };

  const handleDataSave = async (): Promise<void> => {
    const data = form.getValues();
    const isValid = await validationSchema.isValid(data);

    if (!isValid) return;

    const submitData = mapFormDataToSubmitData(data, customFields);

    if (videoPreviewFile) await postIntroVideoMutation(videoPreviewFile);
    if (shouldDeleteVideo) await deleteIntroVideoMutation();
    await editCourseDetailsAsyncMutation(submitData);
  };

  const handleRulesDataSave = async (): Promise<void> => {
    const data = rulesForm.getValues();
    const isValid = await CourseRulesOptionsSchemas.isValid(data);

    if (!isValid) return;

    const submitData = mapRulesFormDataToSubmitData(data);
    await editCourseRulesMutation(submitData);
  };

  const handleModalConfirm = async (): Promise<void> => {
    handleSave();
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };

  const handleSuccess = (): void => {
    queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
    // Invalidate the users/me endpoint to refetch the can_create_course policies, in order to show/disable/hide the "Add course" button
    queryClient.invalidateQueries([queryKeys.userProfile]);
    generalNotification("success", <p>{t("courseEdit.courseUpdatedSuccessfully")}</p>);
    onOptionsDrawerClose();
  };

  useEffect(() => {
    if (shouldTriggerValidation) trigger();
  }, [shouldTriggerValidation, trigger]);

  // Trigger validation on initial render
  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (!scrollId) return;

    const element = document.getElementById(scrollId);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [isOptionsDrawerOpen, scrollId]);

  useEffect(() => {
    const newDefaultValues = getDefaultValues(course, customFields);
    form.reset(newDefaultValues);
  }, [course, customFields, form]);

  const isSaving = isLoadingEditCourseDetails || isLoadingIntroVideo || isLoadingEditCourseRules;
  const isSaveTooltipDisabled = isFormValid || tabsWithErrors.length === 0;

  return (
    <>
      <ActionDrawer
        onClose={onOptionsDrawerClose}
        headerTitle={t("courseEdit.courseOptions")}
        isOpen={isOptionsDrawerOpen}
        size="md"
        customFooter={
          <div css={courseOptionsDrawerFooterStyles}>
            <Tooltip content={errorMessage} disabled={isSaveTooltipDisabled}>
              <Button
                onClick={handleApply}
                disabled={!isFormValid || !!courseShareIsMutating}
                isLoading={isSaving}
                data-testid="drawer-save"
              >
                {t("general.save")}
              </Button>
            </Tooltip>
            <Button color="secondary" onClick={onOptionsDrawerClose} data-testid="drawer-cancel">
              {t("general.cancel")}
            </Button>
          </div>
        }
      >
        {tabsToShow.length > 0 && (
          <Tabs
            tabs={tabsToShow}
            selectedTab={activeTab}
            onChangeTab={(index): void => setActiveTab(index)}
            data-testid="course-options-drawer-body"
          />
        )}
      </ActionDrawer>

      {isModalOpen && (
        <ConfirmationModal
          id="deactivate-confirmation-modal"
          header={t("courses.courseDeactivation")}
          bodyTitle={
            <div
              dangerouslySetInnerHTML={{
                __html: t("courses.confirmCourseDeactivation", {
                  title: course.name,
                }),
              }}
            />
          }
          footerButton={t("courseEdit.deactivate")}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onConfirm={handleModalConfirm}
          confirmationButtonColor="primary"
        />
      )}
    </>
  );
};

export default CourseOptionsDrawer;
