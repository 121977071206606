// Packages or third-party libraries
import React, { FC, ReactElement } from "react";
import { useQuery } from "react-query";
import { Input, InputError, Select, ToggleSwitch } from "@epignosis_llc/gnosis";
import {
  BarcodeSVG,
  CategoriesSVG,
  SmartTagSVG,
  UnitVideoSVG,
  BillingSVG,
  IconCheckedWithViewboxSVG,
  LockIconSVG,
} from "@epignosis_llc/gnosis/icons";
import { Controller } from "react-hook-form";

// Components
import TabWrapper from "../../components/TabWrapper";
import TabFormField from "../../components/TabFormField";
import IntroVideoOptions from "./components/IntroVideoOptions";
import CustomFieldsOptions from "./components/CustomFieldsOptions";

// Utils, hooks
import { getCurrencySymbol } from "@utils/helpers/currency";
import { createCategoriesWithLevels } from "@views/Categories/helpers";
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";

// Other imports
import { getCategories } from "@api";
import { CreatableSelectOption } from "types/common";
import { TabProps } from "../../types";
import queryKeys from "@constants/queryKeys";
import { courseOptionsIds } from "../../constants";

const InfoTab: FC<TabProps> = ({
  course,
  customFieldsWithValues = [],
  form,
  videoPreviewFile = null,
  handleFileChanged,
  setShouldDeleteVideo,
}) => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();

  const { currency = null } = domainSettings ?? {};
  const { policies } = course ?? {};
  const {
    can_create_category: canCreateCategory = false,
    can_update_price: canUpdatePrice = false,
    can_update_locked: canUpdateLocked = false,
  } = policies ?? {};

  const {
    register,
    control,
    formState: { errors },
  } = form;

  const { data: categoriesTree } = useQuery(
    [queryKeys.categories.categories],
    () => getCategories(false, ""),
    {
      select: (categories) => ({
        data: categories._data,
        pagination: categories._meta?.pagination,
      }),
    },
  );

  const categoriesWithLevels =
    categoriesTree && categoriesTree.data && createCategoriesWithLevels(categoriesTree.data);

  if (!course) return null;

  return (
    <TabWrapper>
      <TabFormField
        text={t("general.code")}
        icon={BarcodeSVG}
        note={t("courseEdit.options.codeNote")}
        id={courseOptionsIds.codeOptions}
      >
        <>
          <Input
            id="catalog-code"
            type="text"
            placeholder={t("general.insertCode")}
            aria-label={t("general.code")}
            {...register("code")}
            maxLength={20}
          />
          {errors.code && <InputError className="error-msg">{errors.code.message}</InputError>}
        </>
      </TabFormField>

      <TabFormField
        text={t("general.category")}
        icon={CategoriesSVG}
        note={t("courseEdit.options.categoryNote")}
        id={courseOptionsIds.categoryOptions}
      >
        <>
          <Controller
            name="category"
            control={control}
            render={({ field: { value: category, onChange } }): ReactElement => {
              const selectedCategory = categoriesWithLevels?.find(({ label, value }) => {
                const matchByLabel = label.toString() === category?.name?.toString();
                const matchByValue = value.toString() === category?.id?.toString();
                return matchByLabel || matchByValue;
              });

              return (
                <Select
                  type={canCreateCategory ? "creatable" : "select"}
                  isClearable={true}
                  id="catalog-category"
                  aria-label={t("general.category")}
                  placeholder={t("general.selectCategories", { count: 1 })}
                  value={selectedCategory}
                  options={categoriesWithLevels}
                  isSearchable
                  noOptionsMessage={(): string => t("general.noOptions")}
                  onChange={(option): void => {
                    const { label, value, __isNew__ } = (option as CreatableSelectOption) ?? {};

                    const cat = value
                      ? {
                          name: !__isNew__ ? null : label,
                          id: !__isNew__ ? value : null,
                        }
                      : null;

                    onChange(cat);
                  }}
                />
              );
            }}
          />
          {errors.category?.name && (
            <InputError className="error-msg">{errors.category.name.message}</InputError>
          )}
        </>
      </TabFormField>

      {customFieldsWithValues.length > 0 && (
        <TabFormField
          text={t("courseEdit.customFields")}
          icon={SmartTagSVG}
          note={t("courseEdit.options.customFieldsNote")}
          id={courseOptionsIds.customFieldsOptions}
        >
          <CustomFieldsOptions customFields={customFieldsWithValues} form={form} />
        </TabFormField>
      )}

      <TabFormField
        text={t("general.introVideo")}
        icon={UnitVideoSVG}
        colSpan={2}
        id={courseOptionsIds.introVideoOptions}
      >
        <IntroVideoOptions
          videoPreviewFile={videoPreviewFile}
          course={course}
          form={form}
          handleFileChanged={(file): void => handleFileChanged?.(file)}
          setShouldDeleteVideo={(shouldDelete): void => setShouldDeleteVideo?.(shouldDelete)}
        />
      </TabFormField>

      {canUpdatePrice && (
        <TabFormField
          text={t("general.price")}
          icon={BillingSVG}
          note={t("courseEdit.options.priceNote")}
          id={courseOptionsIds.priceOptions}
        >
          <>
            <Controller
              name="price"
              control={control}
              render={({ field: { onChange, value } }): ReactElement => {
                return (
                  <Input
                    id="course-price"
                    type="number"
                    size="md"
                    aria-label={t("general.price")}
                    placeholder={t("general.setPrice")}
                    iconAfter={getCurrencySymbol(currency)}
                    value={value ?? ""}
                    onChange={(e): void => {
                      const price = e.target.value;
                      onChange(price ? price : null);
                    }}
                  />
                );
              }}
            />
            {errors.price && <InputError className="error-msg">{errors.price.message}</InputError>}
          </>
        </TabFormField>
      )}

      <TabFormField
        text={t("courseEdit.activationStatus")}
        icon={IconCheckedWithViewboxSVG}
        note={t("courseEdit.options.activateNote")}
        hasDivider={canUpdateLocked}
      >
        <Controller
          name="is_active"
          control={control}
          render={({ field: { onChange, value } }): JSX.Element => {
            return (
              <ToggleSwitch
                data-testid="active-course"
                labelAfter={t("courseEdit.activate")}
                defaultChecked={value}
                onChange={(): void => onChange(!value)}
              />
            );
          }}
        />
      </TabFormField>

      {canUpdateLocked && (
        <TabFormField
          text={t("courseEdit.contentLock")}
          icon={LockIconSVG}
          note={t("courseEdit.options.lockNote")}
          hasDivider={false}
          id={courseOptionsIds.lockedOptions}
        >
          <Controller
            name="is_locked"
            control={control}
            render={({ field: { onChange, value } }): JSX.Element => {
              return (
                <ToggleSwitch
                  data-testid="active-course"
                  labelAfter={t("courseEdit.lockContent")}
                  defaultChecked={value}
                  onChange={(): void => onChange(!value)}
                />
              );
            }}
          />
        </TabFormField>
      )}
    </TabWrapper>
  );
};

export default InfoTab;
