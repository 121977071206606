import { AxiosError } from "axios";
import { HandledError, handledApiError } from "./errors";
import { t } from "@utils/i18n";

// Error messages

export const courseErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.course_not_available",
    errorMsg: "", // We dont show a msg on this error
  },
  {
    status: 400,
    id: "bad_request.completion_date_before_enrollment_date",
    errorMsg: "errors.courses.completionDateBeforeEnrollmentDate",
  },
  {
    status: 400,
    id: "bad_request.enrollment_date_in_future",
    errorMsg: "errors.courses.enrollmentDateInFuture",
  },
];

export const courseUnenrollErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 409,
    id: "conflict.user_not_enrolled",
    errorMsg: "errors.unenrollment.userNotEnrolled",
  },
];

export const publishCourseErrors = [
  ...courseErrors,
  {
    status: 409,
    id: "conflict.mandatory_custom_fields_have_not_been_filled",
    errorMsg: "errors.courses.mandatoryFieldsEmpty",
  },
];

export const courseCopyFromDomainErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.invalid_credentials",
    errorMsg: "errors.courses.courseCopyFromDomain.invalidCredentials",
  },
  {
    status: 400,
    id: "bad_request.invalid_domain",
    errorMsg: "errors.courses.courseCopyFromDomain.invalidDomain",
  },
  {
    status: 400,
    id: "bad_request.invalid_token",
    errorMsg: "errors.courses.courseCopyFromDomain.invalidToken",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.course_category_not_found",
    errorMsg: "errors.courses.courseCopyFromDomain.categoryNotFound",
  },
  {
    status: 404,
    id: "not_found.branch_not_found",
    errorMsg: "branches.errors.branchNotFound",
  },
];

// Error handlers

export const handleCourseErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    courseErrors,
    hideNotification,
    t("errors.units.courseNotAvailable"),
    handleError,
  );
};

export const handleUnEnrollmentErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, courseUnenrollErrors, false, "", handleError);
};

export const handlePublishErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    publishCourseErrors,
    false,
    t("errors.units.courseNotAvailable"),
    handleError,
  );
};

export const handleCourseCopyFromDomainErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    courseCopyFromDomainErrors,
    handleError ? true : false,
    t("errors.courses.courseCopyFromDomain.courseCopyError"),
    handleError,
  );
};
