import React, { ChangeEvent, FC, HTMLProps, useLayoutEffect, useRef } from "react";
import { Label } from "@epignosis_llc/gnosis";
import { SerializedStyles, useTheme } from "@emotion/react";
import { RangeInputStyles } from "./styles";
import { i18n } from "@utils/i18n";

type RangeInputProps = HTMLProps<HTMLInputElement> & {
  id: string;
  name: string;
  min: number;
  max: number;
  value: number;
  step?: number;
  label?: string;
  onChange?: (value: number) => void;
};

const THUMB_WIDTH = 16;

const RangeInput: FC<RangeInputProps> = ({
  id,
  name,
  min,
  max,
  value,
  step = 1,
  label,
  onChange,
  children,
  disabled,
  ...rest
}) => {
  const rangeRef = useRef<HTMLInputElement>(null);
  const rangeValueRef = useRef<HTMLDivElement>(null);
  const { rangeInput } = useTheme();
  const isRtl = i18n.dir() === "rtl";
  const gradientPosition = isRtl ? "to left" : "to right";

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = Number(e.target.value);
    onChange && onChange(newValue);
  };

  const setRangeValue = (): void => {
    if (rangeRef.current && rangeValueRef.current) {
      const range = rangeRef.current;
      const rangeValue = rangeValueRef.current;
      const rangeValueWidth = rangeValue.getBoundingClientRect().width;
      const newThumbPosition = Number(((Number(range.value) - min) * 100) / (max - min));
      const newRangeValuePosition = THUMB_WIDTH / 2 - newThumbPosition * 0.15 - rangeValueWidth / 2;
      const color = disabled ? rangeInput.disabled : rangeInput.color;

      // Apply linear-gradient as background color to style differently the range parts
      // Before and after the thumb
      range.style.background = `linear-gradient(${gradientPosition}, ${color} ${newThumbPosition}%, ${rangeInput.backgroundColor} ${newThumbPosition}%)`;

      // Style to position range value
      rangeValue.style.insetInlineStart = `calc(${newThumbPosition}% + (${newRangeValuePosition}px))`;
    }
  };

  // Add listener to position range value every time the range input changes
  useLayoutEffect(() => {
    const element = rangeRef.current;
    element?.addEventListener("input", setRangeValue);
    setRangeValue();

    return () => element?.removeEventListener("input", setRangeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div css={(theme): SerializedStyles => RangeInputStyles(theme, { disabled })}>
      <div className="range-container">
        <input
          type="range"
          id={id}
          name={name}
          min={min}
          max={max}
          step={step}
          value={value}
          ref={rangeRef}
          disabled={disabled}
          onChange={handleChange}
          {...rest}
        />

        <div className="range-value" ref={rangeValueRef}>
          {value}
        </div>
      </div>

      <div className="label-container" data-testid={`${id}-label`}>
        {label && (
          <Label className="label" htmlFor={name}>
            {label}
          </Label>
        )}
        {children}
      </div>
    </div>
  );
};

export default RangeInput;
