// Packages or third-party libraries
import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { SerializedStyles } from "@emotion/react";
import { Button } from "@epignosis_llc/gnosis";
import { TrashAltSVG, UploadCloudIconSVG } from "@epignosis_llc/gnosis/icons";

// Styles
import {
  AIActivityIndicatorStyles,
  GenerateImageButtonStyles,
  ImageSelectorContainerStyles,
} from "./styles";

// Components
import { ConfirmationModal, ImageModal } from "@components";

// Utils, hooks
import { buildImageFile, convertBytesToSizeUnit, generalNotification } from "@utils/helpers";
import { useApplyTranslations } from "@hooks";
import { useConfigurationStore } from "@stores";

// Other imports
import { images } from "@constants/assets";
import { MimeType } from "types/entities";
import { ImageSelectorContainerProps } from "./types";
import { GenerateImageButton } from "@components/AI/GenerateImageButton/GenerateImageButton";
import { AIActivityIndicator } from "@components/AI/AIActivityIndicator/AIActivityIndicator";

const ImageSelectorContainer: FC<ImageSelectorContainerProps> = ({
  image,
  uploadLimitations,
  modalWordings,
  imageCropRatio = 1,
  imageFit = true,
  hideDeleteButton = false,
  hasImagePreview = false,
  isCropperResizable = true,
  actionButtonsInline = false,
  croppedImageDimensions,
  onDelete,
  onSave,
  aiImageProps,
}) => {
  const { t } = useApplyTranslations();
  const { domainSettings } = useConfigurationStore();
  /**
   * Except for @param imageSelectorTitle which you pass only the path for the translation,
   * all other modalWordings require to be passed as translations
   * This happens because you can also pass JSX elements as values.
   */
  const {
    imageSelectorTitle,
    confirmationModalHeader = t("general.deleteImage"),
    confirmationModalBodyTitle = t("accountAndSettings.courseSettings.deleteImageConfirm"),
    confirmationModalBodyText = t("general.actionCantBeUndone"),
    confirmationModalFooterButton = t("general.delete"),
  } = modalWordings;

  const canGenerateImageWithAI = aiImageProps?.enabled ?? false;
  const isGeneratingAIImage = aiImageProps?.isGenerating ?? false;

  const [currentImageFile, setCurrentImageFile] = useState<File | null>(null);
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(image);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const imagePreviewClass = imageFit ? "image-preview-fit" : "image-preview-center";

  const { mime_types: mimeType, size_limit: sizeLimit } =
    domainSettings?.upload_limits[uploadLimitations] || {};
  const acceptedImageTypes = mimeType ?? ["image/gif", "image/jpeg", "image/png"];
  const maxImageSize = sizeLimit ? convertBytesToSizeUnit(sizeLimit, "MB") : 10;
  const areButtonsInline = actionButtonsInline || canGenerateImageWithAI;

  useEffect(() => {
    setCurrentImage(image);
  }, [image]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const imageFile = e.target.files?.[0];

    if (!imageFile) {
      return;
    }

    if (!acceptedImageTypes.includes(imageFile.type as MimeType)) {
      generalNotification("error", <p>{t("notifications.fileTypeNotSupported")}</p>);
      return;
    }

    const imageSizeInMB = convertBytesToSizeUnit(imageFile.size, "MB");
    if (imageSizeInMB > maxImageSize) {
      generalNotification("error", <p>File size exceeds the “{maxImageSize} MB” limit</p>);
      return;
    }
    setCurrentImageFile(imageFile);
    setCurrentImageUrl(URL.createObjectURL(imageFile));
    setIsCropModalOpen(true);

    e.target.value = "";
  };

  const handleOnSave = (croppedCanvas: HTMLCanvasElement): void => {
    // Build image based on the cropped canvas size.
    const imageFile = buildImageFile(croppedCanvas, currentImageFile);

    onSave(imageFile);

    if (hasImagePreview) {
      setCurrentImage(
        URL.createObjectURL(new Blob([imageFile as File], { type: "application/zip" })),
      );
    }

    setIsCropModalOpen(false);
  };

  const handleCloseModal = (): void => {
    setCurrentImageUrl(image);
    setIsCropModalOpen(false);
  };

  return (
    <>
      <div
        css={(theme): SerializedStyles =>
          ImageSelectorContainerStyles(theme, {
            height: "180px",
            actionButtonsInline: areButtonsInline,
            isLoading: isGeneratingAIImage,
          })
        }
      >
        <div className="upload-overlay">
          {isGeneratingAIImage ? (
            <AIActivityIndicator
              overrideStyles={AIActivityIndicatorStyles()}
              text={t("ai.creatingImage")}
            />
          ) : (
            <>
              <Button
                rounded
                className="upload-icon action-button"
                onClick={(): void => fileInputRef.current?.click()}
              >
                <UploadCloudIconSVG height={32} />
              </Button>
              {canGenerateImageWithAI && (
                <GenerateImageButton
                  onClick={aiImageProps?.onGenerate}
                  overrideStyles={GenerateImageButtonStyles({
                    inline: areButtonsInline,
                    isLoading: isGeneratingAIImage,
                  })}
                />
              )}
              {currentImage && !hideDeleteButton && (
                <Button
                  rounded
                  className="delete-icon action-button"
                  onClick={(): void => {
                    setIsDeleteModalOpen(true);
                  }}
                >
                  <TrashAltSVG height={16} />
                </Button>
              )}
            </>
          )}
        </div>

        <img src={currentImage || images.cover} alt={"badge-image"} className={imagePreviewClass} />
        <input
          ref={fileInputRef}
          type="file"
          id="badge"
          className="input-field"
          onChange={handleOnChange}
        />
      </div>

      <ImageModal
        isOpen={isCropModalOpen}
        imageSrc={currentImageUrl}
        cropAspectRatio={imageCropRatio}
        resizable={isCropperResizable}
        croppedImageDimensions={croppedImageDimensions}
        imageSelectorTitle={imageSelectorTitle}
        onClose={handleCloseModal}
        onSave={handleOnSave}
      />

      <ConfirmationModal
        id="delete-image"
        header={confirmationModalHeader}
        bodyTitle={confirmationModalBodyTitle}
        bodyText={confirmationModalBodyText}
        footerButton={confirmationModalFooterButton}
        isOpen={isDeleteModalOpen}
        onClose={(): void => setIsDeleteModalOpen(false)}
        onConfirm={onDelete}
      />

      {aiImageProps?.renderJobPoller?.()}
    </>
  );
};

export default ImageSelectorContainer;
