import { css } from "@emotion/react";

export const selectCardStyles = ({ colors, selectCard }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  background: ${selectCard.backgroundColor};
  border: 1px solid ${selectCard.borderColor};
  cursor: pointer;
  padding: 0.75rem;
  text-align: center;
  border-radius: 5px;

  &:hover {
    background: ${selectCard.hoverBackgroundColor};
    box-shadow: 0 3px 6px 0 ${selectCard.boxShadowColor};
    border: 1px solid ${selectCard.hoverBackgroundColor};
  }

  &.ai-border {
    --border-angle: 0turn; // starting-position
    --primary-color: ${colors.white}; // background
    --border-gradient-color1: #0881f9; // border-blue
    --border-gradient-color2: #f56d34; // border-orange
    --main-bg: conic-gradient(from var(--border-angle), var(--primary-color), var(--primary-color));
    --gradient-border: conic-gradient(
      from var(--border-angle),
      var(--border-gradient-color1) 0%,
      var(--border-gradient-color2) 50%,
      var(--border-gradient-color1) 100%
    );
    border: solid 1px transparent;
    border-radius: 6px;

    background:
      var(--main-bg) padding-box,
      var(--gradient-border) border-box,
      var(--main-bg) border-box;
    animation: bg-spin 2.5s linear;
    animation-iteration-count: 1;

    &:hover {
      animation-play-state: running;

      --primary-color: ${selectCard.hoverBackgroundColor};

      border: solid 1px transparent;
      background:
        var(--main-bg) padding-box,
        var(--gradient-border) border-box,
        var(--main-bg) border-box;
    }

    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }

    @property --border-angle {
      syntax: "<angle>";
      inherits: true;
      initial-value: 0turn;
    }
  }
`;
