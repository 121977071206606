import { learnerURLS } from "@constants/urls";

export const getIsLearnerUrl = (url: string): boolean => {
  return learnerURLS.some((pattern) => {
    const regex = new RegExp(`^${pattern.replace(/:\w+/g, "[^/]+")}$`);

    return (
      learnerURLS.includes(url) ||
      learnerURLS.some(
        (pattern) =>
          url.startsWith(pattern) ||
          learnerURLS.some((pattern) => url.startsWith(`/plus${pattern}`)) ||
          regex.test(url),
      )
    );
  });
};
