// Packages or third-party libraries
import React, { FC, useEffect, useState } from "react";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { useQuery } from "react-query";

// Styles
import { EditorViewStyles } from "./styles";

// Components
import { Editor } from "@components";
import CourseFileUploadModal from "./CourseFileUploadModal";
import SmartTagsModal from "./SmartTagsModal";

// Utils, hooks
import { buildPaginatedSearchQuery } from "@utils/helpers";
import { builtContentForPreview, builtContentForSave } from "./helpers";
import { useApplyTranslations } from "@hooks";

// Other imports
import {
  AIEditorOptions,
  InsertCustomOptions,
  ToolbarButton,
} from "@components/FormElements/Editor/types";
import { Course } from "types/entities";
import { SelectOption } from "types/common";
import { getCourseFiles } from "@api/courses";
import { getUnitSmartTags } from "@views/CourseEdit/api";
import queryKeys from "@constants/queryKeys";
import {
  COURSE_FILES_DEFAULT_STATE,
  DEFAULT_SAVE_INTERVAL,
  COURSE_IMAGE_UPLOAD,
  COURSE_IMAGE_UPLOAD_CLOSE,
  SMART_TAG_INSERT,
  SMART_TAG_CLOSE,
  toolbarButtons,
} from "./constants";

type EditableContentProps = {
  course: Course;
  id: string;
  initialContent: string;
  viewOnlyContent?: string; // Used to show version history content
  placeholder?: string;
  canEdit: boolean;
  saveInterval?: number;
  activeToolbarButtons?: string[] | ToolbarButton;
  quickInsertEnabled?: boolean;
  onChange?: (newContent: string) => void;
  onContentSave?: (newContent: string) => void;
  aiEditorOptions?: AIEditorOptions;
  onAIStatusChanged?: (isWorking: boolean) => void;
};

const EditableContent: FC<EditableContentProps> = ({
  course,
  id,
  initialContent,
  viewOnlyContent,
  placeholder,
  canEdit,
  saveInterval = DEFAULT_SAVE_INTERVAL,
  quickInsertEnabled = true,
  onChange,
  onContentSave,
  aiEditorOptions,
  activeToolbarButtons = toolbarButtons(aiEditorOptions),
  onAIStatusChanged,
}) => {
  const { t } = useApplyTranslations();
  const { policies } = course;
  const {
    can_view_course_files: canViewCourseFiles = false,
    can_update_content: canUpdateContent = false,
  } = policies ?? {};
  const courseId = course.id.toString();
  const searchQuery = buildPaginatedSearchQuery(COURSE_FILES_DEFAULT_STATE);
  const editorPlaceholder = placeholder ?? t("unitEdit.addContent");
  const showEditorView = !canEdit || viewOnlyContent;

  const [isCourseFileUploadModalOpen, setCourseFileUploadModalOpen] = useState(false);
  const [isSmartTagsModalOpen, setSmartTagsModalOpen] = useState(false);

  const { data: filesRes } = useQuery(
    [queryKeys.courses.images, courseId],
    () => getCourseFiles(courseId, searchQuery),
    { enabled: canViewCourseFiles },
  );

  const courseImages = filesRes?._data ?? [];
  const insertImagesOptions: InsertCustomOptions = courseImages.reduce(
    (object, { url, name }) => ({ ...object, [url]: name }),
    {},
  );

  const { data: smartTags = [] } = useQuery([queryKeys.courses.unitSmartTags], getUnitSmartTags, {
    select: (smartTagsRes) => smartTagsRes._data ?? [],
    enabled: canUpdateContent,
  });

  const smartTagsSelectOptions: SelectOption[] = smartTags.map(({ value, label }) => ({
    value,
    label,
  }));
  const hasSmartTags = smartTagsSelectOptions.length > 0;

  const [previewContent, setPreviewContent] = useState<string>(() =>
    builtContentForPreview(initialContent, courseImages),
  );
  const [saveContent, setSaveContent] = useState<string>(initialContent);
  const [isCodeViewActive, setIsCodeViewActive] = useState(false);

  const handleContentSave = (): void => {
    onContentSave && onContentSave(saveContent);
  };

  const handleCodeViewChange = (content: string): void => {
    const newPreviewContent = builtContentForPreview(content, courseImages);
    setPreviewContent(newPreviewContent);
    setSaveContent(content);
    onChange && onChange(content);
  };

  const handleTextViewChange = (content: string): void => {
    setPreviewContent(content);
    const newSaveContent = builtContentForSave(content, courseImages);
    setSaveContent(newSaveContent);
    onChange && onChange(newSaveContent);
  };

  const handleImageUploadClick = (): void => {
    setCourseFileUploadModalOpen(true);
  };

  const handleInsertSmartTagClick = (): void => {
    setSmartTagsModalOpen(true);
  };

  // Set preview content on image change
  useEffect(() => {
    if (courseImages.length > 0) {
      setPreviewContent(builtContentForPreview(initialContent, courseImages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseImages]);

  // Only when viewOnlyContent is reset need to check if preview content should be changed
  useEffect(() => {
    if (!viewOnlyContent) {
      const newPreviewContent = builtContentForPreview(initialContent, courseImages);

      if (newPreviewContent !== previewContent) {
        setPreviewContent(newPreviewContent);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewOnlyContent]);

  // Do not render empty content when cannot edit
  if (!canEdit && !previewContent) return null;

  return (
    <section className="editable-content">
      {showEditorView ? (
        <div css={EditorViewStyles}>
          <FroalaEditorView
            model={
              viewOnlyContent
                ? builtContentForPreview(viewOnlyContent, courseImages)
                : previewContent
            }
          />
        </div>
      ) : (
        <>
          <Editor
            toolbarButtons={activeToolbarButtons}
            id={id}
            model={isCodeViewActive ? saveContent : previewContent}
            placeholderText={editorPlaceholder}
            toolbarInline
            minHeight={0}
            saveInterval={saveInterval}
            insertImagesOptions={insertImagesOptions}
            uploadImageSubscriber={COURSE_IMAGE_UPLOAD}
            uploadImageCloseSubscriber={COURSE_IMAGE_UPLOAD_CLOSE}
            smartTagInsertSubscriber={SMART_TAG_INSERT}
            smartTagCloseSubscriber={SMART_TAG_CLOSE}
            quickInsertEnabled={quickInsertEnabled}
            onChange={isCodeViewActive ? handleCodeViewChange : handleTextViewChange}
            onBlur={handleContentSave}
            onSave={handleContentSave}
            onCodeViewToggle={setIsCodeViewActive}
            onUploadImageButtonClick={handleImageUploadClick}
            onInsertSmartTagButtonClick={handleInsertSmartTagClick}
            aiEditorOptions={aiEditorOptions}
            onAIStatusChanged={onAIStatusChanged}
          />

          <CourseFileUploadModal
            isOpen={isCourseFileUploadModalOpen}
            courseId={courseId}
            onClose={(): void => setCourseFileUploadModalOpen(false)}
          />

          {hasSmartTags && isSmartTagsModalOpen && (
            <SmartTagsModal
              isOpen={isSmartTagsModalOpen}
              options={smartTagsSelectOptions}
              onClose={(): void => setSmartTagsModalOpen(false)}
            />
          )}
        </>
      )}
    </section>
  );
};

export default React.memo(EditableContent);
