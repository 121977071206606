import { css } from "@emotion/react";

export const LearningPathRulesStyles = css`
  .learning-path-content-container {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    & > div:first-of-type {
      width: 100%;
    }
  }

  ul {
    margin: 1rem 0 0;
  }
`;

export const addNewLearningPathContentStyles = css`
  margin-bottom: 0;
`;
