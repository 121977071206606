// Packages or third-party libraries
import React, { FC, useEffect, useState } from "react";
import { Button, Grid, InputError, Select } from "@epignosis_llc/gnosis";
import { Controller, UseFormReturn } from "react-hook-form";
import classNames from "classnames";

// Components
import UnitWeights from "./UnitWeights";

// Utils, hooks
import { getScoreRuleOptions, getScoreUnitsFromOptions, getSpecificUnitsOptions } from "../helpers";
import { useApplyTranslations } from "@hooks";

// Other imports
import { ScoreRule } from "types/entities/Courses";
import { SelectOption } from "types/common";
import { MyUnit } from "types/entities";
import { CourseRulesFormData, ScoreUnit } from "@views/CourseEdit/types";

type ScoreRulesProps = {
  form: UseFormReturn<CourseRulesFormData>;
  units: MyUnit[];
};

const ScoreRules: FC<ScoreRulesProps> = ({ form, units }) => {
  const { t } = useApplyTranslations();
  const { control, watch, setValue } = form;
  const scoreRuleWatch = watch("score_rule");
  const isSpecificUnitsSelected = scoreRuleWatch === ScoreRule.SpecificUnits;
  const scoreRuleOptions = getScoreRuleOptions(units);
  const specificUnitsOptions = getSpecificUnitsOptions(units);
  const scoreUnits = watch("score_units") ?? [];
  const canSetUnitWeights = scoreUnits?.length > 1;

  const [showUnitWeights, setShowUnitWeights] = useState(false);
  const setUnitWeightsBtnClassNames = classNames({ "is-pressed": showUnitWeights });

  const handleWeightsChange = (newScoreUnits: ScoreUnit[]): void => {
    setValue("score_units", newScoreUnits);
  };

  // Hide set weights section when selected unit are less than one
  useEffect(() => {
    if (!canSetUnitWeights) setShowUnitWeights(false);
  }, [canSetUnitWeights]);

  return (
    <Grid templateColumns={[1, 1, 1, 2]} rowGap={1} columnGap={1} className="grid-container">
      <Grid.Item colSpan={[1, 1, 1, 2]} className="half-column">
        <Controller
          name="score_rule"
          control={control}
          render={({ field: { onChange, value } }): JSX.Element => {
            const selectedValue = scoreRuleOptions.find((option) => option.value === value);

            return (
              <Select
                id="completion-rule"
                value={selectedValue}
                options={scoreRuleOptions}
                label={t("courseEdit.scoreRules.calculateScoreBy")}
                aria-label={t("courseEdit.scoreRules.calculateScoreBy")}
                onChange={(option): void => {
                  const { value } = option as SelectOption;
                  onChange(value);

                  // Reset fields when score rule changes
                  setValue("score_units", undefined);
                }}
              />
            );
          }}
        />
      </Grid.Item>

      {isSpecificUnitsSelected && (
        <Grid.Item colSpan={[1, 1, 1, 2]} className="half-column">
          <Controller
            name="score_units"
            control={control}
            render={({
              field: { onChange, value: scoreUnits },
              fieldState: { error },
            }): JSX.Element => {
              const selectedIds = scoreUnits ? scoreUnits.map(({ id }) => id.toString()) : [];
              const selectedValues = scoreUnits
                ? specificUnitsOptions.filter((option) => selectedIds.includes(option.value))
                : [];

              return (
                <>
                  <Select
                    id="score-units"
                    value={selectedValues}
                    options={specificUnitsOptions}
                    label={t("general.units")}
                    aria-label={t("general.units")}
                    placeholder={t("general.selectUnits", { count: 2 })}
                    isSearchable
                    isMulti
                    onChange={(options): void => {
                      const selectedOptions = options as SelectOption[];

                      const newScoreUnits = getScoreUnitsFromOptions(
                        scoreUnits ?? [],
                        selectedOptions,
                      );

                      onChange(newScoreUnits);
                    }}
                  />
                  {error && <InputError>{error.message}</InputError>}
                </>
              );
            }}
          />
        </Grid.Item>
      )}

      {canSetUnitWeights && (
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <Button
            variant="ghost"
            noGutters
            className={setUnitWeightsBtnClassNames}
            onClick={(): void => setShowUnitWeights(true)}
          >
            {t("courseEdit.setUnitWeights")}
          </Button>
        </Grid.Item>
      )}

      {showUnitWeights && (
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <UnitWeights
            scoreUnits={scoreUnits}
            specificUnitsOptions={specificUnitsOptions}
            onChange={handleWeightsChange}
            onClose={(): void => setShowUnitWeights(false)}
          />
        </Grid.Item>
      )}
    </Grid>
  );
};

export default ScoreRules;
