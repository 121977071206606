import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";

import CardPrice from "./CardPrice";

import { catalogCardFooterStyles } from "./styles";

import { Course, OtherProvidersCourses } from "types/entities/Courses";
import { useApplyTranslations } from "@hooks";

export type CourseStoreCardFooterProps = {
  course: Course | OtherProvidersCourses;
  isTalentLibrary?: boolean;
};

const CourseStoreCardFooter: FC<CourseStoreCardFooterProps> = ({
  course,
  isTalentLibrary = false,
}) => {
  const { t } = useApplyTranslations();
  const { remaining_licenses = 0, price, discounted_price } = course ?? {};
  const hasPrice = price && price.amount !== 0;

  if (isTalentLibrary) return null;

  if (remaining_licenses === 0 && !hasPrice) return null;

  return (
    <div css={catalogCardFooterStyles}>
      {remaining_licenses && remaining_licenses > 0 ? (
        <Text fontSize={"sm"} className="footer-info" as="div">
          {t("courseCatalog.licenses", { count: remaining_licenses })}
        </Text>
      ) : (
        <div />
      )}

      {hasPrice && <CardPrice price={price} discountedPrice={discounted_price} />}
    </div>
  );
};

export default CourseStoreCardFooter;
