import React, { FC } from "react";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { categoryTextStyles } from "./styles";
import { SerializedStyles } from "@emotion/react";

type CategoryTextProps = {
  categoryText: string;
  allCategoryParent: string[];
  canEditOptions?: boolean;
  onClick?: () => void;
};

const NO_DISPLAYED_CATEGORY = 2;

const CategoryText: FC<CategoryTextProps> = ({
  categoryText,
  allCategoryParent,
  canEditOptions,
  onClick,
}) => {
  const displayTooltip = allCategoryParent.length > NO_DISPLAYED_CATEGORY;
  const reverted = [...allCategoryParent].reverse();
  const tooltipText = reverted.join(" / ");

  return (
    <div
      css={(theme): SerializedStyles => categoryTextStyles(theme, { canEditOptions })}
      onClick={onClick}
    >
      <Tooltip content={tooltipText} disabled={!displayTooltip}>
        <Text fontSize="sm" className="category" data-testid="course-edit-category">
          {categoryText}
        </Text>
      </Tooltip>
    </div>
  );
};

export default CategoryText;
