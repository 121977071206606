import { css } from "@emotion/react";

export const centeredPanel = ({ upsell }) => css`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 62.5rem;
  gap: 4rem;

  .body p {
    font-size: 1rem;
    color: ${upsell.description};
  }

  footer {
    text-align: center;
    .description {
      margin-bottom: 1.5rem;
    }
    .link,
    .description a {
      display: block;
      width: fit-content;
      margin: 0 auto;
      color: ${upsell.link};

      &:hover {
        color: ${upsell.linkHover};
      }
    }
    .note {
      text-align: center;
      color: ${upsell.note};
      margin-bottom: 4rem;
    }
  }
`;
